import { IconButton, ImageList, ImageListItem, ImageListItemBar, makeStyles, Tooltip } from '@material-ui/core'
import { Add, CheckCircle, Delete } from '@material-ui/icons'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'
import { Image } from '../../types/properties'

type Props = {
  previewHeight?: number
  previewCols?: number
  error?: boolean
  onChange: (images: Image[] | []) => void
  images: Image[]
  disabled?: boolean
}

type StyleProps = Pick<Props, 'error'>

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FCF6C3',
    border: ({ error }: StyleProps) => `2px dashed ${error ? '#F44336' : '#E4D126'}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '75px',
    padding: '5px',
    paddingTop: 0
  },
  removeIcon: {
    color: 'rgba(255, 255, 255, 0.65)',
    padding: '2px'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    marginTop: '5px'
  },
  addIcon: {
    marginTop: '5px',
    marginBottom: '10px'
  },
  listItemRoot: {
    height: 'auto'
  },
  item: {
    borderRadius: '5px'
  },
  errorContainer: {
    color: theme.palette.error.main,
    fontSize: '0.7em'
  }
}))

export const ImagesUpload = ({
  error,
  onChange,
  images,
  disabled,
  previewHeight = 90,
  previewCols = 3
}: Props): JSX.Element => {
  const classes = useStyles({ error })
  const isMounted = useRef(false)
  const [localImages, setLocalImages] = useState<Image[]>(images)

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onload = (e) => {
        const id = uuidv4()
        const name = file.name
        const src = (e.target as FileReader).result as string

        setLocalImages((prevImages) => [...prevImages, { id, name, src, size: file.size }])
      }

      reader.readAsDataURL(file)
    })
  }, [])

  const removeImage = (id: Image['id']) => {
    setLocalImages((prevImages) => prevImages.filter((img) => img.id !== id))
  }

  const moveItemToFirst = (index: number) => {
    setLocalImages((prevImages) => {
      const newImages = [...prevImages]
      newImages.unshift(newImages.splice(index, 1)[0])
      return newImages
    })
  }

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: { 'image/*': ['.png', '.jpeg', '.jpg', '.bmp', '.avif', '.tiff', '.webp', '.gif'] },
    useFsAccessApi: false,
    noClick: true
  })

  useEffect(() => {
    // `isMounted` avoid calling `onChange` when the component first mounts
    if (isMounted.current) {
      onChange(localImages)
    } else {
      isMounted.current = true
    }
  }, [localImages])

  return (
    <div {...getRootProps({ 'aria-label': 'drag and drop area' })} className={classes.container}>
      <label htmlFor="file-uploader" className={classes.label}>
        Drag and drop images here, or click below to choose
      </label>
      <IconButton className={classes.addIcon} size="small" disabled={disabled} onClick={open}>
        <Add />
      </IconButton>
      <input {...getInputProps({ id: 'file-uploader' })} />
      <ImageList rowHeight={previewHeight} cols={previewCols}>
        {localImages.length > 0 &&
          localImages.map(({ id, src, name }, i) => (
            <ImageListItem key={id} data-testid="image-preview" classes={{ item: classes.item }}>
              <img src={src} alt={name} />
              <ImageListItemBar
                classes={{ root: classes.listItemRoot }}
                subtitle={name}
                actionIcon={
                  <div style={{ display: 'flex' }}>
                    <Tooltip title="Remove image">
                      <span>
                        <IconButton
                          aria-label={`remove image ${name}`}
                          className={classes.removeIcon}
                          disabled={disabled}
                          onClick={() => removeImage(id)}
                        >
                          <Delete />
                        </IconButton>
                      </span>
                    </Tooltip>
                    <Tooltip title="Mark as the promo image">
                      <span>
                        <IconButton
                          aria-label={`mark ${name} as the promo `}
                          className={classes.removeIcon}
                          disabled={disabled}
                          onClick={() => moveItemToFirst(i)}
                        >
                          <CheckCircle />
                        </IconButton>
                      </span>
                    </Tooltip>
                  </div>
                }
              />
            </ImageListItem>
          ))}
      </ImageList>
    </div>
  )
}
