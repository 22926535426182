import { Moment } from 'moment'
import { PropertyDetails } from './properties'

export type SingleBookingDetailType = {
  timestamp: string
  booking_date: string
  booking_time: string
  status: BookingStatus
  created_at: string
  updated_at: string
  requester: string
  booked_by: BookingBookedByType
  tenant_feedback: null
  landlord_feedback: null
  contact_number?: string
  agent_name?: string
  address: string
  url?: string
  booking_id: string
  property_title: string
  property_currency_code: string
  property_monthly_rent: number
  property_bedrooms: number
  property_rooms_available: number
  property_bathrooms: number
  property: PropertyDetails
  human_readable_time: null
  property_featured_image: string
  linked_property_third_party_id?: string
  timezone?: string
  type: string
  note: string
  title?: string
}

export type BookingBookedByType = {
  name: string
  user_type: string
  status: string
  user_verification: null
  profile_image_url: string | null
  time_zone: string
  viewing_days: null
  booking_times: null
  stage: string
  company_id: null
  has_paid: boolean
  origin_country: null
  origin_city: null
  destination_country: null
  destination_city: null
  latest_nps_score: null
  latest_feedback: null
  latest_search_filters: string
  user_id: string
}

export type BookingModalDetailsType = {
  agent_name?: string
  contact_number?: string
  url?: string
  property_id: string
  address: string
  tz: { selectValue: string; displayValue: string }
  dateTime: string | Moment
  booking_id: string
  type: string
  note: string
  title?: string
}

export enum BookingStatus {
  CANCELLED = 'cancelled',
  APPROVED = 'approved',
  CONFIRMED = 'confirmed'
}
