import { ChangeEvent, FormEvent, ReactElement, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AppBar, Button, Dialog, IconButton, TextField, Toolbar, Typography } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Transition } from '..'
import { getDocumentState } from '../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { urlPattern } from '../../utils/string'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { RESET_DOCUMENTS_STATUS, SHOW_FEEDBACK, UPDATE_DOCUMENTS_REQUEST } from '../../redux/constants'
import { documentFields } from '../../utils/documentFields'

type UpdateDocumentLinksProps = {
  openModal: boolean
  setOpenModal: () => void
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: 20,
    flex: 1
  },
  modalContainer: {
    display: 'grid',
    gap: 10,
    gridTemplateColumns: 'repeat(2, minmax(320px, 1fr))',
    padding: '2.5rem 5rem',
    [theme.breakpoints.down('sm')]: {
      padding: '2.5rem 3rem'
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(1, minmax(320px, 1fr))'
    }
  },
  field: {
    width: '100%'
  }
}))

export const UpdateDocumentLinks = ({ openModal, setOpenModal }: UpdateDocumentLinksProps): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { documents, updateDocumentsRequested, status } = useSelector(getDocumentState)
  const { customer } = useInfoByMessageId()

  const [documentLinks, setDocumentLinks] = useState({
    document_center_link: documents?.document_center_link || '',
    accountancy_link: documents?.accountancy_link || '',
    banking_link: documents?.banking_link || '',
    car_rental_link: documents?.car_rental_link || '',
    check_in_form_link: documents?.check_in_form_link || '',
    check_out_form_link: documents?.check_out_form_link || '',
    check_out_guide_link: documents?.check_out_guide_link || '',
    city_feed_link: documents?.city_feed_link || '',
    community_link: documents?.community_link || '',
    coworking_link: documents?.coworking_link || '',
    cultural_and_language_link: documents?.cultural_and_language_link || '',
    driving_licence_link: documents?.driving_licence_link || '',
    emergency_link: documents?.emergency_link || '',
    escorted_viewings_link: documents?.escorted_viewings_link || '',
    featured_link: documents?.featured_link || '',
    for_you_link: documents?.for_you_link || '',
    furniture_link: documents?.furniture_link || '',
    guided_tour_link: documents?.guided_tour_link || '',
    health_care_link: documents?.health_care_link || '',
    how_it_works_link: documents?.how_it_works_link || '',
    inspection_form_link: documents?.inspection_form_link || '',
    insurance_link: documents?.insurance_link || '',
    inventory_form_link: documents?.inventory_form_link || '',
    key_contract_terms_link: documents?.key_contract_terms_link || '',
    lease_info_link: documents?.lease_info_link || '',
    mobile_phone_link: documents?.mobile_phone_link || '',
    mover_checklist_link: documents?.mover_checklist_link || '',
    mover_timeline_link: documents?.mover_timeline_link || '',
    moving_and_removals_link: documents?.moving_and_removals_link || '',
    neighborhood_safety_link: documents?.neighborhood_safety_link || '',
    new_feature_drop_link: documents?.new_feature_drop_link || '',
    our_packages_link: documents?.our_packages_link || '',
    our_work_giving_back_link: documents?.our_work_giving_back_link || '',
    partner_spotlight_link: documents?.partner_spotlight_link || '',
    partner_support_link: documents?.partner_support_link || '',
    pay_as_you_go_link: documents?.pay_as_you_go_link || '',
    payments_due_link: documents?.payments_due_link || '',
    pet_relocation_link: documents?.pet_relocation_link || '',
    perchspeak_link: documents?.perchspeak_link || '',
    property_maintenance_link: documents?.property_maintenance_link || '',
    registration_link: documents?.registration_link || '',
    school_search_link: documents?.school_search_link || '',
    shipping_link: documents?.shipping_link || '',
    short_term_stay_link: documents?.short_term_stay_link || '',
    utility_connection_link: documents?.utility_connection_link || '',
    viewing_guide_link: documents?.viewing_guide_link || '',
    visa_and_immigration_link: documents?.visa_and_immigration_link || '',
    welcome_pack_link: documents?.welcome_pack_link || ''
  })

  useEffect(() => {
    if (!!status) {
      let payload = { severity: 'success', title: status }
      if (status === 'Documents Updating Failed') {
        payload = { severity: 'error', title: 'Failed! Please try again' }
      }
      dispatch({ type: SHOW_FEEDBACK, payload })
      dispatch({ type: RESET_DOCUMENTS_STATUS })
    }
  }, [status])

  const detailsOnChangeHandle = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.name) {
      setDocumentLinks({ ...documentLinks, [event.target.name]: event.target.value })
    }
  }

  const handleLinksUpdate = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (customer) {
      dispatch({
        type: UPDATE_DOCUMENTS_REQUEST,
        payload: { userId: customer.linked_user_id, documentLinks }
      })
    }
  }

  return (
    <Dialog data-testid="doc-update-dialog-container" open={openModal} fullScreen TransitionComponent={Transition}>
      <form onSubmit={handleLinksUpdate}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={setOpenModal}
              aria-label="close"
              data-testid="documents-modal-close-btn"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Update Links
            </Typography>
            <Button
              disabled={updateDocumentsRequested}
              color="inherit"
              type="submit"
              data-testid="documents-form-submit-btn"
              data-track-click="document-links-update"
            >
              Update
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.modalContainer}>
          {documentFields.map((field) => (
            <div key={field.id} style={{ width: '75%' }}>
              <Typography variant="h6" style={{ fontSize: '1.2rem' }}>
                {field.title}
              </Typography>
              <TextField
                className={classes.field}
                variant="outlined"
                disabled={updateDocumentsRequested}
                name={field.id}
                onChange={detailsOnChangeHandle}
                value={documentLinks[field.id]}
                inputProps={{ pattern: urlPattern, 'data-testid': `${field.id}-textfield` }}
              />
            </div>
          ))}
        </div>
      </form>
    </Dialog>
  )
}
