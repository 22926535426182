import { ReactElement, useState, useEffect, forwardRef, Ref, FormEvent, ChangeEvent } from 'react'
import {
  Dialog,
  IconButton,
  Typography,
  AppBar,
  Toolbar,
  Button,
  Slide,
  TextField,
  FormControl,
  InputLabel,
  OutlinedInput,
  Select,
  MenuItem
} from '@material-ui/core'
import Autocomplete, { AutocompleteRenderInputParams } from '@material-ui/lab/Autocomplete'
import { TransitionProps } from '@material-ui/core/transitions'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import moment, { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'

import { timezones } from '../../utils/timezones'
import { CLEAR_STORED_BOOKING_LIST, FETCH_BOOKINGS_REQUEST, SHOW_FEEDBACK } from '../../redux/constants'
import { getBookingState } from '../../redux/selectors'
import { Loader } from '..'
import { BookingModalDetailsType } from '../../types/bookings'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { urlPattern } from '../../utils/string'
import { bookingTypes } from '../../utils/constants'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../hooks/featureFlags'

type Props = {
  open: boolean
  setOpen: () => void
  type: string
  details: BookingModalDetailsType
  modalAction: (values: bookingDetailsToSend, type?: string) => void
}

type StyleProps = { type: string }

type dateTimeLocalStateType = MaterialUiPickersDate | string | null

type bookingDetailsToSend = {
  address: string
  agent_name?: string
  booking_date: string
  booking_id?: string
  booking_time: string
  contact_number?: string
  dateTime?: string | Moment
  note: string
  property_id: string
  title?: string
  type: string
  tz: string
  url?: string
}

const useStyles = makeStyles((theme) => ({
  dialogBodyContainer: {
    padding: '25px',
    height: '100%',
    width: '75%',
    margin: '0 auto'
  },
  closeBtn: {
    position: 'absolute',
    right: '10px'
  },
  title: {
    marginLeft: 20,
    flex: 1
  },
  rowContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginTop: 20,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  fieldTitle: {
    padding: '15px 0 10px'
  },
  field: {
    width: 524,
    [theme.breakpoints.down('xs')]: {
      width: 324
    }
  },
  menuPaper: {
    height: 200
  },
  propertyIdField: {
    borderRadius: 0,
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    [theme.breakpoints.down('xs')]: {
      width: 324
    }
  },
  propertyIdFieldBg: {
    backgroundColor: ({ type }: StyleProps) => (type !== 'property_visits' ? '#D3D3D3' : '#FFF')
  },
  defaultLink: {
    backgroundColor: 'lightgrey',
    fontSize: 16,
    fontWeight: 500,
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    height: 56,
    lineHeight: '56px',
    padding: '0 10px',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  displayFlex: {
    display: 'flex'
  },
  loaderContainer: {
    width: 200,
    height: 200
  }
}))

export const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: ReactElement },
  ref: Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export const BookingModal = ({ open, setOpen, type, details, modalAction }: Props): ReactElement => {
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const { addBookingsRequested, editBookingsRequested, status } = useSelector(getBookingState)

  const [bookingModified, setBookingModified] = useState<boolean>(false)
  const [bookingDetails, setBookingDetails] = useState<BookingModalDetailsType>(details)
  const [dateTimeLocalState, setDateTimeLocalState] = useState<dateTimeLocalStateType>(null)
  const [dateTimeError, setDateTimeError] = useState<boolean>(false)
  const classes = useStyles({ type: bookingDetails.type })

  const isSelectAppointmentTypeEnabled = useFeatureToggle(FeatureFlag.SELECT_APPOINTMENT_TYPE)

  const loading = Boolean(addBookingsRequested || editBookingsRequested)

  const actionLabel = type === 'update' || type === 'convert' ? 'update' : 'create'

  const isDateInPast = (dateTime: MaterialUiPickersDate | dateTimeLocalStateType) =>
    moment(dateTime).isBefore(moment(), 'day')

  const handleDateChange = (dateTime: MaterialUiPickersDate) => {
    setDateTimeLocalState(dateTime)
    if (dateTimeError && !isDateInPast(dateTime)) setDateTimeError(false)
  }

  const dateTimeConversion = () => {
    return { date: moment(dateTimeLocalState).format('YYYY-MM-DD'), time: moment(dateTimeLocalState).format('HH:mm') }
  }

  const bookingModalSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (!isDateInPast(dateTimeLocalState)) {
      const dateTimeConverted = dateTimeConversion()
      const bookingDetailsToSend: bookingDetailsToSend = {
        ...bookingDetails,
        booking_date: dateTimeConverted.date,
        booking_time: dateTimeConverted.time,
        tz: bookingDetails.tz.selectValue
      }
      delete bookingDetailsToSend.booking_id
      delete bookingDetailsToSend.dateTime
      modalAction(bookingDetailsToSend, type)
      setBookingModified(true)
    } else {
      setDateTimeError(true)
    }
  }

  useEffect(() => {
    setBookingDetails(details)
    setDateTimeLocalState(moment(details.dateTime, 'YYYY-M-D H:m'))
  }, [details])

  useEffect(() => {
    if (
      (status === 'Booking added' || status === 'Booking Edited' || status === 'Booking Converted') &&
      bookingModified
    ) {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: status, severity: 'success' } })
      closeBookingModal()
    } else if ((status === 'Booking addition failed' || status === 'Booking Edit failed') && bookingModified) {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  }, [status])

  const closeBookingModal = () => {
    setBookingModified(false)
    if (customer && bookingModified && (type === 'create' || type === 'convert')) {
      dispatch({ type: CLEAR_STORED_BOOKING_LIST })
      dispatch({
        type: FETCH_BOOKINGS_REQUEST,
        payload: { userId: customer.linked_user_id, currentPage: 0 }
      })
    }
    setOpen()
  }

  const detailsOnChangeHandle = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.name) {
      setBookingDetails({ ...bookingDetails, [event.target.name]: event.target.value })
    }
  }

  return (
    <Dialog
      data-testid="booking-modal-dialog"
      fullScreen
      open={open}
      onClose={closeBookingModal}
      TransitionComponent={Transition}
    >
      <form onSubmit={bookingModalSubmit} data-testid="create-booking-form">
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={closeBookingModal}
              aria-label="close"
              data-testid="booking-modal-close-btn"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              {type === 'update' || type === 'convert' ? 'Update' : 'Create'} Appointment
            </Typography>
            <Button
              color="inherit"
              type="submit"
              disabled={loading}
              data-testid="booking-form-submit-btn"
              data-track-click={`booking-${actionLabel}`}
            >
              {actionLabel}
            </Button>
          </Toolbar>
        </AppBar>
        <div className={classes.dialogBodyContainer}>
          <div>
            <div className={classes.rowContainer}>
              {/* booking type selection hidden for POC phase 1 release */}
              {isSelectAppointmentTypeEnabled && (
                <div>
                  <Typography variant="subtitle2" className={classes.fieldTitle}>
                    Appointment Type
                  </Typography>
                  <FormControl variant="outlined" className={classes.field}>
                    <InputLabel id="demo-simple-select-outlined-label">Type*</InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={bookingDetails.type}
                      onChange={detailsOnChangeHandle}
                      label="Type*"
                      name="type"
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                      disabled={type === 'convert'}
                    >
                      {bookingTypes.map((type) => {
                        return (
                          <MenuItem value={type.value} key={type.value}>
                            {type.title}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </div>
              )}
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Title
                </Typography>
                <TextField
                  name="title"
                  variant="outlined"
                  label="Enter title"
                  className={classes.field}
                  value={bookingDetails.title?.substring(0, 190) ?? ''}
                  inputProps={{ maxLength: 190 }}
                  onChange={detailsOnChangeHandle}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Contact Name
                </Typography>
                <TextField
                  name="agent_name"
                  variant="outlined"
                  label="Enter Name"
                  value={bookingDetails.agent_name}
                  onChange={detailsOnChangeHandle}
                  className={classes.field}
                  inputProps={{ maxLength: 190, 'data-testid': 'agent-name-input' }}
                />
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Contact Number
                </Typography>
                <TextField
                  name="contact_number"
                  variant="outlined"
                  label="Enter number"
                  inputProps={{ pattern: '^\\+?[0-9]{5,15}$', 'data-testid': 'agent-contact-number-input' }}
                  className={classes.field}
                  value={bookingDetails.contact_number}
                  helperText="A valid phone number contains between 5 to 15 digits"
                  onChange={detailsOnChangeHandle}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Date and Time
                </Typography>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DateTimePicker
                    name="dateTime"
                    ampm={false}
                    showTodayButton
                    error={dateTimeError}
                    helperText={dateTimeError ? 'Date should not be a past date' : null}
                    disablePast
                    inputVariant="outlined"
                    emptyLabel="Select Date and Time*"
                    value={dateTimeLocalState}
                    onChange={handleDateChange}
                    className={classes.field}
                    format="dddd DD/MM/YYYY HH:mm"
                    inputProps={{ 'data-testid': 'date-time-input' }}
                  />
                </MuiPickersUtilsProvider>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Timezone
                </Typography>
                <Autocomplete
                  data-testid="time-zone-autocomplete"
                  disableClearable
                  classes={{ root: classes.field }}
                  options={timezones}
                  getOptionLabel={(timezone: { selectValue: string; displayValue: string }) => timezone.displayValue}
                  value={bookingDetails.tz}
                  onChange={(event, value) => setBookingDetails({ ...bookingDetails, tz: value })}
                  getOptionSelected={(option, value) => option.selectValue === value.selectValue}
                  renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField
                      {...params}
                      required
                      variant="outlined"
                      label="Select Timezone"
                      className={classes.field}
                    />
                  )}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Property ID
                </Typography>
                <div className={`${classes.field} ${classes.displayFlex}`}>
                  <span className={classes.defaultLink}>https://www.perchpeek.com/properties/</span>
                  <FormControl variant="outlined">
                    <InputLabel htmlFor="property_id">ID</InputLabel>
                    <OutlinedInput
                      inputProps={{ pattern: '([a-zA-Z0-9]{6,})', 'data-testid': 'property-id-input' }}
                      name="property_id"
                      label="ID"
                      className={classes.propertyIdFieldBg}
                      required={bookingDetails.type === 'property_visits'}
                      disabled={bookingDetails.type !== 'property_visits'}
                      classes={{ root: classes.propertyIdField }}
                      value={bookingDetails.property_id}
                      onChange={detailsOnChangeHandle}
                    />
                  </FormControl>
                </div>
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  URL
                </Typography>
                <TextField
                  inputProps={{
                    pattern: urlPattern,
                    'data-testid': 'google-map-url-input'
                  }}
                  name="url"
                  variant="outlined"
                  label="Enter URL"
                  className={classes.field}
                  value={bookingDetails.url}
                  onChange={detailsOnChangeHandle}
                />
              </div>
            </div>
            <div className={classes.rowContainer}>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Address
                </Typography>
                <TextField
                  inputProps={{ 'data-testid': 'address-input' }}
                  name="address"
                  variant="outlined"
                  required
                  label="Enter Address"
                  multiline
                  className={classes.field}
                  value={bookingDetails.address}
                  onChange={detailsOnChangeHandle}
                />
              </div>
              <div>
                <Typography variant="subtitle2" className={classes.fieldTitle}>
                  Note
                </Typography>
                <TextField
                  inputProps={{ 'data-testid': 'note-input' }}
                  name="note"
                  variant="outlined"
                  label="Note"
                  multiline
                  className={classes.field}
                  value={bookingDetails.note}
                  onChange={detailsOnChangeHandle}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
      <Dialog open={loading} data-testid="loading-dialog">
        <div className={classes.loaderContainer}>
          <Loader />
        </div>
      </Dialog>
    </Dialog>
  )
}
