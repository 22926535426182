import {
  FormControlLabel,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
  Tooltip
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { ChangeEventHandler, FocusEventHandler, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { Image } from '../../types/properties'
import { urlPattern } from '../../utils/string'
import { ImagesUpload } from '../ImagesUpload'

const previewHeight = 90
const previewCols = 3
const URL = 'URL'
const UPLOAD = 'Upload'

const useStyles = makeStyles(() => ({
  removeIcon: {
    color: 'rgba(255, 255, 255, 0.65)',
    padding: '2px'
  },
  listItemRoot: {
    height: 'auto'
  },
  item: {
    borderRadius: '5px'
  }
}))

type Props = {
  label: string
  disabled?: boolean
  error?: boolean
  helperText?: string
  images: Image[]
  onChange: (images: Image[] | []) => void
}

export const ImagesField = ({ label, disabled, error, helperText, images, onChange }: Props): JSX.Element => {
  const classes = useStyles({ error })
  const [imageOption, setImageOption] = useState(images.length > 1 ? UPLOAD : URL)
  const isWithUrl = imageOption === URL
  const errorText = (() => {
    if (!error) {
      return ' '
    }
    if (isWithUrl) {
      return 'Please enter a valid image URL'
    }
    if (images.length === 0) {
      return 'Please upload at least one image'
    }
    return helperText
  })()

  const [firstImage] = images

  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setImageOption(event.target.value)
  }

  const handleOnBlur: FocusEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    if (!RegExp(urlPattern).test(value as string)) {
      onChange([])
    } else {
      onChange([...images, { id: uuidv4(), name: value, src: value }])
    }
  }

  const handleRemoveImage = (id: Image['id']) => {
    onChange(images.filter((img) => img.id !== id))
  }

  const handleOnError = () => {
    onChange([])
  }

  return (
    <>
      <RadioGroup aria-label="choose how to upload images" value={imageOption} row onChange={handleOnChange}>
        <FormControlLabel value={URL} label={URL} disabled={disabled} control={<Radio />} />
        <FormControlLabel value={UPLOAD} label={UPLOAD} disabled={disabled} control={<Radio />} />
      </RadioGroup>

      {!isWithUrl && (
        <ImagesUpload
          error={error}
          images={images}
          previewHeight={previewHeight}
          previewCols={previewCols}
          onChange={onChange}
          disabled={disabled}
        />
      )}

      {isWithUrl && (
        <>
          {!firstImage && (
            <TextField
              variant="outlined"
              fullWidth
              error={error}
              disabled={disabled}
              inputProps={{
                'aria-label': label
              }}
              onBlur={handleOnBlur}
            />
          )}

          {firstImage && (
            <ImageList rowHeight={previewHeight} cols={previewCols}>
              <ImageListItem key={firstImage.id} classes={{ item: classes.item }}>
                <img src={firstImage.src} alt={firstImage.name} onError={handleOnError} />
                <ImageListItemBar
                  classes={{ root: classes.listItemRoot }}
                  subtitle={firstImage.name}
                  actionIcon={
                    <div style={{ display: 'flex' }}>
                      <Tooltip title="Remove image">
                        <span>
                          <IconButton
                            aria-label={`remove image ${firstImage.name}`}
                            className={classes.removeIcon}
                            disabled={disabled}
                            onClick={() => handleRemoveImage(firstImage.id)}
                          >
                            <Delete />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </div>
                  }
                />
              </ImageListItem>
            </ImageList>
          )}
        </>
      )}

      <FormHelperText variant="outlined" error={true}>
        {errorText}
      </FormHelperText>
    </>
  )
}
