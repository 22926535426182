import {
  FETCH_BOOKINGS_REQUEST,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILURE,
  FETCH_MORE_BOOKINGS_REQUEST,
  ADD_BOOKINGS_REQUEST,
  ADD_BOOKINGS_SUCCESS,
  ADD_BOOKINGS_FAILURE,
  EDIT_BOOKINGS_REQUEST,
  EDIT_BOOKINGS_SUCCESS,
  EDIT_BOOKINGS_FAILURE,
  CANCEL_BOOKINGS_REQUEST,
  CLEAR_STORED_BOOKING_LIST,
  RESET_BOOKING_STATUS
} from '../../constants'
import { BookingState } from '../../../types/state'
import { AnyAction } from 'redux'

const initialState: BookingState = {
  addBookingsRequested: false,
  cancelBookingsRequested: false,
  editBookingsRequested: false,
  fetchBookingsRequested: true,
  fetchMoreBookingsRequested: false,
  bookings: [],
  status: undefined,
  currentPage: 1,
  lastPage: 1
}

export default (state = initialState, action: AnyAction): BookingState => {
  switch (action.type) {
    case FETCH_BOOKINGS_REQUEST:
      return {
        ...state,
        bookings: [],
        fetchBookingsRequested: true,
        status: undefined
      }

    case FETCH_BOOKINGS_SUCCESS:
      return {
        ...state,
        fetchBookingsRequested: false,
        fetchMoreBookingsRequested: false,
        cancelBookingsRequested: false,
        bookings: [...state.bookings, ...action.payload.data.data.bookings.data],
        currentPage: action.payload.data.data.bookings.meta.current_page,
        lastPage: action.payload.data.data.bookings.meta.last_page,
        status: 'Bookings fetched'
      }

    case FETCH_BOOKINGS_FAILURE:
      return {
        ...state,
        fetchBookingsRequested: false,
        fetchMoreBookingsRequested: false,
        bookings: [],
        status: 'Bookings fetching failed',
        currentPage: 1,
        lastPage: 1
      }

    case FETCH_MORE_BOOKINGS_REQUEST:
      return {
        ...state,
        fetchMoreBookingsRequested: true,
        status: undefined
      }

    case ADD_BOOKINGS_REQUEST:
      return {
        ...state,
        addBookingsRequested: true,
        status: undefined
      }

    case ADD_BOOKINGS_SUCCESS:
      return {
        ...state,
        status: 'Booking added',
        addBookingsRequested: false
      }

    case ADD_BOOKINGS_FAILURE:
      return {
        ...state,
        status: 'Booking addition failed',
        addBookingsRequested: false
      }

    case EDIT_BOOKINGS_REQUEST:
      return {
        ...state,
        editBookingsRequested: true,
        status: undefined
      }

    case EDIT_BOOKINGS_SUCCESS:
      const updatedBookingDetails = state.bookings.map((booking) => {
        if (booking.booking_id === action.payload.bookingId) {
          const newBookingDetails = {
            ...booking,
            ...action.payload.newBookingDetails,
            timezone: action.payload.newBookingDetails.tz
          }
          delete newBookingDetails.tz
          delete newBookingDetails.property_id
          return newBookingDetails
        }
        return booking
      })
      return {
        ...state,
        status: action.payload.modificationStatus,
        bookings: updatedBookingDetails,
        editBookingsRequested: false,
        cancelBookingsRequested: false
      }

    case EDIT_BOOKINGS_FAILURE:
      return {
        ...state,
        status: 'Booking Edit failed',
        editBookingsRequested: false
      }

    case CANCEL_BOOKINGS_REQUEST:
      return {
        ...state,
        cancelBookingsRequested: true,
        status: undefined
      }

    case RESET_BOOKING_STATUS:
      return {
        ...state,
        status: undefined
      }

    case CLEAR_STORED_BOOKING_LIST:
      return {
        addBookingsRequested: false,
        cancelBookingsRequested: false,
        editBookingsRequested: false,
        fetchBookingsRequested: true,
        fetchMoreBookingsRequested: false,
        bookings: [],
        status: undefined,
        currentPage: 1,
        lastPage: 1
      }
    default:
      return state
  }
}
