import moment from 'moment'
import { Chat, MessageType, MessageUser } from '../../../types/messages'
import { MessageState } from '../../../types/state'

export const getSortedUpdatedMessages = (
  messages: MessageType[] | undefined,
  messageId: string,
  newChat: Chat,
  userPresentInThread: boolean
): MessageType[] | undefined => {
  const updatedMessages = messages?.map((message: MessageType) => {
    if (message.message_id === messageId) {
      return {
        ...message,
        last_chat: newChat.chat,
        last_chat_name: newChat.name,
        last_chat_created_at: newChat.created_at,
        updated_at: newChat.created_at,
        group: message.group.map((user) => {
          if (
            user.linked_user_id === newChat.sender_user_id ||
            (userPresentInThread && (user.user_type === 'support' || user.user_type === 'va'))
          ) {
            return { ...user, updated_at: newChat.created_at }
          }
          return user
        })
      }
    }
    return message
  })
  const sortedUpdatedMessages = updatedMessages?.sort((a: MessageType, b: MessageType) =>
    moment(a.last_chat_created_at || a.created_at).isBefore(b.last_chat_created_at || b.created_at) ? 1 : -1
  )

  return sortedUpdatedMessages
}

export const getFinalMessages = (state: MessageState, messageId: string, userId: string): MessageType[] => {
  const currentTime = moment.utc().toISOString()
  return state.messages.map((message: MessageType) => {
    if (message.message_id === messageId) {
      return {
        ...message,
        group: message.group.map((user) => {
          if (user.linked_user_id === userId) {
            return { ...user, updated_at: currentTime }
          }
          return user
        })
      }
    }
    return message
  })
}

export const getHasPaidUserMessages = (
  state: MessageState,
  paidMessageId: string,
  paidUserId: string
): MessageType[] => {
  return state.messages.map((message: MessageType) => {
    if (message.message_id === paidMessageId) {
      return {
        ...message,
        group: message.group.map((user) => {
          if (user.linked_user_id === paidUserId) {
            return { ...user, user_has_paid: true }
          }
          return user
        })
      }
    }
    return message
  })
}

export const getHasPriorityUserMessages = (
  state: MessageState,
  selectedUserId: string,
  selectedMessageId: string,
  priority: string
): MessageType[] => {
  return state.messages.map((message: MessageType) => {
    if (message.message_id === selectedMessageId) {
      return {
        ...message,
        group: message.group.map((user) => {
          if (user.linked_user_id === selectedUserId) {
            return { ...user, user_priority: priority }
          }
          return user
        })
      }
    }
    return message
  })
}

export const toggleFavoriteMessage = (
  messages: MessageType[],
  message_id: string,
  toggleIsOn: boolean
): MessageType[] => {
  if (toggleIsOn) {
    return messages.filter((message) => message.message_id !== message_id)
  }

  const message = messages.find((message) => message.message_id === message_id)

  // this condition should never be truthy this is why we are throwing an error
  if (!message) throw new TypeError(`There should be a message with message_id: ${message_id}`)

  message.is_favorite = !message.is_favorite

  return messages
}

export const updateUsersInGroup = (
  messages: MessageType[],
  message_id: string,
  updatedGroupMembers: MessageUser[]
): MessageType[] => {
  const updatedData = messages.map((message) => {
    if (message.message_id === message_id) {
      return {
        ...message,
        group: [
          ...message.group.filter(
            (member) => member.user_type === 'tenant' || member.user_email === 'support@perchpeek.com'
          ),
          ...updatedGroupMembers
        ]
      }
    }
    return message
  })
  return updatedData
}
