import { ConfigureFlopFlip } from '@flopflip/react-broadcast'
import { TFlags, TAdapter } from '@flopflip/types'
import { useLaunchDarkly } from '.'

interface FeatureFlagWrapperProps {
  children: JSX.Element
  defaultFlags: TFlags
  adapter: TAdapter
}

export function FeatureFlagWrapper({ children, adapter, defaultFlags = {} }: FeatureFlagWrapperProps): JSX.Element {
  const { adapterArgs } = useLaunchDarkly()
  return (
    <ConfigureFlopFlip adapter={adapter} adapterArgs={adapterArgs} defaultFlags={defaultFlags}>
      {children}
    </ConfigureFlopFlip>
  )
}
