import { ReactElement } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core'

type PaymentAlertProps = {
  open: boolean
  handler: () => void
  onClose: () => void
  username?: string
}

export function PaymentAlert({ open, handler, onClose, username }: PaymentAlertProps): ReactElement {
  return (
    <Dialog
      data-testid="payment-alert-dialog"
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Confirm'}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Are you sure you want to mark {username} as a paid user?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button data-testid="yes-button" onClick={() => handler()} color="primary" data-track-click="paid-user">
          Yes
        </Button>
        <Button data-testid="no-button" onClick={() => onClose()} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
