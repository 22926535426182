import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getCustomerByMessageId, getSortedChatsByMessageId, getThreadByMessageId } from '../redux/selectors'
import type { ThreadChat, MessageUser, ThreadType } from '../types/messages'

type InfoByMessageId = {
  messageId: string
  thread?: ThreadType
  customer?: MessageUser
  sortedChats?: ThreadChat[]
}

export const useInfoByMessageId = (): InfoByMessageId => {
  const { messageId } = useParams<{ messageId: string }>()
  const thread = useSelector(getThreadByMessageId)(messageId)
  const customer = useSelector(getCustomerByMessageId)(messageId)
  const sortedChats = useSelector(getSortedChatsByMessageId)(messageId)

  return {
    messageId,
    thread,
    customer,
    sortedChats
  }
}
