import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FETCH_PROPERTY_REQUEST } from '../../redux/constants'
import { usePropertyById } from '../../hooks/usePropertyById'

type Content = {
  text: string
  img?: string
}

export const useContent = (chat: string): Content => {
  const dispatch = useDispatch()
  const [content, setContent] = useState<Content>({ text: '' })

  const propertyId = chat.includes('{"property_id"') ? JSON.parse(chat).property_id : undefined
  // here we may provide an undefined propertyId, but that's Ok, getPropertyById is handling it
  const property = usePropertyById(propertyId)

  useEffect(() => {
    if (propertyId) {
      // we want to handle these cases separately, see useEffect below
      return
    }

    if (chat.includes('{"document_id"')) {
      const document = JSON.parse(chat)
      setContent({ text: document.title })
      return
    }

    setContent({ text: chat })
  }, [chat])

  // only for property
  useEffect(() => {
    if (!property) {
      // this case happens when we have a reply for a chat that is not stored locally
      propertyId && dispatch({ type: FETCH_PROPERTY_REQUEST, payload: { propertyId } })
      return
    }

    const { address_line_1, address_line_2, featured_image, images } = property

    const img = featured_image?.url ?? images[0]?.url

    setContent({ text: `${address_line_1} ${address_line_2 ?? ''}`, img })
  }, [property, chat])

  return content
}
