import { ReactElement, useState } from 'react'
import { makeStyles, Dialog, DialogContent, DialogActions, Typography, Button } from '@material-ui/core'

type ConfirmationDialogProps = {
  open: boolean
  setOpen: () => void
  message: string
  onConfirm: () => void
  trackingContext: string
}

const useStyles = makeStyles(() => ({
  confirmationDialogContainer: {
    padding: 30
  }
}))

export const ConfirmationDialog = ({
  open,
  setOpen,
  message,
  onConfirm,
  trackingContext
}: ConfirmationDialogProps): ReactElement => {
  const classes = useStyles()
  const [loading, setLoading] = useState<boolean>(false)

  const handleConfirmation = () => {
    setLoading(true)
    onConfirm()
    setLoading(false)
    setOpen()
  }

  return (
    <>
      <Dialog data-testid="confirmation-dialog-container" open={open}>
        <DialogContent data-testid="confirmation-dialog-content" className={classes.confirmationDialogContainer}>
          <Typography data-testid="confirmation-dialog-message" variant="h6">
            {message}
          </Typography>
        </DialogContent>
        {!loading && (
          <DialogActions data-testid="confirmation-dialog-actions">
            <Button
              data-testid="confirmation-dialog-cancel-button"
              disabled={loading}
              onClick={() => setOpen()}
              color="primary"
            >
              Cancel
            </Button>

            <Button
              data-testid="confirmation-dialog-submit-button"
              disabled={loading}
              onClick={handleConfirmation}
              color="primary"
              data-track-click={`${trackingContext}-yes`}
            >
              Yes
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </>
  )
}
