import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getThreadState } from '../../redux/selectors'
import { UploadedFile } from '../../types/properties'
import { Dialog, DialogActions, Paper, Button, makeStyles, DialogTitle, Typography } from '@material-ui/core'
import { FileUpload } from '../FileUpload'

import { SEND_NEW_CHAT_DOCUMENT_REQUEST } from '../../redux/constants'

interface Props {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  dialogContainer: {
    width: '100%',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px 0 ${theme.spacing(3)}px`
  },
  fileUploadDialog: {
    flexGrow: 1,
    maxWidth: 450
  },
  actions: {
    justifyContent: 'unset',
    padding: `${theme.spacing(3)}px ${theme.spacing(3)}px ${theme.spacing(3)}px 0`
  }
}))

export const ChatFileUploadDialog = ({ open, onClose }: Props): JSX.Element => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    user: {
      info: { linked_user_id, user_name }
    }
  } = useSelector(getThreadState)
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([])

  const acceptedFileTypes = {
    'image/*': ['.png', '.jpeg', '.jpg', '.bmp', '.avif', '.tiff', '.webp', '.gif'],
    'application/pdf': [],
    'video/*': ['.mp4']
  }

  const onUploadFiles = (files: UploadedFile[]) => {
    setUploadedFiles((prevFiles) => [...prevFiles, ...files])
  }

  const onRemoveFile = (fileId: UploadedFile['id']) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId))
  }

  const onClickSend = () => {
    dispatch({
      type: SEND_NEW_CHAT_DOCUMENT_REQUEST,
      payload: {
        file: uploadedFiles[0],
        moverID: linked_user_id,
        chat: { user: { name: user_name, user_id: linked_user_id } }
      }
    })
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.fileUploadDialog
      }}
      aria-labelledby="dialog-title"
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" id="dialog-title">
          Upload File
        </Typography>
      </DialogTitle>
      <Paper className={classes.dialogContainer}>
        <FileUpload
          files={uploadedFiles}
          onChange={onUploadFiles}
          onRemoveFile={onRemoveFile}
          maxFiles={1}
          acceptedFileTypes={acceptedFileTypes}
        />
        <DialogActions className={classes.actions}>
          <Button color="primary" onClick={onClickSend} variant="contained" disabled={!uploadedFiles.length}>
            Send
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </DialogActions>
      </Paper>
    </Dialog>
  )
}
