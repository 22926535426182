import { useTheme } from '@material-ui/core'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export enum ViewMode {
  MOBILE = 1,
  DESKTOP
}

export const useViewMode = (): ViewMode => {
  const { breakpoints } = useTheme()
  const mobile = useMediaQuery(breakpoints.down('sm'))

  if (mobile) {
    return ViewMode.MOBILE
  }

  return ViewMode.DESKTOP
}
