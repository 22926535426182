import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button, { ButtonProps } from '@material-ui/core/Button'

type TaskProps = {
  active?: boolean
  label: string
  handler: () => void
  startIcon?: ButtonProps['startIcon']
  noPadding?: boolean
}

const useStyles = makeStyles(({ palette, breakpoints }) => ({
  navButton: {
    [breakpoints.down('sm')]: {
      display: 'none'
    },
    height: '100%',
    backgroundColor: ({ active }: Partial<TaskProps>) => (active ? palette.primary.dark : palette.primary.main),
    borderRadius: 0,
    padding: ({ noPadding }: Partial<TaskProps>) => (noPadding ? 0 : '0 30px'),
    color: palette.primary.contrastText
  }
}))

export function HeaderNavButton({ active, label, handler, startIcon, noPadding }: TaskProps): ReactElement {
  const classes = useStyles({ active, noPadding })

  return (
    <Button onClick={handler} size="small" className={classes.navButton} startIcon={startIcon}>
      {label}
    </Button>
  )
}
