import { LOGIN_USER_SUCCESS, LOGIN_USER_FAILURE, FETCH_USER_SUCCESS, FETCH_USER_REQUEST } from '../../constants'
import { AuthState } from '../../../types/state'
import { AnyAction } from 'redux'

const initialState: AuthState = {
  currentUser: undefined,
  loginFailed: false,
  token: undefined,
  status: undefined,
  userType: undefined
}

export default (state = initialState, action: AnyAction): AuthState => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
      const { token, userType, getstreamUserId, getstreamUserToken } = action.payload
      return {
        ...state,
        token,
        userType,
        getstreamUserId,
        getstreamUserToken
      }
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        loginFailed: true
      }
    case FETCH_USER_REQUEST:
      return {
        ...state,
        token: action.payload.token
      }
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.data.user,
        status: action.payload.status
      }
    default:
      return state
  }
}
