import { Check, Schedule } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

type styleProps = { isRead?: boolean }

const useStyles = makeStyles((theme) => ({
  msgStatusIcon: {
    fontSize: '1em',
    color: theme.palette.common.white
  },
  readMsgIcon: {
    marginLeft: '-8px',
    opacity: ({ isRead }: styleProps) => (isRead ? 1 : 0.4)
  },
  msgSentTime: {
    fontSize: '0.6em',
    textAlign: 'right'
  }
}))

type Props = {
  isPending: boolean
  isRead?: boolean
}

export const MessageStatus = ({ isPending, isRead }: Props): JSX.Element => {
  const classes = useStyles({ isRead })

  return isPending ? (
    <Schedule data-testid="chat-unsent-icon" className={classes.msgStatusIcon} />
  ) : (
    <>
      <Check data-testid="chat-sent-icon" className={classes.msgStatusIcon} />
      <Check className={`${classes.msgStatusIcon} ${classes.readMsgIcon}`} />
    </>
  )
}
