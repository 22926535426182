import { createTransform } from 'redux-persist'
import { MessageState } from '../types/state'

export const messageStateNestedBlackList = createTransform(
  null,
  // transform state being rehydrated
  (state: MessageState) => {
    return {
      ...state,
      showFavoritedMessages: false
    }
  },
  // define which reducers this transform gets called for.
  { whitelist: ['messageState'] }
)
