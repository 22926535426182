import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  RadioGroup,
  Radio,
  DialogTitle,
  FormControlLabel,
  makeStyles
} from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { SET_USER_PRIORITY_REQUEST } from '../../redux/constants'
import { priorities } from '../../utils/constants'

type PriorityDialogProps = {
  open: boolean
  onClose: () => void
  username?: string
  userId?: string
  messageId: string
  priority?: string
}

const useStyles = makeStyles(() => ({
  container: {
    width: 500
  }
}))

export function PriorityDialog({
  open,
  onClose,
  username,
  userId,
  messageId,
  priority
}: PriorityDialogProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const radioGroupRef = useRef<any>(null)
  const [priorityText, setPriorityText] = useState('')

  useEffect(() => {
    if (priority) {
      setPriorityText(priority)
    }
  }, [priority])

  const handleEntering = () => {
    if (radioGroupRef.current != null) {
      radioGroupRef.current.focus()
    }
  }

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPriorityText(event.target.value)
  }

  const setPriorityFn = () => {
    dispatch({
      type: SET_USER_PRIORITY_REQUEST,
      payload: { userId, messageId, priority: priorityText }
    })
    onClose()
  }

  return (
    <Dialog
      data-testid="set-priority-dialog"
      disableEscapeKeyDown
      maxWidth="xs"
      open={open}
      onClose={onClose}
      TransitionProps={{ onEntering: handleEntering }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.container }}
    >
      <DialogTitle id="alert-dialog-title">{`${priority ? 'Change' : 'Set'} ${username}'s priority`}</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          ref={radioGroupRef}
          aria-label="ringtone"
          name="ringtone"
          value={priorityText}
          onChange={handleChange}
        >
          {priorities.map((priority) => (
            <FormControlLabel value={priority.value} key={priority.label} control={<Radio />} label={priority.label} />
          ))}
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button data-testid="yes-button" onClick={setPriorityFn} color="primary" data-track-click="set-user-priority">
          Yes
        </Button>
        <Button data-testid="no-button" onClick={() => onClose()} color="primary">
          No
        </Button>
      </DialogActions>
    </Dialog>
  )
}
