import { Dispatch, ReactElement, SetStateAction, useEffect, useState } from 'react'
import { Fab, IconButton, makeStyles, TextField, Typography } from '@material-ui/core'
import { Add, Close } from '@material-ui/icons'
import { InfoCard, Loader } from '../../components'
import { useDispatch, useSelector } from 'react-redux'
import { ADD_NEW_USER, FETCH_USERS_REQUEST } from '../../redux/constants'
import { getUsersState } from '../../redux/selectors'
import moment from 'moment'
import { NavLink, useParams } from 'react-router-dom'
import { UserBio } from '../../types/users'
import { useDebounce } from '../../hooks/useDebounce'
import { useViewMode, ViewMode } from '../../hooks/useViewMode'
import { UserFormEditMode } from '../../screens'

type Props = { editMode: UserFormEditMode; setEditMode: Dispatch<SetStateAction<UserFormEditMode>> }
type StyleProps = { mobileView: boolean }

const useStyles = makeStyles((theme) => ({
  sidebarContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      width: ({ mobileView }: StyleProps) => (mobileView ? 'unset' : '30%')
    }
  },
  searchContainer: {
    display: 'flex',
    padding: '15px 10px',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  searchBar: {
    flex: 0.9
  },
  usersList: {
    overflowY: 'scroll',
    flex: 1
  },
  noResultsContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noResultsText: {
    fontSize: '1.3rem'
  },
  cardContainer: {
    position: 'relative'
  },
  individualCard: {
    color: 'unset',
    display: 'block',
    textDecoration: 'none'
  },
  activeLink: {
    backgroundColor: theme.palette.grey[200]
  },
  cardOverlay: {
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    width: '100%',
    height: '100%',
    position: 'absolute',
    cursor: 'not-allowed',
    zIndex: 1
  }
}))

export const UsersListSidebar = ({ editMode, setEditMode }: Props): ReactElement => {
  const { isTouched, touchedID } = editMode
  const viewMode = useViewMode()
  const mobileView = viewMode === ViewMode.MOBILE
  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>()
  const classes = useStyles({ mobileView })
  const { requested, users, upsertRequested } = useSelector(getUsersState)

  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString, 500)

  useEffect(() => {
    const argument = debouncedSearchString ? debouncedSearchString : ''
    fetchUser(argument)
  }, [debouncedSearchString])

  const fetchUser = (searchString = '') => {
    dispatch({ type: FETCH_USERS_REQUEST, payload: { searchString } })
  }

  const addNewUser = () => {
    dispatch({ type: ADD_NEW_USER })
    setEditMode({ isTouched: true, touchedID: 'new_user' })
  }

  const onSearchClear = () => {
    setSearchString('')
    fetchUser()
  }

  return (
    <div className={classes.sidebarContainer}>
      <div className={classes.searchContainer}>
        <NavLink to="/users/new_user">
          <Fab
            color="primary"
            aria-label="Add"
            onClick={addNewUser}
            disabled={users[0]?.user_id === 'new_user' || requested || upsertRequested || isTouched}
          >
            <Add />
          </Fab>
        </NavLink>
        <TextField
          inputProps={{ 'aria-label': 'Search users' }}
          className={classes.searchBar}
          disabled={upsertRequested || isTouched}
          variant="outlined"
          margin="dense"
          label="Search users"
          name="search"
          value={searchString}
          onChange={(e) => setSearchString(e.target.value)}
        />
        <IconButton aria-label="Clear search" disabled={searchString.length === 0} onClick={onSearchClear}>
          <Close />
        </IconButton>
      </div>

      <div className={classes.usersList}>
        {requested && <Loader ariaLabel="Loading users" />}
        {!requested && users.length === 0 && (
          <div className={classes.noResultsContainer}>
            <Typography variant="body1" className={classes.noResultsText}>
              No results
            </Typography>
          </div>
        )}
        {users.length > 0 &&
          users.map(({ user_id, name, email, user_profile_url, created_at, user_type }: UserBio) => (
            <div key={user_id} className={classes.cardContainer}>
              <div
                className={classes.cardOverlay}
                style={{
                  display: (isTouched && touchedID === user_id) || (!isTouched && touchedID === '') ? 'none' : 'block'
                }}
              />
              <NavLink to={`/users/${user_id}`} activeClassName={classes.activeLink} className={classes.individualCard}>
                <InfoCard
                  selected={user_id === userId}
                  containerTestid={user_id}
                  avatarUrl={user_profile_url}
                  title={name || 'New user...'}
                  subtitle={email || 'no email'}
                  dateTime={moment.utc(created_at).format('Do MMM YY')}
                  chips={[{ testId: name, label: user_type || 'No role', color: user_type ? 'secondary' : 'default' }]}
                />
              </NavLink>
            </div>
          ))}
      </div>
    </div>
  )
}
