import { Snackbar as MuiSnackbar } from '@material-ui/core'
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert'
import { useDispatch, useSelector } from 'react-redux'
import { getFeedbackState } from '../../redux/selectors'
import { HIDE_FEEDBACK } from '../../redux/constants'

const Alert = (props: AlertProps) => <MuiAlert elevation={6} variant="filled" {...props} />

export const Snackbar = (): JSX.Element => {
  const { show, severity, title, position, autoHideDuration } = useSelector(getFeedbackState)
  const { vertical, horizontal } = position

  const dispatch = useDispatch()

  return (
    <MuiSnackbar
      data-testid="snackbar-container"
      anchorOrigin={{ vertical, horizontal }}
      open={show}
      autoHideDuration={autoHideDuration}
      onClose={() => dispatch({ type: HIDE_FEEDBACK })}
    >
      <Alert
        data-testid={severity}
        variant="filled"
        severity={severity}
        onClose={() => dispatch({ type: HIDE_FEEDBACK })}
      >
        {title}
      </Alert>
    </MuiSnackbar>
  )
}
