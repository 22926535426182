import { ReactElement, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles, Paper, Typography } from '@material-ui/core'

import { useInfoByMessageId } from '../../../hooks/useInfoByMessageId'
import { getThreadState } from '../../../redux/selectors'
import { GET_USER_STATUS_REQUEST } from '../../../redux/constants'
import { Loader } from '../..'

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px 20px 40px',
    height: '100%'
  }
}))

export function UserInfoStatus(): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const userId = customer?.linked_user_id
  const {
    user: { status }
  } = useSelector(getThreadState)

  useEffect(() => {
    if (!status || userId !== status?.userId) {
      dispatch({ type: GET_USER_STATUS_REQUEST, payload: { userId } })
    }
  }, [])

  if (!status || userId !== status?.userId) {
    return <Loader ariaLabel="Getting user status" />
  }

  return (
    <Paper data-testid="user-info-status-container" className={classes.container}>
      <Typography variant="h6" style={{ textAlign: 'center', marginBottom: 10 }}>
        User Stats
      </Typography>
      <Typography variant="subtitle1" style={{ wordBreak: 'break-all' }}>
        Searches: {status?.search_count}
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
        Recommended Properties: {status?.recommended_properties_count}
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
        Likes: {status?.likes_count}
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
        Dislikes: {status?.dislikes_count}
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
        Number of Chats: {status?.chat_count}
      </Typography>
      <Typography variant="subtitle1" style={{ marginTop: 10 }}>
        Number of Support Chats: {status?.chat_count_from_support}
      </Typography>
    </Paper>
  )
}
