export const rooms = {
  bedrooms: ['1', '2', '3', '4', '5+', 'Studio'],
  bathrooms: ['1', '2', '3', '4', '5+']
}

export const priorities = [
  { label: 'VIP', value: 'vip' },
  { label: 'High', value: 'high' },
  { label: 'Medium', value: 'medium' },
  { label: 'Low', value: 'low' },
  { label: 'None', value: '' }
]

export const bookingTypes = [
  {
    title: 'Airport Transfer',
    value: 'airport_transfer',
    img: 'https://assets.perchpeek.com/app/bookings_assets/airport-transfer.jpg'
  },
  { title: 'Anmeldung', value: 'registration', img: 'https://assets.perchpeek.com/app/bookings_assets/anmeldung.jpg' },
  {
    title: 'Area Tour - Virtual',
    value: 'area_tour_virtual',
    img: 'https://assets.perchpeek.com/app/bookings_assets/area-tour.jpg'
  },
  {
    title: 'Area Tour - In person',
    value: 'area_tour_in_person',
    img: 'https://assets.perchpeek.com/app/bookings_assets/area-tour.jpg'
  },
  { title: 'Bank Account', value: 'bank_account', img: 'https://assets.perchpeek.com/app/bookings_assets/banking.jpg' },
  { title: 'Car Rental', value: 'car_rental', img: 'https://assets.perchpeek.com/app/bookings_assets/car-rental.jpg' },
  {
    title: 'Health Insurance',
    value: 'health_insurance',
    img: 'https://assets.perchpeek.com/app/bookings_assets/health-insurance.jpg'
  },
  { title: 'Intro Call', value: 'intro_call', img: 'https://assets.perchpeek.com/app/bookings_assets/intro-call.jpg' },
  {
    title: 'Language Learning',
    value: 'language_learning',
    img: 'https://assets.perchpeek.com/app/bookings_assets/language-learning.jpg'
  },
  {
    title: 'Pet Relo',
    value: 'pet_relocation',
    img: 'https://assets.perchpeek.com/app/bookings_assets/pet-relocation.jpg'
  },
  { title: 'Property Visits', value: 'property_visits', img: '' },
  { title: 'Shipping', value: 'shipping', img: 'https://assets.perchpeek.com/app/bookings_assets/shipping.jpg' },
  {
    title: 'Temp Accommodation',
    value: 'temp_accomodation',
    img: 'https://assets.perchpeek.com/app/bookings_assets/temporary-accommodation.jpg'
  }
]

export const serviceJSON = [
  {
    id: 'stage_1',
    name: 'Stage 1 - Pre-departure',
    services: [
      {
        id: 'advisory_call',
        label: 'Advisory Call'
      },
      {
        id: 'temporary_accommodation',
        label: 'Temporary Accommodation'
      },
      {
        id: 'shipping',
        label: 'Shipping'
      },
      {
        id: 'airport_transfer',
        label: 'Airport Transfer'
      },
      {
        id: 'pet_relocation',
        label: 'Pet Relocation'
      },
      // {
      //   id: 'area_tour',
      //   label: 'Area Tour',
      // },
      {
        id: 'car_rental',
        label: 'Car Rental'
      },
      {
        id: 'education_assistance',
        label: 'Education Guidance'
      }
    ]
  },
  {
    id: 'stage_2',
    name: 'Stage 2 - Upon arrival',
    services: [
      {
        id: 'registration',
        label: 'Registration'
      },
      {
        id: 'banking',
        label: 'Banking'
      },
      {
        id: 'mobile_phone',
        label: 'Mobile Phone'
      },
      {
        id: 'health_insurance',
        label: 'Health Insurance'
      },
      {
        id: 'cultural_integration',
        label: 'Cultural Integration'
      },
      {
        id: 'language_learning',
        label: 'Language Learning'
      }
    ]
  },
  {
    id: 'stage_3',
    name: 'Stage 3 - Home-finding',
    services: [
      {
        id: 'check_in',
        label: 'Property Guidance'
      },
      {
        id: 'rental_documents',
        label: 'Rental Documents'
      },
      {
        id: 'property_visits',
        label: 'Property Visits'
      },
      {
        id: 'submit_offer',
        label: 'Submit Offer'
      },
      // {
      //   id: 'agency_check',
      //   label: 'Agency Check',
      // },
      {
        id: 'contract_review',
        label: 'Contract Review'
      }
    ]
  },
  {
    id: 'stage_4',
    name: 'Stage 4 - Setting in',
    services: [
      {
        id: 'utilities',
        label: 'Utilities'
      },
      {
        id: 'rental_insurance',
        label: 'Rental Insurance'
      },
      {
        id: 'furniture_rental',
        label: 'Furniture Rental'
      },
      {
        id: 'driving_license',
        label: 'Driving License'
      }
    ]
  }
]
