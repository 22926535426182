import { ChangeEvent, Dispatch, ReactElement, SetStateAction, useState } from 'react'
import {
  Dialog,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  DialogActions,
  Button,
  Typography,
  TextField,
  IconButton
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/core/styles'

import { countries } from '../../utils/listOfCountries'
import { Close } from '@material-ui/icons'
import { PriorityType } from '../../types/state'

type ModalProps = {
  showFilterModal: boolean
  closeFilterModal: () => void
  confirmPriorities: () => void
  checkState: PriorityType
  setCheckState: Dispatch<SetStateAction<PriorityType>>
  clearFilters: () => void
}

type CountriesFullDetails = { name: string; value: string }

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: 720,
    maxWidth: 720,
    padding: '25px 25px 10px 25px'
  },
  close: {
    position: 'absolute',
    top: 0,
    right: 0
  },
  formGroup: {
    flexDirection: 'row'
  },
  filterTitle: {
    marginTop: '1.2rem'
  },
  chip: {
    color: 'white',
    backgroundColor: '#1EBA8B'
  }
}))

export const MessageFilterModal = ({
  showFilterModal,
  closeFilterModal,
  confirmPriorities,
  checkState,
  setCheckState,
  clearFilters
}: ModalProps): ReactElement => {
  const classes = useStyles()
  const [chosenCountries, setChosenCountries] = useState<never[] | CountriesFullDetails[]>([])

  const { paid, unpaid, vip, high, medium, low } = checkState

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckState({ ...checkState, [event.target.name]: event.target.checked })
  }

  const handleCountrySelect = (values: CountriesFullDetails[]) => {
    setChosenCountries(values)
    const countries = values.map((value) => value.value)
    setCheckState({ ...checkState, countries })
  }

  const handleClearFilters = () => {
    setChosenCountries([])
    clearFilters()
  }

  const handleSubmit = () => {
    const countries = chosenCountries.map((value: CountriesFullDetails) => value.value)
    setCheckState({ ...checkState, countries })
    confirmPriorities()
  }

  return (
    <Dialog
      data-testid="filter-priority-dialog"
      disableEscapeKeyDown
      open={showFilterModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      classes={{ paper: classes.container }}
    >
      <Typography id="alert-dialog-title-country" variant="h6" style={{ marginBottom: '1rem' }}>
        Filter By Country
      </Typography>
      <IconButton data-testid="close-button" className={classes.close} onClick={closeFilterModal}>
        <Close />
      </IconButton>
      <Autocomplete
        data-testid="country-autocomplete"
        multiple
        id="tags-outlined"
        options={countries}
        getOptionLabel={(option) => option.name}
        filterSelectedOptions
        value={chosenCountries}
        onChange={(event, value) => handleCountrySelect(value)}
        renderInput={(params) => <TextField {...params} variant="outlined" label="Countries" placeholder="Countries" />}
        classes={{ tag: classes.chip }}
      />
      <Typography id="alert-dialog-title-paid" variant="h6" className={classes.filterTitle}>
        Filter By Paid Status
      </Typography>
      <FormControl component="fieldset">
        <FormGroup classes={{ root: classes.formGroup }}>
          <FormControlLabel
            control={
              <Checkbox checked={paid} onChange={handleChange} name="paid" data-testid="paid-priority-checkbox" />
            }
            label="Paid"
          />
          <FormControlLabel
            control={
              <Checkbox checked={unpaid} onChange={handleChange} name="unpaid" data-testid="unpaid-priority-checkbox" />
            }
            label="Unpaid"
          />
        </FormGroup>
      </FormControl>
      <Typography id="alert-dialog-title-priority" variant="h6" className={classes.filterTitle}>
        Filter By Priority
      </Typography>
      <FormControl component="fieldset">
        <FormGroup classes={{ root: classes.formGroup }}>
          <FormControlLabel
            control={<Checkbox checked={vip} onChange={handleChange} name="vip" data-testid="vip-priority-checkbox" />}
            label="VIP"
          />
          <FormControlLabel
            control={
              <Checkbox checked={high} onChange={handleChange} name="high" data-testid="high-priority-checkbox" />
            }
            label="High"
          />
          <FormControlLabel
            control={
              <Checkbox checked={medium} onChange={handleChange} name="medium" data-testid="medium-priority-checkbox" />
            }
            label="Medium"
          />
          <FormControlLabel
            control={<Checkbox checked={low} onChange={handleChange} name="low" data-testid="low-priority-checkbox" />}
            label="Low"
          />
        </FormGroup>
      </FormControl>
      <DialogActions>
        <Button data-testid="clear-button" onClick={handleClearFilters}>
          Clear
        </Button>
        <Button
          data-testid="apply-button"
          disabled={paid || unpaid || vip || high || medium || low || chosenCountries.length ? false : true}
          variant="contained"
          onClick={handleSubmit}
          color="primary"
          data-track-click="search-filters"
        >
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  )
}
