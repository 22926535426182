export const timezones = [
  { selectValue: 'Pacific/Pago_Pago', displayValue: '(GMT-11:00) Pago Pago' },
  { selectValue: 'Pacific/Niue', displayValue: '(GMT-11:00) Niue' },
  { selectValue: 'Pacific/Midway', displayValue: '(GMT-11:00) Midway' },
  { selectValue: 'Pacific/Tahiti', displayValue: '(GMT-10:00) Tahiti' },
  { selectValue: 'Pacific/Rarotonga', displayValue: '(GMT-10:00) Rarotonga' },
  { selectValue: 'Pacific/Honolulu', displayValue: '(GMT-10:00) Honolulu' },
  { selectValue: 'Pacific/Marquesas', displayValue: '(GMT-09:30) Marquesas' },
  { selectValue: 'Pacific/Gambier', displayValue: '(GMT-09:00) Gambier' },
  { selectValue: 'America/Adak', displayValue: '(GMT-09:00) Adak' },
  { selectValue: 'America/Yakutat', displayValue: '(GMT-08:00) Yakutat' },
  { selectValue: 'Pacific/Pitcairn', displayValue: '(GMT-08:00) Pitcairn' },
  { selectValue: 'America/Nome', displayValue: '(GMT-08:00) Nome' },
  { selectValue: 'America/Juneau', displayValue: '(GMT-08:00) Juneau' },
  { selectValue: 'America/Anchorage', displayValue: '(GMT-08:00) Anchorage' },
  { selectValue: 'America/Whitehorse', displayValue: '(GMT-07:00) Whitehorse' },
  { selectValue: 'America/Vancouver', displayValue: '(GMT-07:00) Vancouver' },
  { selectValue: 'America/Tijuana', displayValue: '(GMT-07:00) Tijuana' },
  { selectValue: 'America/Phoenix', displayValue: '(GMT-07:00) Phoenix' },
  { selectValue: 'America/Los_Angeles', displayValue: '(GMT-07:00) Los Angeles' },
  { selectValue: 'America/Hermosillo', displayValue: '(GMT-07:00) Hermosillo' },
  { selectValue: 'America/Dawson_Creek', displayValue: '(GMT-07:00) Dawson Creek' },
  { selectValue: 'America/Dawson', displayValue: '(GMT-07:00) Dawson' },
  { selectValue: 'America/Yellowknife', displayValue: '(GMT-06:00) Yellowknife' },
  { selectValue: 'America/Tegucigalpa', displayValue: '(GMT-06:00) Tegucigalpa' },
  { selectValue: 'America/Swift_Current', displayValue: '(GMT-06:00) Swift Current' },
  { selectValue: 'America/Regina', displayValue: '(GMT-06:00) Regina' },
  { selectValue: 'America/Ojinaga', displayValue: '(GMT-06:00) Ojinaga' },
  { selectValue: 'America/Mazatlan', displayValue: '(GMT-06:00) Mazatlan' },
  { selectValue: 'America/Managua', displayValue: '(GMT-06:00) Managua' },
  { selectValue: 'America/Inuvik', displayValue: '(GMT-06:00) Inuvik' },
  { selectValue: 'America/Guatemala', displayValue: '(GMT-06:00) Guatemala' },
  { selectValue: 'Pacific/Galapagos', displayValue: '(GMT-06:00) Galapagos' },
  { selectValue: 'America/El_Salvador', displayValue: '(GMT-06:00) El Salvador' },
  { selectValue: 'America/Edmonton', displayValue: '(GMT-06:00) Edmonton' },
  { selectValue: 'Pacific/Easter', displayValue: '(GMT-06:00) Easter' },
  { selectValue: 'America/Denver', displayValue: '(GMT-06:00) Denver' },
  { selectValue: 'America/Costa_Rica', displayValue: '(GMT-06:00) Costa Rica' },
  { selectValue: 'America/Chihuahua', displayValue: '(GMT-06:00) Chihuahua' },
  { selectValue: 'America/Cambridge_Bay', displayValue: '(GMT-06:00) Cambridge Bay' },
  { selectValue: 'America/Boise', displayValue: '(GMT-06:00) Boise' },
  { selectValue: 'America/Belize', displayValue: '(GMT-06:00) Belize' },
  { selectValue: 'America/Winnipeg', displayValue: '(GMT-05:00) Winnipeg' },
  { selectValue: 'America/Rio_Branco', displayValue: '(GMT-05:00) Rio Branco' },
  { selectValue: 'America/Resolute', displayValue: '(GMT-05:00) Resolute' },
  { selectValue: 'America/Rankin_Inlet', displayValue: '(GMT-05:00) Rankin Inlet' },
  { selectValue: 'America/Rainy_River', displayValue: '(GMT-05:00) Rainy River' },
  { selectValue: 'America/Panama', displayValue: '(GMT-05:00) Panama' },
  { selectValue: 'America/North_Dakota/New_Salem', displayValue: '(GMT-05:00) North Dakota - New Salem' },
  { selectValue: 'America/North_Dakota/Center', displayValue: '(GMT-05:00) North Dakota - Center' },
  { selectValue: 'America/Monterrey', displayValue: '(GMT-05:00) Monterrey' },
  { selectValue: 'America/Mexico_City', displayValue: '(GMT-05:00) Mexico City' },
  { selectValue: 'America/Merida', displayValue: '(GMT-05:00) Merida' },
  { selectValue: 'America/Menominee', displayValue: '(GMT-05:00) Menominee' },
  { selectValue: 'America/Matamoros', displayValue: '(GMT-05:00) Matamoros' },
  { selectValue: 'America/Lima', displayValue: '(GMT-05:00) Lima' },
  { selectValue: 'America/Jamaica', displayValue: '(GMT-05:00) Jamaica' },
  { selectValue: 'America/Indiana/Tell_City', displayValue: '(GMT-05:00) Indiana - Tell City' },
  { selectValue: 'America/Indiana/Knox', displayValue: '(GMT-05:00) Indiana - Knox' },
  { selectValue: 'America/Guayaquil', displayValue: '(GMT-05:00) Guayaquil' },
  { selectValue: 'America/Eirunepe', displayValue: '(GMT-05:00) Eirunepe' },
  { selectValue: 'America/Chicago', displayValue: '(GMT-05:00) Chicago' },
  { selectValue: 'America/Cayman', displayValue: '(GMT-05:00) Cayman' },
  { selectValue: 'America/Cancun', displayValue: '(GMT-05:00) Cancun' },
  { selectValue: 'America/Bogota', displayValue: '(GMT-05:00) Bogota' },
  { selectValue: 'America/Atikokan', displayValue: '(GMT-05:00) Atikokan' },
  { selectValue: 'America/Tortola', displayValue: '(GMT-04:00) Tortola' },
  { selectValue: 'America/Toronto', displayValue: '(GMT-04:00) Toronto' },
  { selectValue: 'America/Thunder_Bay', displayValue: '(GMT-04:00) Thunder Bay' },
  { selectValue: 'America/St_Vincent', displayValue: '(GMT-04:00) St Vincent' },
  { selectValue: 'America/St_Thomas', displayValue: '(GMT-04:00) St Thomas' },
  { selectValue: 'America/St_Lucia', displayValue: '(GMT-04:00) St Lucia' },
  { selectValue: 'America/St_Kitts', displayValue: '(GMT-04:00) St Kitts' },
  { selectValue: 'America/St_Barthelemy', displayValue: '(GMT-04:00) St Barthelemy' },
  { selectValue: 'America/Santo_Domingo', displayValue: '(GMT-04:00) Santo Domingo' },
  { selectValue: 'America/Santiago', displayValue: '(GMT-04:00) Santiago' },
  { selectValue: 'America/Puerto_Rico', displayValue: '(GMT-04:00) Puerto Rico' },
  { selectValue: 'America/Porto_Velho', displayValue: '(GMT-04:00) Porto Velho' },
  { selectValue: 'America/Port-au-Prince', displayValue: '(GMT-04:00) Port-au-Prince' },
  { selectValue: 'America/Port_of_Spain', displayValue: '(GMT-04:00) Port of Spain' },
  { selectValue: 'America/Pangnirtung', displayValue: '(GMT-04:00) Pangnirtung' },
  { selectValue: 'America/Nipigon', displayValue: '(GMT-04:00) Nipigon' },
  { selectValue: 'America/New_York', displayValue: '(GMT-04:00) New York' },
  { selectValue: 'America/Nassau', displayValue: '(GMT-04:00) Nassau' },
  { selectValue: 'America/Montserrat', displayValue: '(GMT-04:00) Montserrat' },
  { selectValue: 'America/Martinique', displayValue: '(GMT-04:00) Martinique' },
  { selectValue: 'America/Marigot', displayValue: '(GMT-04:00) Marigot' },
  { selectValue: 'America/Manaus', displayValue: '(GMT-04:00) Manaus' },
  { selectValue: 'America/La_Paz', displayValue: '(GMT-04:00) La Paz' },
  { selectValue: 'America/Kentucky/Monticello', displayValue: '(GMT-04:00) Kentucky - Monticello' },
  { selectValue: 'America/Kentucky/Louisville', displayValue: '(GMT-04:00) Kentucky - Louisville' },
  { selectValue: 'America/Iqaluit', displayValue: '(GMT-04:00) Iqaluit' },
  { selectValue: 'America/Indiana/Winamac', displayValue: '(GMT-04:00) Indiana - Winamac' },
  { selectValue: 'America/Indiana/Vincennes', displayValue: '(GMT-04:00) Indiana - Vincennes' },
  { selectValue: 'America/Indiana/Vevay', displayValue: '(GMT-04:00) Indiana - Vevay' },
  { selectValue: 'America/Indiana/Petersburg', displayValue: '(GMT-04:00) Indiana - Petersburg' },
  { selectValue: 'America/Indiana/Marengo', displayValue: '(GMT-04:00) Indiana - Marengo' },
  { selectValue: 'America/Indiana/Indianapolis', displayValue: '(GMT-04:00) Indiana - Indianapolis' },
  { selectValue: 'America/Havana', displayValue: '(GMT-04:00) Havana' },
  { selectValue: 'America/Guyana', displayValue: '(GMT-04:00) Guyana' },
  { selectValue: 'America/Guadeloupe', displayValue: '(GMT-04:00) Guadeloupe' },
  { selectValue: 'America/Grenada', displayValue: '(GMT-04:00) Grenada' },
  { selectValue: 'America/Grand_Turk', displayValue: '(GMT-04:00) Grand Turk' },
  { selectValue: 'America/Dominica', displayValue: '(GMT-04:00) Dominica' },
  { selectValue: 'America/Detroit', displayValue: '(GMT-04:00) Detroit' },
  { selectValue: 'America/Curacao', displayValue: '(GMT-04:00) Curacao' },
  { selectValue: 'America/Cuiaba', displayValue: '(GMT-04:00) Cuiaba' },
  { selectValue: 'America/Caracas', displayValue: '(GMT-04:00) Caracas' },
  { selectValue: 'America/Campo_Grande', displayValue: '(GMT-04:00) Campo Grande' },
  { selectValue: 'America/Boa_Vista', displayValue: '(GMT-04:00) Boa Vista' },
  { selectValue: 'America/Blanc-Sablon', displayValue: '(GMT-04:00) Blanc-Sablon' },
  { selectValue: 'America/Barbados', displayValue: '(GMT-04:00) Barbados' },
  { selectValue: 'America/Asuncion', displayValue: '(GMT-04:00) Asuncion' },
  { selectValue: 'America/Aruba', displayValue: '(GMT-04:00) Aruba' },
  { selectValue: 'America/Antigua', displayValue: '(GMT-04:00) Antigua' },
  { selectValue: 'America/Anguilla', displayValue: '(GMT-04:00) Anguilla' },
  { selectValue: 'America/Thule', displayValue: '(GMT-03:00) Thule' },
  { selectValue: 'Atlantic/Stanley', displayValue: '(GMT-03:00) Stanley' },
  { selectValue: 'America/Sao_Paulo', displayValue: '(GMT-03:00) Sao Paulo' },
  { selectValue: 'America/Santarem', displayValue: '(GMT-03:00) Santarem' },
  { selectValue: 'America/Recife', displayValue: '(GMT-03:00) Recife' },
  { selectValue: 'America/Paramaribo', displayValue: '(GMT-03:00) Paramaribo' },
  { selectValue: 'America/Montevideo', displayValue: '(GMT-03:00) Montevideo' },
  { selectValue: 'America/Moncton', displayValue: '(GMT-03:00) Moncton' },
  { selectValue: 'America/Maceio', displayValue: '(GMT-03:00) Maceio' },
  { selectValue: 'America/Halifax', displayValue: '(GMT-03:00) Halifax' },
  { selectValue: 'America/Goose_Bay', displayValue: '(GMT-03:00) Goose Bay' },
  { selectValue: 'America/Glace_Bay', displayValue: '(GMT-03:00) Glace Bay' },
  { selectValue: 'America/Fortaleza', displayValue: '(GMT-03:00) Fortaleza' },
  { selectValue: 'America/Cayenne', displayValue: '(GMT-03:00) Cayenne' },
  { selectValue: 'Atlantic/Bermuda', displayValue: '(GMT-03:00) Bermuda' },
  { selectValue: 'America/Belem', displayValue: '(GMT-03:00) Belem' },
  { selectValue: 'America/Bahia', displayValue: '(GMT-03:00) Bahia' },
  { selectValue: 'America/Argentina/Ushuaia', displayValue: '(GMT-03:00) Argentina - Ushuaia' },
  { selectValue: 'America/Argentina/Tucuman', displayValue: '(GMT-03:00) Argentina - Tucuman' },
  { selectValue: 'America/Argentina/San_Luis', displayValue: '(GMT-03:00) Argentina - San Luis' },
  { selectValue: 'America/Argentina/San_Juan', displayValue: '(GMT-03:00) Argentina - San Juan' },
  { selectValue: 'America/Argentina/Salta', displayValue: '(GMT-03:00) Argentina - Salta' },
  { selectValue: 'America/Argentina/Rio_Gallegos', displayValue: '(GMT-03:00) Argentina - Rio Gallegos' },
  { selectValue: 'America/Argentina/Mendoza', displayValue: '(GMT-03:00) Argentina - Mendoza' },
  { selectValue: 'America/Argentina/La_Rioja', displayValue: '(GMT-03:00) Argentina - La Rioja' },
  { selectValue: 'America/Argentina/Jujuy', displayValue: '(GMT-03:00) Argentina - Jujuy' },
  { selectValue: 'America/Argentina/Cordoba', displayValue: '(GMT-03:00) Argentina - Cordoba' },
  { selectValue: 'America/Argentina/Catamarca', displayValue: '(GMT-03:00) Argentina - Catamarca' },
  { selectValue: 'America/Argentina/Buenos_Aires', displayValue: '(GMT-03:00) Argentina - Buenos Aires' },
  { selectValue: 'America/Araguaina', displayValue: '(GMT-03:00) Araguaina' },
  { selectValue: 'America/St_Johns', displayValue: '(GMT-02:30) St Johns' },
  { selectValue: 'Atlantic/South_Georgia', displayValue: '(GMT-02:00) South Georgia' },
  { selectValue: 'America/Noronha', displayValue: '(GMT-02:00) Noronha' },
  { selectValue: 'America/Miquelon', displayValue: '(GMT-02:00) Miquelon' },
  { selectValue: 'Atlantic/Cape_Verde', displayValue: '(GMT-01:00) Cape Verde' },
  { selectValue: 'Africa/Abidjan', displayValue: '(GMT) Abidjan' },
  { selectValue: 'Africa/Accra', displayValue: '(GMT) Accra' },
  { selectValue: 'Atlantic/Azores', displayValue: '(GMT) Azores' },
  { selectValue: 'Africa/Bamako', displayValue: '(GMT) Bamako' },
  { selectValue: 'Africa/Banjul', displayValue: '(GMT) Banjul' },
  { selectValue: 'Europe/Belfast', displayValue: '(GMT) Belfast' },
  { selectValue: 'Africa/Bissau', displayValue: '(GMT) Bissau' },
  { selectValue: 'Africa/Conakry', displayValue: '(GMT) Conakry' },
  { selectValue: 'Africa/Dakar', displayValue: '(GMT) Dakar' },
  { selectValue: 'America/Danmarkshavn', displayValue: '(GMT) Danmarkshavn' },
  { selectValue: 'Africa/Freetown', displayValue: '(GMT) Freetown' },
  { selectValue: 'Africa/Lome', displayValue: '(GMT) Lome' },
  { selectValue: 'Africa/Monrovia', displayValue: '(GMT) Monrovia' },
  { selectValue: 'Africa/Nouakchott', displayValue: '(GMT) Nouakchott' },
  { selectValue: 'Africa/Ouagadougou', displayValue: '(GMT) Ouagadougou' },
  { selectValue: 'Atlantic/Reykjavik', displayValue: '(GMT) Reykjavik' },
  { selectValue: 'Africa/Sao_Tome', displayValue: '(GMT) Sao Tome' },
  { selectValue: 'America/Scoresbysund', displayValue: '(GMT) Scoresbysund' },
  { selectValue: 'Atlantic/St_Helena', displayValue: '(GMT) St Helena' },
  { selectValue: 'Africa/Algiers', displayValue: '(GMT+01:00) Algiers' },
  { selectValue: 'Africa/Bangui', displayValue: '(GMT+01:00) Bangui' },
  { selectValue: 'Africa/Brazzaville', displayValue: '(GMT+01:00) Brazzaville' },
  { selectValue: 'Atlantic/Canary', displayValue: '(GMT+01:00) Canary' },
  { selectValue: 'Africa/Casablanca', displayValue: '(GMT+01:00) Casablanca' },
  { selectValue: 'Africa/Douala', displayValue: '(GMT+01:00) Douala' },
  { selectValue: 'Europe/Dublin', displayValue: '(GMT+01:00) Dublin' },
  { selectValue: 'Africa/El_Aaiun', displayValue: '(GMT+01:00) El Aaiun' },
  { selectValue: 'Atlantic/Faroe', displayValue: '(GMT+01:00) Faroe' },
  { selectValue: 'Europe/Guernsey', displayValue: '(GMT+01:00) Guernsey' },
  { selectValue: 'Europe/Isle_of_Man', displayValue: '(GMT+01:00) Isle of Man' },
  { selectValue: 'Europe/Jersey', displayValue: '(GMT+01:00) Jersey' },
  { selectValue: 'Africa/Kinshasa', displayValue: '(GMT+01:00) Kinshasa' },
  { selectValue: 'Africa/Lagos', displayValue: '(GMT+01:00) Lagos' },
  { selectValue: 'Africa/Libreville', displayValue: '(GMT+01:00) Libreville' },
  { selectValue: 'Europe/Lisbon', displayValue: '(GMT+01:00) Lisbon' },
  { selectValue: 'Europe/London', displayValue: '(GMT+01:00) London' },
  { selectValue: 'Africa/Luanda', displayValue: '(GMT+01:00) Luanda' },
  { selectValue: 'Atlantic/Madeira', displayValue: '(GMT+01:00) Madeira' },
  { selectValue: 'Africa/Malabo', displayValue: '(GMT+01:00) Malabo' },
  { selectValue: 'Africa/Ndjamena', displayValue: '(GMT+01:00) Ndjamena' },
  { selectValue: 'Africa/Niamey', displayValue: '(GMT+01:00) Niamey' },
  { selectValue: 'Africa/Porto-Novo', displayValue: '(GMT+01:00) Porto-Novo' },
  { selectValue: 'Africa/Tunis', displayValue: '(GMT+01:00) Tunis' },
  { selectValue: 'Europe/Amsterdam', displayValue: '(GMT+02:00) Amsterdam' },
  { selectValue: 'Europe/Andorra', displayValue: '(GMT+02:00) Andorra' },
  { selectValue: 'Europe/Belgrade', displayValue: '(GMT+02:00) Belgrade' },
  { selectValue: 'Europe/Berlin', displayValue: '(GMT+02:00) Berlin' },
  { selectValue: 'Africa/Blantyre', displayValue: '(GMT+02:00) Blantyre' },
  { selectValue: 'Europe/Bratislava', displayValue: '(GMT+02:00) Bratislava' },
  { selectValue: 'Europe/Brussels', displayValue: '(GMT+02:00) Brussels' },
  { selectValue: 'Europe/Budapest', displayValue: '(GMT+02:00) Budapest' },
  { selectValue: 'Africa/Bujumbura', displayValue: '(GMT+02:00) Bujumbura' },
  { selectValue: 'Africa/Cairo', displayValue: '(GMT+02:00) Cairo' },
  { selectValue: 'Africa/Ceuta', displayValue: '(GMT+02:00) Ceuta' },
  { selectValue: 'Europe/Copenhagen', displayValue: '(GMT+02:00) Copenhagen' },
  { selectValue: 'Africa/Gaborone', displayValue: '(GMT+02:00) Gaborone' },
  { selectValue: 'Europe/Gibraltar', displayValue: '(GMT+02:00) Gibraltar' },
  { selectValue: 'Africa/Harare', displayValue: '(GMT+02:00) Harare' },
  { selectValue: 'Africa/Johannesburg', displayValue: '(GMT+02:00) Johannesburg' },
  { selectValue: 'Europe/Kaliningrad', displayValue: '(GMT+02:00) Kaliningrad' },
  { selectValue: 'Africa/Khartoum', displayValue: '(GMT+02:00) Khartoum' },
  { selectValue: 'Africa/Kigali', displayValue: '(GMT+02:00) Kigali' },
  { selectValue: 'Europe/Ljubljana', displayValue: '(GMT+02:00) Ljubljana' },
  { selectValue: 'Africa/Lubumbashi', displayValue: '(GMT+02:00) Lubumbashi' },
  { selectValue: 'Africa/Lusaka', displayValue: '(GMT+02:00) Lusaka' },
  { selectValue: 'Europe/Luxembourg', displayValue: '(GMT+02:00) Luxembourg' },
  { selectValue: 'Europe/Madrid', displayValue: '(GMT+02:00) Madrid' },
  { selectValue: 'Europe/Malta', displayValue: '(GMT+02:00) Malta' },
  { selectValue: 'Africa/Maputo', displayValue: '(GMT+02:00) Maputo' },
  { selectValue: 'Africa/Maseru', displayValue: '(GMT+02:00) Maseru' },
  { selectValue: 'Africa/Mbabane', displayValue: '(GMT+02:00) Mbabane' },
  { selectValue: 'Europe/Monaco', displayValue: '(GMT+02:00) Monaco' },
  { selectValue: 'Europe/Oslo', displayValue: '(GMT+02:00) Oslo' },
  { selectValue: 'Europe/Paris', displayValue: '(GMT+02:00) Paris' },
  { selectValue: 'Europe/Podgorica', displayValue: '(GMT+02:00) Podgorica' },
  { selectValue: 'Europe/Prague', displayValue: '(GMT+02:00) Prague' },
  { selectValue: 'Europe/Rome', displayValue: '(GMT+02:00) Rome' },
  { selectValue: 'Europe/San_Marino', displayValue: '(GMT+02:00) San Marino' },
  { selectValue: 'Europe/Sarajevo', displayValue: '(GMT+02:00) Sarajevo' },
  { selectValue: 'Europe/Skopje', displayValue: '(GMT+02:00) Skopje' },
  { selectValue: 'Europe/Stockholm', displayValue: '(GMT+02:00) Stockholm' },
  { selectValue: 'Europe/Tirane', displayValue: '(GMT+02:00) Tirane' },
  { selectValue: 'Africa/Tripoli', displayValue: '(GMT+02:00) Tripoli' },
  { selectValue: 'Europe/Vaduz', displayValue: '(GMT+02:00) Vaduz' },
  { selectValue: 'Europe/Vatican', displayValue: '(GMT+02:00) Vatican' },
  { selectValue: 'Europe/Vienna', displayValue: '(GMT+02:00) Vienna' },
  { selectValue: 'Europe/Warsaw', displayValue: '(GMT+02:00) Warsaw' },
  { selectValue: 'Africa/Windhoek', displayValue: '(GMT+02:00) Windhoek' },
  { selectValue: 'Europe/Zagreb', displayValue: '(GMT+02:00) Zagreb' },
  { selectValue: 'Europe/Zurich', displayValue: '(GMT+02:00) Zurich' },
  { selectValue: 'Africa/Addis_Ababa', displayValue: '(GMT+03:00) Addis Ababa' },
  { selectValue: 'Asia/Aden', displayValue: '(GMT+03:00) Aden' },
  { selectValue: 'Asia/Amman', displayValue: '(GMT+03:00) Amman' },
  { selectValue: 'Indian/Antananarivo', displayValue: '(GMT+03:00) Antananarivo' },
  { selectValue: 'Africa/Asmara', displayValue: '(GMT+03:00) Asmara' },
  { selectValue: 'Europe/Athens', displayValue: '(GMT+03:00) Athens' },
  { selectValue: 'Asia/Baghdad', displayValue: '(GMT+03:00) Baghdad' },
  { selectValue: 'Asia/Bahrain', displayValue: '(GMT+03:00) Bahrain' },
  { selectValue: 'Asia/Beirut', displayValue: '(GMT+03:00) Beirut' },
  { selectValue: 'Europe/Bucharest', displayValue: '(GMT+03:00) Bucharest' },
  { selectValue: 'Europe/Chisinau', displayValue: '(GMT+03:00) Chisinau' },
  { selectValue: 'Indian/Comoro', displayValue: '(GMT+03:00) Comoro' },
  { selectValue: 'Asia/Damascus', displayValue: '(GMT+03:00) Damascus' },
  { selectValue: 'Africa/Dar_es_Salaam', displayValue: '(GMT+03:00) Dar es Salaam' },
  { selectValue: 'Africa/Djibouti', displayValue: '(GMT+03:00) Djibouti' },
  { selectValue: 'Asia/Gaza', displayValue: '(GMT+03:00) Gaza' },
  { selectValue: 'Europe/Helsinki', displayValue: '(GMT+03:00) Helsinki' },
  { selectValue: 'Europe/Istanbul', displayValue: '(GMT+03:00) Istanbul' },
  { selectValue: 'Asia/Jerusalem', displayValue: '(GMT+03:00) Jerusalem' },
  { selectValue: 'Africa/Kampala', displayValue: '(GMT+03:00) Kampala' },
  { selectValue: 'Europe/Kiev', displayValue: '(GMT+03:00) Kiev' },
  { selectValue: 'Asia/Kuwait', displayValue: '(GMT+03:00) Kuwait' },
  { selectValue: 'Europe/Mariehamn', displayValue: '(GMT+03:00) Mariehamn' },
  { selectValue: 'Indian/Mayotte', displayValue: '(GMT+03:00) Mayotte' },
  { selectValue: 'Europe/Minsk', displayValue: '(GMT+03:00) Minsk' },
  { selectValue: 'Africa/Mogadishu', displayValue: '(GMT+03:00) Mogadishu' },
  { selectValue: 'Europe/Moscow', displayValue: '(GMT+03:00) Moscow' },
  { selectValue: 'Africa/Nairobi', displayValue: '(GMT+03:00) Nairobi' },
  { selectValue: 'Asia/Nicosia', displayValue: '(GMT+03:00) Nicosia' },
  { selectValue: 'Asia/Qatar', displayValue: '(GMT+03:00) Qatar' },
  { selectValue: 'Europe/Riga', displayValue: '(GMT+03:00) Riga' },
  { selectValue: 'Asia/Riyadh', displayValue: '(GMT+03:00) Riyadh' },
  { selectValue: 'Europe/Simferopol', displayValue: '(GMT+03:00) Simferopol' },
  { selectValue: 'Europe/Sofia', displayValue: '(GMT+03:00) Sofia' },
  { selectValue: 'Europe/Tallinn', displayValue: '(GMT+03:00) Tallinn' },
  { selectValue: 'Europe/Uzhgorod', displayValue: '(GMT+03:00) Uzhgorod' },
  { selectValue: 'Europe/Vilnius', displayValue: '(GMT+03:00) Vilnius' },
  { selectValue: 'Europe/Volgograd', displayValue: '(GMT+03:00) Volgograd' },
  { selectValue: 'Europe/Zaporozhye', displayValue: '(GMT+03:00) Zaporozhye' },
  { selectValue: 'Asia/Baku', displayValue: '(GMT+04:00) Baku' },
  { selectValue: 'Asia/Dubai', displayValue: '(GMT+04:00) Dubai' },
  { selectValue: 'Indian/Mahe', displayValue: '(GMT+04:00) Mahe' },
  { selectValue: 'Indian/Mauritius', displayValue: '(GMT+04:00) Mauritius' },
  { selectValue: 'Asia/Muscat', displayValue: '(GMT+04:00) Muscat' },
  { selectValue: 'Indian/Reunion', displayValue: '(GMT+04:00) Reunion' },
  { selectValue: 'Europe/Samara', displayValue: '(GMT+04:00) Samara' },
  { selectValue: 'Asia/Tbilisi', displayValue: '(GMT+04:00) Tbilisi' },
  { selectValue: 'Asia/Yerevan', displayValue: '(GMT+04:00) Yerevan' },
  { selectValue: 'Asia/Kabul', displayValue: '(GMT+04:30) Kabul' },
  { selectValue: 'Asia/Tehran', displayValue: '(GMT+04:30) Tehran' },
  { selectValue: 'Asia/Aqtau', displayValue: '(GMT+05:00) Aqtau' },
  { selectValue: 'Asia/Aqtobe', displayValue: '(GMT+05:00) Aqtobe' },
  { selectValue: 'Asia/Ashgabat', displayValue: '(GMT+05:00) Ashgabat' },
  { selectValue: 'Asia/Dushanbe', displayValue: '(GMT+05:00) Dushanbe' },
  { selectValue: 'Asia/Karachi', displayValue: '(GMT+05:00) Karachi' },
  { selectValue: 'Indian/Kerguelen', displayValue: '(GMT+05:00) Kerguelen' },
  { selectValue: 'Indian/Maldives', displayValue: '(GMT+05:00) Maldives' },
  { selectValue: 'Asia/Oral', displayValue: '(GMT+05:00) Oral' },
  { selectValue: 'Asia/Qyzylorda', displayValue: '(GMT+05:00) Qyzylorda' },
  { selectValue: 'Asia/Samarkand', displayValue: '(GMT+05:00) Samarkand' },
  { selectValue: 'Asia/Tashkent', displayValue: '(GMT+05:00) Tashkent' },
  { selectValue: 'Asia/Yekaterinburg', displayValue: '(GMT+05:00) Yekaterinburg' },
  { selectValue: 'Asia/Colombo', displayValue: '(GMT+05:30) Colombo' },
  { selectValue: 'Asia/Kolkata', displayValue: '(GMT+05:30) Kolkata' },
  { selectValue: 'Asia/Kathmandu', displayValue: '(GMT+05:45) Kathmandu' },
  { selectValue: 'Asia/Almaty', displayValue: '(GMT+06:00) Almaty' },
  { selectValue: 'Asia/Bishkek', displayValue: '(GMT+06:00) Bishkek' },
  { selectValue: 'Indian/Chagos', displayValue: '(GMT+06:00) Chagos' },
  { selectValue: 'Asia/Dhaka', displayValue: '(GMT+06:00) Dhaka' },
  { selectValue: 'Asia/Omsk', displayValue: '(GMT+06:00) Omsk' },
  { selectValue: 'Asia/Thimphu', displayValue: '(GMT+06:00) Thimphu' },
  { selectValue: 'Asia/Urumqi', displayValue: '(GMT+06:00) Urumqi' },
  { selectValue: 'Indian/Cocos', displayValue: '(GMT+06:30) Cocos' },
  { selectValue: 'Asia/Bangkok', displayValue: '(GMT+07:00) Bangkok' },
  { selectValue: 'Indian/Christmas', displayValue: '(GMT+07:00) Christmas' },
  { selectValue: 'Asia/Ho_Chi_Minh', displayValue: '(GMT+07:00) Ho Chi Minh' },
  { selectValue: 'Asia/Hovd', displayValue: '(GMT+07:00) Hovd' },
  { selectValue: 'Asia/Jakarta', displayValue: '(GMT+07:00) Jakarta' },
  { selectValue: 'Asia/Krasnoyarsk', displayValue: '(GMT+07:00) Krasnoyarsk' },
  { selectValue: 'Asia/Novokuznetsk', displayValue: '(GMT+07:00) Novokuznetsk' },
  { selectValue: 'Asia/Novosibirsk', displayValue: '(GMT+07:00) Novosibirsk' },
  { selectValue: 'Asia/Phnom_Penh', displayValue: '(GMT+07:00) Phnom Penh' },
  { selectValue: 'Asia/Pontianak', displayValue: '(GMT+07:00) Pontianak' },
  { selectValue: 'Asia/Vientiane', displayValue: '(GMT+07:00) Vientiane' },
  { selectValue: 'Asia/Brunei', displayValue: '(GMT+08:00) Brunei' },
  { selectValue: 'Asia/Choibalsan', displayValue: '(GMT+08:00) Choibalsan' },
  { selectValue: 'Asia/Hong_Kong', displayValue: '(GMT+08:00) Hong Kong' },
  { selectValue: 'Asia/Irkutsk', displayValue: '(GMT+08:00) Irkutsk' },
  { selectValue: 'Asia/Kuala_Lumpur', displayValue: '(GMT+08:00) Kuala Lumpur' },
  { selectValue: 'Asia/Kuching', displayValue: '(GMT+08:00) Kuching' },
  { selectValue: 'Asia/Macau', displayValue: '(GMT+08:00) Macau' },
  { selectValue: 'Asia/Makassar', displayValue: '(GMT+08:00) Makassar' },
  { selectValue: 'Asia/Manila', displayValue: '(GMT+08:00) Manila' },
  { selectValue: 'Australia/Perth', displayValue: '(GMT+08:00) Perth' },
  { selectValue: 'Asia/Shanghai', displayValue: '(GMT+08:00) Shanghai' },
  { selectValue: 'Asia/Singapore', displayValue: '(GMT+08:00) Singapore' },
  { selectValue: 'Asia/Taipei', displayValue: '(GMT+08:00) Taipei' },
  { selectValue: 'Asia/Ulaanbaatar', displayValue: '(GMT+08:00) Ulaanbaatar' },
  { selectValue: 'Australia/Eucla', displayValue: '(GMT+08:45) Eucla' },
  { selectValue: 'Asia/Dili', displayValue: '(GMT+09:00) Dili' },
  { selectValue: 'Asia/Jayapura', displayValue: '(GMT+09:00) Jayapura' },
  { selectValue: 'Pacific/Palau', displayValue: '(GMT+09:00) Palau' },
  { selectValue: 'Asia/Pyongyang', displayValue: '(GMT+09:00) Pyongyang' },
  { selectValue: 'Asia/Seoul', displayValue: '(GMT+09:00) Seoul' },
  { selectValue: 'Asia/Tokyo', displayValue: '(GMT+09:00) Tokyo' },
  { selectValue: 'Asia/Yakutsk', displayValue: '(GMT+09:00) Yakutsk' },
  { selectValue: 'Australia/Adelaide', displayValue: '(GMT+09:30) Adelaide' },
  { selectValue: 'Australia/Broken_Hill', displayValue: '(GMT+09:30) Broken Hill' },
  { selectValue: 'Australia/Darwin', displayValue: '(GMT+09:30) Darwin' },
  { selectValue: 'Australia/Brisbane', displayValue: '(GMT+10:00) Brisbane' },
  { selectValue: 'Pacific/Guam', displayValue: '(GMT+10:00) Guam' },
  { selectValue: 'Australia/Hobart', displayValue: '(GMT+10:00) Hobart' },
  { selectValue: 'Australia/Lindeman', displayValue: '(GMT+10:00) Lindeman' },
  { selectValue: 'Australia/Melbourne', displayValue: '(GMT+10:00) Melbourne' },
  { selectValue: 'Pacific/Port_Moresby', displayValue: '(GMT+10:00) Port Moresby' },
  { selectValue: 'Pacific/Saipan', displayValue: '(GMT+10:00) Saipan' },
  { selectValue: 'Australia/Sydney', displayValue: '(GMT+10:00) Sydney' },
  { selectValue: 'Asia/Vladivostok', displayValue: '(GMT+10:00) Vladivostok' },
  { selectValue: 'Australia/Lord_Howe', displayValue: '(GMT+10:30) Lord Howe' },
  { selectValue: 'Pacific/Efate', displayValue: '(GMT+11:00) Efate' },
  { selectValue: 'Pacific/Guadalcanal', displayValue: '(GMT+11:00) Guadalcanal' },
  { selectValue: 'Pacific/Kosrae', displayValue: '(GMT+11:00) Kosrae' },
  { selectValue: 'Asia/Magadan', displayValue: '(GMT+11:00) Magadan' },
  { selectValue: 'Pacific/Norfolk', displayValue: '(GMT+11:00) Norfolk' },
  { selectValue: 'Pacific/Noumea', displayValue: '(GMT+11:00) Noumea' },
  { selectValue: 'Asia/Sakhalin', displayValue: '(GMT+11:00) Sakhalin' },
  { selectValue: 'Asia/Anadyr', displayValue: '(GMT+12:00) Anadyr' },
  { selectValue: 'Pacific/Auckland', displayValue: '(GMT+12:00) Auckland' },
  { selectValue: 'Pacific/Fiji', displayValue: '(GMT+12:00) Fiji' },
  { selectValue: 'Pacific/Funafuti', displayValue: '(GMT+12:00) Funafuti' },
  { selectValue: 'Asia/Kamchatka', displayValue: '(GMT+12:00) Kamchatka' },
  { selectValue: 'Pacific/Kwajalein', displayValue: '(GMT+12:00) Kwajalein' },
  { selectValue: 'Pacific/Majuro', displayValue: '(GMT+12:00) Majuro' },
  { selectValue: 'Pacific/Nauru', displayValue: '(GMT+12:00) Nauru' },
  { selectValue: 'Pacific/Tarawa', displayValue: '(GMT+12:00) Tarawa' },
  { selectValue: 'Pacific/Wake', displayValue: '(GMT+12:00) Wake' },
  { selectValue: 'Pacific/Wallis', displayValue: '(GMT+12:00) Wallis' },
  { selectValue: 'Pacific/Chatham', displayValue: '(GMT+12:45) Chatham' },
  { selectValue: 'Pacific/Apia', displayValue: '(GMT+13:00) Apia' },
  { selectValue: 'Pacific/Fakaofo', displayValue: '(GMT+13:00) Fakaofo' },
  { selectValue: 'Pacific/Tongatapu', displayValue: '(GMT+13:00) Tongatapu' },
  { selectValue: 'Pacific/Kiritimati', displayValue: '(GMT+14:00) Kiritimati' }
]
