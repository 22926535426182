import { ReactElement } from 'react'
import { useDispatch } from 'react-redux'

import { BookingModal } from '..'
import { BookingModalDetailsType, BookingStatus } from '../../types/bookings'
import { EDIT_BOOKINGS_REQUEST } from '../../redux/constants'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'

type BookingModalProps = {
  open: boolean
  setOpen: () => void
  type: string
  details: BookingModalDetailsType
}

type bookingDetailsToSend = {
  address: string
  agent_name?: string
  booking_date: string
  booking_time: string
  contact_number?: string
  note: string
  property_id?: string
  title?: string
  type: string
  tz?: string
  url?: string
}

export const EditBooking = ({ open, setOpen, type, details }: BookingModalProps): ReactElement => {
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()

  const updateBooking = (values: bookingDetailsToSend, type?: string) => {
    if (values && customer) {
      const newBookingDetails = {
        ...values,
        status: type === 'update' || type === 'convert' ? BookingStatus.APPROVED : BookingStatus.CANCELLED
      }
      if (newBookingDetails.type !== 'property_visits') delete newBookingDetails.property_id
      dispatch({
        type: EDIT_BOOKINGS_REQUEST,
        payload: {
          userId: customer.linked_user_id,
          bookingId: details.booking_id,
          newBookingDetails,
          modificationStatus: type === 'update' ? 'Booking Edited' : 'Booking Converted'
        }
      })
    }
  }

  return <BookingModal open={open} setOpen={setOpen} type={type} details={details} modalAction={updateBooking} />
}
