import { ReactElement } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Paper, Typography, Chip, Avatar, Button } from '@material-ui/core'
import FaceIcon from '@material-ui/icons/Error'
import { TaskType } from '../../types/tasks'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '300px',
    margin: '10px 20px',
    padding: '30px 20px 10px 20px',
    boxSizing: 'border-box'
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1
  }
}))

export function Task({ title, body, createdAt, createdBy, assignedTo, mover, overdue, done }: TaskType): ReactElement {
  const classes = useStyles()
  const theme = useTheme()

  return (
    <Paper
      data-testid="task-container"
      className={classes.container}
      elevation={done ? 1 : 3}
      style={{ backgroundColor: done ? theme.palette.background.default : theme.palette.background.paper }}
    >
      <Typography variant="body2" color="primary" style={{ textDecoration: 'underline', textAlign: 'right' }}>
        {mover}
      </Typography>

      {done && (
        <Typography variant="body2" color="textSecondary" style={{ position: 'absolute', top: 15, left: 20 }}>
          Done
        </Typography>
      )}

      {overdue && (
        <Chip
          variant="outlined"
          size="small"
          icon={<FaceIcon />}
          label={'Overdue'}
          color="primary"
          style={{ position: 'absolute', top: 10, left: 20 }}
        />
      )}
      <Typography variant="h5">{title}</Typography>
      <Typography variant="subtitle2" color="textSecondary">{`By ${createdBy}`}</Typography>
      <br />
      <Typography variant="body1">{body}</Typography>
      <br />
      <Typography variant="subtitle1" color="textSecondary">{`Due date: ${createdAt}`}</Typography>
      <br />
      <Typography variant="body2">Assigned to:</Typography>
      {assignedTo.map((item) => {
        return (
          <Chip
            key={item.userId}
            size="small"
            avatar={<Avatar>{item.avatar}</Avatar>}
            label={item.name}
            color="secondary"
            style={{ marginRight: '5px', marginTop: '5px' }}
          />
        )
      })}
      <br />
      <div style={{ textAlign: 'right', marginTop: '10px', height: '40px' }}>
        {!done && <Button color="primary">Mark as Done</Button>}
      </div>
    </Paper>
  )
}
