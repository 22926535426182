import { FiltersType, PriorityType } from '../types/state'

export const urlPattern =
  '(https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|www\\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\\.[^\\s]{2,}|https?:\\/\\/(?:www\\.|(?!www))[a-zA-Z0-9]+\\.[^\\s]{2,}|www\\.[a-zA-Z0-9]+\\.[^\\s]{2,})'

export const makeRandomId = (length: number): string => {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const makeFirstLetterCapital = (text: string): string => {
  if (text === 'vip') return 'VIP'
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export const getSelectedPriorities = (checkState: PriorityType): FiltersType => {
  const { countries, paid, unpaid, vip, high, medium, low } = checkState

  const priorities: FiltersType = { destination: [], has_paid: [], priority: [] }

  // Adding values to respective arrays

  if (priorities.destination) priorities.destination.push(...countries)

  if (paid && priorities.has_paid) priorities.has_paid.push(true)

  if (unpaid && priorities.has_paid) priorities.has_paid.push(false)

  if (vip && priorities.priority) priorities.priority.push('vip')

  if (high && priorities.priority) priorities.priority.push('high')

  if (medium && priorities.priority) priorities.priority.push('medium')

  if (low && priorities.priority) priorities.priority.push('low')

  // Removing keys with empty arrays

  if (priorities.destination?.length === 0) delete priorities.destination
  if (priorities.has_paid?.length === 0) delete priorities.has_paid
  if (priorities.priority?.length === 0) delete priorities.priority

  return priorities
}

export const scrollTo = (position: string): void => {
  document.getElementById(position)?.scrollIntoView()
}
