import React, { ReactElement, useCallback } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Button, Container, Typography, Box } from '@material-ui/core'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import glitchyPerchie from '../../assets/glitchy-perchie.png'

const useStyles = makeStyles({
  root: {
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: '0 auto'
  },
  img: {
    height: 250,
    width: 250
  },
  text: {
    textAlign: 'center'
  },
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row'
  },
  button: {
    margin: '0 5px',
    backgroundColor: '#e90000',
    '&:hover': {
      backgroundColor: '#b11212',
      '@media (hover: none)': {
        backgroundColor: '#b11212'
      }
    }
  },
  helpButton: {
    backgroundColor: '#0094fb',
    '&:hover': {
      backgroundColor: '#1961ae',
      '@media (hover: none)': {
        backgroundColor: '#1961ae'
      }
    }
  }
})

export const ErrorView = (): ReactElement => {
  const classes = useStyles()
  const theme = useTheme()
  const compact = useMediaQuery(theme.breakpoints.down(720))

  const handleReset = useCallback(() => {
    localStorage.removeItem('persist:root')
    window.location.reload()
  }, [])

  return (
    <Container className={classes.root}>
      <img src={glitchyPerchie} alt="glitchy perchie" className={classes.img} />
      <Typography variant="h6" className={classes.text}>
        Sorry... there was an error <br />
      </Typography>
      <Box height={10} />
      <div className={classes.buttonsWrapper}>
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth={compact}
          onClick={window.openZendeskWidget}
          className={`${classes.button} ${classes.helpButton}`}
          startIcon={<HelpOutlineIcon />}
        >
          Help
        </Button>
        <Button
          color="primary"
          size="large"
          variant="contained"
          fullWidth={compact}
          onClick={handleReset}
          className={classes.button}
        >
          Reset
        </Button>
      </div>
    </Container>
  )
}
