import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  UPDATE_DOCUMENTS_REQUEST,
  UPDATE_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENTS_FAILURE,
  RESET_DOCUMENTS_STATUS
} from '../../constants'
import { DocumentState } from '../../../types/state'
import { AnyAction } from 'redux'

const initialState: DocumentState = {
  storedUserId: '',
  fetchDocumentsRequested: true,
  updateDocumentsRequested: false,
  documents: undefined,
  status: undefined
}

export default (state = initialState, action: AnyAction): DocumentState => {
  switch (action.type) {
    case FETCH_DOCUMENTS_REQUEST:
      return {
        ...state,
        fetchDocumentsRequested: true,
        status: undefined
      }

    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        storedUserId: action.payload.userId,
        documents: action.payload.data,
        fetchDocumentsRequested: false,
        status: 'Documents Fetched'
      }

    case FETCH_DOCUMENTS_FAILURE:
      return {
        ...state,
        fetchDocumentsRequested: false,
        status: 'Documents Fetching Failed'
      }

    case UPDATE_DOCUMENTS_REQUEST:
      return {
        ...state,
        updateDocumentsRequested: true,
        status: undefined
      }

    case UPDATE_DOCUMENTS_SUCCESS:
      return {
        ...state,
        documents: action.payload.documentLinks,
        updateDocumentsRequested: false,
        status: 'Documents Updated'
      }

    case UPDATE_DOCUMENTS_FAILURE:
      return {
        ...state,
        updateDocumentsRequested: false,
        status: 'Documents Updating Failed'
      }

    case RESET_DOCUMENTS_STATUS:
      return {
        ...state,
        status: undefined
      }

    default:
      return state
  }
}
