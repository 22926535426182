import { Fragment, ReactElement } from 'react'
import { useConfigureAnalytics } from './analytics'
import { TrackedRoute } from './types'
import { getAuthState } from '../redux/selectors'
import { useSelector } from 'react-redux'
export function SetupAnalytics({ routes }: { routes: TrackedRoute[] }): ReactElement {
  const { currentUser } = useSelector(getAuthState)
  useConfigureAnalytics(routes, currentUser)
  return <Fragment />
}
