import { ReactElement, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Fab } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    padding: '30px 30px 10px 30px',
    display: 'flex',
    alignItems: 'center'
  },
  search: {
    width: '300px'
  },
  add: {
    marginLeft: 'auto'
  }
}))

export function TasksHeader(): ReactElement {
  const classes = useStyles()

  return (
    <Fragment>
      <div data-testid="thread-header-container" className={classes.container}>
        <ToggleButtonGroup
          size="small"
          value={'all'}
          exclusive
          onChange={(value) => {
            // eslint-disable-next-line no-console
            console.log(value)
          }}
        >
          <ToggleButton value="all" size="small" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            {/* <Typography variant="body1">My Tasks</Typography> */}
            All
          </ToggleButton>
          <ToggleButton value="mine" size="small" style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            {/* <Typography variant="body1">All Tasks</Typography> */}
            assigned to me
          </ToggleButton>
        </ToggleButtonGroup>
        <Fab className={classes.add} color="secondary" size="medium" aria-label="edit">
          <AddIcon />
        </Fab>
      </div>
      <Divider variant="middle" />
    </Fragment>
  )
}
