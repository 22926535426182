import { MouseEventHandler, ReactElement, useEffect, useReducer } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
  CircularProgress
} from '@material-ui/core'
import { HomeOutlined, Add } from '@material-ui/icons'
import { v4 as uuidv4 } from 'uuid'
import { captureException } from '@sentry/react'

import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { PropertyUploadType } from '../../types/properties'
import { rooms } from '../../utils/constants'
import { RECOMMEND_MULTIPLE_PROPERTIES_REQUEST, SHOW_FEEDBACK } from '../../redux/constants'
import { getPropertyState } from '../../redux/selectors'
import { PropertyForm, PropertyFormProps } from '../PropertyForm'
import { analyticsActions, EventName } from '../../analytics'

const MAX_PROPERTIES = 9

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none'
  },
  nav: {
    marginTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr min-content'
  },
  properties: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2)
  },
  text: {
    marginTop: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    justifyContent: 'unset',
    padding: theme.spacing(3)
  }
}))

type State = {
  properties: PropertyUploadType[]
  activeProperty: PropertyUploadType
  loading: boolean
  hasErrors: boolean
}

enum ActionType {
  SAVE = 'save_properties',
  ADD = 'add_property',
  SWITCH = 'switch_property',
  UPDATE = 'update_property'
}

type Action =
  | { type: ActionType.SAVE; loading: State['loading'] }
  | { type: ActionType.ADD }
  | { type: ActionType.SWITCH; property: State['activeProperty'] }
  | {
      type: ActionType.UPDATE
      property: State['activeProperty']
      hasErrors: State['hasErrors']
    }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SAVE: {
      return { ...state, loading: action.loading }
    }
    case ActionType.ADD: {
      const activeProperty: PropertyUploadType = {
        id: uuidv4(),
        bedrooms: rooms.bedrooms[0],
        bathrooms: rooms.bedrooms[0],
        currency_code: 'EUR',
        images: [],
        is_off_listed: false
      }
      return {
        ...state,
        properties: [...state.properties, activeProperty],
        activeProperty
      }
    }
    case ActionType.SWITCH: {
      return {
        ...state,
        activeProperty: action.property
      }
    }
    case ActionType.UPDATE: {
      const properties = state.properties.map((property) => {
        if (property.id === action.property.id) {
          return action.property
        }
        return property
      })

      return {
        ...state,
        properties,
        activeProperty: action.property,
        hasErrors: action.hasErrors
      }
    }
    default: {
      return state
    }
  }
}

const initState = (): State => {
  const activeProperty: PropertyUploadType = {
    id: uuidv4(),
    bedrooms: rooms.bedrooms[0],
    bathrooms: rooms.bathrooms[0],
    currency_code: 'EUR',
    images: [],
    is_off_listed: false
  }

  return {
    properties: [activeProperty],
    activeProperty,
    loading: false,
    hasErrors: false
  }
}

export type MultiplePropertyUploadProps = {
  open: boolean
  onClose: () => void
}

export function MultiplePropertyUpload({ open, onClose }: MultiplePropertyUploadProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { propertiesToUploadStatus } = useSelector(getPropertyState)
  const { customer } = useInfoByMessageId()
  const [{ properties, activeProperty, loading, hasErrors }, localDispatch] = useReducer(reducer, initState())

  const disableCTA = hasErrors || loading

  const trackPropertiesUpload = (properties: PropertyUploadType[]) => {
    properties.forEach((property) => {
      const trackingName = property.is_off_listed ? 'submit-property-off-listed' : 'submit-property'
      analyticsActions.track({
        name: EventName.Submit,
        trackingName,
        ...(property.property_url && { realEstatePortal: new URL(property.property_url as string).hostname })
      })
    })
  }

  const handleAddProperty = () => {
    if (!hasErrors) {
      localDispatch({ type: ActionType.ADD })
    }
  }

  const handleSwitchProperty = (property: State['activeProperty']) => {
    localDispatch({ type: ActionType.SWITCH, property })
  }

  const handleUpdateProperty: PropertyFormProps['onChange'] = (property, hasErrors) => {
    localDispatch({ type: ActionType.UPDATE, property, hasErrors })
  }

  const handleOnSubmit: MouseEventHandler<HTMLButtonElement> = () => {
    if (!hasErrors) {
      localDispatch({ type: ActionType.SAVE, loading: true })
      trackPropertiesUpload(properties)
      dispatch({
        type: RECOMMEND_MULTIPLE_PROPERTIES_REQUEST,
        payload: { customerId: customer?.linked_user_id, propertiesToUpload: properties }
      })
    }
  }

  useEffect(() => {
    if (propertiesToUploadStatus && loading) {
      let severity = 'success'
      if (propertiesToUploadStatus === 'Multiple Properties Recommended') {
        onClose()
      } else {
        severity = 'error'
        captureException(new Error(`Multiple property upload failed - ${propertiesToUploadStatus}`))
      }
      dispatch({ type: SHOW_FEEDBACK, payload: { title: propertiesToUploadStatus, severity } })
      localDispatch({ type: ActionType.SAVE, loading: false })
    }
  }, [propertiesToUploadStatus, loading])

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" id="dialog-title">
          Recommended properties
        </Typography>
        <div className={classes.nav}>
          <div className={classes.properties}>
            {properties.map((property, i) => (
              <Button
                key={`property-${property.id}`}
                className={classes.button}
                variant="contained"
                size="large"
                startIcon={<HomeOutlined />}
                color={activeProperty === property ? 'secondary' : 'default'}
                disabled={loading}
                onClick={() => handleSwitchProperty(property)}
              >
                Property {i + 1}
              </Button>
            ))}
          </div>
          <Fab
            size="small"
            color="default"
            aria-label="add property"
            disabled={disableCTA || properties.length >= MAX_PROPERTIES}
            onClick={handleAddProperty}
          >
            <Add />
          </Fab>
        </div>
        <Typography id="dialog-description" variant="subtitle1" className={classes.text}>
          Recommended properties for <b>{customer?.user_name}</b>
        </Typography>
      </DialogTitle>
      <DialogContent key={`id-${activeProperty.id}`} classes={{ root: classes.content }}>
        <PropertyForm
          property={activeProperty}
          country={customer?.user_destination_country}
          disabled={loading}
          onChange={handleUpdateProperty}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleOnSubmit}
          size="large"
          variant="contained"
          disabled={disableCTA}
          color="primary"
          data-track-click="add-properties"
          {...(loading && {
            endIcon: <CircularProgress role="alert" aria-live="assertive" aria-label="Adding properties" size={15} />
          })}
        >
          Add properties
        </Button>
        <Button onClick={onClose} size="large" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
