import { TFlags, TFlagVariation } from '@flopflip/types'
import { FeatureFlagDetails, FeatureFlag } from './types'

export const featureFlagDetails: FeatureFlagDetails[] = [
  {
    flag: FeatureFlag.EXAMPLE_FLAG,
    default: false
  },
  {
    flag: FeatureFlag.CREATE_CONTENT_CARDS,
    default: false
  },
  {
    flag: FeatureFlag.SELECT_APPOINTMENT_TYPE,
    default: false
  },
  {
    flag: FeatureFlag.ALLOW_CARD_DELETION,
    default: false
  },
  {
    flag: FeatureFlag.REPLY_DOCUMENT_CHAT,
    default: false
  },
  {
    flag: FeatureFlag.USER_PACKAGE_SELECTION,
    default: false
  },
  {
    flag: FeatureFlag.CREATE_OFF_LISTED_PROPERTY,
    default: false
  },
  {
    flag: FeatureFlag.COMMENT_PROPERTY_RECOMMENDATION_CHAT,
    default: false
  },
  {
    flag: FeatureFlag.CHAT_DOCUMENTS_TAB,
    default: false
  },
  {
    flag: FeatureFlag.ASSIGN_PRIORITY,
    default: false
  },
  {
    flag: FeatureFlag.MARK_AS_PAID,
    default: false
  },
  {
    flag: FeatureFlag.ASSIGN_TEAM,
    default: false
  },
  {
    flag: FeatureFlag.RECOMMENDATIONS_TAB,
    default: false
  },
  {
    flag: FeatureFlag.RECOMMEND_SINGLE_PROPERTY,
    default: false
  },
  {
    flag: FeatureFlag.UPLOAD_DOCUMENT,
    default: false
  },
  {
    flag: FeatureFlag.RECOMMEND_MULTIPLE_PROPERTIES,
    default: false
  },
  {
    flag: FeatureFlag.EDIT_PROPERTY,
    default: false
  },
  {
    flag: FeatureFlag.APPOINTMENTS,
    default: false
  },
  {
    flag: FeatureFlag.DISABLE_OPS_V1,
    default: false
  }
]

export const defaultFlags: TFlags = featureFlagDetails.reduce((acc: { [key: string]: TFlagVariation }, curr) => {
  acc[curr.flag] = curr.default
  return acc
}, {})
