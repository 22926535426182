import { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Alert, AlertTitle } from '@material-ui/lab'

import { getAuthState, getMessageState } from '../../redux/selectors'
import { FETCH_MESSAGES_REQUEST } from '../../redux/constants'
import { Header, MessagesSidebar, Loader } from '../../components'
import { usePusherForMessages } from '../../hooks/usePusher'
import { Thread } from '..'
import smartlookClient from 'smartlook-client'
import { isProduction } from '../../utils/env'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh'
  },
  flexWrapper: {
    height: 'calc(100vh - 50px)',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1
  },
  messagesSidebar: {
    height: '100vh',
    width: '250px',
    padding: '0 10px',
    backgroundColor: theme.palette.primary.main
  },
  drawer: {
    width: '270px'
  },
  flexView: {
    position: 'fixed',
    top: 50,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.3)'
  }
}))

export function Messages(): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { userType, currentUser } = useSelector(getAuthState)
  const { messages } = useSelector(getMessageState)

  const { messageId } = useParams<{ messageId?: string }>()

  useEffect(() => {
    dispatch({ type: FETCH_MESSAGES_REQUEST })
  }, [])

  useEffect(() => {
    if (currentUser && isProduction) {
      const { user_id, name, email } = currentUser
      smartlookClient.identify(user_id, { name, email })
    }
  }, [currentUser])

  usePusherForMessages()

  const isVaUser = userType === 'va' && messages.length

  return (
    <div data-testid="messages-container" className={classes.container}>
      <Header />
      <div className={classes.flexWrapper}>
        <MessagesSidebar />
        <div className={classes.contentWrapper}>
          {messageId ? (
            <Thread />
          ) : isVaUser ? (
            <div className={classes.flexView} data-testid="error-view">
              <Alert variant="filled" severity="error">
                <AlertTitle>Error</AlertTitle>
                <div>There are no allocated users for your account.</div>
                <div>If this is an error, please contact your line-manager.</div>
              </Alert>
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  )
}
