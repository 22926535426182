import { MessageUser, UserStautsType } from './messages'
import { PropertyDetails } from './properties'

export type UserServiceItem = {
  stage?: number | null
  custom_name: string
  country?: string | null
  created_at?: string | null
  updated_at?: string | null
  url?: string | null
}

type UserServices = {
  available: UserServiceItem[]
  interested: UserServiceItem[]
  included: UserServiceItem[]
}

export type UserInfo = {
  info: MessageUser
  status?: UserStautsType & { userId: string }
  recommendationsProperties: UserInfoRecommendations
  services: UserServices
}

export type RecommendationsProperty = {
  property: PropertyDetails
  created_at: string
  updated_at: string
  status: string
}

type UserInfoRecommendations = {
  properties: RecommendationsProperty[]
  loading: boolean
  error: boolean
  status?: string
}

export type ReplyToChat = {
  sender: string
  chat: string
  chatId: string
}

export type FetchingStates = {
  requested: boolean
  success: boolean
  failure: boolean
}

export enum CardType {
  content = 'content',
  video_external = 'video_external',
  document_external = 'document_external'
}

export enum ContentPayloadAttribute {
  contentful_id = 'contentful_id',
  title = 'title',
  description = 'description',
  video_url = 'video_url',
  document_url = 'document_url'
}

export type CardTypeKey = keyof typeof CardType

export type ContentPayloadAttributeKey = keyof typeof ContentPayloadAttribute

export type ContentPayload = { type: CardTypeKey } & { [key in keyof typeof ContentPayloadAttribute]?: string }
