import axios, { AxiosPromise, Method } from 'axios'
import { BookingModalDetailsType } from '../types/bookings'
import { CommentChatType } from '../types/messages'
import { PropertyUploadType, UploadedFile } from '../types/properties'
import { ContentPayload } from '../types/thread'
import { UserBio } from '../types/users'

import baseUrl from '../utils/env'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type BodyType = { [key: string]: any }

type HeadersReturnType = {
  'Content-Type': string
}

type AuthHeaderReturnType = {
  Authorization: string
}

export default class Api {
  public headers(): HeadersReturnType {
    return {
      'Content-Type': 'application/json'
    }
  }

  public authHeaders(token: string): AuthHeaderReturnType {
    return {
      Authorization: 'Bearer ' + token
    }
  }

  public request(
    method: Method,
    path: string,
    token: string | null,
    data: BodyType | null,
    timeout = 5000
  ): AxiosPromise<string> {
    return axios({
      method: method,
      url: `${baseUrl}${path}`,
      headers: {
        'x-available-retries': method === 'get' ? 3 : 0,
        ...this.headers(),
        ...(token && this.authHeaders(token))
      },
      data: data,
      ...(method === 'get' && { timeout }),
      validateStatus: (status: number) => (status >= 200 && status < 300) || status === 401
    })
  }

  public login(email: string, password: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', '/login', '', { email, password })
  }

  public fetchMessages(token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', '/messages?page=1', token, null)
  }

  public fetchMoreMessages(currentPage: number, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages?page=${currentPage + 1}`, token, null)
  }

  public fetchLastMessage(token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', '/messages?fetch=1', token, null)
  }

  public fetchMessagePreview(messageID: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages/preview/${messageID}`, token, null)
  }

  public fetchFavoritedMessages(currentPage: number, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages/favorites?page=${currentPage + 1}`, token, null)
  }

  public toggleFavoriteMessage(messageID: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/messages/favorites/${messageID}`, token, null)
  }

  public fetchProperties(token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', '/properties', token, null)
  }

  public fetchChats(token: string, messageID: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages/${messageID}`, token, null)
  }

  public fetchMoreChats(token: string, messageID: string, currentPage: number): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages/${messageID}?page=${currentPage + 1}`, token, null)
  }

  public fetchChatsTillParent(token: string, messageID: string, chatID: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages/${messageID}/scroll/${chatID}`, token, null)
  }

  public fetchWholeThread(token: string, message_id: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/messages/${message_id}/chats`, token, null, 0)
  }

  public sendChat(token: string, messageID: string, chat: string, parent_id?: string): AxiosPromise<string> {
    const instance = new Api()
    const data = parent_id ? { chat, parent_id } : { chat }
    return instance.request('post', `/messages/${messageID}`, token, data)
  }

  public sendDocument(token: string, moverID: string, messageID: string, { file }: UploadedFile): AxiosPromise<string> {
    const instance = new Api()

    const formData = new FormData()
    formData.append('user_id', moverID)
    formData.append('document', file)
    formData.append('title', file.name)

    return instance.request('post', `/messages/${messageID}/document`, token, formData)
  }

  public markAsRead(token: string, messageID: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/messages/${messageID}/read`, token, null)
  }

  public deleteMessage(token: string, messageID: string, chatID: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('delete', `/messages/${messageID}/chats/${chatID}`, token, null)
  }

  public actionChat(token: string, chatID: string, data: Partial<CommentChatType>): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/${chatID}/actioned`, token, data)
  }

  public fetchPropertyDetails(propertyID: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/properties/${propertyID}`, token, null)
  }

  public fetchSearchResults(
    searchString: string,
    token: string,
    filters: string[],
    currentPage: number,
    favorited_messages: boolean
  ): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/messages/search?page=${currentPage + 1}`, token, {
      search: searchString,
      filters,
      favorited_messages
    })
  }

  public fetchUserDocument(documentId: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/user/documents/${documentId}`, token, null)
  }

  public fetchUserInfo(userId: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/users/${userId}/stats`, token, null)
  }

  public fetchUserServices(userId: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/users/${userId}/services`, token, null)
  }

  public updateUserServices(userId: string, token: string, services: string[], type: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${userId}/services`, token, {
      services,
      service_type: type
    })
  }

  public updateUserServicesURL(
    userId: string,
    token: string,
    service: { services: { [x: string]: string } }
  ): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('patch', `/users/${userId}/service-links`, token, service)
  }

  public fetchUser(token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', '/user', token, null)
  }

  public fetchNotes(token: string, userId: string, currentPage?: number): AxiosPromise<string> {
    const instance = new Api()
    const page = currentPage ? currentPage + 1 : 1
    return instance.request('get', `/users/${userId}/notes?page=${page}`, token, null)
  }

  public createNote(token: string, userId: string, note: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${userId}/notes`, token, {
      user_id: userId,
      note
    })
  }

  public deleteNote(token: string, userId: string, noteId: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('delete', `/users/${userId}/notes/${noteId}`, token, null)
  }

  public pinNote(token: string, userId: string, noteId: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${userId}/notes/${noteId}/pin`, token, null)
  }

  public unpinNote(token: string, userId: string, noteId: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${userId}/notes/${noteId}/unpin`, token, null)
  }

  public editNote(token: string, userId: string, noteId: string, note: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${userId}/notes/${noteId}`, token, {
      note
    })
  }

  public markUserPaid(token: string, userId: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${userId}/paid`, token, null)
  }

  public setPriority(token: string, userId: string, priority: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${userId}/priority`, token, { priority })
  }

  public fetchTeamMembers(token: string, message_id: string, search: string): AxiosPromise<string> {
    const instance = new Api()
    const searchQuery = search.length > 0 ? `?search=${search}` : ''
    return instance.request('get', `/messages/${message_id}/team${searchQuery}`, token, null)
  }

  public setTeamMembers(token: string, message_id: string, user_ids: string[]): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/messages/${message_id}/team`, token, { user_ids })
  }

  public fetchRecommendationsProperties(token: string, userId: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/users/${userId}/properties/recommendations`, token, null, 0)
  }

  public recommendProperty(user_id: string, token: string, property_id: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${user_id}/properties/recommend`, token, { property_id })
  }

  public unlistProperty(token: string, property_id: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/properties/${property_id}/unlist`, token, null)
  }

  public updateProperty(user_id: string, token: string, property: PropertyUploadType): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${user_id}/properties/${property.id}`, token, property)
  }

  public recommendMultipleProperties(
    user_id: string,
    token: string,
    properties: PropertyUploadType[]
  ): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/properties`, token, { user_id, properties })
  }

  public fetchBookings(userId: string, token: string, currentPage: number): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/users/${userId}/bookings/all?page=${currentPage}`, token, null)
  }

  public addBooking(user_id: string, token: string, bookingData: BookingModalDetailsType): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${user_id}/bookings/legacy`, token, bookingData)
  }

  public updateBookingDetails(
    token: string,
    userId: string,
    bookingId: string,
    newBookingDetails: BookingModalDetailsType
  ): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${userId}/bookings/${bookingId}`, token, newBookingDetails)
  }

  public fetchDocuments(user_id: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/users/${user_id}/profile`, token, null)
  }

  // eslint-disable-next-line
  public updateDocuments(user_id: string, token: string, documentLinks: any): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${user_id}/profile`, token, documentLinks)
  }

  public fetchUsers(search: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/cs-profiles/search`, token, { search })
  }

  public addUser(token: string, userDetails: Partial<UserBio>): AxiosPromise<string> | void {
    const instance = new Api()
    return instance.request('post', `/cs-profiles/create`, token, userDetails)
  }

  public updateUser(userId: string, token: string, userDetails: Partial<UserBio>): AxiosPromise<string> | void {
    const instance = new Api()
    return instance.request('put', `/users/${userId}/bio`, token, userDetails)
  }

  public createCard(user_id: string, token: string, data: ContentPayload): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('post', `/users/${user_id}/activity/feed`, token, data)
  }

  public fetchPackages(userId: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('get', `/users/${userId}/packages`, token, null)
  }

  public updatePackage(userId: string, packageId: string, token: string): AxiosPromise<string> {
    const instance = new Api()
    return instance.request('put', `/users/${userId}/packages`, token, { package: packageId })
  }
}
