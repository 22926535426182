import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import { push } from 'connected-react-router'
import {
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  MARK_AS_READ_MESSAGE_REQUEST,
  MARK_AS_READ_MESSAGE_SUCCESS,
  MARK_AS_READ_MESSAGE_FAILURE,
  FETCH_MORE_MESSAGES_REQUEST,
  FETCH_MORE_MESSAGES_SUCCESS,
  FETCH_MORE_MESSAGES_FAILURE,
  FETCH_LAST_MESSAGE_REQUEST,
  FETCH_LAST_MESSAGE_SUCCESS,
  FETCH_LAST_MESSAGE_FAILURE,
  SEARCH_MESSAGES_REQUEST,
  SEARCH_MESSAGES_SUCCESS,
  SEARCH_MESSAGES_FAILURE,
  FETCH_MESSAGE_BY_ID_SUCCESS,
  FETCH_MESSAGE_BY_ID_FAILURE,
  FETCH_MESSAGE_BY_ID_REQUEST,
  TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST,
  TOGGLE_FAVORITE_MESSAGE_STATUS_SUCCESS,
  TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE,
  FETCH_FAVORITED_MESSAGES_REQUEST,
  FETCH_MORE_FAVORITED_MESSAGES_REQUEST,
  FETCH_FAVORITED_MESSAGES_FAILURE,
  FETCH_FAVORITED_MESSAGES_SUCCESS,
  TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST,
  TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_SUCCESS,
  CLEAR_SEARCH_RESULT_REQUEST,
  UPDATE_TEAM_MEMBERS_SUCCESS,
  UPDATE_TEAM_MEMBERS_FAILURE,
  UPDATE_TEAM_MEMBERS_REQUEST,
  FETCH_ASSIGNED_TEAM_MEMBERS_FAILURE,
  FETCH_ASSIGNED_TEAM_MEMBERS_SUCCESS,
  FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST,
  SHOW_FEEDBACK
} from '../../constants'
import { getAuthState, getIdFromPath, getMessageState } from '../../selectors'
import type { PayloadType } from '../../../types/state'
import Api from '../../api'

const instance = new Api()

export function* fetchMessagesFn(): SagaIterator {
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchMessages, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_MESSAGES_SUCCESS, payload: response.data })
      const messageId = yield select(getIdFromPath)

      if (!messageId) {
        yield put(push(`/messages/${response.data.data.messages.data[0].message_id}`))
      }
    } else {
      yield put({ type: FETCH_MESSAGES_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_MESSAGES_FAILURE })
  }
}

export function* markAsReadMessageFn({ payload }: PayloadType): SagaIterator {
  const { messageId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.markAsRead, token, messageId)
    if (response && response.data && response.status === 200) {
      yield put({ type: MARK_AS_READ_MESSAGE_SUCCESS, payload: response.data })
    } else {
      yield put({ type: MARK_AS_READ_MESSAGE_FAILURE })
    }
  } catch (err) {
    yield put({ type: MARK_AS_READ_MESSAGE_FAILURE })
  }
}

export function* fetchMoreMessagesFn({ payload }: PayloadType): SagaIterator {
  const { currentPage } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchMoreMessages, currentPage, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_MORE_MESSAGES_SUCCESS, payload: response.data })
    } else {
      yield put({ type: FETCH_MORE_MESSAGES_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_MORE_MESSAGES_FAILURE })
  }
}

export function* fetchLastMessageFn(): SagaIterator {
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchLastMessage, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_LAST_MESSAGE_SUCCESS, payload: response.data })
    } else {
      yield put({ type: FETCH_LAST_MESSAGE_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_LAST_MESSAGE_FAILURE })
  }
}

export function* fetchMessageByIdFn({ payload }: PayloadType): SagaIterator {
  const { token } = yield select(getAuthState)
  const { messageId } = payload

  try {
    const response = yield call(instance.fetchMessagePreview, messageId, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_MESSAGE_BY_ID_SUCCESS, payload: response.data })
    } else {
      yield put({ type: FETCH_MESSAGE_BY_ID_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_MESSAGE_BY_ID_FAILURE })
  }
}

export function* searchMessagesByNameFn({ payload }: PayloadType): SagaIterator {
  const { searchString, priorities, searchMessagesCurrentPage, loadMore } = payload
  const { token } = yield select(getAuthState)
  const { showFavoritedMessages } = yield select(getMessageState)

  try {
    const response = yield call(
      instance.fetchSearchResults,
      searchString,
      token,
      priorities,
      searchMessagesCurrentPage,
      showFavoritedMessages
    )
    if (response && response.data && response.status === 200) {
      yield put({
        type: SEARCH_MESSAGES_SUCCESS,
        payload: { messages: response.data.data.data, meta: response.data.data.meta, loadMore }
      })
    } else {
      yield put({ type: SEARCH_MESSAGES_FAILURE })
    }
  } catch (err) {
    yield put({ type: SEARCH_MESSAGES_FAILURE })
  }
}

export function* clearSearchResultsFn(): SagaIterator {
  const { showFavoritedMessages } = yield select(getMessageState)

  yield put({ type: showFavoritedMessages ? FETCH_FAVORITED_MESSAGES_REQUEST : FETCH_MESSAGES_REQUEST })
}

export function* fetchFavoritedMessagesFn(): SagaIterator {
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchFavoritedMessages, 0, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_FAVORITED_MESSAGES_SUCCESS, payload: { messages: response.data.data.messages } })
    } else {
      // change it to put failed notification action
      yield put({ type: FETCH_FAVORITED_MESSAGES_FAILURE })
    }
  } catch (err) {
    // change it to put failed notification action
    yield put({ type: FETCH_FAVORITED_MESSAGES_FAILURE })
  }
}

export function* fetchMoreFavoritedMessagesFn({ payload }: PayloadType): SagaIterator {
  const { currentPage } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchFavoritedMessages, currentPage, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_MORE_MESSAGES_SUCCESS, payload: response.data })
    } else {
      yield put({ type: FETCH_MORE_MESSAGES_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_MORE_MESSAGES_FAILURE })
  }
}

export function* toggleFavoriteMessageFn({ type, payload }: PayloadType): SagaIterator {
  const { message_id } = payload
  const { token } = yield select(getAuthState)
  const successActionTypeToDispatch =
    type === TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST
      ? TOGGLE_FAVORITE_MESSAGE_STATUS_SUCCESS
      : TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_SUCCESS

  try {
    const response = yield call(instance.toggleFavoriteMessage, message_id, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: successActionTypeToDispatch, payload: { message_id } })
    } else {
      yield put({ type: TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
  }
}

export function* fetchTeamMembersFn({ payload }: PayloadType): SagaIterator {
  const { search } = payload
  const { token } = yield select(getAuthState)
  const messageId = yield select(getIdFromPath)

  try {
    const response = yield call(instance.fetchTeamMembers, token, messageId, search)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_ASSIGNED_TEAM_MEMBERS_SUCCESS, payload: { users: response.data.data } })
    } else {
      yield put({ type: FETCH_ASSIGNED_TEAM_MEMBERS_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: FETCH_ASSIGNED_TEAM_MEMBERS_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
  }
}

export function* setTeamMembersFn({ payload }: PayloadType): SagaIterator {
  const { users } = payload
  const { token } = yield select(getAuthState)
  const messageId = yield select(getIdFromPath)
  const user_ids = Object.keys(users)
  const usersData = Object.values(users)

  try {
    const response = yield call(instance.setTeamMembers, token, messageId, user_ids)
    if (response && response.data && response.status === 200) {
      yield put({ type: UPDATE_TEAM_MEMBERS_SUCCESS, payload: { messageId, users: usersData } })
    } else {
      yield put({ type: UPDATE_TEAM_MEMBERS_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: UPDATE_TEAM_MEMBERS_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
  }
}

export default function* mesageSaga(): Generator {
  yield all([
    takeEvery(FETCH_MESSAGES_REQUEST, fetchMessagesFn),
    takeEvery(MARK_AS_READ_MESSAGE_REQUEST, markAsReadMessageFn),
    takeEvery(FETCH_MORE_MESSAGES_REQUEST, fetchMoreMessagesFn),
    takeEvery(FETCH_LAST_MESSAGE_REQUEST, fetchLastMessageFn),
    takeEvery(FETCH_MESSAGE_BY_ID_REQUEST, fetchMessageByIdFn),
    takeEvery(SEARCH_MESSAGES_REQUEST, searchMessagesByNameFn),
    takeEvery(FETCH_FAVORITED_MESSAGES_REQUEST, fetchFavoritedMessagesFn),
    takeEvery(CLEAR_SEARCH_RESULT_REQUEST, clearSearchResultsFn),
    takeEvery(FETCH_MORE_FAVORITED_MESSAGES_REQUEST, fetchMoreFavoritedMessagesFn),
    takeEvery(TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST, toggleFavoriteMessageFn),
    takeEvery(TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST, toggleFavoriteMessageFn),
    takeEvery(FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST, fetchTeamMembersFn),
    takeEvery(UPDATE_TEAM_MEMBERS_REQUEST, setTeamMembersFn)
  ])
}
