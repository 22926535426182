import ReactDOM from 'react-dom'
import App from './App'
import { ErrorView } from './screens'
import { ErrorBoundary } from '@sentry/react'
import './monitoring/monitoring'
import './index.css'

ReactDOM.render(
  <ErrorBoundary fallback={ErrorView}>
    <App />
  </ErrorBoundary>,

  document.getElementById('root')
)
