import { createSelector } from 'reselect'
import { RouterState } from 'connected-react-router'
import { memoize } from 'lodash'
import moment from 'moment'

import type { PropertyDetails } from '../../types/properties'
import type {
  AuthState,
  ThreadState,
  MessageState,
  RootState,
  NoteState,
  BookingState,
  PropertyState,
  DocumentState,
  UsersState,
  FeedbackState,
  PackageState
} from '../../types/state'
import type { MessageUser, ThreadType, ThreadChat } from '../../types/messages'

const getAuthState = (state: RootState): AuthState => state.authState
const getMessageState = (state: RootState): MessageState => state.messageState
const getThreadState = (state: RootState): ThreadState => state.threadState
const getNoteState = (state: RootState): NoteState => state.noteState
const getBookingState = (state: RootState): BookingState => state.bookingState
const getPropertyState = (state: RootState): PropertyState => state.propertyState
const getDocumentState = (state: RootState): DocumentState => state.documentState
const getUsersState = (state: RootState): UsersState => state.usersState
const getRouterState = (state: RootState): RouterState => state.router
const getFeedbackState = (state: RootState): FeedbackState => state.feedback
const getPackagesState = (state: RootState): PackageState => state.packageState

const getPropertyById = createSelector(getPropertyState, ({ properties }) =>
  memoize((propertyId?: string): PropertyDetails | undefined => {
    // this condition is needed because we use this selector when there could be no propertyId (see useContent hook)
    if (!propertyId) {
      return undefined
    }

    return properties && properties.find(({ property_id }) => property_id === propertyId)
  })
)

const getThreadByMessageId = createSelector(getThreadState, ({ threads }) =>
  memoize((messageId: string): ThreadType | undefined => {
    return threads && threads.find(({ message }) => message.message_id === messageId)
  })
)

const getCustomerByMessageId = createSelector(getThreadByMessageId, (threadSelector) =>
  memoize((messageId: string): MessageUser | undefined => {
    const thread = threadSelector(messageId)
    return thread && thread.message.group.find(({ user_type }) => user_type === 'tenant')
  })
)

const getSortedChatsByMessageId = createSelector(getThreadByMessageId, (threadSelector) =>
  memoize((messageId: string): ThreadChat[] | undefined => {
    const thread = threadSelector(messageId)

    if (!thread) {
      return
    }

    const { chats = [], pending = [], undeliveredChats = [] } = thread
    // we are filtering out null/undefined chats that may exist locally as a side effect of OP-115
    const combineChats = [...chats, ...pending, ...undeliveredChats].filter((chat) => Boolean(chat))

    return combineChats
      .sort((chatOne: ThreadChat, chatTwo: ThreadChat) => {
        const chatOneCreated = moment(chatOne.created_at)
        const chatTwoCreated = moment(chatTwo.created_at)

        if (chatOneCreated < chatTwoCreated) return 1
        if (chatOneCreated > chatTwoCreated) return -1

        return 0
      })
      .reverse()
  })
)

const getIdFromPath = createSelector(getRouterState, ({ location: { pathname } }) => {
  const regex = /\/messages\/|\/users\//

  if (regex.test(pathname)) {
    const id = pathname.split('/')
    return id[id.length - 1]
  }

  return ''
})

export {
  getAuthState,
  getMessageState,
  getThreadState,
  getNoteState,
  getBookingState,
  getPropertyState,
  getDocumentState,
  getUsersState,
  getPropertyById,
  getThreadByMessageId,
  getCustomerByMessageId,
  getSortedChatsByMessageId,
  getIdFromPath,
  getFeedbackState,
  getPackagesState
}
