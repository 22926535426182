import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  FETCH_DOCUMENTS_REQUEST,
  FETCH_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENTS_FAILURE,
  UPDATE_DOCUMENTS_REQUEST,
  UPDATE_DOCUMENTS_SUCCESS,
  UPDATE_DOCUMENTS_FAILURE
} from '../../constants'
import { PayloadType } from '../../../types/state'
import Api from '../../api'
import { getAuthState } from '../../selectors'

const instance = new Api()

export function* fetchDocumentsFn({ payload }: PayloadType): SagaIterator {
  const { userId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchDocuments, userId, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_DOCUMENTS_SUCCESS, payload: { data: response.data.data.profile, userId } })
    } else {
      yield put({ type: FETCH_DOCUMENTS_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_DOCUMENTS_FAILURE })
  }
}

export function* updateDocumentsFn({ payload }: PayloadType): SagaIterator {
  const { userId, documentLinks } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.updateDocuments, userId, token, documentLinks)
    if (response && response.status === 200) {
      yield put({ type: UPDATE_DOCUMENTS_SUCCESS, payload: { documentLinks } })
    } else {
      yield put({ type: UPDATE_DOCUMENTS_FAILURE })
    }
  } catch (error) {
    yield put({ type: UPDATE_DOCUMENTS_FAILURE })
  }
}

export default function* documentSaga(): Generator {
  yield all([
    takeEvery(FETCH_DOCUMENTS_REQUEST, fetchDocumentsFn),
    takeEvery(UPDATE_DOCUMENTS_REQUEST, updateDocumentsFn)
  ])
}
