// Auth
export const LOGIN_USER_REQUEST = 'auth/LOGIN_USER_REQUEST'
export const LOGIN_USER_SUCCESS = 'auth/LOGIN_USER_SUCCESS'
export const LOGIN_USER_FAILURE = 'auth/LOGIN_USER_FAILURE'

export const FETCH_USER_REQUEST = 'auth/FETCH_USER_REQUEST'
export const FETCH_USER_SUCCESS = 'auth/FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'auth/FETCH_USER_FAILURE'

export const LOGOUT_USER_REQUEST = 'auth/LOGOUT_USER_REQUEST'

// Message
export const FETCH_MESSAGES_REQUEST = 'message/FETCH_MESSAGES_REQUEST'
export const FETCH_MESSAGES_SUCCESS = 'message/FETCH_MESSAGES_SUCCESS'
export const FETCH_MESSAGES_FAILURE = 'message/FETCH_MESSAGES_FAILURE'

export const UPDATE_LAST_CHAT_REQUEST = 'message/UPDATE_LAST_CHAT_REQUEST'
export const UPDATE_LAST_CHAT_SUCCESS = 'message/UPDATE_LAST_CHAT_SUCCESS'
export const UPDATE_LAST_CHAT_FAILURE = 'message/UPDATE_LAST_CHAT_FAILURE'

export const UPDATE_LAST_CHAT_IN_FILTERS_REQUEST = 'message/UPDATE_LAST_CHAT_IN_FILTERS_REQUEST'

export const MARK_AS_READ_MESSAGE_REQUEST = 'message/MARK_AS_READ_MESSAGE_REQUEST'
export const MARK_AS_READ_MESSAGE_SUCCESS = 'message/MARK_AS_READ_MESSAGE_SUCCESS'
export const MARK_AS_READ_MESSAGE_FAILURE = 'message/MARK_AS_READ_MESSAGE_FAILURE'

export const FETCH_MORE_MESSAGES_REQUEST = 'message/FETCH_MORE_MESSAGES_REQUEST'
export const FETCH_MORE_MESSAGES_SUCCESS = 'message/FETCH_MORE_MESSAGES_SUCCESS'
export const FETCH_MORE_MESSAGES_FAILURE = 'message/FETCH_MORE_MESSAGES_FAILURE'

export const FETCH_LAST_MESSAGE_REQUEST = 'message/FETCH_LAST_MESSAGE_REQUEST'
export const FETCH_LAST_MESSAGE_SUCCESS = 'message/FETCH_LAST_MESSAGE_SUCCESS'
export const FETCH_LAST_MESSAGE_FAILURE = 'message/FETCH_LAST_MESSAGE_FAILURE'

export const FETCH_MESSAGE_BY_ID_REQUEST = 'message/FETCH_MESSAGE_BY_ID_REQUEST'
export const FETCH_MESSAGE_BY_ID_SUCCESS = 'message/FETCH_MESSAGE_BY_ID_SUCCESS'
export const FETCH_MESSAGE_BY_ID_FAILURE = 'message/FETCH_MESSAGE_BY_ID_FAILURE'

export const SEARCH_MESSAGES_REQUEST = 'message/SEARCH_MESSAGES_REQUEST'
export const SEARCH_MESSAGES_SUCCESS = 'message/SEARCH_MESSAGES_SUCCESS'
export const SEARCH_MESSAGES_FAILURE = 'message/SEARCH_MESSAGES_FAILURE'

export const CLEAR_SEARCH_RESULT_REQUEST = 'message/CLEAR_SEARCH_RESULT_REQUEST'

export const CLICK_BADGE_ICON_REQUEST = 'message/CLICK_BADGE_ICON_REQUEST'

export const TOGGLE_MESSAGE_SIDEBAR_REQUEST = 'message/TOGGLE_MESSAGE_SIDEBAR_REQUEST'

export const FETCH_FAVORITED_MESSAGES_REQUEST = 'message/FETCH_FAVORITED_MESSAGES_REQUEST'
export const FETCH_MORE_FAVORITED_MESSAGES_REQUEST = 'message/FETCH_MORE_FAVORITED_MESSAGES_REQUEST'
export const FETCH_FAVORITED_MESSAGES_SUCCESS = 'message/FETCH_FAVORITED_MESSAGES_SUCCESS'
export const FETCH_FAVORITED_MESSAGES_FAILURE = 'message/FETCH_FAVORITED_MESSAGES_FAILURE'

export const TOGGLE_BETWEEN_ALL_AND_FAVORITED_MESSAGES = 'message/TOGGLE_BETWEEN_ALL_AND_FAVORITED_MESSAGES'

export const TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST = 'message/TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST'
export const TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST = 'message/TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST'
export const TOGGLE_FAVORITE_MESSAGE_STATUS_SUCCESS = 'message/TOGGLE_FAVORITE_MESSAGE_STATUS_SUCCESS'
export const TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_SUCCESS = 'message/TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_SUCCESS'
export const TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE = 'message/TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE'

// Thread
export const FETCH_THREADS_REQUEST = 'thread/FETCH_THREAD_REQUEST'
export const FETCH_THREADS_SUCCESS = 'thread/FETCH_THREAD_SUCCESS'
export const FETCH_THREADS_FAILURE = 'thread/FETCH_THREAD_FAILURE'

export const FETCH_WHOLE_THREAD_REQUEST = 'thread/FETCH_WHOLE_THREAD_REQUEST'
export const FETCH_WHOLE_THREAD_SUCCESS = 'thread/FETCH_WHOLE_THREAD_SUCCESS'
export const FETCH_WHOLE_THREAD_FAILURE = 'thread/FETCH_WHOLE_THREAD_FAILURE'

export const UPDATE_THREAD_REQUEST = 'thread/UPDATE_THREAD_REQUEST'
export const UPDATE_THREAD_SUCCESS = 'thread/UPDATE_THREAD_SUCCESS'
export const UPDATE_THREAD_FAILURE = 'thread/UPDATE_THREAD_FAILURE'

export const FETCH_MORE_THREADS_REQUEST = 'thread/FETCH_MORE_THREADS_REQUEST'
export const FETCH_MORE_THREADS_SUCCESS = 'thread/FETCH_MORE_THREADS_SUCCESS'
export const FETCH_MORE_THREADS_FAILURE = 'thread/FETCH_MORE_THREADS_FAILURE'

export const FETCH_THREADS_TILL_PARENT_REQUEST = 'thread/FETCH_THREADS_TILL_PARENT_REQUEST'
export const FETCH_THREADS_TILL_PARENT_SUCCESS = 'thread/FETCH_THREADS_TILL_PARENT_SUCCESS'
export const FETCH_THREADS_TILL_PARENT_FAILURE = 'thread/FETCH_THREADS_TILL_PARENT_FAILURE'
export const RESET_FETCH_THREADS_TILL_PARENT_STATUS = 'thread/RESET_FETCH_THREADS_TILL_PARENT_STATUS'

export const SEND_NEW_CHAT_REQUEST = 'thread/SEND_NEW_CHAT_REQUEST'
export const SEND_NEW_CHAT_SUCCESS = 'thread/SEND_NEW_CHAT_SUCCESS'
export const SEND_NEW_CHAT_FAILURE = 'thread/SEND_NEW_CHAT_FAILURE'

export const DELETE_CHAT_REQUEST = 'thread/DELETE_CHAT_REQUEST'
export const DELETE_CHAT_SUCCESS = 'thread/DELETE_CHAT_SUCCESS'
export const DELETE_CHAT_FAILURE = 'thread/DELETE_CHAT_FAILURE'

export const ACTION_CHAT_REQUEST = 'thread/ACTION_CHAT_REQUEST'
export const ACTION_CHAT_SUCCESS = 'thread/ACTION_CHAT_SUCCESS'
export const ACTION_CHAT_FAILURE = 'thread/ACTION_CHAT_FAILURE'
export const ACTION_CHAT_RESET = 'thread/ACTION_CHAT_RESET'

export const GET_USER_STATUS_REQUEST = 'thread/GET_USER_STATUS_REQUEST'
export const GET_USER_STATUS_SUCCESS = 'thread/GET_USER_STATUS_SUCCESS'
export const GET_USER_STATUS_FAILURE = 'thread/GET_USER_STATUS_FAILURE'

export const GET_USER_SERVICES_REQUEST = 'thread/GET_USER_SERVICES_REQUEST'
export const GET_USER_SERVICES_SUCCESS = 'thread/GET_USER_SERVICES_SUCCESS'
export const GET_USER_SERVICES_FAILURE = 'thread/GET_USER_SERVICES_FAILURE'

export const UPDATE_USER_SERVICES_REQUEST = 'thread/UPDATE_USER_SERVICES_REQUEST'
export const UPDATE_USER_SERVICES_SUCCESS = 'thread/UPDATE_USER_SERVICES_SUCCESS'
export const UPDATE_USER_SERVICES_FAILURE = 'thread/UPDATE_USER_SERVICES_FAILURE'
export const CLEAR_USER_SERVICES_STATUS = 'thread/CLEAR_USER_SERVICES_STATUS'

export const ADD_PENDING_CHAT = 'thread/ADD_PENDING_CHAT'

export const UPDATE_USER_READ_TIME = 'thread/UPDATE_USER_READ_TIME'

export const RESET_API_ERROR = 'thread/RESET_API_ERROR'

export const MARK_USER_PAID_REQUEST = 'thread/MARK_USER_PAID_REQUEST'
export const MARK_USER_PAID_SUCCESS = 'thread/MARK_USER_PAID_SUCCESS'
export const MARK_USER_PAID_FAILURE = 'thread/MARK_USER_PAID_FAILURE'

export const RESET_PAID_SUCCESS_STATUS = 'thread/RESET_PAID_SUCCESS_STATUS'

export const SET_USER_PRIORITY_REQUEST = 'thread/SET_USER_PRIORITY_REQUEST'
export const SET_USER_PRIORITY_SUCCESS = 'thread/SET_USER_PRIORITY_SUCCESS'
export const SET_USER_PRIORITY_FAILURE = 'thread/SET_USER_PRIORITY_FAILURE'
export const RESET_UPDATE_PRIORITY_SUCCESS_STATUS = 'thread/RESET_UPDATE_PRIORITY_SUCCESS_STATUS'

export const FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST = 'thread/FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST'
export const FETCH_ASSIGNED_TEAM_MEMBERS_SUCCESS = 'thread/FETCH_ASSIGNED_TEAM_MEMBERS_SUCCESS'
export const FETCH_ASSIGNED_TEAM_MEMBERS_FAILURE = 'thread/FETCH_ASSIGNED_TEAM_MEMBERS_FAILURE'

export const UPDATE_TEAM_MEMBERS_REQUEST = 'thread/UPDATE_TEAM_MEMBERS_REQUEST'
export const UPDATE_TEAM_MEMBERS_SUCCESS = 'thread/UPDATE_TEAM_MEMBERS_SUCCESS'
export const UPDATE_TEAM_MEMBERS_FAILURE = 'thread/UPDATE_TEAM_MEMBERS_FAILURE'

export const GET_RECOMMENDATIONS_PROPERTIES_REQUEST = 'thread/GET_RECOMMENDATIONS_PROPERTIES_REQUEST'
export const GET_RECOMMENDATIONS_PROPERTIES_SUCCESS = 'thread/GET_RECOMMENDATIONS_PROPERTIES_SUCCESS'
export const GET_RECOMMENDATIONS_PROPERTIES_FAILURE = 'thread/GET_RECOMMENDATIONS_PROPERTIES_FAILURE'

export const GET_DISLIKED_PROPERTIES_REQUEST = 'thread/GET_DISLIKED_PROPERTIES_REQUEST'
export const GET_DISLIKED_PROPERTIES_SUCCESS = 'thread/GET_DISLIKED_PROPERTIES_SUCCESS'
export const GET_DISLIKED_PROPERTIES_FAILURE = 'thread/GET_DISLIKED_PROPERTIES_FAILURE'

export const UNLIST_USER_INFO_PROPERTY_REQUEST = 'thread/UNLIST_USER_INFO_PROPERTY_REQUEST'
export const UNLIST_USER_INFO_PROPERTY_SUCCESS = 'thread/UNLIST_USER_INFO_PROPERTY_SUCCESS'
export const UNLIST_USER_INFO_PROPERTY_FAILURE = 'thread/UNLIST_USER_INFO_PROPERTY_FAILURE'

export const REMOVE_PROPERTY_FROM_RECOMMENDATIONS = 'thread/REMOVE_PROPERTY_FROM_RECOMMENDATIONS'

export const RESET_STATUS = 'thread/RESET_STATUS'
export const CLEAR_USER_INFO_DETAILS = 'thread/CLEAR_USER_INFO_DETAILS'

export const SET_REPLY_MESSAGE_DETAILS = 'thread/SET_REPLY_MESSAGE_DETAILS'
export const CLEAR_REPLY_MESSAGE_DETAILS = 'thread/CLEAR_REPLY_MESSAGE_DETAILS'

export const CREATE_CARD_REQUEST = 'thread/CREATE_CARD_REQUEST'
export const CREATE_CARD_SUCCESS = 'thread/CREATE_CARD_SUCCESS'
export const CREATE_CARD_FAILURE = 'thread/CREATE_CARD_FAILURE'

// Note
export const FETCH_NOTES_REQUEST = 'note/FETCH_NOTES_REQUEST'
export const FETCH_NOTES_SUCCESS = 'note/FETCH_NOTES_SUCCESS'
export const FETCH_NOTES_FAILURE = 'note/FETCH_NOTES_FAILURE'

export const FETCH_MORE_NOTES_REQUEST = 'note/FETCH_MORE_NOTES_REQUEST'
export const FETCH_MORE_NOTES_SUCCESS = 'note/FETCH_MORE_NOTES_SUCCESS'

export const CREATE_NOTE_REQUEST = 'note/CREATE_NOTE_REQUEST'
export const CREATE_NOTE_SUCCESS = 'note/CREATE_NOTE_SUCCESS'
export const CREATE_NOTE_FAILURE = 'note/CREATE_NOTE_FAILURE'

export const UPDATE_NOTE_REQUEST = 'note/UPDATE_NOTE_REQUEST'
export const UPDATE_NOTE_SUCCESS = 'note/UPDATE_NOTE_SUCCESS'
export const UPDATE_NOTE_FAILURE = 'note/UPDATE_NOTE_FAILURE'

export const DELETE_NOTE_REQUEST = 'note/DELETE_NOTE_REQUEST'
export const DELETE_NOTE_SUCCESS = 'note/DELETE_NOTE_SUCCESS'
export const DELETE_NOTE_FAILURE = 'note/DELETE_NOTE_FAILURE'

export const PIN_NOTE_REQUEST = 'note/PIN_NOTE_REQUEST'
export const PIN_NOTE_SUCCESS = 'note/PIN_NOTE_SUCCESS'
export const PIN_NOTE_FAILURE = 'note/PIN_NOTE_FAILURE'

export const UNPIN_NOTE_REQUEST = 'note/UNPIN_NOTE_REQUEST'
export const UNPIN_NOTE_SUCCESS = 'note/UNPIN_NOTE_SUCCESS'
export const UNPIN_NOTE_FAILURE = 'note/UNPIN_NOTE_FAILURE'

// Booking
export const FETCH_BOOKINGS_REQUEST = 'booking/FETCH_BOOKINGS_REQUEST'
export const FETCH_BOOKINGS_SUCCESS = 'booking/FETCH_BOOKINGS_SUCCESS'
export const FETCH_BOOKINGS_FAILURE = 'booking/FETCH_BOOKINGS_FAILURE'

export const FETCH_MORE_BOOKINGS_REQUEST = 'booking/FETCH_MORE_BOOKINGS_REQUEST'

export const ADD_BOOKINGS_REQUEST = 'booking/ADD_BOOKINGS_REQUEST'
export const ADD_BOOKINGS_SUCCESS = 'booking/ADD_BOOKINGS_SUCCESS'
export const ADD_BOOKINGS_FAILURE = 'booking/ADD_BOOKINGS_FAILURE'

export const EDIT_BOOKINGS_REQUEST = 'booking/EDIT_BOOKINGS_REQUEST'
export const EDIT_BOOKINGS_SUCCESS = 'booking/EDIT_BOOKINGS_SUCCESS'
export const EDIT_BOOKINGS_FAILURE = 'booking/EDIT_BOOKINGS_FAILURE'

export const CANCEL_BOOKINGS_REQUEST = 'booking/CANCEL_BOOKINGS_REQUEST'

export const CLEAR_STORED_BOOKING_LIST = 'booking/CLEAR_STORED_BOOKING_LIST'

export const RESET_BOOKING_STATUS = 'booking/RESET_BOOKING_STATUS'

// Property
export const FETCH_PROPERTY_REQUEST = 'property/FETCH_PROPERTY_REQUEST'
export const FETCH_PROPERTY_SUCCESS = 'property/FETCH_PROPERTY_SUCCESS'
export const FETCH_PROPERTY_FAILURE = 'property/FETCH_PROPERTY_FAILURE'

export const RECOMMEND_PROPERTY_REQUEST = 'property/RECOMMEND_PROPERTY_REQUEST'
export const RECOMMEND_PROPERTY_SUCCESS = 'property/RECOMMEND_PROPERTY_SUCCESS'
export const RECOMMEND_PROPERTY_FAILURE = 'property/RECOMMEND_PROPERTY_FAILURE'

export const UNLIST_PROPERTY_REQUEST = 'property/UNLIST_PROPERTY_REQUEST'
export const UNLIST_PROPERTY_SUCCESS = 'property/UNLIST_PROPERTY_SUCCESS'
export const UNLIST_PROPERTY_FAILURE = 'property/UNLIST_PROPERTY_FAILURE'

export const UPDATE_PROPERTY_REQUEST = 'property/UPDATE_PROPERTY_REQUEST'
export const UPDATE_PROPERTY_SUCCESS = 'property/UPDATE_PROPERTY_SUCCESS'
export const UPDATE_PROPERTY_FAILURE = 'property/UPDATE_PROPERTY_FAILURE'

export const PARTIAL_CLEAR_PROPERTY_STATE = 'property/PARTIAL_CLEAR_PROPERTY_STATE'
export const CLEAR_PROPERTIES = 'property/CLEAR_PROPERTIES'

export const RECOMMEND_MULTIPLE_PROPERTIES_REQUEST = 'property/RECOMMEND_MULTIPLE_PROPERTIES_REQUEST'
export const RECOMMEND_MULTIPLE_PROPERTIES_SUCCESS = 'property/RECOMMEND_MULTIPLE_PROPERTIES_SUCCESS'
export const RECOMMEND_MULTIPLE_PROPERTIES_FAILURE = 'property/RECOMMEND_MULTIPLE_PROPERTIES_FAILURE'

// Document

export const FETCH_DOCUMENTS_REQUEST = 'document/FETCH_DOCUMENTS_REQUEST'
export const FETCH_DOCUMENTS_SUCCESS = 'document/FETCH_DOCUMENTS_SUCCESS'
export const FETCH_DOCUMENTS_FAILURE = 'document/FETCH_DOCUMENTS_FAILURE'

export const SEND_NEW_CHAT_DOCUMENT_REQUEST = 'document/SEND_NEW_CHAT_DOCUCUMENT_REQUEST'
export const SEND_NEW_CHAT_DOCUMENT_SUCCESS = 'document/SEND_NEW_CHAT_DOCUCUMENT_SUCCESS'
export const SEND_NEW_CHAT_DOCUMENT_FAILURE = 'document/SEND_NEW_CHAT_DOCUCUMENT_FAILURE'

export const UPDATE_DOCUMENTS_REQUEST = 'document/UPDATE_DOCUMENTS_REQUEST'
export const UPDATE_DOCUMENTS_SUCCESS = 'document/UPDATE_DOCUMENTS_SUCCESS'
export const UPDATE_DOCUMENTS_FAILURE = 'document/UPDATE_DOCUMENTS_FAILURE'

export const RESET_DOCUMENTS_STATUS = 'document/RESET_DOCUMENTS_STATUS'

export const FETCH_USER_DOCUMENT_REQUEST = 'thread/FETCH_USER_DOCUMENT_REQUEST'
export const FETCH_USER_DOCUMENT_SUCCESS = 'thread/FETCH_USER_DOCUMENT_SUCCESS'
export const FETCH_USER_DOCUMENT_FAILURE = 'thread/FETCH_USER_DOCUMENT_FAILURE'

// Users

export const FETCH_USERS_REQUEST = 'users/FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'users/FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'users/FETCH_USERS_FAILURE'

export const ADD_NEW_USER = 'users/ADD_NEW_USER'
export const REMOVE_NEW_USER = 'users/REMOVE_NEW_USER'

export const ADD_NEW_USER_REQUEST = 'users/ADD_NEW_USER_REQUEST'
export const ADD_NEW_USER_SUCCESS = 'users/ADD_NEW_USER_SUCCESS'
export const ADD_NEW_USER_FAILURE = 'users/ADD_NEW_USER_FAILURE'

export const UPDATE_USER_DETAILS_REQUEST = 'users/UPDATE_USER_DETAILS_REQUEST'
export const UPDATE_USER_DETAILS_SUCCESS = 'users/UPDATE_USER_DETAILS_SUCCESS'
export const UPDATE_USER_DETAILS_FAILURE = 'users/UPDATE_USER_DETAILS_FAILURE'

// Feedback

export const SHOW_FEEDBACK = 'snackbar/SHOW_FEEDBACK'
export const HIDE_FEEDBACK = 'snackbar/HIDE_FEEDBACK'

// Packages

export const FETCH_PACKAGES_REQUEST = 'packages/FETCH_PACKAGES_REQUEST'
export const FETCH_PACKAGES_SUCCESS = 'packages/FETCH_PACKAGES_SUCCESS'
export const FETCH_PACKAGES_FAILURE = 'packages/FETCH_PACKAGES_FAILURE'

export const UPDATE_PACKAGE_REQUEST = 'packages/UPDATE_PACKAGE_REQUEST'
export const UPDATE_PACKAGE_SUCCESS = 'packages/UPDATE_PACKAGE_SUCCESS'
export const UPDATE_PACKAGE_FAILURE = 'packages/UPDATE_PACKAGE_FAILURE'
