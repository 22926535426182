import { AnyAction } from 'redux'
import { FeedbackState } from '../../../types/state'
import { HIDE_FEEDBACK, SHOW_FEEDBACK } from '../../constants'

export const initialState: FeedbackState = {
  title: '',
  severity: 'success',
  show: false,
  position: {
    horizontal: 'left',
    vertical: 'bottom'
  },
  autoHideDuration: 3000
}

export default (state = initialState, action: AnyAction): FeedbackState => {
  switch (action.type) {
    case SHOW_FEEDBACK:
      return { ...state, ...action.payload, show: true }

    case HIDE_FEEDBACK:
      return { ...state, show: false }

    default:
      return state
  }
}
