import { ChangeEvent, ReactElement, useState, useEffect, useRef, FormEvent, Dispatch, SetStateAction } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextareaAutosize,
  TextField,
  Typography
} from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { getUsersState } from '../../redux/selectors'
import { useParams, useHistory } from 'react-router-dom'
import { ADD_NEW_USER_REQUEST, REMOVE_NEW_USER, UPDATE_USER_DETAILS_REQUEST } from '../../redux/constants'
import { UserFormEditMode } from '../../screens'
import { UserRoles } from '../../types/messages'

type Props = {
  editMode: UserFormEditMode
  setEditMode: Dispatch<SetStateAction<UserFormEditMode>>
}

function a11yProps(index: number): Record<string, string> {
  return {
    id: `user-info-tab-${index}`,
    'aria-controls': `user-info-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FAFAFA',
    height: 'fit-content',
    padding: 16,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      padding: '16px 32px',
      overflowY: 'scroll',
      height: '100%'
    }
  },
  basicDetailsContainer: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      height: 'fit-content'
    }
  },
  avatarContainer: {
    width: theme.spacing(15),
    height: theme.spacing(15),
    margin: 'auto',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(25),
      height: theme.spacing(25)
    },
    [theme.breakpoints.up('md')]: {
      margin: 'auto 20px'
    }
  },
  avatar: {
    width: 'inherit',
    height: 'inherit',
    borderRadius: 100,
    objectFit: 'cover'
  },
  emptyAvatar: {
    width: 'inherit',
    height: 'inherit',
    borderRadius: 100,
    backgroundColor: '#FCF6C3',
    border: 'dashed #E4D126',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  fileInput: {
    display: 'none'
  },
  textDetailsContainer: {
    padding: '50px 25px',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between'
    }
  },
  formControlRoot: {
    width: '100%',
    marginBottom: '20px',
    [theme.breakpoints.up('md')]: {
      width: '300px'
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px'
    }
  },
  biosContainer: {
    border: '1px solid lightgrey',
    borderRadius: 5,
    backgroundColor: 'white'
  },
  input: {
    backgroundColor: 'white'
  },
  tabs: {
    marginLeft: 25,
    backgroundColor: '#E1E1E1',
    minWidth: 50,
    maxWidth: 250,
    [theme.breakpoints.up('sm')]: {
      margin: 25
    }
  },
  selectedTab: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    borderRadius: 5
  },
  divider: {
    width: '97%',
    margin: '16px auto',
    height: 2,
    [theme.breakpoints.up('md')]: {
      margin: 'auto'
    }
  },
  msgInputContainer: {
    width: '100%',
    flexGrow: 1
  },
  messageInput: {
    padding: '16px 10px 0px',
    resize: 'none',
    fontFamily: 'Roboto',
    fontSize: 16,
    border: 'none',
    outline: 'none'
  },
  btnsContainer: {
    margin: '30px 0'
  },
  saveBtn: {
    [theme.breakpoints.up('md')]: {
      height: 50,
      width: 170
    }
  },
  cancelBtn: {
    marginLeft: 20
  }
}))

export const UserProfileForm = ({ editMode, setEditMode }: Props): ReactElement => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const { userId } = useParams<{ userId: string }>()
  const { requested, upsertRequested, users } = useSelector(getUsersState)
  const [tabIndex, setTabIndex] = useState<number>(0)
  const [image, setImage] = useState<File | null>()
  const fileInputRef = useRef<HTMLInputElement>(null)
  const basicDetailsContainerRef = useRef<HTMLDivElement>(null)

  const defaultFormState: Record<string, string> = {
    name: '',
    email: '',
    role: '',
    user_type: '',
    bio: '',
    tip_1: '',
    tip_2: '',
    user_profile_url: '',
    slack_handle: ''
  }
  const [existingFormData, setExistingFormData] = useState(defaultFormState)
  const [newFormData, setNewFormData] = useState(defaultFormState)

  const nameForActiveTab = tabIndex === 0 ? 'bio' : tabIndex === 1 ? 'tip_1' : 'tip_2'

  useEffect(() => {
    basicDetailsContainerRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    })

    setTabIndex(0)

    const getUserFromUsers = users.find((user) => user.user_id === userId)

    if (getUserFromUsers) {
      const { name, email, user_type, role, bio, tip_1, tip_2, user_profile_url, slack_handle } = getUserFromUsers
      const updatedData = {
        name,
        email,
        role: role || '',
        user_type: user_type || '',
        bio: bio || '',
        tip_1: tip_1 || '',
        tip_2: tip_2 || '',
        user_profile_url,
        slack_handle: slack_handle || ''
      }
      setExistingFormData(updatedData)
      setNewFormData(updatedData)
    }

    // Both the conditions in 'if' is needed because:
    // if first condition is removed, and click on add user, it adds it and then removes it at the same time
    // if second condition is removed, then it just keeps on dispatching the REMOVE_NEW_USER action
    if (users[0]?.user_id === 'new_user' && userId !== 'new_user') {
      dispatch({ type: REMOVE_NEW_USER })
    }
    // Added users.length to cover the below case:
    // Select a user (eg: Paul) -> Search for a user (eg: Kevin) but don't click it -> Click refresh ->
    // The form on the right is blank but Paul is selected. That's because userId (which is still Paul's userId)
    // is not in the users array. That's because the array still has all the users returned as Kevin.
    // Now post refresh, as all users are fetched again, the length changes and now we will have Paul's data to
    // show it on the right
  }, [userId, users.length])

  useEffect(() => {
    if (image) {
      const reader = new FileReader()
      reader.onloadend = () => setNewFormData({ ...newFormData, user_profile_url: reader.result as string })

      if (!editMode.isTouched) {
        setEditMode({ isTouched: true, touchedID: userId })
      }

      reader.readAsDataURL(image)
    }
  }, [image])

  const openFileUploader = () => fileInputRef.current?.click()

  const imageInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files && event.target.files[0]

    file && file.type.startsWith('image') ? setImage(file) : setImage(null)

    event.target.value = ''
  }

  const handleFormDataChange = (event: ChangeEvent<{ name?: string; value: unknown }>) => {
    if (event.target.name) {
      setNewFormData({ ...newFormData, [event.target.name]: event.target.value as string })

      if (!editMode.isTouched) {
        setEditMode({ isTouched: true, touchedID: userId })
      }
    }
  }

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    userId === 'new_user'
      ? dispatch({ type: ADD_NEW_USER_REQUEST, payload: { userDetails: newFormData } })
      : dispatch({
          type: UPDATE_USER_DETAILS_REQUEST,
          payload: { userDetails: { ...newFormData, user_id: userId } }
        })

    setEditMode({ isTouched: false, touchedID: '' })
  }

  const handleCancel = () => {
    if (userId === 'new_user') {
      dispatch({ type: REMOVE_NEW_USER })
      history.push(`/users/${users[1].user_id}`)
    }

    setNewFormData(existingFormData)
    setEditMode({ isTouched: false, touchedID: '' })
  }

  return (
    <form className={classes.container} onSubmit={handleSubmit}>
      <div className={classes.basicDetailsContainer} ref={basicDetailsContainerRef}>
        <div className={classes.avatarContainer}>
          {newFormData.user_profile_url ? (
            <img
              src={newFormData.user_profile_url}
              alt="User avatar"
              className={classes.avatar}
              onClick={openFileUploader}
            />
          ) : (
            <div className={classes.emptyAvatar} onClick={openFileUploader}>
              <Typography variant="h6" id="upload-avatar">
                Avatar
              </Typography>
            </div>
          )}
          <input
            aria-labelledby="upload-avatar"
            type="file"
            name="file"
            id="file"
            disabled={upsertRequested || requested}
            className={classes.fileInput}
            ref={fileInputRef}
            accept="image/*"
            onChange={imageInputChange}
          />
        </div>

        <div className={classes.textDetailsContainer}>
          <FormControl variant="outlined" className={classes.formControlRoot}>
            <TextField
              inputProps={{ 'aria-label': 'Name' }}
              variant="outlined"
              name="name"
              placeholder="Name"
              value={newFormData.name}
              onChange={handleFormDataChange}
              required
              disabled={upsertRequested || requested}
              className={classes.input}
              fullWidth
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRoot}>
            <TextField
              inputProps={{ 'aria-label': 'Email' }}
              variant="outlined"
              name="email"
              type="email"
              placeholder="Email"
              value={newFormData.email}
              onChange={handleFormDataChange}
              required
              disabled={upsertRequested || requested}
              className={classes.input}
              fullWidth
            />
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRoot}>
            <InputLabel id="role-label">Role</InputLabel>
            <Select
              labelId="role-label"
              id="user-role"
              label="Role"
              name="role"
              disabled={upsertRequested || requested}
              defaultValue=""
              value={newFormData.role}
              onChange={handleFormDataChange}
              className={classes.input}
              fullWidth
              required
            >
              <MenuItem value={''} disabled>
                Role title
              </MenuItem>
              <MenuItem value={UserRoles.CUSTOMER_SUCCESS_LEAD}>Customer Success Lead</MenuItem>
              <MenuItem value={UserRoles.CUSTOMER_SUCCESS_MANAGER}>Customer Success Manager</MenuItem>
              <MenuItem value={UserRoles.CUSTOMER_SUCCESS_ASSOCIATE}>Customer Success Associate</MenuItem>
              <MenuItem value={UserRoles.SUPPORT_TEAM}>Support Team</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRoot}>
            <InputLabel id="user-type-label">User Type</InputLabel>
            <Select
              labelId="user-type-label"
              id="user-type"
              label="User Type"
              name="user_type"
              disabled={upsertRequested || requested}
              defaultValue=""
              value={newFormData.user_type}
              onChange={handleFormDataChange}
              className={classes.input}
              fullWidth
              required
            >
              <MenuItem value={''} disabled>
                User Type
              </MenuItem>
              <MenuItem value={'support'}>Support</MenuItem>
              <MenuItem value={'va'}>VA</MenuItem>
            </Select>
          </FormControl>

          <FormControl variant="outlined" className={classes.formControlRoot}>
            <TextField
              inputProps={{ 'aria-label': 'Slack handle' }}
              variant="outlined"
              name="slack_handle"
              placeholder="Slack handle"
              value={newFormData.slack_handle}
              onChange={handleFormDataChange}
              required
              disabled={upsertRequested || requested}
              className={classes.input}
              fullWidth
            />
          </FormControl>
        </div>
      </div>

      <div className={classes.biosContainer}>
        <Tabs
          value={tabIndex}
          onChange={(e, tab) => setTabIndex(tab)}
          aria-label="User profile tabs"
          scrollButtons="auto"
          variant="fullWidth"
          TabIndicatorProps={{ style: { display: 'none' } }}
          disabled={upsertRequested || requested}
        >
          <Tab
            classes={{ root: classes.tabs, selected: classes.selectedTab }}
            label="Bio"
            {...a11yProps(0)}
            disabled={upsertRequested}
          />
          <Tab
            classes={{ root: classes.tabs, selected: classes.selectedTab }}
            label="Relocation Tip 1"
            {...a11yProps(1)}
            disabled={upsertRequested}
          />
          <Tab
            classes={{ root: classes.tabs, selected: classes.selectedTab }}
            label="Relocation Tip 2"
            {...a11yProps(2)}
            disabled={upsertRequested}
          />
        </Tabs>

        <Divider classes={{ root: classes.divider }} />

        <FormControl variant="outlined" className={classes.msgInputContainer}>
          <TextareaAutosize
            aria-label={nameForActiveTab}
            className={classes.messageInput}
            minRows={14}
            maxRows={16}
            placeholder="Enter something awesome..."
            disabled={upsertRequested || requested}
            name={nameForActiveTab}
            value={newFormData[nameForActiveTab]}
            onChange={(e) => {
              setNewFormData({ ...newFormData, [e.target.name]: e.target.value })
              if (!editMode.isTouched) {
                setEditMode({ isTouched: true, touchedID: userId })
              }
            }}
          />
        </FormControl>
      </div>

      <div className={classes.btnsContainer}>
        <Button
          disabled={!editMode.isTouched || requested}
          type="submit"
          variant="contained"
          color="secondary"
          className={classes.saveBtn}
        >
          Save
        </Button>
        <Button
          disabled={!editMode.isTouched || requested}
          variant="text"
          color="primary"
          onClick={handleCancel}
          className={classes.cancelBtn}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}
