import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { SagaIterator } from 'redux-saga'
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  ADD_NEW_USER_FAILURE,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  SHOW_FEEDBACK
} from '../../constants'

import Api from '../../api'
import { getAuthState, getIdFromPath } from '../../selectors'
import { PayloadType } from '../../../types/state'

const instance = new Api()

export function* fetchUsersFn({ payload }: PayloadType): SagaIterator {
  const { token } = yield select(getAuthState)
  const { searchString } = payload
  const userId = yield select(getIdFromPath)

  try {
    const response = yield call(instance.fetchUsers, searchString, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_USERS_SUCCESS, payload: { users: response.data.data } })

      if (!userId || userId === 'new_user') {
        yield put(push(`/users/${response.data.data[0].user_id}`))
      }
    } else {
      yield put({ type: FETCH_USERS_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: FETCH_USERS_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
  }
}

export function* addUserFn({ payload }: PayloadType): SagaIterator {
  const { token } = yield select(getAuthState)
  const { userDetails } = payload

  try {
    const response = yield call(instance.addUser, token, userDetails)
    if (response && response.data && response.status === 200) {
      yield put({
        type: ADD_NEW_USER_SUCCESS,
        payload: { userDetails: { ...userDetails, user_id: response.data.data.user_id } }
      })
      yield put(push(`/users/${response.data.data.user_id}`))
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'User successfully added', severity: 'success' } })
    } else {
      yield put({ type: ADD_NEW_USER_FAILURE })
      // TODO: In certain scenarios, like trying to create an exiting user
      // we should show more specific/better error message
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: ADD_NEW_USER_FAILURE })
    // TODO: In certain scenarios, like trying to create an exiting user
    // we should show more specific/better error message
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
  }
}

export function* updateUserFn({ payload }: PayloadType): SagaIterator {
  const { token } = yield select(getAuthState)
  const { userDetails } = payload

  try {
    const response = yield call(instance.updateUser, userDetails.user_id, token, userDetails)
    if (response && response.data && response.status === 200) {
      yield put({ type: UPDATE_USER_DETAILS_SUCCESS, payload: { userDetails } })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'User successfully updated', severity: 'success' } })
    } else {
      yield put({ type: UPDATE_USER_DETAILS_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: UPDATE_USER_DETAILS_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
  }
}

export default function* usersSaga(): Generator {
  yield all([takeEvery(FETCH_USERS_REQUEST, fetchUsersFn)])
  yield all([takeEvery(ADD_NEW_USER_REQUEST, addUserFn)])
  yield all([takeEvery(UPDATE_USER_DETAILS_REQUEST, updateUserFn)])
}
