import moment from 'moment'
import { AnyAction } from 'redux'
import { UsersState } from '../../../types/state'
import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  ADD_NEW_USER,
  REMOVE_NEW_USER,
  ADD_NEW_USER_REQUEST,
  ADD_NEW_USER_SUCCESS,
  ADD_NEW_USER_FAILURE,
  UPDATE_USER_DETAILS_REQUEST,
  UPDATE_USER_DETAILS_SUCCESS,
  UPDATE_USER_DETAILS_FAILURE,
  UPDATE_TEAM_MEMBERS_REQUEST,
  UPDATE_TEAM_MEMBERS_SUCCESS,
  UPDATE_TEAM_MEMBERS_FAILURE,
  FETCH_ASSIGNED_TEAM_MEMBERS_SUCCESS,
  FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST
} from '../../constants'
import { updateUserDetails } from './utils'

export const initialState: UsersState = {
  requested: false,
  success: false,
  failure: false,
  upsertRequested: false,
  upsertFailure: false,
  users: []
}

export default (state = initialState, action: AnyAction): UsersState => {
  switch (action.type) {
    case FETCH_USERS_REQUEST:
    case FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        requested: true,
        success: false,
        failure: false,
        upsertRequested: false,
        upsertFailure: false,
        users: []
      }

    case FETCH_USERS_SUCCESS:
    case FETCH_ASSIGNED_TEAM_MEMBERS_SUCCESS:
      const { users } = action.payload

      return {
        ...state,
        requested: false,
        success: true,
        failure: false,
        upsertRequested: false,
        upsertFailure: false,
        users
      }

    case FETCH_USERS_FAILURE:
      return {
        ...state,
        requested: false,
        success: false,
        failure: true,
        upsertRequested: false,
        upsertFailure: false
      }

    case ADD_NEW_USER:
      return {
        ...state,
        requested: false,
        success: true,
        failure: false,
        upsertRequested: false,
        upsertFailure: false,
        users: [
          {
            user_id: 'new_user',
            name: '',
            email: '',
            bio: '',
            tip_1: '',
            tip_2: '',
            role: '',
            user_type: '',
            user_profile_url: '',
            slack_handle: '',
            created_at: moment().format()
          },
          ...state.users
        ]
      }

    case REMOVE_NEW_USER:
      const [, ...rest] = state.users
      return {
        ...state,
        requested: false,
        success: true,
        failure: false,
        upsertRequested: false,
        upsertFailure: false,
        users: rest
      }

    case ADD_NEW_USER_REQUEST:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: true,
        upsertFailure: false
      }

    case ADD_NEW_USER_SUCCESS:
      const { userDetails: newUser } = action.payload
      state.users[0] = newUser
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: false,
        upsertFailure: false,
        users: state.users
      }

    case ADD_NEW_USER_FAILURE:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: false,
        upsertFailure: true
      }

    case UPDATE_USER_DETAILS_REQUEST:
    case UPDATE_TEAM_MEMBERS_REQUEST:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: true,
        upsertFailure: false
      }

    case UPDATE_USER_DETAILS_SUCCESS:
      const { userDetails: newDetails } = action.payload
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: false,
        upsertFailure: false,
        users: updateUserDetails(state.users, newDetails)
      }

    case UPDATE_USER_DETAILS_FAILURE:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: false,
        upsertFailure: true
      }

    case UPDATE_TEAM_MEMBERS_SUCCESS:
    case UPDATE_TEAM_MEMBERS_FAILURE:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        upsertRequested: false,
        upsertFailure: false
      }

    default:
      return state
  }
}
