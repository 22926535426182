import { DocumentFieldsValues } from '../types/documents'

export const documentFields: { title: string; id: DocumentFieldsValues }[] = [
  { title: 'Document Center', id: 'document_center_link' },
  { title: 'Accountancy', id: 'accountancy_link' },
  { title: 'Banking', id: 'banking_link' },
  { title: 'Car rental', id: 'car_rental_link' },
  { title: 'Check-in form', id: 'check_in_form_link' },
  { title: 'Check-out form', id: 'check_out_form_link' },
  { title: 'Check-Out Guide', id: 'check_out_guide_link' },
  { title: 'City feed', id: 'city_feed_link' },
  { title: 'Community', id: 'community_link' },
  { title: 'Coworking', id: 'coworking_link' },
  { title: 'Cultural and language', id: 'cultural_and_language_link' },
  { title: 'Driving licence', id: 'driving_licence_link' },
  { title: 'Emergency', id: 'emergency_link' },
  { title: 'Escorted viewings', id: 'escorted_viewings_link' },
  { title: 'Featured', id: 'featured_link' },
  { title: 'For you', id: 'for_you_link' },
  { title: 'Furniture', id: 'furniture_link' },
  { title: 'Guided Tour', id: 'guided_tour_link' },
  { title: 'Health care', id: 'health_care_link' },
  { title: 'How it works', id: 'how_it_works_link' },
  { title: 'Inspection Form', id: 'inspection_form_link' },
  { title: 'Insurance', id: 'insurance_link' },
  { title: 'Inventory Form', id: 'inventory_form_link' },
  { title: 'Key Contract Terms', id: 'key_contract_terms_link' },
  { title: 'Lease Info', id: 'lease_info_link' },
  { title: 'Mobile phone', id: 'mobile_phone_link' },
  { title: 'Mover checklist', id: 'mover_checklist_link' },
  { title: 'Mover timeline', id: 'mover_timeline_link' },
  { title: 'Moving and Removals', id: 'moving_and_removals_link' },
  { title: 'Neighborhood safety', id: 'neighborhood_safety_link' },
  { title: 'New feature drop', id: 'new_feature_drop_link' },
  { title: 'Our packages', id: 'our_packages_link' },
  { title: 'Our work giving back', id: 'our_work_giving_back_link' },
  { title: 'Partner spotlight', id: 'partner_spotlight_link' },
  { title: 'Partner support', id: 'partner_support_link' },
  { title: 'Pay As You Go', id: 'pay_as_you_go_link' },
  { title: 'Payments Due', id: 'payments_due_link' },
  { title: 'Pet relocation', id: 'pet_relocation_link' },
  { title: 'PerchSpeak', id: 'perchspeak_link' },
  { title: 'Property Maintenance', id: 'property_maintenance_link' },
  { title: 'Registration form', id: 'registration_link' },
  { title: 'School search', id: 'school_search_link' },
  { title: 'Shipping', id: 'shipping_link' },
  { title: 'Short term stay', id: 'short_term_stay_link' },
  { title: 'Utility connection', id: 'utility_connection_link' },
  { title: 'Viewing guide', id: 'viewing_guide_link' },
  { title: 'Visa and immigration', id: 'visa_and_immigration_link' },
  { title: 'Welcome Pack', id: 'welcome_pack_link' }
]
