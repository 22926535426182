import { ChangeEventHandler, FocusEventHandler, MouseEventHandler, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  CircularProgress
} from '@material-ui/core'

import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { urlPattern } from '../../utils/string'
import { CREATE_CARD_REQUEST, SHOW_FEEDBACK } from '../../redux/constants'
import { getThreadState } from '../../redux/selectors'
import { CardTypeKey, CardType, ContentPayloadAttributeKey, ContentPayloadAttribute } from '../../types/thread'

const isEmpty = (value: string | null) => !value || (value as string).length === 0

const isNotURL = (value: string | null) => !RegExp(urlPattern).test(value as string)

type FieldKey = 'contentful_id' | 'title' | 'description' | 'video_external_url' | 'document_external_url'

interface Field {
  attribute: ContentPayloadAttributeKey
  label: string
  required: boolean
  errorMessage: string
  maxLength?: number
  hasError: (value: string | null) => boolean
}

interface CardValue {
  type: CardTypeKey
  label: string
  fields: Field[]
}

const META_FIELD: { [key in FieldKey]: Field } = {
  contentful_id: {
    attribute: ContentPayloadAttribute.contentful_id,
    label: 'Contentful ID *',
    required: true,
    errorMessage: 'Please enter a valid Contentful ID',
    hasError: isEmpty
  },
  title: {
    attribute: ContentPayloadAttribute.title,
    label: 'Title *',
    required: true,
    errorMessage: 'Please enter a valid title',
    maxLength: 255,
    hasError: isEmpty
  },
  description: {
    attribute: 'description',
    label: 'Description',
    required: false,
    errorMessage: 'Please enter a valid description',
    hasError: () => false
  },
  video_external_url: {
    attribute: ContentPayloadAttribute.video_url,
    label: 'Link *',
    required: true,
    errorMessage: 'Please enter a valid video link',
    hasError: isNotURL
  },
  document_external_url: {
    attribute: ContentPayloadAttribute.document_url,
    label: 'Link *',
    required: true,
    errorMessage: 'Please enter a valid document link',
    hasError: isNotURL
  }
}

const META_CARD: {
  [key in CardTypeKey]: CardValue
} = {
  [CardType.content]: {
    type: CardType.content,
    label: 'Content',
    fields: [META_FIELD.contentful_id]
  },
  [CardType.video_external]: {
    type: CardType.video_external,
    label: 'Drive: Video',
    fields: [META_FIELD.title, META_FIELD.description, META_FIELD.video_external_url]
  },
  [CardType.document_external]: {
    type: CardType.document_external,
    label: 'Drive: Document',
    fields: [META_FIELD.title, META_FIELD.description, META_FIELD.document_external_url]
  }
}

const useStyles = makeStyles((theme) => ({
  properties: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2)
  },
  text: {
    marginTop: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    minHeight: 370
  },
  actions: {
    justifyContent: 'unset',
    padding: theme.spacing(3)
  }
}))

interface State {
  metaCard: CardValue
  activeCard: { [key in Field['attribute']]?: string }
  errors: { [key in Field['attribute']]?: boolean }
  loading: boolean
}

export type SendCardProps = {
  open: boolean
  onClose: () => void
}

export function SendContent({ open, onClose }: SendCardProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const { contentStatus } = useSelector(getThreadState)
  const [{ metaCard, activeCard, errors, loading }, setState] = useState<State>({
    metaCard: META_CARD.content,
    activeCard: {},
    errors: {},
    loading: false
  })

  const disabled =
    loading ||
    Object.values(errors).some((error) => error) ||
    !metaCard.fields
      .filter(({ required }) => required)
      .every(({ attribute }) => Object.keys(activeCard).includes(attribute))

  const handleCardTypeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const cardType = event.target.value as CardTypeKey

    setState((previousState) => ({ ...previousState, metaCard: META_CARD[cardType], activeCard: {}, errors: {} }))
  }

  const handleOnChangeInput =
    (field: Field): FocusEventHandler<HTMLInputElement> =>
    (event) => {
      const value = event.currentTarget.value
      const hasError = field.hasError(value)

      setState((previousState) => ({
        ...previousState,
        errors: { ...previousState.errors, [field.attribute]: hasError }
      }))

      if (!hasError) {
        setState((previousState) => ({
          ...previousState,
          activeCard: { ...previousState.activeCard, [field.attribute]: value }
        }))
      }
    }

  const handleOnSubmit: MouseEventHandler<HTMLButtonElement> = () => {
    setState((previousState) => ({ ...previousState, loading: true }))

    dispatch({
      type: CREATE_CARD_REQUEST,
      payload: { customerId: customer?.linked_user_id, card: { type: metaCard.type, ...activeCard } }
    })
  }

  useEffect(() => {
    if (!!contentStatus && loading) {
      let severity = 'success'
      if (contentStatus === 'Content Sent') {
        onClose()
      } else {
        severity = 'error'
      }
      dispatch({ type: SHOW_FEEDBACK, payload: { title: contentStatus, severity } })

      setState((previousState) => ({ ...previousState, loading: false }))
    }
  }, [contentStatus, loading])

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" aria-labelledby="dialog-title">
      <DialogTitle disableTypography>
        <Typography variant="h5" id="dialog-title">
          Send content to <b>{customer?.user_name}</b>
        </Typography>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <RadioGroup aria-label="choose card type" value={metaCard.type} row onChange={handleCardTypeChange}>
          {Object.entries(META_CARD).map(([key, card]) => (
            <FormControlLabel key={key} value={key} label={card.label} control={<Radio />} />
          ))}
        </RadioGroup>
        {metaCard.fields.map((field) => (
          <TextField
            key={`${metaCard.type}-${field.attribute}`}
            variant="outlined"
            fullWidth
            name={field.attribute}
            label={field.label}
            helperText={!!errors[field.attribute] ? field.errorMessage : ' '}
            error={!!errors[field.attribute]}
            inputProps={{
              'aria-label': field.label,
              ...(field.maxLength && { maxLength: field.maxLength })
            }}
            onChange={handleOnChangeInput(field)}
          />
        ))}
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleOnSubmit}
          size="large"
          variant="contained"
          disabled={disabled}
          color="primary"
          data-track-click={`send-content-${metaCard.type}`}
          {...(loading && {
            endIcon: <CircularProgress role="alert" aria-live="assertive" aria-label="Sending content" size={15} />
          })}
        >
          Send
        </Button>
        <Button onClick={onClose} size="large" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
