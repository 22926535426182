import { ReactElement, Fragment, useState, useEffect, MouseEventHandler } from 'react'
import { NavLink } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, IconButton } from '@material-ui/core'
import { StarBorder, Star } from '@material-ui/icons'
import { MessageType } from '../../types/messages'
import { supportUserId, supportUserName } from '../../utils/env'
import {
  CLICK_BADGE_ICON_REQUEST,
  TOGGLE_MESSAGE_SIDEBAR_REQUEST,
  CLEAR_STORED_BOOKING_LIST,
  CLEAR_REPLY_MESSAGE_DETAILS,
  CLEAR_USER_INFO_DETAILS,
  PARTIAL_CLEAR_PROPERTY_STATE,
  TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST,
  TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST
} from '../../redux/constants'
import { makeFirstLetterCapital } from '../../utils/string'
import { getMessageState } from '../../redux/selectors'
import { InfoCard } from '..'
import type { Chip } from '..'

type StylesProps = {
  userPaid?: boolean
  priority?: string
  showBadge: boolean
}

const visibility = (params?: boolean | string) => (params ? 'unset' : 'hidden')

const useStyles = makeStyles((theme) => ({
  link: {
    color: 'unset',
    display: 'block',
    textDecoration: 'none'
  },
  activeLink: {
    backgroundColor: theme.palette.grey[200]
  },
  paidBadge: {
    marginRight: 10,
    visibility: ({ userPaid }: StylesProps) => visibility(userPaid)
  },
  priority: {
    visibility: ({ priority }: StylesProps) => visibility(priority)
  },
  badge: {
    height: '10px',
    width: '10px',
    margin: '0 10px',
    borderRadius: '10px',
    backgroundColor: theme.palette.secondary.main,
    visibility: ({ showBadge }: StylesProps) => visibility(showBadge)
  },
  star: {
    padding: 0
  },
  filledStar: {
    color: '#FEC207'
  }
}))

export type Props = {
  deviceType: 'mobile' | 'desktop'
  message: MessageType
  testId: 'normal' | 'searched'
  selected?: boolean
}
export const Message = ({ deviceType, message, testId, selected }: Props): ReactElement => {
  const { group, last_chat, last_chat_name, updated_at, created_at, message_id, is_favorite, last_chat_created_at } =
    message
  const dispatch = useDispatch()
  const { searchMessages, toggleFavoriteInProgress } = useSelector(getMessageState)
  const [isFavorite, setIsFavorite] = useState(is_favorite)

  useEffect(() => {
    if (!toggleFavoriteInProgress) {
      setIsFavorite(is_favorite)
    }
  }, [toggleFavoriteInProgress])

  const tenant = group.find((item) => item.user_type === 'tenant' || item.user_type === 'landlord')
  const lastChatName = last_chat_name.trim() === supportUserName.trim() ? 'You' : last_chat_name

  const isBeforeToday = moment(last_chat_created_at).isBefore(moment(), 'day')
  const dateTime = moment
    .utc(last_chat_created_at)
    .local()
    .format(isBeforeToday ? 'Do MMM YY' : 'h:mm A')

  // TODO: we need to consider about the va user type
  const supportUser = group.find((item) => item.linked_user_id === supportUserId)
  const showBadge = moment(supportUser?.updated_at).isBefore(updated_at) || supportUser?.updated_at === created_at
  const priority = tenant && tenant.user_priority

  const classes = useStyles({
    userPaid: tenant?.user_has_paid,
    priority: tenant?.user_priority,
    showBadge
  })

  const chips: Chip[] = [
    {
      testId: 'paid-chip',
      color: 'secondary',
      size: 'small',
      label: 'Paid',
      variant: 'outlined',
      style: classes.paidBadge
    },
    {
      testId: 'priority-chip',
      color: 'secondary',
      size: 'small',
      label: makeFirstLetterCapital(priority || ''),
      variant: 'default',
      style: classes.priority
    }
  ]

  const handler = () => {
    dispatch({
      type: CLICK_BADGE_ICON_REQUEST,
      payload: { messageId: message_id, userId: supportUser?.linked_user_id }
    })
    if (deviceType === 'mobile') {
      dispatch({ type: TOGGLE_MESSAGE_SIDEBAR_REQUEST })
    }
    dispatch({ type: CLEAR_REPLY_MESSAGE_DETAILS })
    dispatch({ type: CLEAR_STORED_BOOKING_LIST })
    dispatch({ type: CLEAR_USER_INFO_DETAILS })
    dispatch({ type: PARTIAL_CLEAR_PROPERTY_STATE })
  }

  const handleFavoriteMessage: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.stopPropagation()
    event.preventDefault()
    setIsFavorite((favorite) => !favorite)

    const actionType =
      searchMessages && searchMessages.length > 0
        ? TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST
        : TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST

    dispatch({ type: actionType, payload: { message_id } })
  }

  return (
    <Fragment>
      <NavLink
        to={`/messages/${message_id}`}
        activeClassName={classes.activeLink}
        className={classes.link}
        onClick={handler}
      >
        <InfoCard
          selected={Boolean(selected)}
          containerTestid={`${testId}-${deviceType}-message-item`}
          avatarUrl={tenant?.user_profile_image}
          title={tenant?.user_name || ''}
          subtitle={`${lastChatName}: ${last_chat}`}
          dateTime={dateTime}
          chips={chips}
        >
          <>
            <div data-testid="badge-icon" className={classes.badge} />
            <IconButton
              onClick={handleFavoriteMessage}
              disabled={toggleFavoriteInProgress}
              classes={{ root: classes.star }}
            >
              {isFavorite ? (
                <Star data-testid="filled-star" className={classes.filledStar} />
              ) : (
                <StarBorder data-testid="empty-star" />
              )}
            </IconButton>
          </>
        </InfoCard>
        <Divider />
      </NavLink>
    </Fragment>
  )
}
