import moment from 'moment'
import { memo, ReactElement } from 'react'
import { Paper, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

import { ThreadPropertyCard, DeleteChat } from '..'
import { ChatStatus, PropertyChat, PusherPropertyChat } from '../../types/messages'
import { ThreadPropertyType } from '../../types/properties'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px 0'
  },
  paper: {
    borderRadius: '20px 20px 0 20px',
    boxSizing: 'border-box',
    padding: '15px 20px 10px 20px',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    marginLeft: 'auto',
    width: 600,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 400
    }
  }
}))

// eslint-disable-next-line react/display-name
export const SuggestedPropertyChat = memo((info: PropertyChat | PusherPropertyChat): ReactElement => {
  const { status, created_at } = info
  const classes = useStyles()
  const isDeleted = status === ChatStatus.DELETED

  return (
    <div className={classes.container} data-testid="suggested-property-chat">
      {!isDeleted ? (
        <ThreadPropertyCard info={info} type={ThreadPropertyType.SUGGESTED} />
      ) : (
        <Paper className={classes.paper}>
          <DeleteChat type="support" />
        </Paper>
      )}
      <Typography variant="subtitle2" color="textSecondary" style={{ fontSize: '0.6em', textAlign: 'right' }}>
        {moment.utc(created_at).local().format('dddd, Do of MMMM Y, H:mm')}
      </Typography>
    </div>
  )
})
