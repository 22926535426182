import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Chip, Typography } from '@material-ui/core'

type Props = {
  selected?: boolean
  containerTestid: string
  avatarUrl?: string
  title: string
  subtitle: string
  dateTime: string
  chips: Chip[]
  children?: ReactElement
}

export type Chip = {
  testId: string
  color?: 'default' | 'primary' | 'secondary'
  size?: 'medium' | 'small'
  label: string
  variant?: 'default' | 'outlined'
  style?: string
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '15px 10px 15px 15px',
    boxSizing: 'border-box',
    width: '100%',
    wordBreak: 'break-all',
    position: 'relative'
  },
  avatar: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginTop: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      width: theme.spacing(7),
      height: theme.spacing(7)
    },
    backgroundColor: theme.palette.secondary.main
  },
  profileInfoContainer: {
    display: 'flex',
    width: 'inherit'
  },
  textContainer: {
    marginLeft: 15,
    overflow: 'hidden',
    flex: 1
  },
  text: {
    width: 'auto',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  title: {
    maxWidth: 200
  },
  subtitle: {
    fontSize: '0.75em'
  },
  dateTime: {
    fontSize: '0.7em'
  },
  bottomTextView: {
    display: 'flex',
    marginTop: 12
  },
  chipContainer: {
    flex: 1
  },
  statusIconContainer: {
    display: 'flex',
    alignItems: 'center'
  }
}))

export const InfoCard = ({
  selected,
  containerTestid,
  avatarUrl,
  title,
  subtitle,
  dateTime,
  chips,
  children
}: Props): ReactElement => {
  const classes = useStyles()

  return (
    <div aria-selected={Boolean(selected)} data-testid={containerTestid} className={classes.container}>
      <div className={classes.profileInfoContainer}>
        <Avatar aria-label="avatar" className={classes.avatar} src={avatarUrl} />
        <div className={classes.textContainer}>
          <div className={classes.titleContainer}>
            <Typography data-testid="user-name" variant="subtitle1" className={`${classes.text} ${classes.title}`}>
              {title}
            </Typography>
            <Typography variant="body2" color="textSecondary" className={classes.dateTime}>
              {dateTime}
            </Typography>
          </div>
          <Typography variant="body2" color="textPrimary" className={`${classes.text} ${classes.subtitle}`}>
            {subtitle}
          </Typography>
        </div>
      </div>

      <div className={classes.bottomTextView}>
        <div className={classes.chipContainer}>
          {chips.map(({ testId, color, size, label, variant, style }: Chip) => (
            <Chip
              key={testId}
              color={color || 'secondary'}
              size={size || 'small'}
              label={label}
              variant={variant || 'outlined'}
              className={style}
              data-testid={testId}
            />
          ))}
        </div>
        <div className={classes.statusIconContainer}>{children}</div>
      </div>
    </div>
  )
}
