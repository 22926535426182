import { memo, ReactElement } from 'react'
import { Typography } from '@material-ui/core'
import { Block } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'

type Props = { type: string }

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex'
  },
  color: {
    color: ({ type }: Props) => (type === 'support' ? 'white' : 'rgba(0, 0, 0, 0.87)')
  },
  chat: {
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap',
    marginLeft: 5
  }
}))

// eslint-disable-next-line react/display-name
export const DeleteChat = memo(({ type }: Props): ReactElement => {
  const classes = useStyles({ type })
  return (
    <div data-testid="deleted-chat" className={classes.container}>
      <Block className={classes.color} />
      <Typography variant="body1" className={`${classes.chat} ${classes.color}`}>
        This message was deleted
      </Typography>
    </div>
  )
})
