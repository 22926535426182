import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
  FETCH_MORE_NOTES_REQUEST,
  FETCH_MORE_NOTES_SUCCESS,
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  UPDATE_NOTE_REQUEST,
  UPDATE_NOTE_SUCCESS,
  UPDATE_NOTE_FAILURE,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  PIN_NOTE_REQUEST,
  PIN_NOTE_SUCCESS,
  PIN_NOTE_FAILURE,
  UNPIN_NOTE_REQUEST,
  UNPIN_NOTE_SUCCESS,
  UNPIN_NOTE_FAILURE
} from '../../constants'
import { PayloadType } from '../../../types/state'
import Api from '../../api'
import { getAuthState } from '../../selectors'

const instance = new Api()

export function* fetchNotesFn({ payload }: PayloadType): SagaIterator {
  const { userId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchNotes, token, userId)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_NOTES_SUCCESS, payload: { notes: response.data.data, userId } })
    } else {
      yield put({ type: FETCH_NOTES_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_NOTES_FAILURE })
  }
}

export function* fetchMoreNotesFn({ payload }: PayloadType): SagaIterator {
  const { userId, currentPage } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchNotes, token, userId, currentPage)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_MORE_NOTES_SUCCESS, payload: { notes: response.data.data } })
    } else {
      yield put({ type: FETCH_NOTES_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_NOTES_FAILURE })
  }
}

export function* createNoteFn({ payload }: PayloadType): SagaIterator {
  const { userId, note } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.createNote, token, userId, note)
    if (response && response.data && response.status === 200) {
      yield put({ type: CREATE_NOTE_SUCCESS, payload: { notes: response.data.data } })
    } else {
      yield put({ type: CREATE_NOTE_FAILURE })
    }
  } catch (err) {
    yield put({ type: CREATE_NOTE_FAILURE })
  }
}

export function* updateNoteFn({ payload }: PayloadType): SagaIterator {
  const { userId, noteId, newNoteText } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.editNote, token, userId, noteId, newNoteText)
    if (response && response.data && response.status === 200) {
      yield put({ type: UPDATE_NOTE_SUCCESS, payload: { noteId, userId, newNoteText } })
    } else {
      yield put({ type: UPDATE_NOTE_FAILURE })
    }
  } catch (err) {
    yield put({ type: UPDATE_NOTE_FAILURE })
  }
}

export function* deleteNoteFn({ payload }: PayloadType): SagaIterator {
  const { userId, noteId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.deleteNote, token, userId, noteId)
    if (response && response.data && response.status === 200) {
      yield put({ type: DELETE_NOTE_SUCCESS, payload: { noteId } })
    } else {
      yield put({ type: DELETE_NOTE_FAILURE })
    }
  } catch (err) {
    yield put({ type: DELETE_NOTE_FAILURE })
  }
}

export function* pinNoteFn({ payload }: PayloadType): SagaIterator {
  const { userId, noteId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.pinNote, token, userId, noteId)
    if (response && response.data && response.status === 200) {
      yield put({ type: PIN_NOTE_SUCCESS, payload: { notes: response.data.data } })
    } else {
      yield put({ type: PIN_NOTE_FAILURE })
    }
  } catch (err) {
    yield put({ type: PIN_NOTE_FAILURE })
  }
}

export function* unpinNoteFn({ payload }: PayloadType): SagaIterator {
  const { userId, noteId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.unpinNote, token, userId, noteId)
    if (response && response.data && response.status === 200) {
      yield put({ type: UNPIN_NOTE_SUCCESS, payload: { notes: response.data.data } })
    } else {
      yield put({ type: UNPIN_NOTE_FAILURE })
    }
  } catch (err) {
    yield put({ type: UNPIN_NOTE_FAILURE })
  }
}

export default function* noteSaga(): Generator {
  yield all([
    takeEvery(FETCH_NOTES_REQUEST, fetchNotesFn),
    takeEvery(FETCH_MORE_NOTES_REQUEST, fetchMoreNotesFn),
    takeEvery(CREATE_NOTE_REQUEST, createNoteFn),
    takeEvery(UPDATE_NOTE_REQUEST, updateNoteFn),
    takeEvery(DELETE_NOTE_REQUEST, deleteNoteFn),
    takeEvery(PIN_NOTE_REQUEST, pinNoteFn),
    takeEvery(UNPIN_NOTE_REQUEST, unpinNoteFn)
  ])
}
