import { ReactElement } from 'react'
import { captureException } from '@sentry/react'

import {
  InterestedPropertyChat,
  NoResultsChat,
  SuggestedPropertyChat,
  SupportSimpleChat,
  DocumentChat,
  TenantSimpleChat,
  CommentChat
} from '..'

import { ChatType, ThreadChat } from '../../types/messages'
import { isCommentChat, isDocumentChat, isNoResultsChat, isPropertyChat, isSent } from './typeguards'

export function Chat(info: ThreadChat): ReactElement | null {
  const { chat } = info

  if (!chat) {
    return null
  }

  if (!isSent(info)) {
    return <SupportSimpleChat {...info} />
  }

  const isTenant = info.user.user_type === 'tenant'

  if (isPropertyChat(info)) {
    return info.type === ChatType.RECOMMENDATION ? (
      <SuggestedPropertyChat {...info} />
    ) : (
      <InterestedPropertyChat {...info} />
    )
  }

  if (isDocumentChat(info)) {
    return <DocumentChat {...info} isTenant={isTenant} />
  }

  if (isCommentChat(info)) {
    if (!info.activity_feed_item) {
      // Can happen when someone has replied to a comment, not currently supported
      // but needs to be handled as can crash the app - there is data already in develop
      // that QA have added via postman.
      captureException(new Error('Unsupported comment reply could not be rendered'))
      return null
    }

    return <CommentChat {...info} />
  }

  if (isNoResultsChat(info)) return <NoResultsChat {...info} />

  return isTenant ? <TenantSimpleChat {...info} /> : <SupportSimpleChat {...info} />
}
