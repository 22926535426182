import moment from 'moment'
import { SingleBookingDetailType } from '../../../types/bookings'
import {
  Chat,
  NewChat,
  ChatStatus,
  ThreadTillParentMessage,
  ThreadType,
  MessageUser,
  CommentChatType
} from '../../../types/messages'
import { PropertyDetails, PropertyUnlistingStatus, SinglePropertyDetail } from '../../../types/properties'
import { ThreadState } from '../../../types/state'
import { UserInfo, RecommendationsProperty, UserServiceItem } from '../../../types/thread'

// eslint-disable-next-line
export const getNewThreads = (state: ThreadState, messageId: string, data: any) => {
  const index = state.threads.findIndex((thread: ThreadType) => thread.message.message_id === messageId)
  const newThreads = [...state.threads]
  const newChats: Chat[] = data.data.chats.data

  if (index !== -1) {
    newThreads[index].chats = [...state.threads[index].chats, ...newChats]
    newThreads[index].message = data.data.message
    newThreads[index].currentPage = data.data.chats.meta.current_page
    newThreads[index].lastPage = data.data.chats.meta.last_page
  }
  return newThreads
}

export const getThreadTillParent = (
  state: ThreadState,
  messageId: string,
  data: ThreadTillParentMessage
): ThreadType[] => {
  const index = state.threads.findIndex((thread: ThreadType) => thread.message.message_id === messageId)
  const newThreads = [...state.threads]
  const newChats: Chat[] = data.messages
  if (index !== -1) {
    newThreads[index].chats = [...newChats]
    newThreads[index].currentPage = data.pages
  }
  return newThreads
}

export const getWholeThread = (threads: ThreadType[], messageId: string, data: Chat[]): ThreadType[] => {
  const index = threads.findIndex((thread: ThreadType) => thread.message.message_id === messageId)
  const newThreads = [...threads]
  const newChats: Chat[] = data
  if (index !== -1) {
    newThreads[index].chats = [...newChats]
    newThreads[index].currentPage = newThreads[index].lastPage
  }
  return newThreads
}

export const getUpdateThread = (state: ThreadState, messageId: string, newChat: Chat): ThreadType[] => {
  return state.threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      return { ...thread, chats: [newChat, ...thread.chats] }
    }
    return thread
  })
}

export const getThreadsWithPendingChat = (state: ThreadState, messageId: string, newChat: NewChat): ThreadType[] => {
  return state.threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      return {
        ...thread,
        pending: [{ ...newChat, status: ChatStatus.PENDING }, ...thread.pending]
      }
    }
    return thread
  })
}

export const getThreadsWithNewChat = (
  state: ThreadState,
  messageId: string,
  newChat: Chat,
  resend: boolean
): ThreadType[] => {
  return state.threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      return {
        ...thread,
        chats: [newChat, ...thread.chats],
        pending: thread.pending.filter((pendingChat) => pendingChat.created_at !== newChat.created_at),
        undeliveredChats: resend
          ? thread.undeliveredChats.filter((chat) => chat.created_at !== newChat.created_at)
          : thread.undeliveredChats
      }
    }
    return thread
  })
}

export const getThreadsWithFailedChat = (
  threads: ThreadState['threads'],
  messageId: string,
  failedChat: NewChat
): ThreadType[] => {
  const thread = threads.find(({ message }) => message.message_id === messageId)

  if (!thread) {
    throw new TypeError('There should be a thread for the failed chat')
  }

  thread.pending = thread.pending.filter((pendingChat) => pendingChat.created_at !== failedChat.created_at)
  thread.undeliveredChats = [{ ...failedChat, status: ChatStatus.FAILED, messageId }, ...thread.undeliveredChats]

  return threads
}

export const deleteChat = (
  threads: ThreadState['threads'],
  messageId: string,
  chatId: string
): ThreadState['threads'] => {
  return threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      const chats = thread.chats.map((chat: Chat): Chat => {
        if (chat.chat_id === chatId) {
          return {
            ...chat,
            status: ChatStatus.DELETED
          }
        }
        return chat
      })

      return {
        ...thread,
        chats
      }
    }
    return thread
  })
}

export const updateChat = (
  threads: ThreadState['threads'],
  messageId: string,
  chat: CommentChatType
): ThreadState['threads'] => {
  return threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      const chats = thread.chats.map((currentChat: Chat): Chat => {
        if (currentChat.chat_id === chat.chat_id) {
          return chat
        }
        return currentChat
      })

      return {
        ...thread,
        chats
      }
    }
    return thread
  })
}

export const getFinalThreads = (state: ThreadState, messageId: string, userId: string): ThreadType[] => {
  return state.threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      const updatedGroup = thread.message.group.map((groupItem) => {
        if (groupItem.linked_user_id === userId) {
          groupItem.updated_at = moment.utc().toISOString()
        }
        return groupItem
      })
      thread.message.group = updatedGroup
      return {
        ...thread
      }
    }
    return thread
  })
}

export const getHasPaidUserThreads = (state: ThreadState, messageId: string, userId: string): ThreadType[] => {
  return state.threads.map((thread: ThreadType) => {
    if (thread.message.message_id === messageId) {
      const updatedGroup = thread.message.group.map((groupItem) => {
        if (groupItem.linked_user_id === userId) {
          groupItem.user_has_paid = true
        }
        return groupItem
      })
      thread.message.group = updatedGroup
      return {
        ...thread
      }
    }
    return thread
  })
}

export const getHasPriorityUserThreads = (
  state: ThreadState,
  selectedMessageId: string,
  selectedUserId: string,
  priority: string
): ThreadType[] => {
  return state.threads.map((thread: ThreadType) => {
    if (thread.message.message_id === selectedMessageId) {
      const updatedGroup = thread.message.group.map((groupItem) => {
        if (groupItem.linked_user_id === selectedUserId) {
          groupItem.user_priority = priority
        }
        return groupItem
      })
      thread.message.group = updatedGroup
      return {
        ...thread
      }
    }
    return thread
  })
}

/*
  Taking all the services in the function params, getting the associated services of servicesURLData
  by using Array.reduce and mutating it by adding URL.
  After getting all service objects, check if the services needs to be inserted or updated in includedService.
  Push if insert, reassign if update.
*/
export const addURLToService = (
  availableService: UserServiceItem[],
  includedService: UserServiceItem[],
  interestedService: UserServiceItem[],
  servicesURLData: Record<string, string>
): UserServiceItem[] => {
  const keys = Object.keys(servicesURLData)

  const serviceWithURL = [...availableService, ...includedService, ...interestedService].reduce(
    (filtered: UserServiceItem[], service: UserServiceItem) => {
      if (service.custom_name === keys[0]) {
        filtered.push({ ...service, url: servicesURLData[keys[0]] })
        keys.shift()
      }
      return filtered
    },
    []
  )

  const includedServiceNames: Record<string, number> = {}

  includedService.forEach((service, index) => (includedServiceNames[service.custom_name] = index))

  serviceWithURL.forEach((service) => {
    const serviceUpsert = includedServiceNames[service.custom_name]
    if (serviceUpsert === undefined) {
      includedService.push(service)
    } else {
      includedService[serviceUpsert] = service
    }
  })

  return includedService
}

export const getPropertiesFromResponse = (properties: SinglePropertyDetail[]): PropertyDetails[] =>
  properties.map(({ property }) => property)

export const bookingExists = (
  bookings: SingleBookingDetailType[],
  bookingId: string
): SingleBookingDetailType | undefined => {
  return bookings.find((booking) => booking.booking_id === bookingId)
}

export const addBookingToState = (
  existingInState: SingleBookingDetailType[],
  properties: SingleBookingDetailType[],
  currentPageInState = 1,
  currentPage = -1
): SingleBookingDetailType[] => {
  if (currentPageInState === currentPage) {
    return properties
  }

  const updatedProperties = [...existingInState]
  properties.forEach((booking) => {
    const bookingExistsInState = bookingExists(existingInState, booking.booking_id)
    if (!bookingExistsInState) updatedProperties.push(booking)
  })

  return updatedProperties
}

export const propertyConvertedToBooking = (
  properties: RecommendationsProperty[],
  property_id: string
): RecommendationsProperty[] => properties.filter(({ property }) => property.property_id !== property_id)

export const convertedToBooking = (
  properties: SingleBookingDetailType[],
  booking_id: string
): SingleBookingDetailType[] => properties.filter((property) => property.booking_id !== booking_id)

export const unlistPropertyInUserInfo = (state: ThreadState, propertyId: string, type: string): ThreadState => {
  const key = `${type}Properties` as keyof Pick<UserInfo, 'recommendationsProperties'>
  const typedProperties = state.user[key]

  const properties = typedProperties.properties.map((property: RecommendationsProperty) => ({
    ...property,
    ...(property.property.property_id === propertyId &&
      'property' in property && { property: { ...property.property, status: 'unlisted' } })
  }))

  return {
    ...state,
    user: {
      ...state.user,
      [key]: {
        ...typedProperties,
        properties,
        status: PropertyUnlistingStatus.PROPERTY_UNLISTING_SUCCESS
      }
    }
  }
}

export const getRemainingThreads = (
  threads: ThreadState['threads'],
  fetchedMessageId: string
): ThreadState['threads'] => {
  return threads
    .filter((thread) => {
      return thread.message.message_id !== fetchedMessageId
    })
    .slice(0, 25)
    .map((thread) => {
      if (thread.undeliveredChats.some((chat) => !chat)) {
        // we are getting rid of all null/undefined undeliveredChats chats that may exist locally as a side effect of OP-115
        thread.undeliveredChats = thread.undeliveredChats.filter((chat) => Boolean(chat))
      }

      return thread
    })
}

export const updateUsersInGroup = (
  threads: ThreadType[],
  message_id: string,
  updatedGroupMembers: MessageUser[]
): ThreadType[] => {
  const updatedData = threads.map((thread) => {
    if (thread.message.message_id === message_id) {
      return {
        ...thread,
        message: {
          ...thread.message,
          group: [
            ...thread.message.group.filter(
              (member) => member.user_type === 'tenant' || member.user_email === 'support@perchpeek.com'
            ),
            ...updatedGroupMembers
          ]
        }
      }
    }
    return thread
  })

  return updatedData
}
