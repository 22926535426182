import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Pusher from 'pusher-js'
import { captureException } from '@sentry/react'
import { history } from '../redux/store'

import baseUrl, { pusherKey } from '../utils/env'
import {
  PusherPayload,
  PusherMaintenancePayload,
  PusherMembers,
  PusherMember,
  MessageType,
  PusherParentChat,
  ChatType,
  ActivityFeedItem
} from '../types/messages'
import {
  FETCH_LAST_MESSAGE_REQUEST,
  UPDATE_THREAD_REQUEST,
  UPDATE_LAST_CHAT_REQUEST,
  MARK_AS_READ_MESSAGE_REQUEST,
  UPDATE_LAST_CHAT_IN_FILTERS_REQUEST,
  UPDATE_USER_READ_TIME,
  FETCH_MESSAGE_BY_ID_REQUEST
} from '../redux/constants'
import { getAuthState } from '../redux/selectors'
import { useInfoByMessageId } from './useInfoByMessageId'
import { store } from '../App'

type ChatDetailsFromPusher = {
  activity_feed_item?: ActivityFeedItem
  chat: string
  chat_id: string
  created_at: string
  name: string
  parent_chat: PusherParentChat
  sender_name: string
  sender_user_id: string
  sender_user_type: string
  status: string
  type: ChatType
  user: {
    name: string
    profile_image_url: string
    user_id: string
    user_type: string
  }
}

export function usePusherForMessages(): void {
  const { dispatch, getState } = store

  const { token, currentUser } = getState().authState

  const userId = localStorage.getItem('userId')

  useEffect(() => {
    if (!token) return

    const pusher = new Pusher(pusherKey, {
      cluster: 'eu',
      authEndpoint: baseUrl + '/broadcast/auth/user',
      auth: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    })

    //Create Pusher private channel
    const channel = pusher.subscribe('private-perchpeek-notifications-' + userId)

    //Create Pusher public channel
    const publicChannel = pusher.subscribe('perchpeek-notifications')

    // new incoming message
    channel.bind('message', () => {
      // eslint-disable-next-line no-console
      console.log('======= new incoming message =====')
      dispatch({ type: FETCH_LAST_MESSAGE_REQUEST })
    })

    // new incoming chat
    channel.bind('chat', (payloadData: PusherPayload) => {
      // eslint-disable-next-line no-console
      console.log('======= new incoming chat =====')
      const data = payloadData.data

      if (!data.chat_object) {
        const error = new Error('A falsy chat_object in new chat\n' + JSON.stringify(data))
        captureException(error)
      }

      if (data.chat_object.type === ChatType.COMMENT && !data.chat_object.activity_feed_item) {
        captureException(new Error('Replies to comments are not currently supported in pusher'))
        return
      }

      const newChat: ChatDetailsFromPusher = {
        activity_feed_item: data.chat_object.activity_feed_item,
        chat: data.chat,
        chat_id: data.chat_object.chat_id,
        created_at: data.created_at,
        name: data.user_name,
        parent_chat: data.chat_object.parent_chat,
        sender_name: data.user_name,
        sender_user_id: data.user_id,
        sender_user_type: data.user_type,
        status: data.chat_object.status,
        type: data.chat_object.type,
        user: {
          name: data.user_name,
          profile_image_url: data.user_profile_image,
          user_id: data.user_id,
          user_type: data.user_type
        }
      }

      const { messages, searchMessages, showFavoritedMessages } = getState().messageState
      const { threads } = getState().threadState

      const message =
        messages.find((message) => message.message_id === data.message_id) ??
        threads.find(({ message }) => message.message_id === data.message_id)

      if (data.users_who_favorited_message === undefined) {
        const error = new Error('There is no users_who_favorited_message in new chat\n' + JSON.stringify(data))
        captureException(error)
      }
      const usersWhoFavoritedMessage = data.users_who_favorited_message || []
      const currentUserHasFavorited = usersWhoFavoritedMessage.some((userId: string) => userId === currentUser?.user_id)

      /*
      case 1: no message & toggle off -> dispatch
      case 2: no message & toggle on & current User Has Favorited -> dispatch
      case 3: no message & toggle on & current User Has not Favorited -> ignore event
      */
      if (!message && (!showFavoritedMessages || (showFavoritedMessages && currentUserHasFavorited))) {
        dispatch({ type: FETCH_MESSAGE_BY_ID_REQUEST, payload: { messageId: data.message_id } })
      }

      // case 1: message present & toggle off
      // case 2: message present & toggle on
      if (message) {
        addIncomingChatToThread(newChat, data.message_id)
        sortFilteredMessages(newChat, data.message_id, searchMessages)
        markAsRead(newChat, data.message_id)
      }

      // TODO: Send web notification
    })

    // Maintenance mode
    publicChannel.bind('chat', (data: PusherMaintenancePayload) => {
      console.info('======= Maintenance mode =======')
      if (data.data.data.status === 'on') {
        history.push('/maintenance')
      } else {
        history.push('/messages')
      }
    })

    return () => {
      pusher.unsubscribe('private-perchpeek-notifications-' + userId)
      pusher.unsubscribe('perchpeek-notifications')
    }
  }, [token])

  const addIncomingChatToThread = (newChat: ChatDetailsFromPusher, messageId: string) => {
    const locationMessageId = window.location.pathname.split('/')[2]

    if (newChat.sender_user_id !== userId) {
      dispatch({ type: UPDATE_THREAD_REQUEST, payload: { newChat, messageId } })
    }

    const chat = {
      [ChatType.RECOMMENDATION]: '[Property Recommendation]',
      [ChatType.DOCUMENT]: '[Document]',
      [ChatType.IMAGE]: '[Image]',
      [ChatType.NOTIFICATION_ENQUIRED]: newChat.chat,
      [ChatType.NOTIFICATION_RECOMMENDATION_DECLINED]: newChat.chat,
      [ChatType.NOTIFICATION_RECOMMENDATION_ENQUIRED]: newChat.chat,
      [ChatType.NO_PROPERTY_RESULTS]: newChat.chat,
      [ChatType.TEXT]: newChat.chat,
      [ChatType.COMMENT]: newChat.chat
    }[newChat.type]

    dispatch({
      type: UPDATE_LAST_CHAT_REQUEST,
      payload: { newChat: { ...newChat, chat }, messageId, userPresentInThread: locationMessageId === messageId }
    })
  }

  const sortFilteredMessages = (newChat: ChatDetailsFromPusher, messageId: string, searchMessages?: MessageType[]) => {
    const locationMessageId = window.location.pathname.split('/')[2]

    if (searchMessages && searchMessages.length > 0) {
      dispatch({
        type: UPDATE_LAST_CHAT_IN_FILTERS_REQUEST,
        payload: { newChat, messageId, userPresentInThread: locationMessageId === messageId }
      })
    }
  }

  const markAsRead = (newChat: ChatDetailsFromPusher, messageId: string) => {
    const path = window.location.pathname
    const locationMessageId = path.split('/')[2]

    if (newChat.user.user_id === userId || locationMessageId !== messageId) return

    dispatch({ type: MARK_AS_READ_MESSAGE_REQUEST, payload: { token, messageId } })
  }
}

export const usePusherForThread = (): string[] => {
  const dispatch = useDispatch()
  const { token } = useSelector(getAuthState)
  const { messageId } = useInfoByMessageId()
  const [pusherChannelMembers, setPusherChannelMembers] = useState<string[]>([])

  useEffect(() => {
    const pusher = new Pusher(pusherKey, {
      cluster: 'eu',
      authEndpoint: baseUrl + '/broadcast/auth',
      auth: {
        headers: {
          Authorization: 'Bearer ' + token
        }
      }
    })

    //subscribe to pusher channel
    const channel = pusher.subscribe('presence-perchpeek-chat-' + messageId)
    //Add other users already on the thread to channelMembers
    channel.bind('pusher:subscription_succeeded', (members: PusherMembers) => {
      const channelMembers = Object.entries(members.members)
        .filter((item) => {
          const details = item[1]
          return details?.user_type !== 'support' && details?.user_type !== 'va'
        })
        .map((item) => item[0])
      setPusherChannelMembers(channelMembers)
    })
    //Listen for when a user joins the channel
    channel.bind('pusher:member_added', (member: PusherMember) => {
      if (member.info?.user_type !== 'support' && member.info?.user_type !== 'va') {
        setPusherChannelMembers((prevPusherChannelMembers) => [...prevPusherChannelMembers, member.id])
      }
    })
    //Listen for when a user leaves the channel
    channel.bind('pusher:member_removed', (member: PusherMember) => {
      setPusherChannelMembers((prevPusherChannelMembers) =>
        prevPusherChannelMembers.filter((memberId) => memberId !== member.id)
      )
      dispatch({ type: UPDATE_USER_READ_TIME, payload: { userId: member.id, messageId } })
    })

    return () => {
      pusher.unsubscribe('presence-perchpeek-chat-' + messageId)
    }
  }, [messageId])

  return pusherChannelMembers
}
