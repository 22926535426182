import {
  FETCH_THREADS_SUCCESS,
  FETCH_MORE_THREADS_REQUEST,
  FETCH_MORE_THREADS_SUCCESS,
  FETCH_MORE_THREADS_FAILURE,
  SEND_NEW_CHAT_SUCCESS,
  SEND_NEW_CHAT_FAILURE,
  DELETE_CHAT_SUCCESS,
  ADD_PENDING_CHAT,
  UPDATE_USER_READ_TIME,
  MARK_USER_PAID_SUCCESS,
  RESET_PAID_SUCCESS_STATUS,
  UPDATE_THREAD_REQUEST,
  SET_USER_PRIORITY_SUCCESS,
  SET_REPLY_MESSAGE_DETAILS,
  CLEAR_REPLY_MESSAGE_DETAILS,
  RESET_UPDATE_PRIORITY_SUCCESS_STATUS,
  GET_USER_STATUS_SUCCESS,
  GET_RECOMMENDATIONS_PROPERTIES_SUCCESS,
  GET_RECOMMENDATIONS_PROPERTIES_FAILURE,
  RESET_STATUS,
  CLEAR_USER_INFO_DETAILS,
  REMOVE_PROPERTY_FROM_RECOMMENDATIONS,
  UNLIST_USER_INFO_PROPERTY_SUCCESS,
  UNLIST_USER_INFO_PROPERTY_FAILURE,
  GET_USER_SERVICES_SUCCESS,
  FETCH_THREADS_TILL_PARENT_FAILURE,
  FETCH_THREADS_TILL_PARENT_REQUEST,
  FETCH_THREADS_TILL_PARENT_SUCCESS,
  RESET_FETCH_THREADS_TILL_PARENT_STATUS,
  UPDATE_USER_SERVICES_REQUEST,
  UPDATE_USER_SERVICES_SUCCESS,
  UPDATE_USER_SERVICES_FAILURE,
  FETCH_USER_DOCUMENT_REQUEST,
  FETCH_USER_DOCUMENT_SUCCESS,
  FETCH_USER_DOCUMENT_FAILURE,
  UPDATE_TEAM_MEMBERS_SUCCESS,
  CLEAR_USER_SERVICES_STATUS,
  FETCH_WHOLE_THREAD_FAILURE,
  FETCH_WHOLE_THREAD_REQUEST,
  FETCH_WHOLE_THREAD_SUCCESS,
  SEND_NEW_CHAT_DOCUMENT_SUCCESS,
  SEND_NEW_CHAT_DOCUMENT_REQUEST,
  SEND_NEW_CHAT_DOCUMENT_FAILURE,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAILURE,
  CREATE_CARD_REQUEST,
  ACTION_CHAT_FAILURE,
  ACTION_CHAT_SUCCESS,
  ACTION_CHAT_RESET
} from '../../constants'
import { Status, ThreadState } from '../../../types/state'
import {
  getFinalThreads,
  getHasPaidUserThreads,
  getNewThreads,
  getThreadsWithNewChat,
  getThreadsWithPendingChat,
  getThreadsWithFailedChat,
  getUpdateThread,
  deleteChat,
  getHasPriorityUserThreads,
  propertyConvertedToBooking,
  unlistPropertyInUserInfo,
  getThreadTillParent,
  getRemainingThreads,
  updateUsersInGroup,
  getWholeThread,
  updateChat
} from './utils'
import { UndeliveredChat, ChatStatus, MessageUser } from '../../../types/messages'
import { AnyAction } from 'redux'
import { PropertyUnlistingStatus } from '../../../types/properties'

export const initialState: ThreadState = {
  threads: [],
  undeliveredChats: [],
  fetchMoreThreadsRequested: false,
  user: {
    info: {
      linked_user_id: '',
      updated_at: '',
      user_created_at: '',
      user_email: '',
      user_has_paid: false,
      user_priority: '',
      user_type: '',
      role: '',
      user_profile_questionnaire: null
    },
    status: undefined,
    recommendationsProperties: {
      properties: [],
      loading: true,
      error: false,
      status: undefined
    },
    services: { available: [], interested: [], included: [] }
  },
  paidSuccess: false,
  updatedPriority: false,
  selectedForReplyChat: { sender: '', chat: '', chatId: '' },
  fetchParentMessage: { requested: false, success: false, failure: false },
  fetchWholeThread: { requested: false, success: false, failure: false },
  sendDocument: { requested: false, success: false, failure: false },
  actionedChatStatus: undefined
}

export default (state = initialState, action: AnyAction): ThreadState => {
  switch (action.type) {
    case FETCH_THREADS_SUCCESS:
      const { chats: fetchedChats, message: fetchedMessageInfo } = action.payload.data
      const fetchedMessageId: string = fetchedMessageInfo.message_id

      const undeliveredChatsInThread = state.undeliveredChats.filter((chat: UndeliveredChat) => {
        if (chat.messageId === fetchedMessageId) {
          return { ...chat }
        }
      })
      const remainingThreads = getRemainingThreads(state.threads, fetchedMessageId)
      const info = fetchedMessageInfo.group.find((member: MessageUser) => member.user_type === 'tenant')

      return {
        ...state,
        threads: [
          {
            chats: fetchedChats.data,
            message: fetchedMessageInfo,
            currentPage: fetchedChats.meta.current_page,
            lastPage: fetchedChats.meta.last_page,
            undeliveredChats: undeliveredChatsInThread,
            pending: []
          },
          ...remainingThreads
        ],
        user: { ...state.user, info },
        fetchWholeThread: { requested: false, success: false, failure: false }
      }

    case UPDATE_THREAD_REQUEST:
      const { newChat: new_chat, messageId: message_id } = action.payload
      return {
        ...state,
        threads: getUpdateThread(state, message_id, new_chat)
      }

    case FETCH_MORE_THREADS_REQUEST:
      return {
        ...state,
        fetchMoreThreadsRequested: true
      }

    case FETCH_MORE_THREADS_SUCCESS:
      const { messageId, data } = action.payload
      return {
        ...state,
        threads: getNewThreads(state, messageId, data),
        fetchMoreThreadsRequested: false
      }

    case FETCH_MORE_THREADS_FAILURE:
      return {
        ...state,
        fetchMoreThreadsRequested: false
      }

    case FETCH_THREADS_TILL_PARENT_REQUEST:
      return {
        ...state,
        fetchParentMessage: { requested: true, success: false, failure: false }
      }

    case FETCH_THREADS_TILL_PARENT_SUCCESS:
      const { messageId: msgID, data: newData } = action.payload
      return {
        ...state,
        threads: getThreadTillParent(state, msgID, newData),
        fetchParentMessage: { requested: false, success: true, failure: false }
      }

    case FETCH_THREADS_TILL_PARENT_FAILURE:
      return {
        ...state,
        fetchParentMessage: { requested: false, success: false, failure: true }
      }

    case RESET_FETCH_THREADS_TILL_PARENT_STATUS:
      return {
        ...state,
        fetchParentMessage: { requested: false, success: false, failure: false }
      }

    case SEND_NEW_CHAT_DOCUMENT_REQUEST:
      return {
        ...state,
        sendDocument: { requested: true, success: false, failure: false }
      }

    case SEND_NEW_CHAT_DOCUMENT_SUCCESS: {
      const { messageId: msgId, chat } = action.payload
      return {
        ...state,
        threads: getThreadsWithNewChat(state, msgId, chat, false),
        sendDocument: { requested: false, success: true, failure: false }
      }
    }

    case SEND_NEW_CHAT_DOCUMENT_FAILURE:
      return {
        ...state,
        sendDocument: { requested: false, success: false, failure: true }
      }

    case FETCH_WHOLE_THREAD_REQUEST:
      return {
        ...state,
        fetchWholeThread: { requested: true, success: false, failure: false }
      }

    case FETCH_WHOLE_THREAD_SUCCESS:
      const { messageId: msg_ID, data: new_Data } = action.payload
      return {
        ...state,
        threads: getWholeThread(state.threads, msg_ID, new_Data),
        fetchWholeThread: { requested: false, success: true, failure: false }
      }

    case FETCH_WHOLE_THREAD_FAILURE:
      return {
        ...state,
        fetchWholeThread: { requested: false, success: false, failure: true }
      }

    case ADD_PENDING_CHAT:
      const { messageId: addPendingMessageId, chat: addPendingChat } = action.payload
      return {
        ...state,
        threads: getThreadsWithPendingChat(state, addPendingMessageId, addPendingChat)
      }

    case SEND_NEW_CHAT_SUCCESS:
      const { messageId: msgId, chat, resend } = action.payload
      return {
        ...state,
        threads: getThreadsWithNewChat(state, msgId, chat, resend),
        undeliveredChats: resend
          ? state.undeliveredChats.filter((undeliveredChat) => undeliveredChat.created_at !== chat.created_at)
          : state.undeliveredChats
      }

    case SEND_NEW_CHAT_FAILURE:
      const { messageId: failedMessageId, chat: failedChat } = action.payload
      return {
        ...state,
        threads: getThreadsWithFailedChat([...state.threads], failedMessageId, failedChat),
        undeliveredChats: [
          { ...failedChat, status: ChatStatus.FAILED, messageId: failedMessageId },
          ...state.undeliveredChats
        ]
      }

    case DELETE_CHAT_SUCCESS:
      const { messageId: activeMessageId, chatId } = action.payload
      return {
        ...state,
        threads: deleteChat(state.threads, activeMessageId, chatId)
      }

    case ACTION_CHAT_SUCCESS:
      return {
        ...state,
        actionedChatStatus: Status.SUCCESS,
        threads: updateChat(state.threads, action.payload.messageId, action.payload.chat)
      }

    case ACTION_CHAT_FAILURE:
      return {
        ...state,
        actionedChatStatus: Status.ERROR
      }

    case ACTION_CHAT_RESET:
      return {
        ...state,
        actionedChatStatus: undefined
      }

    case UPDATE_USER_READ_TIME:
      const { userId: user_id, messageId: msg_id } = action.payload
      return {
        ...state,
        threads: getFinalThreads(state, msg_id, user_id)
      }

    case MARK_USER_PAID_SUCCESS:
      const { userId: paidUserId, messageId: paidMessageId } = action.payload
      return {
        ...state,
        paidSuccess: true,
        threads: getHasPaidUserThreads(state, paidMessageId, paidUserId)
      }

    case SET_USER_PRIORITY_SUCCESS:
      const { userId: selectedUserId, messageId: selectedMessageId, priority } = action.payload
      return {
        ...state,
        updatedPriority: true,
        threads: getHasPriorityUserThreads(state, selectedMessageId, selectedUserId, priority)
      }

    case RESET_PAID_SUCCESS_STATUS:
      return {
        ...state,
        paidSuccess: false
      }

    case RESET_UPDATE_PRIORITY_SUCCESS_STATUS:
      return {
        ...state,
        updatedPriority: false
      }

    case SET_REPLY_MESSAGE_DETAILS:
      const { sender, chat: text, chatId: id } = action.payload
      return {
        ...state,
        selectedForReplyChat: {
          sender,
          chat: text,
          chatId: id
        }
      }

    case CLEAR_REPLY_MESSAGE_DETAILS:
      return {
        ...state,
        selectedForReplyChat: {
          sender: '',
          chatId: '',
          chat: ''
        }
      }

    case UPDATE_TEAM_MEMBERS_SUCCESS:
      const { messageId: messageID, users } = action.payload
      return {
        ...state,
        threads: updateUsersInGroup(state.threads, messageID, users)
      }

    case GET_USER_STATUS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          status: { ...action.payload.data, userId: action.payload.userId }
        }
      }

    case GET_USER_SERVICES_SUCCESS:
      const { data: servicesData } = action.payload
      return {
        ...state,
        user: {
          ...state.user,
          services: {
            available: servicesData.available_services,
            interested: servicesData.interested_services,
            included: servicesData.included_services
          }
        }
      }

    case CLEAR_USER_SERVICES_STATUS:
      return {
        ...state,
        servicesStatus: undefined
      }

    case UPDATE_USER_SERVICES_REQUEST:
      return {
        ...state,
        servicesStatus: undefined
      }

    case UPDATE_USER_SERVICES_FAILURE:
      return {
        ...state,
        servicesStatus: 'error'
      }

    case UPDATE_USER_SERVICES_SUCCESS:
      return {
        ...state,
        servicesStatus: 'success'
      }

    case GET_RECOMMENDATIONS_PROPERTIES_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          recommendationsProperties: {
            properties: action.payload.properties,
            loading: false,
            error: false,
            status: 'success'
          }
        }
      }

    case GET_RECOMMENDATIONS_PROPERTIES_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          recommendationsProperties: {
            ...state.user.recommendationsProperties,
            error: true
          }
        }
      }

    case REMOVE_PROPERTY_FROM_RECOMMENDATIONS:
      const { properties: recommendationsPropertiesList } = state.user.recommendationsProperties
      const { id: idToRemove } = action.payload
      const updatedPropertiesList = propertyConvertedToBooking(recommendationsPropertiesList, idToRemove)
      return {
        ...state,
        user: {
          ...state.user,
          recommendationsProperties: {
            ...state.user.recommendationsProperties,
            properties: updatedPropertiesList,
            status: 'Converted to booking'
          }
        }
      }

    case UNLIST_USER_INFO_PROPERTY_SUCCESS:
      return unlistPropertyInUserInfo(state, action.payload.propertyId, action.payload.type)

    case UNLIST_USER_INFO_PROPERTY_FAILURE:
      return {
        ...state,
        user: {
          ...state.user,
          recommendationsProperties: {
            ...state.user.recommendationsProperties,
            status: PropertyUnlistingStatus.PROPERTY_UNLISTING_FAILED
          }
        }
      }

    case RESET_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          recommendationsProperties: { ...state.user.recommendationsProperties, status: undefined }
        }
      }
    case FETCH_USER_DOCUMENT_REQUEST:
      return {
        ...state,
        documentFetching: true
      }

    case FETCH_USER_DOCUMENT_SUCCESS:
    case FETCH_USER_DOCUMENT_FAILURE:
      return {
        ...state,
        documentFetching: false
      }

    case CLEAR_USER_INFO_DETAILS:
      return {
        ...state,
        documentFetching: undefined,
        user: {
          info: {
            linked_user_id: '',
            updated_at: '',
            user_created_at: '',
            user_email: '',
            user_has_paid: false,
            user_priority: '',
            user_type: '',
            role: '',
            user_profile_questionnaire: null
          },
          status: undefined,
          recommendationsProperties: {
            properties: [],
            loading: true,
            error: false,
            status: ''
          },
          services: {
            available: [],
            interested: [],
            included: []
          }
        }
      }

    case CREATE_CARD_REQUEST:
      return {
        ...state,
        contentStatus: undefined
      }

    case CREATE_CARD_SUCCESS:
    case CREATE_CARD_FAILURE:
      return {
        ...state,
        contentStatus: action.payload.message
      }

    default:
      return state
  }
}
