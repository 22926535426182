import { ReactElement, useEffect, useState } from 'react'
import { Drawer, Hidden, IconButton, makeStyles } from '@material-ui/core'
import { Menu } from '@material-ui/icons'
import { Header, UserProfileForm, UsersListSidebar } from '../../components'
import { useSelector } from 'react-redux'
import { getUsersState } from '../../redux/selectors'
import { useParams } from 'react-router-dom'

export type UserFormEditMode = { isTouched: boolean; touchedID: string }

const useStyles = makeStyles((theme) => ({
  screen: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit'
    }
  },
  headerHeight: {
    height: 50
  },
  container: {
    display: 'flex',
    minHeight: 'calc(100vh - 50px)',
    [theme.breakpoints.down('sm')]: {
      minHeight: 'calc(100vh - 80px)',
      marginTop: 30
    }
  },
  drawerWidth: {
    width: '90%'
  },
  menuBtn: {
    position: 'absolute',
    top: 100,
    left: 10
  }
}))

export const UserManagement = (): ReactElement => {
  const classes = useStyles()
  const { userId } = useParams<{ userId: string }>()
  const { failure, upsertFailure } = useSelector(getUsersState)
  const [openUsersListDrawer, setOpenUsersListDrawer] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<UserFormEditMode>({ isTouched: false, touchedID: '' })

  useEffect(() => {
    if (failure || upsertFailure) {
      setEditMode({ isTouched: true, touchedID: userId })
    }
  }, [failure || upsertFailure])

  useEffect(() => {
    openUsersListDrawer && setOpenUsersListDrawer(false)
  }, [userId])

  return (
    <div data-testid="user-management-screen" className={classes.screen}>
      <div className={classes.headerHeight}>
        <Header />
      </div>
      <Hidden mdUp>
        <IconButton
          aria-label="users-menu-hamburger"
          className={classes.menuBtn}
          onClick={() => setOpenUsersListDrawer(true)}
        >
          <Menu />
        </IconButton>
        <Drawer
          open={openUsersListDrawer}
          onClose={() => setOpenUsersListDrawer(false)}
          classes={{ paperAnchorLeft: classes.drawerWidth }}
        >
          <UsersListSidebar editMode={editMode} setEditMode={setEditMode} />
        </Drawer>
      </Hidden>
      <div className={classes.container}>
        <Hidden smDown>
          <UsersListSidebar editMode={editMode} setEditMode={setEditMode} />
        </Hidden>
        <UserProfileForm editMode={editMode} setEditMode={setEditMode} />
      </div>
    </div>
  )
}
