import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Thread } from '../Thread'

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh'
  },
  flexWrapper: {
    height: '100%',
    display: 'flex'
  },
  contentWrapper: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1
  }
}))

export function Archive(): ReactElement {
  const classes = useStyles()

  return (
    <div data-testid="messages-container" className={classes.container}>
      <div className={classes.flexWrapper}>
        <div className={classes.contentWrapper}>
          <Thread isArchive />
        </div>
      </div>
    </div>
  )
}
