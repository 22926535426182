import moment from 'moment'
import { memo, ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Typography } from '@material-ui/core'

import { ThreadPropertyCard } from '..'

import { DeleteChat } from '../DeleteChat'
import { ChatStatus, PropertyChat, PusherPropertyChat } from '../../types/messages'
import { ThreadPropertyType } from '../../types/properties'

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    margin: '10px 0',
    marginRight: 'auto',
    width: '100%'
  },
  paper: {
    borderRadius: '20px 20px 20px 0',
    boxSizing: 'border-box',
    padding: '15px 20px',
    position: 'relative',
    maxWidth: 600
  }
}))

// eslint-disable-next-line react/display-name
export const InterestedPropertyChat = memo((info: PropertyChat | PusherPropertyChat): ReactElement => {
  const { status, created_at } = info
  const classes = useStyles()

  return (
    <div className={classes.container} data-testid="interested-property-chat">
      {status === ChatStatus.ACTIVE ? (
        <>
          <Typography variant="subtitle2" color="textSecondary">
            Interested Property:
          </Typography>
          <ThreadPropertyCard info={info} type={ThreadPropertyType.INTERESTED} />
        </>
      ) : (
        <Paper elevation={2} className={classes.paper}>
          <DeleteChat type="tenant" />
        </Paper>
      )}
      <Typography variant="subtitle2" color="textSecondary" style={{ fontSize: '0.6em' }}>
        {moment.utc(created_at).local().format('dddd, Do of MMMM Y, H:mm')}
      </Typography>
    </div>
  )
})
