import { AnyAction } from 'redux'
import { PackageState } from '../../../types/state'
import {
  FETCH_PACKAGES_REQUEST,
  FETCH_PACKAGES_SUCCESS,
  FETCH_PACKAGES_FAILURE,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE
} from '../../constants'

export const initialState: PackageState = {
  requested: false,
  success: false,
  failure: false,
  updateFailure: false,
  updateRequested: false,
  updateSuccess: false,
  packages: {
    package_id: '',
    packages: []
  }
}

export default (state = initialState, action: AnyAction): PackageState => {
  switch (action.type) {
    case FETCH_PACKAGES_REQUEST:
      return {
        ...state,
        requested: true,
        success: false,
        failure: false
      }

    case FETCH_PACKAGES_SUCCESS: {
      const { data: packages } = action.payload
      return {
        ...state,
        requested: false,
        success: true,
        failure: false,
        packages
      }
    }

    case FETCH_PACKAGES_FAILURE:
      return {
        ...state,
        requested: false,
        success: false,
        failure: true
      }

    case UPDATE_PACKAGE_SUCCESS: {
      const { package_id } = action.payload

      return {
        ...initialState,
        updateSuccess: true,
        packages: { ...state.packages, package_id }
      }
    }
    case UPDATE_PACKAGE_REQUEST:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        updateSuccess: false,
        updateFailure: false,
        updateRequested: true
      }

    case UPDATE_PACKAGE_FAILURE:
      return {
        ...state,
        requested: false,
        success: false,
        failure: false,
        updateSuccess: false,
        updateFailure: true,
        updateRequested: false
      }

    default:
      return state
  }
}
