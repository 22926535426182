import { PropertyDetails } from './properties'
import { CardType, ContentPayload } from './thread'

export type MessageUser = {
  linked_user_id: string
  updated_at: string
  user_country_code?: string
  user_created_at: string
  user_destination_country?: string
  user_email: string
  user_name?: string
  user_phone_number?: string
  user_profile_image?: string
  user_has_paid: boolean
  user_priority: string
  user_type: string
  role: string
  user_profile_questionnaire: {
    city: string | null
    country: string | null
    moveDate: string | null
    moving_with: string | null
    onboarded: boolean | null
    originCity: string | null
    originCountry: string | null
  } | null
}

export type MessageType = {
  created_at: string
  updated_at: string
  group: MessageUser[]
  last_chat: string
  last_chat_created_at: string
  last_chat_name: string
  message_id: string
  type: string
  is_favorite: boolean
}

export type MessagesTypes = {
  currentPage: number
  lastPage: number
  messages: MessageType[]
}

export type Sender = {
  card_brand?: string
  card_last_four?: string
  company_id?: string
  country_code?: string
  created_at: string
  email: string
  name: string
  phone_no_full?: string
  phone_number?: string
  profile_image_url?: string
  slug: string
  stage: string
  status: string
  time_zone?: string
  user_id: string
  user_type: string
  user_verification?: string
}

export enum UserRoles {
  CUSTOMER_SUCCESS_LEAD = 'customer-success-lead',
  CUSTOMER_SUCCESS_MANAGER = 'customer-success-manager',
  CUSTOMER_SUCCESS_ASSOCIATE = 'customer-success-associate',
  SUPPORT_TEAM = 'support-team'
}

export type UserType = {
  company_id?: string
  name: string
  email: string
  profile_image_url?: string
  stage?: string
  status?: string
  time_zone?: string
  user_id: string
  user_type?: string
  user_verification?: string
  viewing_days?: string
  booking_times?: string
  role?: {
    slug: UserRoles
  }
}

export enum ChatStatus {
  ACTIVE = 'active',
  DELETED = 'deleted',
  PENDING = 'pending',
  FAILED = 'failed'
}

type ChatBase = {
  activity_feed_item?: ActivityFeedItem
  chat: string
  created_at: string
  name: string
  sender?: Sender
  sender_name: string
  sender_user_id?: string
  sender_user_type: string
  status: ChatStatus.ACTIVE | ChatStatus.DELETED
  user: UserType
  read?: boolean
  chat_id: string
  parent_id?: string
  parent_chat?: Chat
  messageId?: string
}

export enum ChatType {
  TEXT = 'text',
  RECOMMENDATION = 'property_recommendation',
  NOTIFICATION_ENQUIRED = 'notification_property_enquired',
  NOTIFICATION_RECOMMENDATION_ENQUIRED = 'notification_property_recommendation_enquired',
  NOTIFICATION_RECOMMENDATION_DECLINED = 'notification_property_recommendation_declined',
  NO_PROPERTY_RESULTS = 'no_property_results',
  DOCUMENT = 'document_uploaded',
  IMAGE = 'image_uploaded',
  COMMENT = 'card_comment'
}

export type PusherPropertyChat = ChatBase & {
  type:
    | ChatType.RECOMMENDATION
    | ChatType.NOTIFICATION_ENQUIRED
    | ChatType.NOTIFICATION_RECOMMENDATION_ENQUIRED
    | ChatType.NOTIFICATION_RECOMMENDATION_DECLINED
  // chats from pusher events do not have property field
}

export type PropertyChat = PusherPropertyChat & {
  property: PropertyDetails
}

export type TextChat = ChatBase & {
  type: ChatType.TEXT
  property: null
}

export type NoResultsChat = ChatBase & {
  type: ChatType.NO_PROPERTY_RESULTS
  property: null
}

export type DocumentChat = ChatBase & {
  type: ChatType.DOCUMENT | ChatType.IMAGE
  property: null
}

export enum PropertyFeedCards {
  property_recommendation = 'property_recommendation',
  property_enquiry_full = 'property_enquiry_full',
  property_enquiry_minimal = 'property_enquiry_minimal'
}

export const ActivityFeedItemTypes = { ...CardType, ...PropertyFeedCards }

export type ActivityFeedItemTypeKeys = keyof typeof ActivityFeedItemTypes

export type ActivityFeedItem = {
  activity_feed_item_id: string
  type: ActivityFeedItemTypeKeys
  data: ContentPayload | PropertyDetails
}

export type CommentChatType = ChatBase & {
  type: ChatType.COMMENT
  property?: PropertyDetails
  actioned: boolean
  activity_feed_item: ActivityFeedItem
}

export type Chat = TextChat | PusherPropertyChat | PropertyChat | NoResultsChat | DocumentChat | CommentChatType

export type NewChat = Omit<ChatBase, 'chat_id' | 'status' | 'parent_chat' | 'user'> & {
  status: ChatStatus.ACTIVE
  parent_chat?: {
    chat_id: ChatBase['chat_id']
    sender_name: ChatBase['sender_name']
    chat: ChatBase['chat']
  }
  user: {
    name: ChatBase['user']['name']
    user_id: ChatBase['user']['user_id']
  }
}

export type PendingChat = Omit<NewChat, 'status'> & {
  status: ChatStatus.PENDING
}
export type UndeliveredChat = Omit<NewChat, 'status'> & {
  status: ChatStatus.FAILED
}

export type ThreadChat = Chat | PendingChat | UndeliveredChat

export type ThreadType = {
  chats: Chat[]
  message: MessageType
  currentPage: number
  lastPage: number
  pending: PendingChat[]
  undeliveredChats: UndeliveredChat[]
}

export type ThreadTillParentMessage = {
  messages: Chat[]
  message: string | null
  pages: number
}

export type UserStautsType = {
  chat_count: number
  chat_count_from_support: number
  dislikes_count: number
  interested_properties_count: number
  likes_count: number
  recommended_properties_count: number
  search_count: number
}

export type PusherPayload = {
  data: {
    chat: string
    created_at: string
    chat_object: {
      activity_feed_item?: ActivityFeedItem
      chat_id: string
      chat: string
      user: {
        user_id: string
      }
      message_id: string
      type: ChatType
      status: ChatStatus.ACTIVE | ChatStatus.DELETED
      parent_chat: PusherParentChat
    }
    message_id: string
    type: string
    user_id: string
    user_name: string
    user_type: string
    user_profile_image: string
    users_who_favorited_message: string[]
  }
}

export type PusherParentChat = {
  chat_id: string
  chat: string
  user: {
    user_id: string
  }
  message_id: string
  type: string
  status: string
}

export type PusherMaintenancePayload = {
  data: {
    data: {
      name: string
      status: string
    }
  }
}

export type PusherMemberInfo = {
  name?: string
  user_type?: string
}

export type PusherMembers = {
  count: number
  me: {
    info?: PusherMemberInfo
  }
  members: {
    ['memberId']?: PusherMemberInfo
  }
  myID: string
}

export type PusherMember = {
  id: string
  info?: PusherMemberInfo
}
