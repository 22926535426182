import { all } from 'redux-saga/effects'
import authSaga from './auth'
import messageSaga from './message'
import threadSaga from './thread'
import noteSaga from './note'
import bookingSaga from './booking'
import propertySaga from './property'
import documentSaga from './document'
import usersSaga from './users'
import packagesSaga from './packages'

export default function* rootSaga(): Generator {
  yield all([
    authSaga(),
    messageSaga(),
    threadSaga(),
    noteSaga(),
    bookingSaga(),
    propertySaga(),
    documentSaga(),
    usersSaga(),
    packagesSaga()
  ])
}
