import { ReactElement, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'

import { getThreadState } from '../../redux/selectors'
import { FETCH_THREADS_TILL_PARENT_REQUEST, RESET_FETCH_THREADS_TILL_PARENT_STATUS } from '../../redux/constants'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { useContent } from './useContent'

type Props = {
  chatId: string
  chat: string
  sender: string
}

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#E4E4E4',
    borderRadius: 5,
    width: '90%',
    padding: 10,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    cursor: 'pointer'
  },
  textContent: {
    overflow: 'hidden'
  },
  sender: {
    fontSize: '0.7em'
  },
  text: {
    marginTop: 5
  },
  img: {
    maxWidth: 75
  }
}))

export const ParentChatBubble = ({ chat, chatId, sender }: Props): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [shouldScroll, setShouldScroll] = useState<boolean>(false)
  const {
    fetchParentMessage: { success }
  } = useSelector(getThreadState)

  const { thread } = useInfoByMessageId()
  const content = useContent(chat)

  const scrollToParent = () => {
    const parentIsFetched = thread?.chats.find((chat) => chat.chat_id === chatId)
    if (parentIsFetched) {
      const parentElement = document.getElementById(chatId)

      parentElement?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    } else {
      dispatch({ type: FETCH_THREADS_TILL_PARENT_REQUEST, payload: { chatId } })
      setShouldScroll(true)
    }
  }

  useEffect(() => {
    if (success && shouldScroll) {
      dispatch({ type: RESET_FETCH_THREADS_TILL_PARENT_STATUS })
      scrollToParent()
      setShouldScroll(false)
    }
  }, [success])

  return (
    <div data-testid="reply-to-message-container" className={classes.container} onClick={scrollToParent}>
      <div className={classes.textContent}>
        <Typography data-testid="sender" variant="subtitle2" className={classes.sender}>
          {sender}
        </Typography>
        <Typography data-testid="text" variant="body2" className={classes.text}>
          {content.text}
        </Typography>
      </div>
      {content.img && <img src={content.img} alt="property image" className={classes.img} />}
    </div>
  )
}
