// eslint-disable-next-line
// @ts-nocheck
import { createStore, applyMiddleware, Middleware, compose, Store } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'

import { persistStore, persistReducer, Persistor } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2'

import rootReducer, { RootReducerState } from './reducer'
import rootSaga from './sagas'
import { messageStateNestedBlackList } from './transforms'

type WindowWithDevTools = Window & {
  __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose
}

const isReduxDevtoolsExtenstionExist = (arg: Window | WindowWithDevTools): arg is WindowWithDevTools => {
  return '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__' in arg
}

const isDebugMode = process.env.NODE_ENV === 'development'
const isProduction = process.env.NODE_ENV === 'production'

const persistConfig = {
  key: 'root',
  storage,
  transforms: [messageStateNestedBlackList],
  stateReconciler: autoMergeLevel2,
  blacklist: ['propertyState', 'router']
}

const sagaMiddleware = createSagaMiddleware()
const loggerMiddleware = createLogger({
  collapsed: true
})

export const history = createBrowserHistory()

export default function configureStore(initialState = {}): { store: Store<RootReducerState>; persistor: Persistor } {
  const middlewares: Middleware[] = [sagaMiddleware, routerMiddleware(history)]

  if (isDebugMode) {
    middlewares.push(loggerMiddleware)
  }

  const enhancers = applyMiddleware(...middlewares)
  const composeEnhancers =
    !isProduction && isReduxDevtoolsExtenstionExist(window) ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose

  const persistedReducer = persistReducer(persistConfig, rootReducer(history))
  const store = createStore(persistedReducer, initialState, composeEnhancers(enhancers))
  const persistor = persistStore(store)

  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
