import mixpanel from 'mixpanel-browser'
import { useEffect } from 'react'
import { matchPath, useLocation } from 'react-router-dom'
import { UserType } from '../types/messages'
import { AnalyticsEvent, EventName, TrackedRoute } from './types'

const { REACT_MIXPANEL_CLIENT_ID } = process.env

const urlMapping: Record<string, string> = {
  'localhost|-develop|-dev': '79f2a13627980cdd837df86105e0cdce',
  '-staging': '02cb147f3326be5eb878a03e081a1461',
  '-production|app.perchpeek|support.perchpeek': '6e6e4d9bea1de13547ab74c51c3d92d3'
}

const [, mixPanelClientId = REACT_MIXPANEL_CLIENT_ID] =
  Object.entries(urlMapping).find(([key]) => new RegExp(key, 'g').test(window.location.hostname)) || []

mixpanel.init(mixPanelClientId as string)

type MixPanelUserProps = {
  $name: UserType['name']
  $email: UserType['email']
}

export const analyticsActions = {
  identify: (id: string): void => mixpanel.identify(id),
  alias: (id: string): void => mixpanel.alias(id),
  track: ({ name, ...rest }: AnalyticsEvent): void => mixpanel.track(name, rest),
  people: {
    set: (props: MixPanelUserProps): void => mixpanel.people.set(props)
  }
}
// Example event tracking usage:
// analyticsActions.track({ name: EventName.ButtonClick, buttonName: 'test' })

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleNativeEvent = (eventName: keyof typeof EventName, dataAttribute: string) => (e: any) => {
  // search for the tracking name one level up if the current element doesn't have it
  const trackingName = e.target.dataset[dataAttribute] ?? e.target.parentElement?.dataset[dataAttribute]

  if (trackingName) {
    analyticsActions.track({ name: eventName, trackingName })
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const handleSubmitEvent = (eventName: keyof typeof EventName) => (e: any) => {
  if (e.key === 'Enter' && !e.shiftKey) {
    handleNativeEvent(eventName, 'trackSubmit')(e)
  }
}

const useTrackPageView = (routes: TrackedRoute[]): void => {
  const { pathname } = useLocation()
  const route = routes.find(({ path }) => matchPath(pathname, { path, exact: true, strict: false })) ?? null

  useEffect(() => {
    if (route) {
      analyticsActions.track({ name: EventName.PageView, pageId: route.id, pagePath: route.path as string })
    }
  }, [route])
}

export const useTrack = (selector?: string | null): void => {
  const target = (selector ? document.querySelector(selector) : window) ?? window
  const clickHandler = handleNativeEvent(EventName.Click, 'trackClick')
  const filterHandler = handleNativeEvent(EventName.Filter, 'trackFilter')
  const submitHandler = handleSubmitEvent(EventName.Submit)

  useEffect(() => {
    target.addEventListener('click', clickHandler)
    target.addEventListener('keyup', filterHandler)
    target.addEventListener('keydown', submitHandler)

    return () => {
      target.removeEventListener('click', clickHandler)
      target.removeEventListener('keyup', filterHandler)
      target.removeEventListener('keydown', submitHandler)
    }
  }, [target, clickHandler, filterHandler, submitHandler])
}

export const useAnalyticsIdentify = (user?: UserType): void => {
  if (user) {
    analyticsActions.identify(user.user_id)
    analyticsActions.people.set({
      $name: user.name,
      $email: user.email
    })
  }
}

export const useConfigureAnalytics = (routes: TrackedRoute[], user?: UserType): void => {
  useAnalyticsIdentify(user)
  useTrackPageView(routes)
  useTrack()
}

export const useAnalytics = (): typeof analyticsActions => ({
  ...analyticsActions
})
