import { combineReducers, AnyAction, Reducer, CombinedState } from 'redux'
import { connectRouter } from 'connected-react-router'
import type { History } from 'history'

import authReducer from './reducer/auth'
import messageReducer from './reducer/message'
import threadReducer from './reducer/thread'
import noteReducer from './reducer/note'
import bookingReducer from './reducer/booking'
import propertyReducer from './reducer/property'
import documentReducer from './reducer/document'
import usersReducer from './reducer/users'
import feedbackReducer from './reducer/feedback'
import packagesReducer from './reducer/package'

import { LOGOUT_USER_REQUEST } from './constants'

import type { RootState } from '../types/state'

export type RootReducerState = CombinedState<RootState>

const createAppReducer = (history: History<unknown>): Reducer<RootReducerState> =>
  combineReducers({
    authState: authReducer,
    messageState: messageReducer,
    threadState: threadReducer,
    noteState: noteReducer,
    bookingState: bookingReducer,
    propertyState: propertyReducer,
    documentState: documentReducer,
    usersState: usersReducer,
    feedback: feedbackReducer,
    router: connectRouter(history),
    packageState: packagesReducer
  })

const rootReducer =
  (history: History<unknown>) =>
  (state: RootState | undefined, action: AnyAction): RootReducerState => {
    if (action.type === LOGOUT_USER_REQUEST) {
      state = undefined
    }

    const appReducer = createAppReducer(history)

    return appReducer(state, action)
  }

export default rootReducer
