import { ReactElement } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Paper, Typography } from '@material-ui/core'

import restingPerchie from '../../assets/resting-perchie.png'

const useStyles = makeStyles(() => ({
  container: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f9f9f9'
  },
  contentContainer: {
    width: '65%',
    height: '75%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    padding: 50
  },
  perchieLogo: {
    height: 250,
    width: 250
  },
  email: {
    color: 'blue',
    textDecoration: 'underline'
  },
  reloadButton: {
    backgroundColor: '#2983E2',
    color: 'white',
    '&:hover': {
      backgroundColor: '#2983E2'
    }
  }
}))

export function Maintenance(): ReactElement {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div data-testid="maintenance-mode-container" className={classes.container}>
      <Paper className={classes.contentContainer}>
        <img src={restingPerchie} alt="logo" className={classes.perchieLogo} />
        <div>
          <Typography variant={'h6'}>The PerchPeek platforms are currently down for planned maintenance.</Typography>
          <Typography variant={'h6'} style={{ fontSize: '1.2rem' }}>
            Please check back in 30 minutes.
          </Typography>
          <Typography variant={'subtitle1'}>
            For any urgent issues, please email{' '}
            <Typography component={'span'} className={classes.email}>
              relocation@perchpeek.com.
            </Typography>
          </Typography>
        </div>
        <Button variant="contained" className={classes.reloadButton} onClick={() => history.push('/messages')}>
          Retry
        </Button>
      </Paper>
    </div>
  )
}
