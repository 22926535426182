import { ReactElement, Fragment, useEffect, useState, FormEvent } from 'react'
import {
  Button,
  Dialog,
  DialogContent,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Select,
  MenuItem,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
  Typography
} from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { getThreadState } from '../../redux/selectors'
import {
  CLEAR_USER_SERVICES_STATUS,
  GET_USER_SERVICES_REQUEST,
  SHOW_FEEDBACK,
  UPDATE_USER_SERVICES_REQUEST
} from '../../redux/constants'

import { urlPattern } from '../../utils/string'
import { serviceJSON } from '../../utils/constants'
import { UserServiceItem } from '../../types/thread'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { Transition } from '..'

type ServicesDialogProps = {
  open: boolean
  onClose: () => void
  userId?: string
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginLeft: 20,
    flex: 1
  },
  table: {
    width: '100%'
  },
  sectionName: {
    fontWeight: 'bold',
    fontSize: 16
  },
  selectItem: {
    fontSize: 14
  },
  urlCell: {
    width: '456.72px',
    [theme.breakpoints.down('lg')]: {
      width: '340.72px'
    },
    [theme.breakpoints.down('sm')]: {
      width: '240.72px'
    }
  }
}))

export function ServicesDialog({ open, onClose, userId }: ServicesDialogProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const {
    servicesStatus,
    user: {
      services: { included: includedServices }
    }
  } = useSelector(getThreadState)
  const [tempIncludedServices, setTempIncludedServices] = useState<UserServiceItem[]>([])
  const { messageId } = useInfoByMessageId()

  const [newLinks, setNewLinks] = useState<{ [x: string]: string }>({})

  useEffect(() => {
    if (servicesStatus === 'success') {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: 'Services successfully updated', severity: 'success' } })
      onClose()
    } else if (servicesStatus === 'error') {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
    }

    return () => {
      dispatch({ type: CLEAR_USER_SERVICES_STATUS })
    }
  }, [servicesStatus])

  useEffect(() => {
    dispatch({ type: GET_USER_SERVICES_REQUEST, payload: { userId } })
  }, [messageId])

  useEffect(() => {
    setTempIncludedServices(includedServices)
    getIncludedURLValue(includedServices)
  }, [includedServices])

  // ~~~~~~~~~~ Event handlers ~~~~~~~~~~

  const handleIncludedChange = (event: React.ChangeEvent<{ value: unknown }>, identifier: string) => {
    const items = tempIncludedServices.filter((service) => service.custom_name === identifier)
    if (items.length === 0 && event.target.value === 'included') {
      setTempIncludedServices([
        ...tempIncludedServices,
        {
          custom_name: identifier
        }
      ])
    }
    if (items.length > 0 && event.target.value === 'non-included') {
      const updatedIncludedServices = tempIncludedServices.filter((service) => service.custom_name !== identifier)
      setTempIncludedServices(updatedIncludedServices)
    }
  }

  // ~~~~~~~~~~ Event handlers end ~~~~~~~~~~

  // ~~~~~~~~~~ Get Values ~~~~~~~~~~

  const getIncludedValue = (services: UserServiceItem[], identifier: string) => {
    const contains = services.filter((service) => service.custom_name === identifier)
    return contains.length === 0 ? 'non-included' : 'included'
  }

  const getIncludedURLValue = (services: UserServiceItem[]) => {
    const tempExistingLinks: Record<string, string> = {}
    services.forEach((service) => {
      if (service.url) tempExistingLinks[service.custom_name] = service.url
    })
    setNewLinks({ ...tempExistingLinks })
  }

  // ~~~~~~~~~~ Get Values end ~~~~~~~~~~

  // ~~~~~~~~~~ Update Values ~~~~~~~~~~

  const updateServicesFn = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    dispatch({
      type: UPDATE_USER_SERVICES_REQUEST,
      payload: {
        userId,
        includedServices: tempIncludedServices.map(({ custom_name }) => custom_name),
        links: Object.keys(newLinks).length > 0 ? newLinks : {}
      }
    })
  }

  // ~~~~~~~~~~ Update Values end ~~~~~~~~~~

  return (
    <Dialog
      data-testid="services-dialog"
      disableEscapeKeyDown
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <form onSubmit={updateServicesFn}>
        <AppBar position="sticky">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={onClose}
              aria-label="close"
              data-testid="services-modal-close-btn"
            >
              <Close />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              Services
            </Typography>
            <Button
              color="inherit"
              type="submit"
              data-testid="services-form-submit-btn"
              data-track-click="services-update"
            >
              Save
            </Button>
          </Toolbar>
        </AppBar>
        <DialogContent style={{ padding: '16px 24px' }}>
          <Table className={classes.table} aria-label="service-table">
            <TableBody>
              {serviceJSON.map((row) => (
                <Fragment key={row.id}>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <span className={classes.sectionName}>{row.name}</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className={classes.sectionName}>Included</span>
                    </TableCell>
                    <TableCell align="center">
                      <span className={classes.sectionName}>Review Option URL</span>
                    </TableCell>
                  </TableRow>
                  {row.services.map((service) => (
                    <TableRow key={service.id}>
                      <TableCell component="th" scope="row">
                        {service.label}
                      </TableCell>
                      <TableCell align="center">
                        <Select
                          labelId="select-included-label"
                          id="select-included-id"
                          inputProps={{ 'data-testid': `select-included-${service.id}` }}
                          value={getIncludedValue(tempIncludedServices, service.id)}
                          onChange={(event) => handleIncludedChange(event, service.id)}
                          className={classes.selectItem}
                        >
                          <MenuItem value={'included'}>Included</MenuItem>
                          <MenuItem value={'non-included'}>Not included</MenuItem>
                        </Select>
                      </TableCell>
                      <TableCell align="center" className={classes.urlCell}>
                        <TextField
                          value={newLinks[service.id] || ''}
                          onChange={(event) => setNewLinks({ ...newLinks, [service.id]: event.target.value })}
                          label="Add Link"
                          variant="filled"
                          inputProps={{ pattern: urlPattern, 'data-testid': `URL-Textbox-${service.id}` }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </form>
    </Dialog>
  )
}
