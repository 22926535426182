import { makeStyles } from '@material-ui/core'
import { ReactElement, useState } from 'react'
import { Header, Loader } from '../../components'
import { useLocation } from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  screen: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      height: 'inherit'
    }
  },
  headerHeight: {
    height: 50,
    [theme.breakpoints.down('sm')]: {
      height: 0
    }
  },
  container: {
    display: 'flex',
    minHeight: 'calc(100vh - 50px)'
  }
}))
export const Manage = (): ReactElement => {
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const { search } = useLocation()
  const v2Url = new URLSearchParams(search).get('v2Url')

  return (
    <div className={classes.screen}>
      <div className={classes.headerHeight}>
        <Header />
      </div>
      <div className={classes.container}>
        {isLoading && <Loader />}
        <iframe
          title="iframe-manage"
          src={`/web-app${decodeURIComponent(v2Url ?? '/')}`}
          width="100%"
          scrolling="no"
          style={{ display: isLoading ? 'none' : 'block', border: 0, overflow: 'hidden' }}
          onLoad={() => {
            setIsLoading(false)
          }}
        />
      </div>
    </div>
  )
}
