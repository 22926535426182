import { ReactElement, useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  makeStyles,
  Checkbox,
  FormControlLabel,
  InputAdornment
} from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { useDispatch, useSelector } from 'react-redux'
import { useDebounce } from '../../hooks/useDebounce'
import { FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST, UPDATE_TEAM_MEMBERS_REQUEST } from '../../redux/constants'
import { getUsersState } from '../../redux/selectors'
import { InfoCard, Loader } from '..'
import moment from 'moment'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { UserBio } from '../../types/users'
import { MessageUser } from '../../types/messages'

type Props = {
  open: boolean
  close: () => void
  moverName: string
}

const useStyles = makeStyles(() => ({
  root: {
    height: 600,
    width: 460
  },
  dialogContent: {
    padding: '8px 0',
    display: 'flex',
    flexDirection: 'column'
  },
  searchbox: {
    margin: '0 10px',
    width: '95%'
  },
  usersList: {
    overflowY: 'auto',
    margin: '10px 0',
    flex: 1
  },
  cardContainer: {
    paddingRight: 8
  },
  includeBtn: {
    marginRight: 'unset'
  },
  checkbox: {
    '&$checked': {
      color: '#3294FD'
    }
  },
  checked: {},
  dialogActions: {
    justifyContent: 'unset',
    margin: '0 0 24px 16px'
  },
  saveBtn: {
    height: '3rem',
    width: '6rem'
  }
}))

export const AssignTeamModal = ({ open, close, moverName }: Props): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { requested, users, upsertRequested } = useSelector(getUsersState)
  const { thread } = useInfoByMessageId()

  const [searchString, setSearchString] = useState('')
  const debouncedSearchString = useDebounce(searchString, 500)
  const [assignedTeamMembers, setAssignedTeamMembers] = useState<Record<string, MessageUser>>({})

  useEffect(() => {
    if (open) {
      let assignedMembers = { ...assignedTeamMembers }
      thread?.message.group.forEach((member) => {
        if (member.user_type !== 'tenant' && member.user_email !== 'support@perchpeek.com') {
          assignedMembers = { ...assignedMembers, [member.linked_user_id]: member }
        }
      })
      setAssignedTeamMembers(assignedMembers)
      const argument = debouncedSearchString ? debouncedSearchString : ''
      fetchAssignedTeamMembers(argument)
    }
  }, [open, debouncedSearchString])

  const fetchAssignedTeamMembers = (search: string) =>
    dispatch({ type: FETCH_ASSIGNED_TEAM_MEMBERS_REQUEST, payload: { search } })

  const handleIncludedChange = (user: UserBio) => {
    if (!assignedTeamMembers[user.user_id]) {
      const memberData = {
        linked_user_id: user.user_id,
        updated_at: user.created_at,
        user_created_at: user.created_at,
        user_email: user.email,
        user_name: user.name,
        user_profile_image: user.user_profile_url,
        user_has_paid: false,
        user_priority: 'low',
        user_type: user.user_type,
        role: user.role,
        user_profile_questionnaire: null
      }
      setAssignedTeamMembers({ ...assignedTeamMembers, [user.user_id]: memberData })
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { [user.user_id]: omit, ...rest } = assignedTeamMembers
      setAssignedTeamMembers(rest)
    }
  }

  const onSave = () => {
    dispatch({ type: UPDATE_TEAM_MEMBERS_REQUEST, payload: { users: assignedTeamMembers } })
    close()
  }

  return (
    <>
      <Dialog
        data-testid="assigned-users-modal"
        classes={{ paper: classes.root }}
        open={open}
        onClose={close}
        maxWidth="md"
      >
        <DialogTitle id="Movers-name">{moverName}&apos;s Team...</DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <TextField
            inputProps={{ 'data-testid': `seach-assigned-users-searchbar` }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              )
            }}
            variant="outlined"
            margin="dense"
            label="Search"
            name="search"
            value={searchString}
            onChange={(e) => setSearchString(e.target.value)}
            className={classes.searchbox}
          />
          <div className={classes.usersList}>
            {requested && <Loader />}
            {users.map((user) => (
              <div
                key={user.user_id}
                className={classes.cardContainer}
                style={{ backgroundColor: assignedTeamMembers[user.user_id] ? 'rgba(50, 148, 253, 0.2)' : 'unset' }}
              >
                <InfoCard
                  selected={assignedTeamMembers[user.user_id] ? true : false}
                  containerTestid={user.user_id}
                  avatarUrl={user.user_profile_url}
                  title={user.name}
                  subtitle={user.email}
                  dateTime={moment.utc(user.created_at).format('Do MMM YY')}
                  chips={[{ testId: user.name, label: user.user_type }]}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        classes={{ root: classes.checkbox, checked: classes.checked }}
                        checked={assignedTeamMembers[user.user_id] ? true : false}
                        onChange={() => handleIncludedChange(user)}
                        name="checkedBtn"
                      />
                    }
                    label="Include"
                    classes={{ root: classes.includeBtn }}
                  />
                </InfoCard>
              </div>
            ))}
          </div>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
          <Button
            disabled={requested || upsertRequested || Object.keys(assignedTeamMembers).length === 0}
            onClick={onSave}
            color="secondary"
            variant="contained"
            className={classes.saveBtn}
          >
            Save
          </Button>
          <Button onClick={() => close()} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
