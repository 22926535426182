import { AnyAction } from 'redux'
import {
  FETCH_NOTES_REQUEST,
  FETCH_NOTES_SUCCESS,
  FETCH_NOTES_FAILURE,
  FETCH_MORE_NOTES_REQUEST,
  FETCH_MORE_NOTES_SUCCESS,
  CREATE_NOTE_REQUEST,
  CREATE_NOTE_SUCCESS,
  CREATE_NOTE_FAILURE,
  PIN_NOTE_REQUEST,
  PIN_NOTE_SUCCESS,
  PIN_NOTE_FAILURE,
  UNPIN_NOTE_REQUEST,
  UNPIN_NOTE_SUCCESS,
  UNPIN_NOTE_FAILURE,
  UPDATE_NOTE_REQUEST,
  UPDATE_NOTE_FAILURE,
  UPDATE_NOTE_SUCCESS,
  DELETE_NOTE_FAILURE,
  DELETE_NOTE_REQUEST,
  DELETE_NOTE_SUCCESS,
  RESET_API_ERROR
} from '../../constants'
import { NoteState } from '../../../types/state'
import { getDeleteNoteData, getUpdateNoteData } from './utils'

export const initialState: NoteState = {
  activeUserId: '',
  notes: [],
  currentPage: 0,
  lastPage: 0,
  notesUpdationRequested: false,
  fetchNotesRequested: false,
  fetchMoreNotesRequested: false,
  fetchMoreNotesFailed: false,
  fetchNotesFailed: false,
  failedNoteApi: false
}

export default (state = initialState, action: AnyAction): NoteState => {
  switch (action.type) {
    case FETCH_NOTES_REQUEST:
      return {
        ...state,
        fetchNotesRequested: true,
        fetchMoreNotesRequested: false,
        fetchMoreNotesFailed: false,
        fetchNotesFailed: false,
        failedNoteApi: false
      }

    case FETCH_NOTES_SUCCESS:
      const {
        notes: { data: notesData, meta }
      } = action.payload
      return {
        ...state,
        activeUserId: action.payload.userId,
        notes: notesData,
        currentPage: meta.current_page,
        lastPage: meta.last_page,
        fetchNotesRequested: false,
        fetchMoreNotesFailed: false,
        fetchNotesFailed: false
      }

    case FETCH_NOTES_FAILURE:
      return {
        ...state,
        fetchNotesRequested: false,
        fetchMoreNotesRequested: false,
        fetchNotesFailed: true
      }

    case FETCH_MORE_NOTES_REQUEST:
      return {
        ...state,
        fetchMoreNotesRequested: true,
        fetchMoreNotesFailed: false,
        fetchNotesFailed: false
      }

    case FETCH_MORE_NOTES_SUCCESS:
      const {
        notes: { data: moreNotesData, meta: metaData }
      } = action.payload
      return {
        ...state,
        notes: [...state.notes, ...moreNotesData],
        currentPage: metaData.current_page,
        lastPage: metaData.last_page,
        fetchNotesRequested: false,
        fetchMoreNotesRequested: false,
        fetchMoreNotesFailed: false,
        fetchNotesFailed: false
      }

    case CREATE_NOTE_REQUEST:
      return {
        ...state,
        failedNoteApi: false,
        notesUpdationRequested: true
      }

    case CREATE_NOTE_SUCCESS:
      const {
        notes: { data, meta: metadata }
      } = action.payload
      return {
        ...state,
        notes: data,
        currentPage: metadata.current_page,
        lastPage: metadata.last_page,
        notesUpdationRequested: false,
        failedNoteApi: false
      }

    case CREATE_NOTE_FAILURE:
      return {
        ...state,
        failedNoteApi: true
      }

    case UPDATE_NOTE_REQUEST:
      return {
        ...state,
        failedNoteApi: false,
        notesUpdationRequested: true
      }

    case UPDATE_NOTE_SUCCESS:
      const { noteId: updateNoteId, userId: updateUserId, newNoteText: updateNoteText } = action.payload
      return {
        ...state,
        failedNoteApi: false,
        notesUpdationRequested: false,
        notes: getUpdateNoteData(state.notes, updateNoteId, updateUserId, updateNoteText)
      }

    case UPDATE_NOTE_FAILURE:
      return {
        ...state,
        failedNoteApi: true
      }

    case DELETE_NOTE_REQUEST:
      return {
        ...state,
        notesUpdationRequested: true
      }

    case DELETE_NOTE_SUCCESS:
      const { noteId: deleteNoteId } = action.payload
      return {
        ...state,
        failedNoteApi: false,
        notesUpdationRequested: false,
        notes: getDeleteNoteData(state.notes, deleteNoteId)
      }

    case DELETE_NOTE_FAILURE:
      return {
        ...state,
        failedNoteApi: true,
        notesUpdationRequested: false
      }

    case PIN_NOTE_REQUEST:
      return {
        ...state,
        failedNoteApi: false,
        notesUpdationRequested: true
      }

    case PIN_NOTE_SUCCESS:
      const { notes: pinnedUpdatedNotesData } = action.payload
      return {
        ...state,
        failedNoteApi: false,
        notes: pinnedUpdatedNotesData.data,
        currentPage: pinnedUpdatedNotesData.current_page,
        lastPage: pinnedUpdatedNotesData.last_page,
        notesUpdationRequested: false
      }

    case PIN_NOTE_FAILURE:
      return {
        ...state,
        failedNoteApi: true,
        notesUpdationRequested: false
      }

    case UNPIN_NOTE_REQUEST:
      return {
        ...state,
        failedNoteApi: false,
        notesUpdationRequested: true
      }

    case UNPIN_NOTE_SUCCESS:
      const { notes: unpinnedUpdatedNotesData } = action.payload
      return {
        ...state,
        failedNoteApi: false,
        notes: unpinnedUpdatedNotesData.data,
        currentPage: unpinnedUpdatedNotesData.current_page,
        lastPage: unpinnedUpdatedNotesData.last_page,
        notesUpdationRequested: false
      }

    case UNPIN_NOTE_FAILURE:
      return {
        ...state,
        failedNoteApi: true,
        notesUpdationRequested: false
      }

    case RESET_API_ERROR:
      return {
        ...state,
        failedNoteApi: false
      }

    default:
      return state
  }
}
