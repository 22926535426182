import { ReactElement, useEffect } from 'react'
import { Redirect, Route, useLocation } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Provider, useDispatch, useSelector } from 'react-redux'
import launchDarklyAdapter from '@flopflip/launchdarkly-adapter'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import axios, { AxiosError } from 'axios'

import { Login, Maintenance, Messages, UserManagement, Manage, Archive } from './screens'
import { PrivateRoute, Snackbar } from './components'
import configureStore, { history } from './redux/store'
import { FETCH_USER_REQUEST, LOGOUT_USER_REQUEST } from './redux/constants'
import smartlookClient from 'smartlook-client'
import { isProduction } from './utils/env'
import { FeatureFlagWrapper, defaultFlags, FeatureFlag, useLaunchDarklyStatus } from './hooks/featureFlags'
import { SetupAnalytics, TrackedRoute } from './analytics'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { getAuthState } from './redux/selectors'

export const { store, persistor } = configureStore()

const routes = [
  {
    id: 'root',
    path: '/',
    exact: true,
    render: function RedirectToLogin() {
      return <Redirect to={{ pathname: '/login' }} />
    }
  },
  { id: 'login', path: '/login', exact: true, component: Login },
  {
    id: 'archive',
    path: '/archive/:messageId?',
    exact: true,
    component: Archive,
    isPrivate: true
  },
  { id: 'manage', path: '/manage/:userId?', exact: true, component: Manage, isPrivate: true },
  { id: 'messages', path: '/messages/:messageId?', exact: true, component: Messages, isPrivate: true },
  {
    id: 'messagesInfo',
    path: '/messages/:messageId/:modal',
    exact: true,
    component: Messages,
    isPrivate: true
  },
  { id: 'users', path: '/users/:userId?', exact: true, component: UserManagement, isPrivate: true },
  { id: 'maintenance', path: '/maintenance', exact: true, component: Maintenance, isPrivate: true }
] as TrackedRoute[]

export const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#304054'
    }
  },
  props: {
    MuiModal: {
      // makes the modal/drawer less accessible to assistive technologies, like screen readers, but needed for the zendesk widget
      disableEnforceFocus: true
    }
  }
})

const RoutesWithTracking = () => {
  const isArchive = useLocation().pathname.includes('/archive')
  const shouldDisableV1 = useFeatureToggle(FeatureFlag.DISABLE_OPS_V1)
  const { isConfigured } = useLaunchDarklyStatus()
  const dispatch = useDispatch()
  const { currentUser } = useSelector(getAuthState)
  const token = localStorage.getItem('authToken')
  const loadAllRoutes = !!token && !!currentUser

  useEffect(() => {
    if (!loadAllRoutes) {
      // fetch user data so authState's `currentUser` get's populated
      dispatch({ type: FETCH_USER_REQUEST, payload: { token } })
    }
  }, [loadAllRoutes])

  return (
    <>
      <SetupAnalytics routes={routes} />
      {isConfigured &&
        routes.map((route) => {
          if (!loadAllRoutes && ['login', 'archive'].includes(route.id)) {
            return route.isPrivate ? <PrivateRoute key={route.id} {...route} /> : <Route key={route.id} {...route} />
          }

          if (route.id === 'messages' && !isArchive && shouldDisableV1) {
            return <Redirect to={{ pathname: '/manage' }} />
          }

          return route.isPrivate ? <PrivateRoute key={route.id} {...route} /> : <Route key={route.id} {...route} />
        })}
    </>
  )
}

function App(): ReactElement {
  useEffect(() => {
    if (isProduction) smartlookClient.init('74574c42c1775d119589c4041561ad3e1289cb87')
  }, [])

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx or a 401 cause this function to trigger
      if (response.status === 401 && response.data.message === 'Invalid Token') {
        // for now we don't have a way to refresh the token, the fast solution is to log out the user
        store.dispatch({ type: LOGOUT_USER_REQUEST })
      }

      return response
    },
    function (error) {
      const { code, config } = error as AxiosError
      const retries = config.headers['x-available-retries']

      // Any canceled request with available retries is retried
      if (['ECONNABORTED', 'ETIMEDOUT'].includes(code || '') && retries > 0) {
        config.headers['x-available-retries']--

        return axios.request(config)
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      if (error.response.status === 503 && window.location.pathname !== '/maintenance') {
        history.push('/maintenance')
      }

      return error
    }
  )

  if (window.Cypress) {
    window.store = store
  }

  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <FeatureFlagWrapper adapter={launchDarklyAdapter} defaultFlags={defaultFlags}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <RoutesWithTracking />
              </ConnectedRouter>
              <Snackbar />
            </PersistGate>
          </FeatureFlagWrapper>
        </Provider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  )
}

export default App
