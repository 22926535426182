import { RouteProps } from 'react-router-dom'

export enum EventName {
  Click = 'Click',
  PageView = 'PageView',
  LoginEvent = 'LoginEvent',
  Filter = 'Filter',
  Submit = 'Submit',
  TimedEvent = 'TimedEvent'
}

export type AnalyticsEvent =
  | { name: keyof typeof EventName; trackingName: string }
  | { name: EventName.Submit; trackingName: string; realEstatePortal: string }
  | { name: keyof typeof EventName; pageId: string; pagePath: string }
  | { name: EventName.TimedEvent; eventId: string; elapsedTime: number }
  | { name: keyof typeof EventName }

export type TrackedRoute = { id: string; isPrivate: boolean } & RouteProps
