import { MouseEventHandler, ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress
} from '@material-ui/core'

import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { PropertyUploadType } from '../../types/properties'
import { SHOW_FEEDBACK, UPDATE_PROPERTY_REQUEST } from '../../redux/constants'
import { getPropertyState } from '../../redux/selectors'
import { PropertyForm, PropertyFormProps } from '../PropertyForm'

const useStyles = makeStyles((theme) => ({
  nav: {
    marginTop: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr min-content'
  },
  text: {
    marginTop: theme.spacing(3)
  },
  content: {
    display: 'flex',
    flexDirection: 'column'
  },
  actions: {
    justifyContent: 'unset',
    padding: theme.spacing(3)
  }
}))

type State = {
  activeProperty: PropertyUploadType
  loading: boolean
  hasErrors: boolean
}

export type PropertyEditProps = {
  open: boolean
  property: PropertyUploadType
  onClose: (updatedProperty?: PropertyUploadType) => void
}

export function PropertyEdit({ open, property, onClose }: PropertyEditProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { status } = useSelector(getPropertyState)
  const { customer } = useInfoByMessageId()
  const [{ activeProperty, loading, hasErrors }, setState] = useState<State>({
    activeProperty: property,
    loading: false,
    hasErrors: false
  })

  const disableCTA = hasErrors || loading

  const handleOnChange: PropertyFormProps['onChange'] = (activeProperty, hasErrors) => {
    setState((previousState) => ({ ...previousState, activeProperty, hasErrors }))
  }

  const handleOnSubmit: MouseEventHandler<HTMLButtonElement> = () => {
    if (!hasErrors) {
      setState((previousState) => ({ ...previousState, loading: true }))
      dispatch({
        type: UPDATE_PROPERTY_REQUEST,
        payload: { customerId: customer?.linked_user_id, property: activeProperty }
      })
    }
  }

  useEffect(() => {
    if (status && loading) {
      let severity = 'success'
      if (status === 'Property Updated') {
        onClose(activeProperty)
      } else {
        severity = 'error'
      }
      dispatch({ type: SHOW_FEEDBACK, payload: { title: status, severity } })
      setState((previousState) => ({ ...previousState, loading: false }))
    }
  }, [status, loading])

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle disableTypography>
        <Typography variant="h5" id="dialog-title">
          Edit property
        </Typography>
      </DialogTitle>
      <DialogContent key={`id-${activeProperty.id}`} classes={{ root: classes.content }}>
        <PropertyForm
          property={property}
          country={customer?.user_destination_country}
          disabled={loading}
          onChange={handleOnChange}
          editMode
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button
          onClick={handleOnSubmit}
          size="large"
          variant="contained"
          disabled={disableCTA}
          color="primary"
          data-track-click="edit-property"
          {...(loading && {
            endIcon: <CircularProgress role="alert" aria-live="assertive" aria-label="Updating property" size={15} />
          })}
        >
          Update
        </Button>
        <Button onClick={() => onClose()} size="large" color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}
