import { PropertyDetails, PropertyUploadType } from '../../../types/properties'
import { PropertyState } from '../../../types/state'
import { Chat, PropertyChat } from '../../../types/messages'

export const addOrUpdateProperties = (
  existingProperties: PropertyDetails[],
  properties: PropertyDetails[]
): PropertyDetails[] => {
  const updatedProperties = [...existingProperties]

  properties.forEach((property) => {
    const index = existingProperties.findIndex(({ property_id }) => property_id === property.property_id)
    if (index === -1) {
      updatedProperties.push(property)
    } else {
      updatedProperties[index] = {
        ...property
      }
    }
  })

  return updatedProperties
}

export const unlistPropertyInState = (state: PropertyState, property_id: string): PropertyDetails[] => {
  return state.properties.map((property) => {
    if (property.property_id === property_id) {
      return {
        ...property,
        status: 'unlisted'
      }
    }
    return property
  })
}

export const getPropertiesFromChats = (chats: Chat[]): PropertyDetails[] => {
  return chats
    .filter((chat: Chat): chat is PropertyChat => 'property' in chat && Boolean(chat.property))
    .map((chat: PropertyChat) => chat.property)
}

const normalizeRoom = (room: number | string) => {
  if (Number(room) === 0) return 'Studio'
  if (!room) return '1'
  if (Number(room) >= 5) return '5+'

  return room.toString()
}

export const castPropertyDetailsToPropertyUpload = (property: PropertyDetails): PropertyUploadType => ({
  id: property.property_id,
  property_url: property.third_party_property_id,
  title: property.title,
  description: property.description || undefined,
  bedrooms: normalizeRoom(property.bedrooms),
  bathrooms: normalizeRoom(property.bathrooms),
  currency_code: property.currency_code || 'EUR',
  total_cost: property.monthly_rent / 100,
  images: property.images.map(({ url, title }) => ({ id: url, name: title || url, src: url })),
  location_data: {
    address_line_1: property.address_line_1,
    address_line_2: property.address_line_2,
    address_line_3: property.address_line_3,
    postcode: property.postcode,
    city: property.city,
    country: property.country,
    latitude: property.latitude,
    longitude: property.longitude
  },
  is_off_listed: property.is_off_listed ?? false
})
