import { ReactElement, useState, useEffect, FormEvent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { getPropertyState } from '../../redux/selectors'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { PARTIAL_CLEAR_PROPERTY_STATE, RECOMMEND_PROPERTY_REQUEST, SHOW_FEEDBACK } from '../../redux/constants'

type RecommendPropertyModalProps = {
  open: boolean
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
    cursor: 'pointer'
  },
  actions: {
    padding: theme.spacing(3)
  },
  unavailable: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    height: '70%'
  }
}))

export function RecommendPropertyModal({ open, onClose }: RecommendPropertyModalProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const { recommendPropertyRequested, status: propertyStateStatus } = useSelector(getPropertyState)

  const [recommendPropertyId, setRecommendPropertyId] = useState<string>('')

  const recommendProperty = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    dispatch({
      type: RECOMMEND_PROPERTY_REQUEST,
      payload: { customerId: customer?.linked_user_id, propertyId: recommendPropertyId }
    })
    setRecommendPropertyId('')
  }

  const handleClose = () => {
    onClose()
    dispatch({ type: PARTIAL_CLEAR_PROPERTY_STATE })
  }

  useEffect(() => {
    const errorMsg =
      propertyStateStatus &&
      (!propertyStateStatus.includes('Property Fetch') ||
        !propertyStateStatus.includes('Property Recommend') ||
        !propertyStateStatus.includes('Property Unlist'))

    if (propertyStateStatus === 'Property Recommended') {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: propertyStateStatus, severity: 'success' } })
    } else if (propertyStateStatus && errorMsg) {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: propertyStateStatus, severity: 'error' } })
    }
  }, [propertyStateStatus])

  return (
    <Dialog fullWidth={true} maxWidth="xs" open={open} aria-labelledby="dialog-title">
      <DialogTitle disableTypography>
        <Typography variant="h5" id="dialog-title">
          Property ID or Immoscout Link
        </Typography>
        <CloseIcon data-testid="close-icon" aria-label="close" className={classes.closeButton} onClick={handleClose} />
      </DialogTitle>
      <form data-testid="recommend-form" onSubmit={recommendProperty}>
        <DialogContent>
          {customer ? (
            <TextField
              id="recommend"
              label="Recommend Property"
              variant="outlined"
              value={recommendPropertyId}
              fullWidth
              onChange={(event) => setRecommendPropertyId(event.target.value)}
            />
          ) : (
            <div data-testid="recommend-property-NA" className={classes.unavailable}>
              <Typography variant="h6" style={{ fontWeight: 700 }}>
                This option is not available for this type of customer
              </Typography>
            </div>
          )}
        </DialogContent>
        {customer && (
          <DialogActions className={classes.actions}>
            <Button
              data-track-click="add-property-id"
              data-testid="recommend-button"
              disabled={recommendPropertyRequested || recommendPropertyId.length < 6}
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
            >
              Recommend
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}
