import { FocusEventHandler, ReactElement, useEffect, useRef } from 'react'
import { TextField, TextFieldProps } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Wrapper } from '@googlemaps/react-wrapper'

import { AddressDetails } from '../../types/properties'

const useStyles = makeStyles((theme) => ({
  textInput: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  '@global': {
    '.pac-container': {
      zIndex: '2000'
    },
    '.pac-icon': {
      display: 'none'
    },
    '.pac-item': {
      minHeight: 48,
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      cursor: 'pointer',
      paddingTop: 6,
      boxSizing: 'border-box',
      outline: '0',
      WebkitTapHighlightColor: 'transparent',
      paddingBottom: 6,
      paddingLeft: 16,
      paddingRight: 16,
      [theme.breakpoints.up('sm')]: {
        minHeight: 'auto'
      },
      '&:hover': {
        backgroundColor: theme.palette.action.hover
      },
      '&-selected': {
        backgroundColor: theme.palette.action.selected
      }
    }
  }
}))

const variableComponents = ['city', 'postcode', 'country']

const typesMapping = [
  [variableComponents[0], ['locality', 'postal_town', 'administrative_area_level', 'colloquial_area']],
  [variableComponents[1], ['postal_code', 'postal_town', 'sublocality', 'administrative_area_level']],
  [variableComponents[2], ['country']]
] as [keyof AddressDetails, string[]][]

const getAddressDetailsFromPlace = ({
  geometry,
  formatted_address,
  address_components
}: google.maps.places.PlaceResult = {}): AddressDetails => {
  const address = {
    latitude: geometry?.location?.lat(),
    longitude: geometry?.location?.lng(),
    address_line_1: formatted_address
  } as AddressDetails

  typesMapping.forEach(([key, allowedTypes]) => {
    const component =
      // look for an exact match
      address_components?.find(({ types }) => types.some((type) => allowedTypes.includes(type))) ||
      // look for a partial match
      address_components?.find((component) =>
        component.types.some((type) => allowedTypes.some((t) => type.includes(t)))
      )

    if (component) {
      ;(address[key] as string) = component.long_name
    }
  })

  return address
}

type AddressProps = {
  country?: string
  textFieldProps?: Partial<TextFieldProps>
  onChange: (address?: AddressDetails) => void
}

const PlacesAutocomplete = ({ country, textFieldProps, onChange }: AddressProps): ReactElement => {
  const classes = useStyles()
  const inputRef = useRef<null | HTMLInputElement>(null)
  const service = useRef<null | google.maps.places.Autocomplete>(null)
  const handleOnBlur: FocusEventHandler<HTMLInputElement> = (event) => {
    if ((event.currentTarget.value ?? '').length === 0) {
      onChange(undefined)
    }
  }
  const handleOnChange = () => {
    const place = service.current?.getPlace()

    if (place) {
      const address = getAddressDetailsFromPlace(place)

      const hasVariableDetails = variableComponents.every((component) => Object.keys(address).includes(component))

      if (hasVariableDetails) {
        onChange(address)
      } else {
        onChange(undefined)
      }
    }
  }

  useEffect(() => {
    if (!service.current && inputRef.current) {
      service.current = new google.maps.places.Autocomplete(inputRef.current, {
        ...(country && { componentRestrictions: { country } }),
        types: ['geocode'],
        fields: ['address_components', 'formatted_address', 'geometry.location']
      })

      service.current.addListener('place_changed', handleOnChange)
    }

    return () => {
      // Removing the list container from the DOM
      // causes the Autocomplete to not work when re-opening the modal
      // document.querySelector('.pac-container')?.remove()
    }
  }, [country, inputRef.current])

  return (
    <TextField
      {...textFieldProps}
      inputRef={inputRef}
      onBlur={handleOnBlur}
      inputProps={{ className: classes.textInput, placeholder: '', role: 'searchbox', ...textFieldProps?.inputProps }}
    />
  )
}

export const Address = (props: AddressProps): ReactElement => {
  const apiKey = process.env.REACT_APP_MAPS_API_KEY || ''

  return (
    <Wrapper apiKey={apiKey} libraries={['places']} language="en">
      <PlacesAutocomplete {...props} />
    </Wrapper>
  )
}
