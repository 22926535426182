import _ from 'lodash'
import moment from 'moment'
import {
  FETCH_MESSAGES_SUCCESS,
  FETCH_MORE_MESSAGES_REQUEST,
  FETCH_MORE_MESSAGES_SUCCESS,
  FETCH_MORE_MESSAGES_FAILURE,
  FETCH_LAST_MESSAGE_SUCCESS,
  SEARCH_MESSAGES_SUCCESS,
  CLEAR_SEARCH_RESULT_REQUEST,
  UPDATE_LAST_CHAT_REQUEST,
  CLICK_BADGE_ICON_REQUEST,
  FETCH_MESSAGES_FAILURE,
  TOGGLE_MESSAGE_SIDEBAR_REQUEST,
  SEARCH_MESSAGES_REQUEST,
  SEARCH_MESSAGES_FAILURE,
  MARK_USER_PAID_SUCCESS,
  SET_USER_PRIORITY_SUCCESS,
  UPDATE_LAST_CHAT_IN_FILTERS_REQUEST,
  FETCH_MESSAGE_BY_ID_SUCCESS,
  TOGGLE_FAVORITE_MESSAGE_STATUS_SUCCESS,
  FETCH_FAVORITED_MESSAGES_SUCCESS,
  TOGGLE_BETWEEN_ALL_AND_FAVORITED_MESSAGES,
  TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_SUCCESS,
  FETCH_FAVORITED_MESSAGES_REQUEST,
  FETCH_MESSAGES_REQUEST,
  FETCH_FAVORITED_MESSAGES_FAILURE,
  TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE,
  TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST,
  TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST,
  UPDATE_TEAM_MEMBERS_SUCCESS
} from '../../constants'
import { MessageState } from '../../../types/state'
import {
  getFinalMessages,
  getHasPaidUserMessages,
  getHasPriorityUserMessages,
  getSortedUpdatedMessages,
  toggleFavoriteMessage,
  updateUsersInGroup
} from './utils'
import { MessageType } from '../../../types/messages'
import { AnyAction } from 'redux'

const initialState: MessageState = {
  messages: [],
  isFetchingMessages: false,
  currentPage: 0,
  lastPage: 0,
  fetchMoreMessagesRequested: false,
  searchMessages: undefined,
  searchMessageRequest: false,
  searchMessagesCurrentPage: 0,
  searchMessagesLastPage: 0,
  showFavoritedMessages: false,
  toggleFavoriteInProgress: false,
  mobileSidebarOpen: false
}

// TODO: sort out return type
// eslint-disable-next-line
export default (state = initialState, action: AnyAction): any => {
  switch (action.type) {
    case FETCH_MESSAGES_REQUEST:
    case FETCH_FAVORITED_MESSAGES_REQUEST:
      return {
        ...state,
        messages: [],
        isFetchingMessages: true
      }
    case FETCH_MESSAGES_SUCCESS:
      const {
        data: {
          messages: {
            data: firstMessages,
            meta: { current_page: currentPage, last_page: lastPage }
          }
        }
      } = action.payload
      return {
        ...state,
        messages: _.uniqBy(firstMessages, 'message_id'),
        currentPage: currentPage,
        lastPage: lastPage,
        isFetchingMessages: false,
        showFavoritedMessages: false
      }

    case FETCH_FAVORITED_MESSAGES_FAILURE:
    case FETCH_MESSAGES_FAILURE:
      return {
        ...state,
        isFetchingMessages: false
      }

    case UPDATE_LAST_CHAT_REQUEST:
      const { newChat, messageId, userPresentInThread } = action.payload
      return {
        ...state,
        messages: getSortedUpdatedMessages(state.messages, messageId, newChat, userPresentInThread)
      }

    case UPDATE_LAST_CHAT_IN_FILTERS_REQUEST:
      const { newChat: NewChat, messageId: messageID, userPresentInThread: userPresent } = action.payload
      return {
        ...state,
        searchMessages: getSortedUpdatedMessages(state.searchMessages, messageID, NewChat, userPresent)
      }

    case FETCH_MORE_MESSAGES_REQUEST:
      return {
        ...state,
        fetchMoreMessagesRequested: true
      }

    case FETCH_MORE_MESSAGES_SUCCESS:
      const {
        data: {
          messages: {
            data: newMessages,
            meta: { current_page: newCurrentPage, last_page: newLastPage }
          }
        }
      } = action.payload
      return {
        ...state,
        // We will need to check the duplicated message_id when the next page messsages is loaded
        messages: _.uniqBy([...state.messages, ...newMessages], 'message_id'),
        currentPage: newCurrentPage,
        lastPage: newLastPage,
        fetchMoreMessagesRequested: false
      }

    case FETCH_MORE_MESSAGES_FAILURE:
      return {
        ...state,
        fetchMoreMessagesRequested: false
      }

    case FETCH_LAST_MESSAGE_SUCCESS:
      const {
        data: { messages: lastMessages }
      } = action.payload
      const sortedMessages = _.uniqBy([...state.messages, lastMessages], 'message_id').sort(
        (a: MessageType, b: MessageType) =>
          moment(a.last_chat_created_at || a.created_at).isBefore(b.last_chat_created_at || b.created_at) ? 1 : -1
      )
      return {
        ...state,
        messages: sortedMessages
      }

    case FETCH_MESSAGE_BY_ID_SUCCESS:
      const { data: message } = action.payload
      const sorted = _.uniqBy([...state.messages, message], 'message_id').sort((a: MessageType, b: MessageType) =>
        moment(a.last_chat_created_at || a.created_at).isBefore(b.last_chat_created_at || b.created_at) ? 1 : -1
      )
      return {
        ...state,
        messages: sorted
      }

    case SEARCH_MESSAGES_REQUEST:
      return {
        ...state,
        searchMessageRequest: true,
        searchMessagesCurrentPage: 0,
        searchMessagesLastPage: 0,
        isFetchingMessages: true
      }

    case SEARCH_MESSAGES_SUCCESS:
      const { messages, meta, loadMore } = action.payload
      const searchMessages = state.searchMessages && loadMore ? [...state.searchMessages, ...messages] : [...messages]
      return {
        ...state,
        searchMessages,
        searchMessagesCurrentPage: meta.current_page,
        searchMessagesLastPage: meta.last_page,
        searchMessageRequest: false,
        isFetchingMessages: false
      }

    case SEARCH_MESSAGES_FAILURE:
      return {
        ...state,
        searchMessageRequest: false,
        searchMessages: undefined,
        isFetchingMessages: false
      }

    case UPDATE_TEAM_MEMBERS_SUCCESS:
      const { messageId: message_ID, users } = action.payload
      return {
        ...state,
        messages: updateUsersInGroup(state.messages, message_ID, users)
      }

    case CLEAR_SEARCH_RESULT_REQUEST:
      return {
        ...state,
        messages: [],
        searchMessages: undefined,
        searchMessageRequest: false,
        searchMessagesCurrentPage: 0,
        searchMessagesLastPage: 0,
        currentPage: 0,
        lastPage: 0
      }

    case CLICK_BADGE_ICON_REQUEST:
      const { userId, messageId: msgId } = action.payload
      return {
        ...state,
        messages: getFinalMessages(state, msgId, userId)
      }

    case TOGGLE_BETWEEN_ALL_AND_FAVORITED_MESSAGES:
      return {
        ...state,
        showFavoritedMessages: !state.showFavoritedMessages
      }

    case FETCH_FAVORITED_MESSAGES_SUCCESS:
      const { messages: favoritedMessagesData } = action.payload
      return {
        ...state,
        messages: favoritedMessagesData.data,
        currentPage: favoritedMessagesData.meta.current_page,
        lastPage: favoritedMessagesData.meta.last_page,
        isFetchingMessages: false
      }

    case TOGGLE_FAVORITE_MESSAGE_STATUS_REQUEST:
    case TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_REQUEST:
      return {
        ...state,
        toggleFavoriteInProgress: true
      }
    case TOGGLE_FAVORITE_MESSAGE_STATUS_FAILURE:
      return {
        ...state,
        toggleFavoriteInProgress: false
      }

    case TOGGLE_MESSAGE_SIDEBAR_REQUEST:
      return {
        ...state,
        mobileSidebarOpen: !state.mobileSidebarOpen
      }

    case TOGGLE_FAVORITE_MESSAGE_STATUS_SUCCESS:
      const { message_id } = action.payload
      return {
        ...state,
        messages: toggleFavoriteMessage(state.messages, message_id, state.showFavoritedMessages),
        toggleFavoriteInProgress: false
      }

    case TOGGLE_FAVORITE_SEARCH_MESSAGE_STATUS_SUCCESS:
      const { message_id: searchMessageId } = action.payload
      return {
        ...state,
        // this action is fired only when there are some searchMessages, so the [] is just to avoid ts error
        searchMessages: toggleFavoriteMessage(state.searchMessages || [], searchMessageId, state.showFavoritedMessages),
        toggleFavoriteInProgress: false
      }

    case MARK_USER_PAID_SUCCESS:
      const { userId: paidUserId, messageId: paidMessageId } = action.payload
      return {
        ...state,
        messages: getHasPaidUserMessages(state, paidMessageId, paidUserId)
      }

    case SET_USER_PRIORITY_SUCCESS:
      const { userId: selectedUserId, messageId: selectedMessageId, priority } = action.payload
      return {
        ...state,
        messages: getHasPriorityUserMessages(state, selectedUserId, selectedMessageId, priority)
      }

    default:
      return state
  }
}
