import { useEffect, useMemo } from 'react'
import launchDarklyAdapter from '@flopflip/launchdarkly-adapter'
import { useAdapterStatus } from '@flopflip/react-broadcast'
import { TLaunchDarklyAdapterArgs } from '@flopflip/types'
import { allFeatureFlags } from './types'
import { getAuthState } from '../..//redux/selectors'
import { useSelector } from 'react-redux'

const { REACT_APP_LAUNCH_DARKLY_CLIENT_ID } = process.env

interface UseLaunchDarkly {
  adapterArgs: TLaunchDarklyAdapterArgs
}

const ANONYMOUS_USER_ID = 'anonymous'

const urlMapping: Record<string, string> = {
  'localhost|-develop|-dev': '62e8d926797a6811964ffbef',
  '-staging': '62e8d90b04b7a511437c3a50',
  '-production|app.perchpeek|support.perchpeek': '62e8d90b04b7a511437c3a51'
}

const [, launchDarklyClientId = REACT_APP_LAUNCH_DARKLY_CLIENT_ID] =
  Object.entries(urlMapping).find(([key]) => new RegExp(key, 'g').test(window.location.hostname)) || []

export const useLaunchDarkly = (): UseLaunchDarkly => {
  const { currentUser } = useSelector(getAuthState)
  const { isConfigured } = useAdapterStatus()

  const { user_id: key, email, name } = currentUser || { user_id: ANONYMOUS_USER_ID }

  useEffect(() => {
    if (isConfigured) {
      launchDarklyAdapter.updateUserContext({ key })
    }
  }, [isConfigured, key])

  const flags = useMemo(
    () =>
      allFeatureFlags.reduce((acc: { [key: string]: boolean }, flagName) => {
        acc[flagName] = true
        return acc
      }, {}),
    []
  )

  const launchDarklyAdapterArgs: TLaunchDarklyAdapterArgs = {
    sdk: {
      clientSideId: launchDarklyClientId as string,
      clientOptions: {
        fetchGoals: false
      }
    },
    flags,
    user: {
      key,
      email,
      name
    },
    throwOnInitializationFailure: false
  }

  return {
    adapterArgs: launchDarklyAdapterArgs
  }
}

interface LaunchDarklyAdaptorStatus {
  isConfigured: boolean
  isReady: boolean
}

export const useLaunchDarklyStatus = (): LaunchDarklyAdaptorStatus => {
  const { isConfigured, isReady } = useAdapterStatus()

  return { isConfigured, isReady }
}
