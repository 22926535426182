import React, { ReactElement, Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles, Typography, Container, Divider, Avatar, Select, MenuItem, InputLabel } from '@material-ui/core'
import { Email, DateRange, House, People, Explore, Redeem } from '@material-ui/icons'

import { getPackagesState, getThreadState } from '../../../redux/selectors'
import { FETCH_PACKAGES_REQUEST, UPDATE_PACKAGE_REQUEST } from '../../../redux/constants'
import { Loader } from '../../Loader'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../../hooks/featureFlags'

type InfoData = { title: string; value?: string | null; icon: JSX.Element }[]

const useStyles = makeStyles((theme) => ({
  sectionTitle: {
    textAlign: 'center',
    margin: '1em 0',
    fontWeight: 700
  },
  list: {
    marginTop: 20,
    display: 'flex',
    rowGap: 20,
    flexWrap: 'wrap',
    alignItems: 'center',
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.black,

    '& dt': {
      flexBasis: '30%',
      display: 'flex',
      gap: 20,
      alignItems: 'center',
      fontSize: 18,
      fontWeight: 600
    },

    '& dd': {
      flexBasis: '70%',
      margin: 0
    }
  },
  itemContainer: {
    marginTop: 24
  },
  displayFlex: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    backgroundColor: 'grey'
  },
  descriptionList: {
    marginLeft: '1.5em'
  },
  definitionTerm: {
    fontWeight: 600,
    fontSize: 18
  },
  packagePickerContainer: {
    marginTop: 20,
    display: 'flex',
    rowGap: 20,
    flexWrap: 'wrap',
    alignItems: 'center'
  },
  packagePickerTitle: {
    flexBasis: '30%',
    display: 'flex',
    gap: 20,
    alignItems: 'center',
    '& label': {
      fontSize: 18,
      fontWeight: 600,
      color: theme.palette.common.black
    }
  },
  packagePickerSelect: {
    minWidth: 150,
    fontSize: 14,
    marginRight: 20
  }
}))

export function UserInfoDetails(): ReactElement {
  const classes = useStyles()
  const {
    user: { info }
  } = useSelector(getThreadState)

  const {
    packages,
    requested: isLoadingPackages,
    failure: hasPackageFetchError,
    updateFailure: hasPackageUpdateError
  } = useSelector(getPackagesState)

  const isPackageSelectEnabled = useFeatureToggle(FeatureFlag.USER_PACKAGE_SELECTION)

  const dispatch = useDispatch()

  const { user_email, user_profile_questionnaire, linked_user_id } = info

  useEffect(() => {
    if (isPackageSelectEnabled) {
      dispatch({ type: FETCH_PACKAGES_REQUEST, payload: { userId: linked_user_id } })
    }
  }, [])

  const handlePackageChange = (e: React.ChangeEvent<{ value: string | unknown }>) => {
    const packageType = packages?.packages.find((userPackage) => userPackage.package_id === e.target.value)
    dispatch({ type: UPDATE_PACKAGE_REQUEST, payload: { packageId: packageType?.package_id, userId: linked_user_id } })
  }

  const data: InfoData = [
    {
      title: 'Email address:',
      value: user_email,
      icon: <Email />
    },
    {
      title: 'Move-in date:',
      value: user_profile_questionnaire?.moveDate,
      icon: <DateRange />
    },
    {
      title: 'Who are you moving with?',
      value: user_profile_questionnaire?.moving_with,
      icon: <People />
    },
    {
      title: 'Origin:',
      value:
        user_profile_questionnaire?.originCity &&
        user_profile_questionnaire?.originCountry &&
        `${user_profile_questionnaire?.originCity} ${user_profile_questionnaire?.originCountry}`,
      icon: <House />
    },
    {
      title: 'Destination:',
      value: info?.user_profile_questionnaire?.city,
      icon: <Explore />
    }
  ]

  return (
    <>
      <Container data-testid="user-content-container">
        <Typography variant="h5" className={classes.sectionTitle}>
          User Info
        </Typography>
        <Divider />

        {isLoadingPackages ? (
          <Loader />
        ) : (
          <>
            <dl className={classes.list}>
              {data.map(({ title, value, icon }) => {
                const describeTerm = value ?? 'Data Not Available :('
                return (
                  <Fragment key={title}>
                    <dt>
                      <Avatar variant="rounded" className={classes.avatar}>
                        {icon}
                      </Avatar>
                      {title}
                    </dt>
                    <dd aria-label={describeTerm}>{describeTerm}</dd>
                  </Fragment>
                )
              })}
            </dl>
            {isPackageSelectEnabled && (
              <div className={classes.packagePickerContainer}>
                <div className={classes.packagePickerTitle}>
                  <Avatar variant="rounded" className={classes.avatar}>
                    <Redeem />
                  </Avatar>
                  <InputLabel id="select-package-label">Package</InputLabel>
                </div>
                <Select
                  labelId="select-package-label"
                  id="select-package-id"
                  className={classes.packagePickerSelect}
                  value={packages?.package_id}
                  onChange={handlePackageChange}
                  error={hasPackageUpdateError || hasPackageFetchError}
                  disabled={hasPackageFetchError}
                >
                  {packages?.packages.map((packageType) => (
                    <MenuItem key={packageType.package_id} value={packageType.package_id}>
                      {packageType.package_name}
                    </MenuItem>
                  ))}
                </Select>
                {hasPackageFetchError && (
                  <Typography data-testid="failed-to-fetch" variant="body2" color="error">
                    Failed to fetch packages
                  </Typography>
                )}
                {hasPackageUpdateError && (
                  <Typography data-testid="failed-to-fetch" variant="body2" color="error">
                    Failed to update package
                  </Typography>
                )}
              </div>
            )}
          </>
        )}
      </Container>
    </>
  )
}
