import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  FETCH_PACKAGES_REQUEST,
  FETCH_PACKAGES_SUCCESS,
  FETCH_PACKAGES_FAILURE,
  UPDATE_PACKAGE_REQUEST,
  UPDATE_PACKAGE_SUCCESS,
  UPDATE_PACKAGE_FAILURE,
  SHOW_FEEDBACK
} from '../../constants'

import { PayloadType } from '../../../types/state'

import Api from '../../api'
import { getAuthState } from '../../selectors'

const instance = new Api()

export function* fetchPackagesFn({ payload }: PayloadType): SagaIterator {
  const { token } = yield select(getAuthState)
  const { userId } = payload

  try {
    const response = yield call(instance.fetchPackages, userId, token)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_PACKAGES_SUCCESS, payload: response.data })
    } else {
      yield put({ type: FETCH_PACKAGES_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed to fetch packages! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: FETCH_PACKAGES_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed to fetch packages! Try Again.', severity: 'error' } })
  }
}

export function* updatePackageFn({ payload }: PayloadType): SagaIterator {
  const { token } = yield select(getAuthState)
  const { packageId, userId } = payload

  try {
    const response = yield call(instance.updatePackage, userId, packageId, token)
    if (response && response.status === 200) {
      yield put({ type: UPDATE_PACKAGE_SUCCESS, payload: response.data.data })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Package successfully updated', severity: 'success' } })
    } else {
      yield put({ type: UPDATE_PACKAGE_FAILURE })
      yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed to update package! Try Again.', severity: 'error' } })
    }
  } catch (err) {
    yield put({ type: UPDATE_PACKAGE_FAILURE })
    yield put({ type: SHOW_FEEDBACK, payload: { title: 'Failed to update package! Try Again.', severity: 'error' } })
  }
}

export default function* usersSaga(): Generator {
  yield all([takeEvery(FETCH_PACKAGES_REQUEST, fetchPackagesFn)])
  yield all([takeEvery(UPDATE_PACKAGE_REQUEST, updatePackageFn)])
}
