import { UserType } from './messages'

export type PropertyImageType = {
  caption: string | null
  created_at: string
  index: number | null
  title: string | null
  type: string
  url: string
}

export type SinglePropertyDetail = {
  created_at: string
  property: PropertyDetails
  status: string
}

export type AddressDetails = {
  city: string | null
  postcode: string | null
  country: string | null
  latitude: number
  longitude: number
  address_line_1: string | null
  address_line_2: string | null
  address_line_3: string | null
}

export type PropertyDetails = {
  ac_check: boolean
  added_by: string | null
  apartment_check: string | null
  available_from: string
  balcony_check: boolean
  bathrooms: number
  bed_check: boolean
  bedrooms: number
  bills: string
  bookings_count: number
  couple_check: boolean
  created_at: string
  currency_code: string | null
  description: string | null
  dishwasher_check: boolean
  documents: []
  doorman_check: boolean
  dryer_check: boolean
  dss_check: boolean
  ensuite_check: string
  featured_check: string
  featured_image: PropertyImageType
  flat_check: string | null
  furnished_type: string
  garden_check: boolean
  has_expressed_interest: boolean
  heating_check: string | null
  holding_deposit: number | null
  house_check: string | null
  images: PropertyImageType[]
  is_disliked: boolean
  is_liked: boolean
  landlord: UserType
  landlord_name: string | null
  landlord_profile_id: string | null
  landlord_profile_image_url: string | null
  lift_check: boolean
  living_room_check: string | null
  maximum_stay: number | null
  minimum_stay: number | null
  monthly_rent: number
  nearest_stations: []
  parking_check: boolean
  pet_check: boolean
  property_id: string
  property_type: string
  reference_needed_check: boolean
  rooms: []
  rooms_available: number
  security_deposit: number | null
  smoking_check: boolean
  status: string
  student_check: boolean
  third_party_property_id: string | null
  title: string
  transport: []
  tv_check: boolean
  updated_at: string
  washing_check: boolean
  wheelchair_check: boolean
  wifi_check: boolean
  is_off_listed: boolean
} & AddressDetails

export type Image = {
  id: string
  name: string
  src: string | undefined
  size?: number
}

export type UploadedFile = {
  id: string
  file: File
}

export type PropertyUploadType = {
  id: string
  property_url?: string | null
  bedrooms: string | null
  bathrooms: string | null
  title?: string
  description?: string
  currency_code: string | null
  total_cost?: number
  location_data?: AddressDetails
  images: Image[]
  is_off_listed: boolean
}

export enum ThreadPropertyType {
  SUGGESTED = 'suggested',
  INTERESTED = 'interested'
}

export enum UserInfoTabsTitle {
  RECOMMENDATIONS = 'recommendations',
  INTERESTED = 'interested',
  CONTACTED = 'contacted'
}

export enum PropertyUnlistingStatus {
  PROPERTY_UNLISTING_SUCCESS = 'Unlisting success',
  PROPERTY_UNLISTING_FAILED = 'Unlisting failed'
}
