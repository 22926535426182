import { ReactElement, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteScroll from 'react-infinite-scroller'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import { Add } from '@material-ui/icons'
import moment from 'moment'

import { getBookingState } from '../../redux/selectors'
import { FETCH_BOOKINGS_REQUEST, FETCH_MORE_BOOKINGS_REQUEST } from '../../redux/constants'
import { BookingModalDetailsType, BookingStatus } from '../../types/bookings'
import { Loader, BookingCard, AddBooking, EditBooking } from '..'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'

const useStyles = makeStyles(() => ({
  bookingsContainer: {
    height: '100%',
    position: 'relative',
    overflow: 'auto'
  },
  loadMoreIcon: {
    width: '100%',
    textAlign: 'center'
  },
  noData: {
    height: 'inherit',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'grey'
  },
  addBookingButton: {
    position: 'fixed',
    bottom: 25,
    right: 25
  }
}))

export const Bookings = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const {
    fetchBookingsRequested,
    fetchMoreBookingsRequested,
    cancelBookingsRequested,
    bookings,
    currentPage,
    lastPage
  } = useSelector(getBookingState)

  const bookingDetailsInitialState: BookingModalDetailsType = {
    agent_name: '',
    contact_number: '',
    url: '',
    property_id: '',
    address: '',
    tz: { selectValue: 'Europe/London', displayValue: '(GMT+01:00) London' },
    dateTime: moment(),
    booking_id: '',
    type: 'property_visits',
    note: '',
    title: ''
  }

  const [openAddBooking, setOpenAddBooking] = useState<boolean>(false)
  const [openEditBooking, setOpenEditBooking] = useState<boolean>(false)
  const [bookingModalDetails, setBookingModalDetails] = useState<BookingModalDetailsType>(bookingDetailsInitialState)
  const [hasMoreBookings, setHasMoreBookings] = useState<boolean>(false)

  useEffect(() => {
    fetchMoreBookingsRequestedRequest('initial')
  }, [])

  useEffect(() => {
    if (currentPage < lastPage) {
      setHasMoreBookings(true)
    } else {
      setHasMoreBookings(false)
    }
  }, [currentPage])

  const fetchMoreBookingsRequestedRequest = (type: string) => {
    setHasMoreBookings(false)

    if (!customer) {
      return
    }

    const action = type === 'initial' ? FETCH_BOOKINGS_REQUEST : FETCH_MORE_BOOKINGS_REQUEST
    const payload = { userId: customer.linked_user_id, currentPage: type === 'initial' ? currentPage : currentPage + 1 }

    dispatch({ type: action, payload })
  }

  if (fetchBookingsRequested || cancelBookingsRequested) return <Loader />

  return (
    <div data-testid="bookings-container" className={classes.bookingsContainer}>
      <InfiniteScroll
        loadMore={() => {
          if (hasMoreBookings) fetchMoreBookingsRequestedRequest('loadMore')
        }}
        hasMore={hasMoreBookings}
        initialLoad={false}
        useWindow={false}
      >
        <div>
          {bookings.length > 0 &&
            bookings.map((booking) => {
              if (
                booking.status === BookingStatus.APPROVED ||
                booking.status === BookingStatus.CONFIRMED ||
                booking.status === BookingStatus.CANCELLED
              ) {
                return (
                  <BookingCard
                    key={booking.booking_id}
                    booking={booking}
                    setOpen={() => setOpenEditBooking(true)}
                    setBookingModalDetails={setBookingModalDetails}
                  />
                )
              }
            })}
        </div>

        {fetchMoreBookingsRequested && (
          <div className={classes.loadMoreIcon} data-testid="fetch-more-loading">
            <CircularProgress color="secondary" />
          </div>
        )}
      </InfiniteScroll>
      {!fetchBookingsRequested && bookings.length === 0 && (
        <div className={classes.noData} data-testid="empty-booking">
          <h3>No Bookings Available</h3>
        </div>
      )}

      <AddBooking open={openAddBooking} setOpen={() => setOpenAddBooking(false)} details={bookingModalDetails} />
      <EditBooking
        open={openEditBooking}
        setOpen={() => setOpenEditBooking(false)}
        type={'update'}
        details={bookingModalDetails}
      />

      <Fab
        data-track-click="booking-add"
        data-testid="add-button"
        color="secondary"
        aria-label="add"
        className={classes.addBookingButton}
        onClick={() => {
          setBookingModalDetails(bookingDetailsInitialState)
          setOpenAddBooking(true)
        }}
      >
        <Add />
      </Fab>
    </div>
  )
}
