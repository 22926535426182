import { put, takeEvery, call, all, select, takeLatest } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  RECOMMEND_PROPERTY_REQUEST,
  RECOMMEND_PROPERTY_SUCCESS,
  RECOMMEND_PROPERTY_FAILURE,
  UNLIST_PROPERTY_REQUEST,
  UNLIST_PROPERTY_SUCCESS,
  UNLIST_PROPERTY_FAILURE,
  UNLIST_USER_INFO_PROPERTY_SUCCESS,
  UNLIST_USER_INFO_PROPERTY_FAILURE,
  RECOMMEND_MULTIPLE_PROPERTIES_SUCCESS,
  RECOMMEND_MULTIPLE_PROPERTIES_FAILURE,
  RECOMMEND_MULTIPLE_PROPERTIES_REQUEST,
  GET_RECOMMENDATIONS_PROPERTIES_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
  UPDATE_PROPERTY_REQUEST
} from '../../constants'
import { PayloadType } from '../../../types/state'
import Api from '../../api'
import { getAuthState } from '../../selectors'
import { Image, PropertyUploadType } from '../../../types/properties'

const instance = new Api()

export function* fetchPropertyFn({ payload }: PayloadType): SagaIterator {
  const { propertyId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchPropertyDetails, propertyId, token)

    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_PROPERTY_SUCCESS, payload: { property: [response.data.data] } })
    } else {
      yield put({ type: FETCH_PROPERTY_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_PROPERTY_FAILURE })
  }
}

export function* recommendPropertyFn({ payload }: PayloadType): SagaIterator {
  const { customerId, propertyId } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.recommendProperty, customerId, token, propertyId)

    if (response && response.data && response.status === 200) {
      yield put({ type: RECOMMEND_PROPERTY_SUCCESS })
    } else {
      const responseMessage = response.response?.data?.message

      yield put({
        type: RECOMMEND_PROPERTY_FAILURE,
        payload: { message: responseMessage ? responseMessage : 'Failed! Try again' }
      })
    }
  } catch (err) {
    yield put({ type: RECOMMEND_PROPERTY_FAILURE, payload: { message: 'Failed! Try again' } })
  }
}

export function* unlistPropertyFn({ payload }: PayloadType): SagaIterator {
  const { userId, propertyId, userInfo } = payload
  const { token } = yield select(getAuthState)
  let additionalAction = UNLIST_USER_INFO_PROPERTY_FAILURE

  try {
    const response = yield call(instance.unlistProperty, token, propertyId)
    if (response && response.data && response.status === 200) {
      yield put({ type: UNLIST_PROPERTY_SUCCESS, payload: { propertyId } })
      additionalAction = UNLIST_USER_INFO_PROPERTY_SUCCESS
    } else {
      yield put({ type: UNLIST_PROPERTY_FAILURE })
    }
  } catch (err) {
    yield put({ type: UNLIST_PROPERTY_FAILURE })
  } finally {
    if (userInfo?.unlistInUserInfo) {
      yield put({ type: additionalAction, payload: { propertyId, type: userInfo.type } })

      if (userInfo.type === 'recommendations') {
        yield put({ type: GET_RECOMMENDATIONS_PROPERTIES_REQUEST, payload: { userId } })
      }
    }
  }
}

export function* recommendMultiplePropertyFn({ payload }: PayloadType): SagaIterator {
  const { customerId, propertiesToUpload } = payload
  const { token } = yield select(getAuthState)

  try {
    const properties = propertiesToUpload.map((property: PropertyUploadType) => ({
      ...property,
      bedrooms: property.bedrooms === 'Studio' ? '0' : property.bedrooms === '5+' ? '5' : property.bedrooms,
      bathrooms: property.bathrooms === '5+' ? '5' : property.bathrooms,
      images: property.images.map(({ src }: Image) => src)
    }))

    const response = yield call(instance.recommendMultipleProperties, customerId, token, properties)

    if (response && response.data && response.status === 200) {
      yield put({ type: RECOMMEND_MULTIPLE_PROPERTIES_SUCCESS })
    } else {
      const responseMessage = response.response?.data?.message

      yield put({
        type: RECOMMEND_MULTIPLE_PROPERTIES_FAILURE,
        payload: { message: responseMessage ? responseMessage : 'Failed! Try again' }
      })
    }
  } catch (err) {
    yield put({ type: RECOMMEND_MULTIPLE_PROPERTIES_FAILURE, payload: { message: 'Failed! Try again' } })
  }
}

export function* updatePropertyFn({ payload }: PayloadType): SagaIterator {
  const { customerId, property } = payload
  const { token } = yield select(getAuthState)

  try {
    const propertyToUpdate = {
      ...property,
      bedrooms: property.bedrooms === 'Studio' ? '0' : property.bedrooms === '5+' ? '5' : property.bedrooms,
      bathrooms: property.bathrooms === '5+' ? '5' : property.bathrooms,
      images: property.images.map(({ src }: Image) => src)
    }

    const response = yield call(instance.updateProperty, customerId, token, propertyToUpdate)

    if (response && response.data && response.status === 200) {
      yield put({ type: UPDATE_PROPERTY_SUCCESS, payload: { property } })
    } else {
      const responseMessage = response.response?.data?.message
      yield put({
        type: UPDATE_PROPERTY_FAILURE,
        payload: { message: responseMessage ?? 'Failed! Try again' }
      })
    }
  } catch (err) {
    yield put({ type: UPDATE_PROPERTY_FAILURE, payload: { message: 'Failed! Try again' } })
  } finally {
  }
}

export default function* propertySaga(): Generator {
  yield all([
    takeEvery(FETCH_PROPERTY_REQUEST, fetchPropertyFn),
    takeEvery(RECOMMEND_PROPERTY_REQUEST, recommendPropertyFn),
    takeEvery(UNLIST_PROPERTY_REQUEST, unlistPropertyFn),
    takeLatest(RECOMMEND_MULTIPLE_PROPERTIES_REQUEST, recommendMultiplePropertyFn),
    takeLatest(UPDATE_PROPERTY_REQUEST, updatePropertyFn)
  ])
}
