import { ReactElement, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Header, TasksHeader, Task, AddTaskModal } from '../../components'
import { TaskType } from '../../types/tasks'
import { getAuthState } from '../../redux/selectors'

const tasksData: TaskType[] = []

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
    backgroundColor: theme.palette.background.default
  },
  wrapper: {
    boxSizing: 'border-box',
    padding: '0 40px',
    [theme.breakpoints.down('sm')]: {
      padding: '60px 0px 0px 0px'
    }
  },
  tasksWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: '20px 10px'
  }
}))

export function Tasks(): ReactElement {
  const classes = useStyles()
  const history = useHistory()
  const { token } = useSelector(getAuthState)
  const [showAddTask, setShowAddTask] = useState(false)

  if (!token) {
    history.push('/')
  }

  return (
    <div className={classes.container} data-testid="tasks-container">
      <Header />
      <div className={classes.wrapper}>
        <TasksHeader />

        <div className={classes.tasksWrapper}>
          {tasksData.length === 0 ? (
            <Typography variant="subtitle1" style={{ textAlign: 'center', marginTop: 50 }}>
              Coming Soon
            </Typography>
          ) : (
            <>
              {tasksData.map((item) => {
                return <Task key={item.id} {...item} />
              })}
            </>
          )}
        </div>
      </div>
      <AddTaskModal
        open={showAddTask}
        onClose={() => {
          setShowAddTask(false)
        }}
      />
    </div>
  )
}
