import { memo, MouseEvent, ReactElement, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'
import { makeStyles } from '@material-ui/core/styles'
import { IconButton, Menu, MenuItem, Paper, Tooltip, Typography } from '@material-ui/core'
import { ErrorOutline, KeyboardArrowDown } from '@material-ui/icons'

import { getAuthState } from '../../redux/selectors'
import { DELETE_CHAT_REQUEST, SEND_NEW_CHAT_REQUEST, SET_REPLY_MESSAGE_DETAILS } from '../../redux/constants'
import { ConfirmationDialog, DeleteChat, ParentChatBubble } from '..'
import { ChatStatus, NewChat, PendingChat, TextChat, UndeliveredChat } from '../../types/messages'
import { isSent } from '../Chat/typeguards'
import { MessageStatus } from '../MessageStatus'
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px 0',
    display: 'flex'
  },
  doubleClick: {
    flex: 1
  },
  dataContainer: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    }
  },
  msgContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  errorIcon: {
    color: 'red',
    fontSize: 30,
    marginRight: 10
  },
  tooltip: {
    fontSize: 15
  },
  paper: {
    flex: 1,
    borderRadius: '20px 20px 0 20px',
    boxSizing: 'border-box',
    padding: '15px 20px 10px 20px',
    backgroundColor: theme.palette.primary.main,
    position: 'relative',
    '&:hover': {
      [theme.breakpoints.up('md')]: {
        '& $messageOptions': {
          visibility: 'visible'
        }
      }
    }
  },
  messageOptions: {
    color: theme.palette.common.white,
    visibility: 'visible',
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden'
    },
    position: 'absolute',
    top: 0,
    right: 0,
    '&:hover': {
      cursor: 'pointer'
    }
  },
  sender: {
    color: theme.palette.common.white,
    wordBreak: 'break-word',
    fontSize: '0.7em',
    marginBottom: 5
  },
  chat: {
    color: theme.palette.primary.contrastText,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  msgStatusContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  msgSentTime: {
    fontSize: '0.6em',
    textAlign: 'right'
  }
}))

export const SupportSimpleChat = memo((info: TextChat | PendingChat | UndeliveredChat): ReactElement => {
  const { chat, sender_name, name, read, created_at, parent_chat, parent_id, status } = info
  const isPending = !isSent(info)
  const isFailed = status === ChatStatus.FAILED
  const chat_id = !isSent(info) ? undefined : info.chat_id
  const isDeleted = status === ChatStatus.DELETED

  const classes = useStyles({ read })
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { currentUser } = useSelector(getAuthState)
  const dispatch = useDispatch()
  const isArchive = useLocation().pathname.includes('/archive')

  const hoursPassed = moment().diff(moment(created_at), 'h')

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleReplyToMsg = () => {
    setAnchorEl(null)

    dispatch({
      type: SET_REPLY_MESSAGE_DETAILS,
      payload: { sender: sender_name || name, chat, chatId: (info as TextChat).chat_id }
    })
  }

  const deleteMessage = () => {
    setAnchorEl(null)
    dispatch({ type: DELETE_CHAT_REQUEST, payload: { chatId: (info as TextChat).chat_id } })
  }

  const sendMessage = () => {
    if (!currentUser) return

    setAnchorEl(null)
    const newChat: NewChat = {
      chat,
      created_at,
      name,
      sender_name,
      sender_user_type: currentUser.user_type as string,
      parent_id,
      parent_chat,
      status: ChatStatus.ACTIVE,
      user: {
        name: currentUser.name,
        user_id: currentUser.user_id
      }
    }
    dispatch({ type: SEND_NEW_CHAT_REQUEST, payload: { chat: newChat, resend: true } })
  }

  const doubleClickHandle = () => {
    if (status === ChatStatus.ACTIVE) handleReplyToMsg()
  }

  return (
    <>
      <div className={classes.container} id={chat_id} data-testid="support-simple-chat">
        <div className={classes.doubleClick} onDoubleClick={doubleClickHandle} />
        <div className={classes.dataContainer}>
          <div className={isFailed ? classes.msgContainer : ''}>
            {isFailed && (
              <Tooltip
                data-testid="chat-sending-failed"
                title="Message not sent"
                placement="left"
                arrow
                classes={{ tooltip: classes.tooltip }}
              >
                <ErrorOutline className={classes.errorIcon} />
              </Tooltip>
            )}
            <Paper className={classes.paper}>
              {!isDeleted ? (
                <>
                  {parent_chat && (
                    <ParentChatBubble
                      sender={parent_chat.sender_name}
                      chat={parent_chat.chat}
                      chatId={parent_chat.chat_id}
                    />
                  )}
                  {sender_name && (
                    <Typography data-testid="chat-sender-name" variant="subtitle2" className={classes.sender}>
                      {`${sender_name}:`}
                    </Typography>
                  )}
                  {!isArchive && (
                    <>
                      <IconButton
                        data-testid="support-simple-chat-options-icon"
                        aria-label="message-options"
                        classes={{ root: classes.messageOptions }}
                        onClick={handleClick}
                      >
                        <KeyboardArrowDown />
                      </IconButton>
                      <Menu
                        data-testid="menu"
                        id="menu"
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={() => setAnchorEl(null)}
                      >
                        {isFailed && (
                          <MenuItem data-testid="chat-resend-option" onClick={sendMessage}>
                            Resend
                          </MenuItem>
                        )}
                        <MenuItem data-testid="chat-reply-option" disabled={isPending} onClick={handleReplyToMsg}>
                          Reply
                        </MenuItem>
                        <MenuItem
                          data-testid="chat-delete-option"
                          disabled={hoursPassed > 24 || isFailed}
                          onClick={() => setOpenConfirmationDialog(true)}
                        >
                          Delete
                        </MenuItem>
                      </Menu>
                    </>
                  )}
                  <Typography data-testid="chat-message" variant="body1" className={classes.chat}>
                    {chat}
                  </Typography>
                  <div className={classes.msgStatusContainer}>
                    <MessageStatus isPending={isPending} isRead={read} />
                  </div>
                </>
              ) : (
                <DeleteChat type="support" />
              )}
            </Paper>
          </div>
          <Typography
            data-testid="chat-sent-time"
            variant="subtitle2"
            color="textSecondary"
            className={classes.msgSentTime}
          >
            {moment.utc(created_at).local().format('dddd, Do of MMMM Y, H:mm')}
          </Typography>
        </div>
      </div>

      <ConfirmationDialog
        open={openConfirmationDialog}
        setOpen={() => setOpenConfirmationDialog(false)}
        message="Are you sure you want to delete this message?"
        onConfirm={deleteMessage}
        trackingContext="chat-simple-delete"
      />
    </>
  )
})
