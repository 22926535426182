import { ReactElement, useState } from 'react'
import clsx from 'clsx'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import {
  Typography,
  Card,
  CardHeader,
  CardActions,
  Collapse,
  IconButton,
  CardContent,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

import { NoResultsChat as NoResultsChatProps } from '../../types/messages'

type locationInfoType = {
  address: string
  latitude: number
  longitude: number
}

type commuteLocationsType = locationInfoType[]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: 400
    },
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest
      })
    },
    expandOpen: {
      transform: 'rotate(180deg)'
    }
  })
)

export function NoResultsChat({ chat }: NoResultsChatProps): ReactElement {
  const [expanded, setExpanded] = useState(false)
  const classes = useStyles()
  const searchParameters = JSON.parse(chat)
  const {
    rent_more_than,
    rent_less_than,
    currency_code,
    room_more_than,
    room_less_than,
    commuteLocations,
    commuteMode,
    commuteTime,
    movingWith,
    specificRequirements
  } = searchParameters.filters

  const commuteLocationsList = (commuteLocationsArr: commuteLocationsType) => {
    let places = ''
    commuteLocationsArr.forEach((location: locationInfoType) => {
      places += ` ${location.address} &`
    })
    places = places.slice(0, -1)
    return places
  }

  const tableData = [
    {
      title: 'Budget:',
      value: `${rent_more_than < 0 ? 0 : rent_more_than / 100} - ${(rent_less_than / 100) * 1} (${currency_code})`
    },
    {
      title: 'No of bedrooms:',
      value: `${room_more_than} - ${room_less_than}`
    },
    {
      title: 'Commute Location:',
      value: commuteLocationsList(commuteLocations)
    },
    {
      title: 'Commute Mode:',
      value: commuteMode
    },
    {
      title: 'Commute Time:',
      value: commuteTime
    },
    {
      title: 'Moving Alone?',
      value: movingWith
    },
    {
      title: 'Specific Requirements:',
      value: specificRequirements
    }
  ]

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  return (
    <Card className={classes.root} data-testid="no-result-chat">
      <CardHeader
        disableTypography
        onClick={handleExpandClick}
        style={{ cursor: 'pointer' }}
        title={<Typography variant="h6">No results found</Typography>}
        subheader={<Typography variant="body2">Click for more details</Typography>}
      />
      <CardActions disableSpacing>
        <IconButton
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded
          })}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
          data-testid="no-result-chat-expand-icon"
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent style={{ padding: 0 }}>
          <TableContainer className={classes.root} component={Paper}>
            <Table aria-label="no results table">
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell align="right">Value</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData.map((data) => {
                  return (
                    <TableRow key={data.title}>
                      <TableCell component="th" scope="row">
                        {data.title}
                      </TableCell>
                      <TableCell style={{ width: 200 }} align="right">
                        {data.value}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Collapse>
    </Card>
  )
}
