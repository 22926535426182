import { put, takeEvery, call, all, select } from 'redux-saga/effects'
import { SagaIterator } from 'redux-saga'
import {
  FETCH_BOOKINGS_REQUEST,
  FETCH_BOOKINGS_SUCCESS,
  FETCH_BOOKINGS_FAILURE,
  FETCH_MORE_BOOKINGS_REQUEST,
  ADD_BOOKINGS_REQUEST,
  ADD_BOOKINGS_SUCCESS,
  ADD_BOOKINGS_FAILURE,
  EDIT_BOOKINGS_REQUEST,
  EDIT_BOOKINGS_SUCCESS,
  EDIT_BOOKINGS_FAILURE,
  CANCEL_BOOKINGS_REQUEST
} from '../../constants'
import { PayloadType } from '../../../types/state'
import Api from '../../api'
import { getAuthState } from '../../selectors'

const instance = new Api()

export function* fetchBookingFn({ payload }: PayloadType): SagaIterator {
  const { userId, currentPage } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.fetchBookings, userId, token, currentPage)
    if (response && response.data && response.status === 200) {
      yield put({ type: FETCH_BOOKINGS_SUCCESS, payload: { data: response.data, userId } })
    } else {
      yield put({ type: FETCH_BOOKINGS_FAILURE })
    }
  } catch (err) {
    yield put({ type: FETCH_BOOKINGS_FAILURE })
  }
}

export function* addBookingFn({ payload }: PayloadType): SagaIterator {
  const { userId, bookingDetails } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.addBooking, userId, token, bookingDetails)
    if (response && response.status === 200) {
      yield put({ type: ADD_BOOKINGS_SUCCESS })
    } else {
      yield put({ type: ADD_BOOKINGS_FAILURE })
    }
  } catch (err) {
    yield put({ type: ADD_BOOKINGS_FAILURE })
  }
}

export function* editBookingFn({ payload }: PayloadType): SagaIterator {
  const { userId, bookingId, newBookingDetails, modificationStatus } = payload
  const { token } = yield select(getAuthState)

  try {
    const response = yield call(instance.updateBookingDetails, token, userId, bookingId, newBookingDetails)
    if (response && response.status === 200) {
      yield put({ type: EDIT_BOOKINGS_SUCCESS, payload: { bookingId, newBookingDetails, modificationStatus } })
    } else {
      yield put({ type: EDIT_BOOKINGS_FAILURE })
    }
  } catch (error) {
    yield put({ type: EDIT_BOOKINGS_FAILURE })
  }
}

export default function* bookingSaga(): Generator {
  yield all([
    takeEvery(FETCH_BOOKINGS_REQUEST, fetchBookingFn),
    takeEvery(FETCH_MORE_BOOKINGS_REQUEST, fetchBookingFn),
    takeEvery(ADD_BOOKINGS_REQUEST, addBookingFn),
    takeEvery(EDIT_BOOKINGS_REQUEST, editBookingFn),
    takeEvery(CANCEL_BOOKINGS_REQUEST, editBookingFn)
  ])
}
