import { useCallback, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { UploadedFile } from '../../types/properties'
import { makeStyles, IconButton } from '@material-ui/core'
import { Add, Delete } from '@material-ui/icons'
import { useDropzone, Accept, FileRejection, ErrorCode } from 'react-dropzone'

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#FCF6C3',
    border: ({ error }: { error: boolean }) => `2px dashed ${error ? '#F44336' : '#E4D126'}`,
    borderRadius: '5px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '75px',
    padding: '5px',
    paddingTop: 0
  },
  addIcon: {
    marginTop: '5px',
    marginBottom: '10px'
  },
  deleteFile: {
    marginLeft: theme.spacing(1)
  },
  filePreviewList: {
    paddingLeft: 0,
    marginTop: 0,
    marginBottom: 0
  },
  filePreviewItem: {
    listStyle: 'none'
  },
  label: {
    fontWeight: 'bold',
    fontSize: '.8rem',
    marginTop: '5px'
  },
  errorContainer: {
    color: theme.palette.error.main,
    fontSize: '0.7em'
  }
}))

interface Props {
  files: UploadedFile[]
  onChange: (files: UploadedFile[]) => void
  onRemoveFile: (fileId: string) => void
  maxFiles?: number
  acceptedFileTypes: Accept
  maxSize?: number
}

export const FileUpload = ({
  onChange,
  maxFiles = 1,
  files,
  onRemoveFile,
  acceptedFileTypes,
  maxSize = 4718592 // 4.5mb
}: Props): JSX.Element => {
  const classes = useStyles({ error: false })
  const [errors, setErrors] = useState('')

  const getErrorMessage = (errorCode: ErrorCode | string) =>
    ({
      [ErrorCode.FileInvalidType]: 'File type was not valid, please check and try again',
      [ErrorCode.FileTooLarge]: `File was too large, the max file size is ${maxSize / 1024 / 1024}mb`,
      [ErrorCode.FileTooSmall]: `File was too small`,
      [ErrorCode.TooManyFiles]: `Max number of files exceeded. Please only upload ${maxFiles} file(s) at a time`
    }[errorCode] || `An unknown error occurred - ${errorCode}`)

  const onDrop = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      if (files.length >= maxFiles) return

      rejectedFiles.forEach(({ errors }) => {
        errors.forEach((error) => {
          setErrors(getErrorMessage(error.code))
        })
      })

      acceptedFiles.forEach((file) => {
        const id = uuidv4()
        onChange([{ id, file }])
      })
    },
    [files]
  )

  const {
    getRootProps,
    getInputProps,
    open: onClickAddIcon,
    isDragActive
  } = useDropzone({ onDrop, maxFiles, noClick: true, accept: acceptedFileTypes, useFsAccessApi: false, maxSize })

  const getFilePreview = () => {
    return (
      <>
        {files.map(({ id, file: { name } }) => (
          <ul key={id} className={classes.filePreviewList}>
            <li className={classes.filePreviewItem}>
              {name}
              <IconButton
                size="small"
                className={classes.deleteFile}
                onClick={() => onRemoveFile(id)}
                aria-label={`Delete ${name}`}
              >
                <Delete />
              </IconButton>
            </li>
          </ul>
        ))}
      </>
    )
  }

  return (
    <div {...getRootProps({ 'aria-label': 'drag and drop area' })} className={classes.container}>
      <input {...getInputProps({ id: 'file-uploader' })} />
      {!isDragActive && files.length < maxFiles && (
        <>
          <label htmlFor="file-uploader" className={classes.label}>
            Drag and drop files here, or click below to choose
          </label>
          <IconButton className={classes.addIcon} size="small" onClick={onClickAddIcon}>
            <Add aria-label={'Upload file'} />
          </IconButton>
          {errors && <p className={classes.errorContainer}>{errors}</p>}
        </>
      )}
      {isDragActive && (
        <label htmlFor="file-uploader" className={classes.label}>
          Drop to upload
        </label>
      )}
      {getFilePreview()}
    </div>
  )
}
