import { ReactElement } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { CircularProgress } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

type Props = {
  ariaLabel?: string
}

export function Loader({ ariaLabel = 'Loading' }: Props): ReactElement {
  const classes = useStyles()

  return (
    <div data-testid="loader-container" className={classes.container}>
      <CircularProgress color="secondary" role="alert" aria-live="assertive" aria-label={ariaLabel} />
    </div>
  )
}
