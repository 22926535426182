import { ReactElement, useState } from 'react'
import moment from 'moment'
import { Button, Divider, makeStyles, SvgIcon, TextareaAutosize } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    width: '100%',
    padding: '0px 10px 5px',
    boxSizing: 'border-box',
    backgroundColor: 'white',
    marginTop: 15,
    minHeight: 70,
    '&:hover': {
      '& $deleteBtn, $editBtn, $pinBtn': {
        display: 'block'
      }
    }
  },
  btnContainer: {
    position: 'absolute',
    top: -10,
    right: 0,
    display: 'flex',
    justifyContent: 'space-between',
    width: 225,
    [theme.breakpoints.down('xs')]: {
      width: 200
    }
  },
  deleteBtn: {
    color: theme.palette.secondary.main,
    fontSize: '0.7em',
    fontWeight: 'bold',
    border: '1px solid #f50057',
    padding: '2px 10px',
    background: 'none',
    display: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: 'white'
    }
  },
  editBtn: {
    color: 'black',
    fontSize: '0.7em',
    fontWeight: 'bold',
    border: '1px solid black',
    padding: '2px 10px',
    background: 'none',
    display: 'none',
    '&:hover': {
      backgroundColor: 'black',
      color: 'white'
    }
  },
  pinBtn: {
    color: 'blue',
    fontSize: '0.7em',
    fontWeight: 'bold',
    border: '1px solid blue',
    padding: '2px 10px',
    background: 'none',
    display: 'none',
    '&:hover': {
      backgroundColor: 'blue',
      color: 'white'
    }
  },
  sender: {
    fontSize: '0.8em',
    color: 'blue',
    boxSizing: 'border-box',
    paddingRight: 180,
    marginBottom: 5
  },
  pin: {
    height: '15px',
    width: '15px',
    float: 'left',
    paddingRight: '5px',
    fill: 'blue'
  },
  text: {
    marginTop: 10,
    fontSize: 15,
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  dateText: {
    fontSize: '0.6em',
    color: 'gray',
    boxSizing: 'border-box',
    marginTop: 5,
    textAlign: 'right'
  },
  divider: {
    marginTop: 5
  },
  editNoteWrapper: {
    width: '100%',
    flexDirection: 'column',
    textAlign: 'center'
  },
  editNote: {
    width: '100%',
    resize: 'none',
    fontSize: 15,
    fontFamily: 'Roboto'
  },
  saveEditBtn: {
    marginTop: 10,
    marginLeft: 10
  }
}))

type Props = {
  sentBy: string
  noteText: string
  noteId: string
  date: string
  isEdited: boolean
  isPinned: boolean
  handlePinNote: (isPinned: boolean, noteId: string) => void
  handleUpdateNote: (noteId: string, editedNote: string) => void
  handleDeleteNote: (noteId: string) => void
}

export function Note({
  sentBy,
  noteText,
  noteId,
  date,
  isEdited,
  isPinned,
  handlePinNote,
  handleUpdateNote,
  handleDeleteNote
}: Props): ReactElement {
  const classes = useStyles()
  const [editMode, setEditMode] = useState(false)
  const [editedNote, setEditedNote] = useState(noteText)

  const handleEditNoteMode = () => setEditMode((editMode) => !editMode)

  return (
    <div data-testid={`note-container-${noteId}`} data-noteid={noteId} className={classes.container}>
      {!editMode ? (
        <>
          <div className={classes.btnContainer}>
            <Button
              data-testid={'pin-note-btn'}
              className={classes.pinBtn}
              onClick={() => handlePinNote(isPinned, noteId)}
              data-track-click={`${isPinned ? 'unpin' : 'pin'}-note`}
            >
              {`${isPinned ? 'Unpin' : 'Pin'}`}
            </Button>
            <Button
              data-testid={'edit-note-btn'}
              className={classes.editBtn}
              onClick={handleEditNoteMode}
              data-track-click="edit-note"
            >
              Edit
            </Button>
            <Button
              data-testid={'delete-note-btn'}
              className={classes.deleteBtn}
              onClick={() => handleDeleteNote(noteId)}
              data-track-click="delete-note"
            >
              Delete
            </Button>
          </div>
          {isPinned && (
            <SvgIcon viewBox="0 0 512 512" width="512" className={classes.pin}>
              <path d="m505.41 144.476-137.886-137.886c-4.25-4.25-9.9-6.59-15.91-6.59s-11.66 2.341-15.909 6.59c-4.25 4.25-6.591 9.9-6.591 15.91s2.341 11.66 6.59 15.91l15.58 15.58-119.957 106.281c-24.641-5.338-50.396-4.701-74.742 1.879-26.421 7.14-50.674 21.201-70.135 40.663-1.407 1.406-2.197 3.314-2.197 5.303s.79 3.897 2.197 5.303l90.156 90.156-159.409 159.408c-11.09 11.09-17.197 25.834-17.197 41.517 0 4.142 3.358 7.5 7.5 7.5 15.683 0 30.427-6.107 41.517-17.197l159.409-159.409 90.156 90.156c1.465 1.464 3.385 2.197 5.304 2.197s3.839-.732 5.304-2.197c19.461-19.461 33.521-43.713 40.662-70.135 6.58-24.348 7.217-50.102 1.879-74.742l106.281-119.958 15.579 15.579c4.25 4.25 9.9 6.59 15.91 6.59s11.661-2.341 15.909-6.59c4.25-4.25 6.591-9.9 6.591-15.91-.001-6.008-2.342-11.658-6.591-15.908zm-467 339.721c-6.27 6.27-14.109 10.4-22.632 12.025 1.625-8.523 5.756-16.362 12.025-22.632l159.409-159.409 10.607 10.607zm265.339-74.692-201.254-201.254c34.561-31.19 81.416-43.425 127.142-32.974l107.087 107.088c10.448 45.726-1.786 92.58-32.975 127.14zm191.054-243.816c-1.416 1.417-3.3 2.196-5.303 2.196s-3.887-.78-5.303-2.197l-95.459-95.458c-2.93-2.928-7.678-2.93-10.607 0-2.929 2.929-2.929 7.678 0 10.606l69.254 69.254-104.159 117.563-98.878-98.878 122.846-108.841c1.548-1.372 2.461-3.321 2.522-5.387.063-2.067-.73-4.068-2.192-5.53l-21.214-21.214c-1.416-1.417-2.196-3.3-2.196-5.303s.78-3.886 2.197-5.303c1.416-1.417 3.3-2.197 5.303-2.197s3.887.78 5.303 2.197l137.887 137.886c1.416 1.417 2.196 3.3 2.196 5.303s-.78 3.886-2.197 5.303z" />
            </SvgIcon>
          )}
          <div data-testid="sentBy" className={classes.sender}>
            {sentBy}
            {isEdited && '(Edited)'}
          </div>
          <div data-testid="text" className={classes.text}>
            {noteText}
          </div>
          {date && <div className={classes.dateText}>{moment.utc(date).local().format('dddd, Do of MMMM, H:mm')}</div>}
          <Divider className={classes.divider} />
        </>
      ) : (
        <div data-testid="edit-note-wrapper" className={classes.editNoteWrapper}>
          <TextareaAutosize
            data-testid="edit-note-input"
            minRows={4}
            maxRows={8}
            className={classes.editNote}
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
          />
          <Button
            data-testid="save-edit-btn"
            variant="contained"
            size="small"
            color="primary"
            className={classes.saveEditBtn}
            onClick={() => {
              handleUpdateNote(noteId, editedNote)
              handleEditNoteMode()
            }}
          >
            Save
          </Button>
          <Button
            data-testid="close-edit-btn"
            size="small"
            className={classes.saveEditBtn}
            onClick={handleEditNoteMode}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>
  )
}
