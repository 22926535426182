import { ChangeEventHandler, MouseEventHandler, ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment'
import { Checkbox, FormControlLabel, Paper, Typography, makeStyles } from '@material-ui/core'
import { VideocamOutlined, DescriptionOutlined } from '@material-ui/icons'

import { ActivityFeedItem, ActivityFeedItemTypeKeys, CommentChatType } from '../../types/messages'
import { CardType } from '../../types/thread'
import { ACTION_CHAT_REQUEST, ACTION_CHAT_RESET, SHOW_FEEDBACK } from '../../redux/constants'
import { getThreadState } from '../../redux/selectors'
import { Status } from '@googlemaps/react-wrapper'

type StyleProps = { isTenant?: boolean; isArchive?: boolean }

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '10px 0',
    display: 'flex'
  },
  flexDummy: {
    flex: ({ isTenant }: StyleProps) => (isTenant ? 0 : 1)
  },
  msgContainer: {
    width: '45%',
    [theme.breakpoints.down('xs')]: {
      width: '75%'
    }
  },
  paper: {
    backgroundColor: ({ isTenant }: StyleProps) => (isTenant ? theme.palette.common.white : theme.palette.primary.main),
    borderRadius: ({ isTenant }: StyleProps) => (isTenant ? '20px 20px 20px 0' : '20px 20px 0 20px'),
    boxSizing: 'border-box',
    padding: '15px 20px',
    position: 'relative'
  },
  msgTime: {
    fontSize: '0.6em',
    textAlign: ({ isTenant }: StyleProps) => (isTenant ? 'unset' : 'right')
  },
  comment: {
    backgroundColor: '#E4E4E4',
    borderRadius: 5,
    width: '90%',
    padding: 10,
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10,
    cursor: ({ isArchive }: StyleProps) => (!isArchive ? 'pointer' : 'auto')
  },
  commentContent: {
    overflow: 'hidden'
  },
  commentSender: {
    fontSize: '0.7em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  },
  commentText: {
    marginTop: 5,
    wordBreak: 'break-word',
    whiteSpace: 'pre-wrap'
  },
  commentImage: {
    alignSelf: 'baseline',
    objectFit: 'contain',
    width: 44
  },
  icon: {
    alignSelf: 'baseline',
    fontSize: '44px'
  }
}))

const isPropertyCard = (type?: ActivityFeedItemTypeKeys) => type?.includes('property')

const getThumbnail = ({
  type,
  data,
  title
}: {
  type: ActivityFeedItem['type']
  data: ActivityFeedItem['data']
  title: string
}) => {
  const classes = useStyles({})

  if (type === CardType.content || type === CardType.document_external) {
    return <DescriptionOutlined className={classes.icon} role="img" />
  }

  if (type === CardType.video_external) {
    return <VideocamOutlined className={classes.icon} role="img" />
  }

  if ('images' in data && data.images.length > 0) {
    return (
      <img src={data.images[0].url} className={classes.commentImage} {...(title && { alt: `property ${title}` })} />
    )
  }

  return null
}

export function CommentChat(props: CommentChatType): ReactElement {
  const { chat_id, chat, created_at, sender_name, sender_user_id, sender_user_type, activity_feed_item, actioned } =
    props
  const { activity_feed_item_id, type, data } = activity_feed_item
  const title = (() => {
    if (isPropertyCard(type)) {
      return 'address_line_1' in data && !!data.address_line_1 ? `property ${data.address_line_1}` : null
    }

    return data.title
  })() as string
  const isTenant = sender_user_type === 'tenant'
  const isArchive = useLocation().pathname.includes('/archive')
  const classes = useStyles({ isTenant, isArchive })
  const history = useHistory()
  const dispatch = useDispatch()
  const { actionedChatStatus } = useSelector(getThreadState)

  const handleOnNavigate: MouseEventHandler<HTMLDivElement> = () => {
    history.push(`/manage?v2Url=/feed/${sender_user_id}/activity/${activity_feed_item_id}`)
  }

  const handleOnAction: ChangeEventHandler<HTMLInputElement> = (event) => {
    dispatch({ type: ACTION_CHAT_REQUEST, payload: { chatId: chat_id, actioned: event.currentTarget.checked } })
  }

  useEffect(() => {
    if (!!actionedChatStatus) {
      let payload = { severity: 'success', title: 'Chat actioned status updated' }
      if (actionedChatStatus !== Status.SUCCESS) {
        payload = { severity: 'error', title: 'Failed! Please try again' }
      }
      dispatch({ type: SHOW_FEEDBACK, payload })
      dispatch({ type: ACTION_CHAT_RESET })
    }
  }, [actionedChatStatus])

  return (
    <div className={classes.container} data-testid="comment-chat">
      <div className={classes.flexDummy} />
      <div className={classes.msgContainer}>
        <Paper elevation={2} className={classes.paper}>
          <div
            className={classes.comment}
            data-track-click="chat-comment-navigate"
            {...(!isArchive && { onClick: handleOnNavigate })}
          >
            <div className={classes.commentContent}>
              <Typography variant="subtitle2" className={classes.commentSender}>
                {sender_name} commented<b>{title ? ` on ${title}` : ''}</b>
              </Typography>
              <Typography variant="body2" className={classes.commentText}>
                {chat}
              </Typography>
            </div>
            {getThumbnail({ type, data, title })}
          </div>
          {isTenant && (
            <FormControlLabel
              id="actioned"
              label="Actioned"
              disabled={isArchive}
              control={
                <Checkbox
                  checked={actioned}
                  // eslint-disable-next-line
                  // @ts-ignore
                  inputProps={{ 'aria-labelledby': 'actioned', 'data-track-click': 'chat-comment-actioned' }}
                  onChange={handleOnAction}
                />
              }
            />
          )}
        </Paper>
        <Typography variant="subtitle2" color="textSecondary" className={classes.msgTime}>
          {moment.utc(created_at).local().format('dddd, Do of MMMM Y, H:mm')}
        </Typography>
      </div>
    </div>
  )
}
