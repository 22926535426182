import {
  Chat,
  ChatType,
  CommentChatType,
  DocumentChat,
  NoResultsChat,
  PropertyChat,
  PusherPropertyChat,
  ThreadChat
} from '../../types/messages'

export const isSent = (chat: ThreadChat): chat is Chat => 'user_type' in chat.user && 'type' in chat

export const isPropertyChat = (chat: ThreadChat): chat is PropertyChat | PusherPropertyChat =>
  isSent(chat) &&
  (chat.type === ChatType.RECOMMENDATION ||
    chat.type === ChatType.NOTIFICATION_ENQUIRED ||
    chat.type === ChatType.NOTIFICATION_RECOMMENDATION_ENQUIRED ||
    chat.type === ChatType.NOTIFICATION_RECOMMENDATION_DECLINED)

export const isDocumentChat = (chat: ThreadChat): chat is DocumentChat =>
  isSent(chat) && (chat.type === ChatType.DOCUMENT || chat.type === ChatType.IMAGE)

export const isCommentChat = (chat: ThreadChat): chat is CommentChatType =>
  isSent(chat) && chat.type === ChatType.COMMENT

export const isNoResultsChat = (chat: ThreadChat): chat is NoResultsChat =>
  isSent(chat) && chat.type === ChatType.NO_PROPERTY_RESULTS
