import { uniqBy } from 'lodash'
import {
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  RECOMMEND_PROPERTY_REQUEST,
  RECOMMEND_PROPERTY_SUCCESS,
  RECOMMEND_PROPERTY_FAILURE,
  UNLIST_PROPERTY_REQUEST,
  UNLIST_PROPERTY_SUCCESS,
  UNLIST_PROPERTY_FAILURE,
  PARTIAL_CLEAR_PROPERTY_STATE,
  FETCH_THREADS_SUCCESS,
  FETCH_MORE_THREADS_SUCCESS,
  FETCH_THREADS_TILL_PARENT_SUCCESS,
  RECOMMEND_MULTIPLE_PROPERTIES_REQUEST,
  RECOMMEND_MULTIPLE_PROPERTIES_SUCCESS,
  RECOMMEND_MULTIPLE_PROPERTIES_FAILURE,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
  CLEAR_PROPERTIES
} from '../../constants'

import { PropertyState } from '../../../types/state'
import { addOrUpdateProperties, getPropertiesFromChats, unlistPropertyInState } from './utils'
import { AnyAction } from 'redux'

export const initialState: PropertyState = {
  recommendPropertyRequested: false,
  unlistPropertyRequested: false,
  properties: [],
  propertiesToUpload: []
}

export default (state = initialState, action: AnyAction): PropertyState => {
  switch (action.type) {
    case FETCH_THREADS_SUCCESS:
      const newProperties = getPropertiesFromChats(action.payload.data.chats.data)
      return {
        ...state,
        properties: uniqBy([...state.properties, ...newProperties], 'property_id')
      }
    case FETCH_MORE_THREADS_SUCCESS:
      const moreProperties = getPropertiesFromChats(action.payload.data.data.chats.data)

      return {
        ...state,
        properties: uniqBy([...state.properties, ...moreProperties], 'property_id')
      }

    case FETCH_THREADS_TILL_PARENT_SUCCESS:
      const scrolledProperties = getPropertiesFromChats(action.payload.data.messages)

      return {
        ...state,
        properties: uniqBy([...state.properties, ...scrolledProperties], 'property_id')
      }

    case FETCH_PROPERTY_REQUEST:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: undefined
      }

    case FETCH_PROPERTY_SUCCESS:
      const { property } = action.payload
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: addOrUpdateProperties(state.properties, property),
        status: 'Property Fetched'
      }

    case FETCH_PROPERTY_FAILURE:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: 'Property Fetching Failed'
      }

    case RECOMMEND_PROPERTY_REQUEST:
      return {
        recommendPropertyRequested: true,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: undefined
      }

    case RECOMMEND_PROPERTY_SUCCESS:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: 'Property Recommended'
      }

    case RECOMMEND_PROPERTY_FAILURE:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: action.payload.message
      }

    case UNLIST_PROPERTY_REQUEST:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: true,
        properties: [...state.properties],
        status: undefined
      }

    case UNLIST_PROPERTY_SUCCESS:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: unlistPropertyInState(state, action.payload.propertyId),
        status: 'Property Unlisted'
      }

    case UNLIST_PROPERTY_FAILURE:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: 'Property Unlisting Failed'
      }

    case UPDATE_PROPERTY_REQUEST:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: true,
        properties: [...state.properties],
        status: undefined
      }

    case UPDATE_PROPERTY_SUCCESS:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: 'Property Updated'
      }

    case UPDATE_PROPERTY_FAILURE:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: 'Property Update Failed'
      }

    case PARTIAL_CLEAR_PROPERTY_STATE:
      return {
        recommendPropertyRequested: false,
        unlistPropertyRequested: false,
        properties: [...state.properties],
        status: undefined
      }

    case CLEAR_PROPERTIES:
      return {
        ...state,
        properties: []
      }

    case RECOMMEND_MULTIPLE_PROPERTIES_REQUEST:
      return {
        ...state,
        propertiesToUploadStatus: undefined,
        propertiesToUpload: [...action.payload.propertiesToUpload]
      }

    case RECOMMEND_MULTIPLE_PROPERTIES_SUCCESS:
      return {
        ...state,
        propertiesToUploadStatus: 'Multiple Properties Recommended'
      }

    case RECOMMEND_MULTIPLE_PROPERTIES_FAILURE:
      return {
        ...state,
        propertiesToUpload: [],
        propertiesToUploadStatus: action.payload.message
      }
    default:
      return state
  }
}
