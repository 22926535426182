import { ReactElement, useState, memo } from 'react'
import {
  Card,
  CardHeader,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  CardMedia,
  CardContent,
  Dialog,
  Container
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CameraAlt, KeyboardArrowDown } from '@material-ui/icons'
import { PropertyDetails, ThreadPropertyType } from '../../types/properties'
import { PusherPropertyChat } from '../../types/messages'
import { isSent } from '../Chat/typeguards'
import { MessageStatus } from '../MessageStatus'
import { ChatAvatar } from '../ChatAvatar'
import { webUrl } from '../../utils/env'

type styleProps = { type?: ThreadPropertyType }

export type CardOptions = {
  name: string
  onClickCallback?: () => void
  isDisabled: boolean
}

type Props = {
  property: PropertyDetails
  cardOptions?: CardOptions[]
  info?: PusherPropertyChat
  type?: ThreadPropertyType
}

const borderBottomLeft = (type?: ThreadPropertyType) => (type === ThreadPropertyType.INTERESTED ? '0' : '20px')
const borderBottomRight = (type?: ThreadPropertyType) => (type === ThreadPropertyType.INTERESTED ? '20px' : '0')

const useStyles = makeStyles((theme) => ({
  card: {
    width: 300,
    margin: 0,
    '&:hover': {
      cursor: 'pointer',
      [theme.breakpoints.up('md')]: {
        '& $settings': {
          visibility: 'visible'
        }
      }
    },
    flex: 1,
    borderRadius: ({ type }: styleProps) => `20px 20px ${borderBottomRight(type)} ${borderBottomLeft(type)}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  cardHeader: {
    padding: '12px'
  },
  emptyCard: {
    width: '100%',
    background: 'rgba(0, 0, 0, 0.5)',
    height: 200,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  propertyUnavailable: {
    background: 'rgba(0, 0, 0, 0.75)',
    color: 'white'
  },
  settings: {
    color: theme.palette.common.white,
    visibility: 'visible',
    [theme.breakpoints.up('md')]: {
      visibility: 'hidden'
    },
    '&:hover': {
      cursor: 'pointer'
    }
  },
  msgStatusContainer: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  thirdPartyIdContainer: {
    textAlign: 'center',
    padding: '50px'
  }
}))

const maybePluralise = (count: number, word: string) => `${count} ${word}${count > 1 ? 's' : ''}`

const CardDetails = ({ property, cardOptions, info, type }: Props): ReactElement => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [showThirdPartyDialog, setShowThirdPartyDialog] = useState<boolean>(false)

  const classes = useStyles({ type })

  const {
    address_line_1,
    address_line_2,
    bedrooms,
    bathrooms,
    currency_code,
    monthly_rent,
    featured_image,
    third_party_property_id,
    property_id,
    status,
    title
  } = property

  const rentWithCurrency =
    currency_code &&
    new Intl.NumberFormat(undefined, { style: 'currency', currency: currency_code }).format(monthly_rent / 100)

  const linkToProperty = () => window.open(`${webUrl}/properties/${property_id}`, '_blank')

  const handleCardOptions = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(anchorEl ? null : event.currentTarget)

  const menuItemCallback = (option: CardOptions) => {
    setAnchorEl(null)
    option.onClickCallback ? option.onClickCallback() : linkToProperty()
  }

  const openThirdPartyProperty = () => {
    if (third_party_property_id) {
      const hasProtocol = third_party_property_id.startsWith('http')
      if (third_party_property_id.includes('.')) {
        window.open(`${!hasProtocol ? 'https://' : ''}${third_party_property_id}`, '_blank')
      } else {
        setShowThirdPartyDialog(true)
      }
    }
  }

  return (
    <Card className={classes.card} data-testid="property-card">
      <CardHeader
        disableTypography
        classes={{ root: classes.cardHeader }}
        title={info && <ChatAvatar name={info.user.name} imageUrl={info.user.profile_image_url} />}
        action={
          cardOptions &&
          cardOptions.length > 1 && (
            <>
              <IconButton aria-label="settings" onClick={handleCardOptions} classes={{ root: classes.settings }}>
                <KeyboardArrowDown />
              </IconButton>
              <Menu
                data-testid="property-card-menu"
                id="menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleCardOptions}
              >
                {cardOptions.map((option: CardOptions) => (
                  <MenuItem
                    data-track-click={`property-card-action-${option.name}`}
                    data-testid={`${option.name}-menu-item`}
                    key={option.name}
                    disabled={option.isDisabled}
                    onClick={() => menuItemCallback(option)}
                  >
                    {option.name}
                  </MenuItem>
                ))}
              </Menu>
            </>
          )
        }
      />
      {status !== 'unlisted' ? (
        <>
          {featured_image?.url ? (
            <CardMedia
              data-testid="card-media"
              onClick={openThirdPartyProperty}
              image={featured_image.url}
              title={title}
              alt={title}
              component="img"
              loading="lazy"
              height="250px"
            />
          ) : (
            <div data-testid="empty-card" onClick={openThirdPartyProperty} className={classes.emptyCard}>
              <CameraAlt />
            </div>
          )}
        </>
      ) : (
        <div
          data-testid="property-unavailable-card"
          onClick={openThirdPartyProperty}
          className={`${classes.emptyCard} ${classes.propertyUnavailable}`}
        >
          <Typography variant="h6">Property Unavailable</Typography>
        </div>
      )}
      <CardContent>
        <Typography data-testid="card-title" onClick={openThirdPartyProperty} variant="subtitle2">
          {address_line_1 ? `${address_line_1} ${address_line_2 ? address_line_2 : ''}` : title}
        </Typography>

        <Typography data-testid="card-subheader" onClick={openThirdPartyProperty} variant="body2">
          {bedrooms === 0 && !bathrooms && 'Studio'}
          {bedrooms > 0 && maybePluralise(bedrooms, 'Bed')}
          {bathrooms > 0 && `${bedrooms > 0 ? ' |' : ''} ${maybePluralise(bathrooms, 'Bath')}`}
        </Typography>

        {monthly_rent > 0 && (
          <Typography data-testid="card-rent" onClick={openThirdPartyProperty} variant="h6">
            {`${rentWithCurrency ? rentWithCurrency : monthly_rent / 100}pcm`}
          </Typography>
        )}

        {info && (
          <div className={classes.msgStatusContainer} data-testid="msg-status">
            <MessageStatus isPending={!isSent(info)} isRead={info.read} />
          </div>
        )}
      </CardContent>
      {showThirdPartyDialog && (
        <Dialog
          data-testid="third-party-id-dialog"
          open={showThirdPartyDialog}
          onClose={() => setShowThirdPartyDialog(false)}
        >
          <Container className={classes.thirdPartyIdContainer}>
            <Typography variant="h6">Third-party ID: {third_party_property_id}</Typography>
          </Container>
        </Dialog>
      )}
    </Card>
  )
}

export const PropertyCard = memo(CardDetails)
