import moment from 'moment'
import { Note } from '../../../types/notes'

export const getUpdateNoteData = (notes: Note[], noteId: string, userId: string, newNoteText: string): Note[] => {
  const updateRequestTime = moment.utc().toISOString()

  const updatedNotes = notes.map((noteItem) => {
    if (noteItem.support_note_id === noteId) {
      return {
        ...noteItem,
        user_id: userId,
        note: newNoteText,
        updated_at: updateRequestTime
      }
    }
    return noteItem
  })

  return updatedNotes
}

export const getDeleteNoteData = (notes: Note[], noteId: string): Note[] => {
  return notes.filter((note) => note.support_note_id !== noteId)
}
