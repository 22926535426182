import { init } from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

const { NODE_ENV, REACT_APP_SENTRY_ENV, REACT_APP_SENTRY_DNS, REACT_APP_RELEASE } = process.env

const PROD = NODE_ENV === 'production'

const urlMapping: Record<string, string> = {
  '-develop': 'dev',
  '-staging': 'staging',
  '-production|support.perchpeek': 'production'
}

const [, environment = REACT_APP_SENTRY_ENV] =
  Object.entries(urlMapping).find(([key]) => new RegExp(key, 'g').test(window.location.hostname)) || []

const enabled = PROD && !!environment

init({
  // needs to be a production bundle and environment does exist
  enabled,

  // Set Sentry environment based on Netlify main site address
  // REACT_APP_SENTRY_ENV allows setting an environment when developing locally
  // REACT_APP_SENTRY_ENV should be set in .env.local or .env.production.local
  environment,

  // set in .env
  dsn: REACT_APP_SENTRY_DNS,

  // this app version, set by semantic-release in package.json
  release: REACT_APP_RELEASE,

  integrations: [
    // The BrowserTracing integration creates a new transaction for each page load and navigation event,
    // and creates a child span for every XMLHttpRequest or fetch request that occurs while those transactions are open.
    new BrowserTracing({
      // Outgoing requests to *.perchpeek.com will get the sentry-trace header attached
      tracingOrigins: ['*.perchpeek.com']
    })
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0
})
