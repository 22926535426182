import React, { ReactElement, useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Divider, Fab, Tooltip, Typography } from '@material-ui/core'
import { Edit, Launch } from '@material-ui/icons'
import { Loader, UpdateDocumentLinks } from '..'
import { getDocumentState } from '../../redux/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import { FETCH_DOCUMENTS_REQUEST } from '../../redux/constants'
import { documentFields } from '../../utils/documentFields'

const useStyles = makeStyles((theme) => ({
  documentsContainer: {
    height: '100%',
    position: 'relative',
    overflow: 'auto',
    padding: ' 0 1rem',
    [theme.breakpoints.down('sm')]: {
      padding: ' 0 3rem'
    }
  },
  editDocumentButton: {
    position: 'fixed',
    bottom: 25,
    right: 25
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.5rem',
    cursor: 'pointer',
    width: 'fit-content',
    color: 'blue'
  }
}))

export const Documents = (): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const { fetchDocumentsRequested, storedUserId, documents } = useSelector(getDocumentState)
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    if (customer && storedUserId !== customer.linked_user_id) {
      dispatch({ type: FETCH_DOCUMENTS_REQUEST, payload: { userId: customer.linked_user_id } })
    }
  }, [])

  const openDocumentLink = (url: string) => {
    if (url.startsWith('http')) {
      window.open(url, '_blank')
    } else {
      window.open(`https://${url}`, '_blank')
    }
  }

  if (fetchDocumentsRequested) return <Loader />

  return (
    <div className={classes.documentsContainer} data-testid="documents-container">
      {documentFields.map((field) => (
        <div key={field.id} style={{ marginTop: '0.9rem' }}>
          <Typography data-testid={field.title} variant="h6">
            {field.title}
          </Typography>
          {documents && documents[field.id] && documents[field.id].length > 0 ? (
            <div className={classes.linkContainer} onClick={() => openDocumentLink(documents[field.id])}>
              <Tooltip data-testid={`${field.id}-${documents[field.id]}`} title={documents[field.id]} placement="right">
                <Typography data-testid={field.id} variant="subtitle1" style={{ fontSize: '1.05rem' }}>
                  Link
                  <Launch style={{ fontSize: '1rem' }} />
                </Typography>
              </Tooltip>
            </div>
          ) : (
            <Typography data-testid={`${field.id}-not-available`} variant="subtitle1">
              Not Available
            </Typography>
          )}
          <Divider />
        </div>
      ))}
      <UpdateDocumentLinks openModal={openModal} setOpenModal={() => setOpenModal(false)} />
      <Fab
        data-testid="edit-button"
        color="primary"
        aria-label="edit"
        className={classes.editDocumentButton}
        onClick={() => setOpenModal(true)}
      >
        <Edit />
      </Fab>
    </div>
  )
}
