import { ChangeEvent, FocusEventHandler, Fragment, MouseEventHandler, ReactElement, useEffect, useReducer } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Box, TextField, Paper, Switch } from '@material-ui/core'
import { SingleBedOutlined, BathtubOutlined, SvgIconComponent } from '@material-ui/icons'

import { urlPattern } from '../../utils/string'
import { AddressDetails, Image, PropertyUploadType } from '../../types/properties'
import { rooms } from '../../utils/constants'
import { Autocomplete } from '@material-ui/lab'
import { data, code, CurrencyCodeRecord } from 'currency-codes'
import { Address, ImagesField } from '..'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../hooks/featureFlags'

const MAX_IMAGE_SIZE = 4718592 // 4.5mb
const MAX_IMAGE_SIZE_TEXT = `The files aggregate size exceeds the maximum size allowed of ${
  MAX_IMAGE_SIZE / 1024 / 1024
}mb`

const roomTypes = [
  ['bedrooms', SingleBedOutlined],
  ['bathrooms', BathtubOutlined]
] as ['bedrooms' | 'bathrooms', SvgIconComponent][]

const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none'
  },
  card: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(3),
    width: '100%',
    backgroundColor: '#F0F0F0',
    display: 'grid',
    alignItems: 'top',
    gap: theme.spacing(3)
  },
  roomList: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(2)
  },
  typeTitle: {
    gridColumn: '1 / 3'
  },
  icon: {
    fontSize: '28px',
    margin: 6
  },
  autoComplete: {
    width: 400,
    marginRight: theme.spacing(1)
  },
  offListContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  }
}))

type PropertyUploadKey = keyof PropertyUploadType

type State = {
  activeProperty: PropertyUploadType
  errors: Errors
}

export type Errors = {
  [K in keyof Omit<PropertyUploadType, 'id'>]: boolean
}

enum ActionType {
  UPDATE = 'update_property',
  TOGGLE_OFF_LISTED = 'toggle_off_listed_property'
}

type Action = {
  type: ActionType
  attribute: PropertyUploadKey
  value: PropertyUploadType[PropertyUploadKey]
}

const computeSize = (images: Image[]) => images.reduce((size, file) => size + (file.size ?? 0), 0)

const isInvalidString = (value: string) => !value || value.length === 0

const computeError = (
  key: PropertyUploadKey,
  value: PropertyUploadType[PropertyUploadKey],
  isOffListed: boolean
): boolean => {
  if (key !== 'title' && isOffListed) {
    return false
  }

  if (key === 'title' && isOffListed) {
    return isInvalidString(value as string)
  }

  if (key === 'property_url') {
    return !RegExp(urlPattern).test(value as string)
  } else if (key === 'location_data' || key === 'total_cost' || key === 'currency_code') {
    return !value
  } else if (key === 'description' || key === 'bedrooms' || key === 'bathrooms') {
    return isInvalidString(value as string)
  } else if (key === 'images') {
    const images = value as PropertyUploadType['images']
    return !images || images.length === 0 || computeSize(images) > MAX_IMAGE_SIZE
  }

  return false
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UPDATE: {
      const addressLine1 = (action.value as PropertyUploadType['location_data'])?.address_line_1
      const activeProperty = {
        ...state.activeProperty,
        [action.attribute]: action.value,
        // mirror "description" to "title" because the backend requires it
        // unless off-listed property - title is required and no need to mirror
        ...(action.attribute === 'location_data' &&
          addressLine1 &&
          !state.activeProperty.is_off_listed && {
            title: addressLine1
          })
      }

      const errors = {
        ...state.errors,
        [action.attribute]: computeError(
          action.attribute,
          activeProperty[action.attribute],
          activeProperty.is_off_listed
        )
      }

      return {
        ...state,
        activeProperty,
        errors
      }
    }

    case ActionType.TOGGLE_OFF_LISTED: {
      const resetProperty = {
        id: state.activeProperty.id,
        images: [],
        is_off_listed: action.value as boolean
      }

      const resetErrors = {
        property_url: false,
        location_data: false,
        description: false,
        bedrooms: false,
        bathrooms: false,
        images: false,
        total_cost: false,
        is_off_listed: false,
        title: false,
        currency_code: false
      }

      if (action.value) {
        return {
          activeProperty: {
            ...resetProperty,
            currency_code: null,
            bedrooms: null,
            bathrooms: null
          },
          errors: { ...resetErrors }
        }
      } else {
        return {
          activeProperty: {
            ...resetProperty,
            currency_code: 'EUR',
            bedrooms: rooms.bedrooms[0],
            bathrooms: rooms.bedrooms[0]
          },
          errors: { ...resetErrors }
        }
      }
    }
    default: {
      return state
    }
  }
}

const initState = (activeProperty: PropertyUploadType): State => ({
  activeProperty,
  errors: {
    property_url: false,
    location_data: false,
    description: false,
    bedrooms: false,
    bathrooms: false,
    images: false,
    total_cost: false,
    is_off_listed: false,
    title: false,
    currency_code: false
  }
})

const formatCurrencyCode = ({ code, currency }: CurrencyCodeRecord) => `${code} - ${currency}`

const keys: PropertyUploadKey[] = [
  'property_url',
  'location_data',
  'description',
  'bedrooms',
  'bathrooms',
  'total_cost',
  'images',
  'title',
  'currency_code'
]

export type PropertyFormProps = {
  property: PropertyUploadType
  country?: string
  disabled?: boolean
  onChange: (property: PropertyUploadType, hasErrors: boolean) => void
  editMode?: boolean
}

export function PropertyForm({
  property,
  country,
  disabled = false,
  onChange,
  editMode = false
}: PropertyFormProps): ReactElement {
  const classes = useStyles()

  const [{ activeProperty, errors }, dispatch] = useReducer(reducer, initState(property))
  const canCreateOffListedProperty = useFeatureToggle(FeatureFlag.CREATE_OFF_LISTED_PROPERTY)
  const isOffListed = activeProperty.is_off_listed

  const hasErrors = !(
    Object.values(errors).every((value) => !value) &&
    keys.every((key) => !computeError(key, activeProperty[key], isOffListed))
  )

  const handleOnChangeInput =
    (attribute: PropertyUploadKey): FocusEventHandler<HTMLInputElement> =>
    (event) => {
      const { currentTarget: input } = event
      const value = attribute === 'total_cost' ? input.valueAsNumber : input.value

      dispatch({ type: ActionType.UPDATE, attribute, value })
    }

  const handleOnChangeOffListProperty = (event: ChangeEvent<HTMLInputElement>): void => {
    dispatch({ type: ActionType.TOGGLE_OFF_LISTED, attribute: 'is_off_listed', value: event.target.checked })
  }

  const handleOnChangeCurrencyCode = (value = isOffListed ? null : 'EUR'): void => {
    dispatch({ type: ActionType.UPDATE, attribute: 'currency_code', value })
  }

  const handleOnChangeAddress = (value?: AddressDetails) => {
    dispatch({ type: ActionType.UPDATE, attribute: 'location_data', value })
  }

  const handleOnChangeImages = (value: PropertyUploadType['images']) => {
    dispatch({ type: ActionType.UPDATE, attribute: 'images', value })
  }

  const handleOnClickType =
    (attribute: PropertyUploadKey, value: string): MouseEventHandler<HTMLButtonElement> =>
    () => {
      dispatch({ type: ActionType.UPDATE, attribute, value })
    }

  useEffect(() => {
    onChange(activeProperty, hasErrors)
  }, [activeProperty])

  return (
    <>
      {canCreateOffListedProperty && (
        <div className={classes.offListContainer}>
          <Typography variant="subtitle2" id="dialog-title">
            Off listed
          </Typography>
          <Switch
            disabled={disabled || editMode}
            checked={isOffListed}
            onChange={handleOnChangeOffListProperty}
            name="setOffListed"
            inputProps={{ 'aria-label': 'set off listed' }}
          />
        </div>
      )}

      {isOffListed ? (
        <div>
          <Typography variant="subtitle2" id="title">
            Title<sup>*</sup>
          </Typography>
          <TextField
            key="title"
            name="title"
            variant="outlined"
            defaultValue={activeProperty.title}
            fullWidth
            error={errors.title}
            helperText={errors.title ? 'Please enter a title' : ' '}
            disabled={disabled}
            inputProps={{
              'aria-labelledby': 'title'
            }}
            onChange={handleOnChangeInput('title')}
          />
        </div>
      ) : (
        <div>
          <Typography variant="subtitle2" id="property_url">
            URL<sup>*</sup>
          </Typography>
          <TextField
            key="property_url"
            name="property_url"
            variant="outlined"
            defaultValue={activeProperty.property_url}
            fullWidth
            error={errors.property_url}
            helperText={errors.property_url ? 'Please enter a valid URL' : ' '}
            disabled={disabled}
            inputProps={{
              'aria-labelledby': 'property_url'
            }}
            onChange={handleOnChangeInput('property_url')}
          />
        </div>
      )}
      <div>
        <Typography variant="subtitle2" id="location_data">
          Address{!isOffListed && <sup>*</sup>}
        </Typography>
        <Address
          textFieldProps={{
            name: 'location_data',
            variant: 'outlined',
            defaultValue: activeProperty.location_data?.address_line_1,
            fullWidth: true,
            error: errors.location_data,
            helperText: errors.location_data ? 'Please enter a valid address' : ' ',
            disabled,
            inputProps: {
              'aria-labelledby': 'location_data'
            }
          }}
          country={country}
          onChange={handleOnChangeAddress}
        />
      </div>

      <div>
        <Typography variant="subtitle2" id="description">
          Description{!isOffListed && <sup>*</sup>}
        </Typography>
        <TextField
          multiline
          minRows={4}
          name="description"
          variant="outlined"
          defaultValue={activeProperty.description}
          fullWidth
          error={errors.description}
          helperText={errors.description ? 'Please enter a description' : ' '}
          disabled={disabled}
          inputProps={{
            'aria-labelledby': 'description'
          }}
          onChange={handleOnChangeInput('description')}
        />
      </div>
      <div>
        <Typography variant="subtitle2" id="images">
          Images{!isOffListed && <sup>*</sup>}
        </Typography>
        <ImagesField
          label="images"
          error={errors.images}
          helperText={MAX_IMAGE_SIZE_TEXT}
          images={activeProperty.images}
          disabled={disabled}
          onChange={handleOnChangeImages}
        />
      </div>
      <div>
        <Typography variant="subtitle2">Type{!isOffListed && <sup>*</sup>}</Typography>
        <Paper elevation={0} className={classes.card}>
          <Typography variant="body2" className={classes.typeTitle}>
            Bedrooms & Bathrooms
          </Typography>
          {roomTypes.map(([type, Icon]) => (
            <Fragment key={`type-${type}`}>
              <Icon className={classes.icon} />
              <Box className={classes.roomList} role="listbox" aria-label={type}>
                {rooms[type].map((room) => {
                  const isPressed = activeProperty[type] === room

                  return (
                    <Button
                      aria-label={`${type} ${room}`}
                      aria-pressed={isPressed}
                      key={`${type} ${room}`}
                      variant="contained"
                      size="medium"
                      className={classes.button}
                      color={isPressed ? 'secondary' : 'default'}
                      disabled={disabled}
                      onClick={handleOnClickType(type, room)}
                    >
                      {room}
                    </Button>
                  )
                })}
              </Box>
            </Fragment>
          ))}
        </Paper>
      </div>
      <div>
        <Typography variant="subtitle2">Cost{!isOffListed && <sup>*</sup>}</Typography>
        <div style={{ display: 'flex' }}>
          <Autocomplete
            aria-label="Currency"
            className={classes.autoComplete}
            options={data}
            getOptionLabel={formatCurrencyCode}
            onChange={(_, val) => handleOnChangeCurrencyCode(val?.code)}
            renderOption={formatCurrencyCode}
            value={activeProperty.currency_code ? code(activeProperty.currency_code) : null}
            disabled={disabled}
            renderInput={(params) => (
              <TextField
                {...params}
                name="currency_code"
                variant="outlined"
                inputProps={{
                  ...params.inputProps,
                  'aria-label': 'Currency',
                  autoComplete: 'new-password' // disable autocomplete and autofill
                }}
                error={errors.currency_code}
                helperText={errors.currency_code ? 'Please enter a currency' : ' '}
              />
            )}
          />
          <TextField
            name="cost"
            variant="outlined"
            fullWidth
            error={errors.total_cost}
            helperText={errors.total_cost ? 'Please enter a cost' : ' '}
            defaultValue={activeProperty.total_cost}
            disabled={disabled}
            InputProps={{
              type: 'number'
            }}
            inputProps={{
              'aria-label': 'Cost',
              min: 0
            }}
            onChange={handleOnChangeInput('total_cost')}
          />
        </div>
      </div>
    </>
  )
}
