import { ChangeEvent, ReactElement, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormControl, FormControlLabel, makeStyles, Paper, Radio, RadioGroup, Typography } from '@material-ui/core'

import { getBookingState, getThreadState } from '../../../redux/selectors'
import { UserInfoDetails } from '../..'
import { UserInfoStatus, UserInfoCards } from '../..'
import {
  GET_RECOMMENDATIONS_PROPERTIES_REQUEST,
  RESET_STATUS,
  REMOVE_PROPERTY_FROM_RECOMMENDATIONS,
  RESET_BOOKING_STATUS,
  SHOW_FEEDBACK
} from '../../../redux/constants'
import { Loader } from '../..'
import { useInfoByMessageId } from '../../../hooks/useInfoByMessageId'
import moment from 'moment'
import { makeFirstLetterCapital } from '../../../utils/string'
import { RecommendationsProperty } from '../../../types/thread'
import { PropertyUnlistingStatus } from '../../../types/properties'

type TabsProps = {
  index: number
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px 40px 20px 40px'
  },
  filterContainer: {
    padding: '24px 0 0 16px'
  },
  cardContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
    gridGap: '40px',
    padding: '24px 16px'
  },
  individualCard: {
    width: 'fit-content'
  },
  statusDateTime: {
    fontSize: '0.6em',
    textAlign: 'right'
  },
  displayFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  height: {
    height: '100%'
  }
}))

const NoResults = () => {
  const classes = useStyles()
  return (
    <div className={`${classes.displayFlex} ${classes.height}`}>
      <h2>Nothing to show here</h2>
    </div>
  )
}

export function UserInfoTabs({ index }: TabsProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const userId = customer?.linked_user_id
  const {
    user: { recommendationsProperties }
  } = useSelector(getThreadState)

  const {
    properties: recommendationPropertiesList,
    loading: recommendationPropertiesLoading,
    error: recommendationPropertiesError,
    status: recommendationPropertiesStatus
  } = recommendationsProperties

  const { status: BookingStatus } = useSelector(getBookingState)

  const [idToRemove, setIdToRemove] = useState<string>('')
  const [recommendationsFilterValue, setRecommendationsFilterValue] = useState('All')
  const [recommendationsList, setRecommendationsList] = useState<RecommendationsProperty[]>([])

  const errorOrLoadingState = () => recommendationPropertiesLoading || recommendationPropertiesError

  const getProperties = () => {
    dispatch({ type: GET_RECOMMENDATIONS_PROPERTIES_REQUEST, payload: { userId } })
  }

  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRecommendationsFilterValue(event.target.value)
    if (event.target.value === 'All') {
      setRecommendationsList(recommendationPropertiesList)
    } else {
      setRecommendationsList(
        recommendationPropertiesList.filter((properties) => properties.status === event.target.value.toLowerCase())
      )
    }
  }

  useEffect(() => {
    if (index === 2) {
      getProperties()
    }
    dispatch({ type: RESET_STATUS })
  }, [index])

  useEffect(() => {
    if (recommendationPropertiesStatus === PropertyUnlistingStatus.PROPERTY_UNLISTING_SUCCESS) {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: 'Done', severity: 'success' } })
      setTimeout(() => {
        dispatch({ type: RESET_STATUS })
      }, 2000)
    } else if (recommendationPropertiesStatus === PropertyUnlistingStatus.PROPERTY_UNLISTING_FAILED) {
      dispatch({ type: SHOW_FEEDBACK, payload: { title: 'Failed! Try Again.', severity: 'error' } })
      setTimeout(() => {
        dispatch({ type: RESET_STATUS })
      }, 2000)
    }
  }, [recommendationPropertiesStatus])

  useEffect(() => {
    if (idToRemove.length > 5 && (BookingStatus === 'Booking Converted' || BookingStatus === 'Booking added')) {
      if (index === 2) {
        dispatch({ type: REMOVE_PROPERTY_FROM_RECOMMENDATIONS, payload: { id: idToRemove } })
      }
      dispatch({ type: RESET_BOOKING_STATUS })
      setIdToRemove('')
    }
  }, [idToRemove, BookingStatus])

  useEffect(() => {
    setRecommendationsList(recommendationPropertiesList)
  }, [recommendationPropertiesList])

  if (index === 2 && errorOrLoadingState()) {
    return (
      <Paper className={`${classes.height} ${classes.displayFlex}`}>
        {recommendationPropertiesLoading ? (
          <Loader ariaLabel="Loading properties" />
        ) : (
          <Typography data-testid="failed-to-fetch" variant="body2" color="textSecondary">
            Failed to fetch properties
          </Typography>
        )}
      </Paper>
    )
  }

  if (index === 2 && recommendationPropertiesList.length === 0) {
    return <NoResults />
  }

  return (
    <>
      {index === 0 && <UserInfoDetails />}
      {index === 1 && <UserInfoStatus />}
      {index === 2 && (
        <>
          <div className={classes.filterContainer}>
            {['All', 'Liked', 'Disliked'].map((type: string) => (
              <FormControl component="fieldset" key={type}>
                <RadioGroup
                  aria-label="Filters"
                  name="filters"
                  value={recommendationsFilterValue}
                  onChange={handleFilterChange}
                >
                  <FormControlLabel
                    control={<Radio data-testid={`${type}-radio-button`} />}
                    value={type}
                    label={type}
                  />
                </RadioGroup>
              </FormControl>
            ))}
          </div>
          {recommendationsList.length > 0 ? (
            <div className={classes.cardContainer} data-testid={'recommendations-properties-cards-container'}>
              {recommendationsList.map(({ property, updated_at, status }) => {
                const statusDateTime = `${makeFirstLetterCapital(status)}, ${moment
                  .utc(updated_at)
                  .local()
                  .format('dddd, Do of MMMM, H:mm')}`

                return (
                  <div key={property.property_id} className={classes.individualCard}>
                    <UserInfoCards property={property} details={{ index, customer }} setIdToRemove={setIdToRemove} />
                    <Typography variant="subtitle2" color="textSecondary" className={classes.statusDateTime}>
                      {statusDateTime}
                    </Typography>
                  </div>
                )
              })}
            </div>
          ) : (
            <NoResults />
          )}
        </>
      )}
    </>
  )
}
