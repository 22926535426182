import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    marginLeft: '0.5rem'
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    backgroundColor: theme.palette.secondary.main
  }
}))

type Props = {
  imageUrl: string | undefined
  name: string
}

export const ChatAvatar = ({ imageUrl, name }: Props): JSX.Element => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Avatar alt={name} src={imageUrl} data-testid="chat-avatar" className={classes.avatar} />
      <Typography className={classes.name} variant="h6">
        {name}
      </Typography>
    </div>
  )
}
