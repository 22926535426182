import { ReactElement, SetStateAction, Dispatch, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Typography, Chip } from '@material-ui/core'
import { DateRange, Edit, Error, Schedule, Cancel } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

import { webUrl } from '../../utils/env'
import { timezones } from '../../utils/timezones'
import { SingleBookingDetailType, BookingModalDetailsType, BookingStatus } from '../../types/bookings'
import { CANCEL_BOOKINGS_REQUEST, EDIT_BOOKINGS_REQUEST } from '../../redux/constants'
import { ConfirmationDialog, ConfirmationDialogType } from '..'
import { useInfoByMessageId } from '../../hooks/useInfoByMessageId'
import restingPerchie from '../../assets/resting-perchie.png'
import { bookingTypes } from '../../utils/constants'
import { getThreadState } from '../../redux/selectors'

type BookingCardProps = {
  booking: SingleBookingDetailType
  setOpen: () => void
  setBookingModalDetails: Dispatch<SetStateAction<BookingModalDetailsType>>
}

const useStyles = makeStyles(() => ({
  individualBookingContainer: {
    minHeight: 180,
    width: '95%',
    display: 'flex',
    margin: '15px auto',
    borderBottom: '2px solid lightgrey',
    borderRadius: 4,
    padding: '10px 0 10px 10px',
    boxSizing: 'border-box'
  },
  rightContainer: {
    marginLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  },
  propertyImg: {
    maxHeight: 192,
    minWidth: 192,
    maxWidth: 192
  },
  timingContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 10
  },
  timing: {
    marginLeft: 5,
    fontWeight: 500
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  buttonWidth: {
    minWidth: 45
  },
  viewButton: {
    paddingLeft: 0
  },
  cancelledContainer: {
    width: '95%',
    backgroundColor: '#FCD5D6',
    borderLeft: '4px solid #F65A63',
    borderRadius: 3,
    margin: '0 0 10px 0',
    padding: 10,
    display: 'flex'
  }
}))

export const BookingCard = ({ booking }: BookingCardProps): ReactElement => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { customer } = useInfoByMessageId()
  const history = useHistory()
  const {
    user: {
      info: { linked_user_id }
    }
  } = useSelector(getThreadState)

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<ConfirmationDialogType>({
    status: false,
    onConfirm: () => null,
    message: ''
  })

  const bookingType = bookingTypes.find((bookingType) => bookingType.value === booking.type)

  const linkToProperty = () => {
    if (booking.property.property_id) window.open(`${webUrl}/properties/${booking.property.property_id}`, '_blank')
  }

  const openUrl = () => {
    if (booking.url?.startsWith('http')) {
      window.open(booking.url, '_blank')
    } else {
      window.open(`https://${booking.url}`, '_blank')
    }
  }

  const getTime = () => {
    if (!booking.booking_time) {
      return ''
    }
    return booking.booking_time.includes(':')
      ? booking.booking_time
      : `${booking.booking_time.substring(0, 2)}:${booking.booking_time.substring(2)}`
  }

  const bookingDetails = () => {
    const bookingTime = getTime()

    const existingTimezone = timezones.find((zone) => zone.selectValue === booking.timezone)

    return {
      address: booking.address || booking.property_title || '',
      agent_name: booking.agent_name && booking.agent_name.length > 0 ? booking.agent_name : undefined,
      booking_id: booking.booking_id || '',
      contact_number: booking.contact_number && booking.contact_number.length > 0 ? booking.contact_number : undefined,
      dateTime: `${booking.booking_date} ${bookingTime}`,
      note: booking.note || '',
      property_id: booking.property.property_id || '',
      tz: existingTimezone || { selectValue: 'Europe/London', displayValue: '(GMT+01:00) London' },
      title: booking.title && booking.title.length > 0 ? booking.title : undefined,
      type: booking.type || 'property_visits',
      url: booking.url && booking.url.length > 0 ? booking.url : undefined
    }
  }

  const editBooking = () => {
    history.push(`/manage?v2Url=/appointments/${linked_user_id}`)
  }

  const confirmationDialogHandle = (type: string) => {
    if (type === 'cancel') {
      setOpenConfirmationDialog({ status: true, onConfirm: cancelBooking, message: type })
    } else {
      setOpenConfirmationDialog({ status: true, onConfirm: reinstateBooking, message: type })
    }
  }

  const cancelBooking = () => {
    if (customer) {
      const allDetails = bookingDetails()
      const newBookingDetails = { ...allDetails, tz: allDetails.tz.selectValue, status: BookingStatus.CANCELLED }
      dispatch({
        type: CANCEL_BOOKINGS_REQUEST,
        payload: { userId: customer.linked_user_id, bookingId: booking.booking_id, newBookingDetails }
      })
    }
  }

  const reinstateBooking = () => {
    if (!customer) return
    const allDetails = bookingDetails()
    const newBookingDetails = { ...allDetails, tz: allDetails.tz.selectValue, status: BookingStatus.APPROVED }
    dispatch({
      type: EDIT_BOOKINGS_REQUEST,
      payload: { userId: customer.linked_user_id, bookingId: booking.booking_id, newBookingDetails }
    })
  }

  return (
    <>
      <div className={classes.individualBookingContainer}>
        <img
          data-testid="booking-thumbnail"
          src={booking.property_featured_image || bookingType?.img || restingPerchie}
          alt="property img"
          onClick={linkToProperty}
          style={{ cursor: booking.property?.property_id ? 'pointer' : 'default' }}
          className={classes.propertyImg}
        />
        <div className={classes.rightContainer}>
          <div className={classes.timingContainer}>
            <Chip label={bookingType?.title || 'Property Visits'} color="primary" style={{ width: 'fit-content' }} />
            <div className={classes.buttonContainer}>
              <Button
                size="small"
                onClick={editBooking}
                classes={{ root: classes.buttonWidth }}
                data-testid="edit-booking-btn"
              >
                <Edit color="secondary" />
              </Button>
              <Button
                disabled={booking.status === BookingStatus.CANCELLED}
                size="small"
                onClick={() => confirmationDialogHandle('cancel')}
                classes={{ root: classes.buttonWidth }}
                data-testid="cancel-booking-btn"
              >
                <Cancel style={{ color: 'grey' }} />
              </Button>
            </div>
          </div>
          {booking.status === BookingStatus.CANCELLED && (
            <div className={classes.cancelledContainer}>
              <div style={{ padding: '10px 10px 0 0' }}>
                <Error color="secondary" />
              </div>
              <div>
                <Typography variant="body2">We&apos;re sorry, but this appointment was cancelled.</Typography>
                <Button
                  size="small"
                  variant="outlined"
                  style={{ borderColor: 'red', margin: '10px 0' }}
                  onClick={() => confirmationDialogHandle('reinstate')}
                >
                  REINSTATE APPOINTMENT
                </Button>
              </div>
            </div>
          )}
          <div className={classes.timingContainer}>
            <div style={{ display: 'flex' }}>
              <DateRange />
              <Typography variant="subtitle1" className={classes.timing}>
                {booking.booking_date}
              </Typography>
              <Schedule style={{ marginLeft: 15 }} />
              <Typography variant="subtitle1" className={classes.timing}>
                {getTime()}
              </Typography>
            </div>
          </div>
          <Typography variant="body2" style={{ wordBreak: 'break-word', marginBottom: 10 }}>
            <b>Address:</b> {booking.address || booking.property_title}
          </Typography>
          <div>
            <Button
              data-testid="gmap-button"
              size="small"
              variant="outlined"
              disabled={!booking.url ? true : false}
              onClick={openUrl}
            >
              {booking.url && booking.url.includes('google') ? 'View on G-Map' : 'View URL'}
            </Button>
          </div>
        </div>
      </div>
      <ConfirmationDialog
        open={openConfirmationDialog.status}
        setOpen={() => setOpenConfirmationDialog({ status: false, onConfirm: () => null, message: '' })}
        message={`Are you sure you want to ${openConfirmationDialog.message} this?`}
        onConfirm={openConfirmationDialog.onConfirm}
        trackingContext={`booking-${openConfirmationDialog.message}`}
      />
    </>
  )
}
