import { ReactElement, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Dialog, AppBar, Tabs, Tab, makeStyles, Toolbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import { RESET_STATUS } from '../../../redux/constants'
import { UserInfoTabs, Transition } from '../..'
import { useFeatureToggle } from '@flopflip/react-broadcast'
import { FeatureFlag } from '../../../hooks/featureFlags'

type InfoModalProps = {
  open: boolean
  onClose: () => void
}

function a11yProps(index: number): Record<string, string> {
  return {
    id: `user-info-modal-tab-${index}`,
    'aria-controls': `tab-${index}`
  }
}

const useStyles = makeStyles(() => ({
  tabsContainer: {
    width: '100%'
  },
  tabsSpacing: {
    justifyContent: 'space-evenly'
  }
}))

export function UserInfoModal({ open, onClose }: InfoModalProps): ReactElement {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [value, setValue] = useState<number>(0)
  const isRecommendationsTabEnabled = useFeatureToggle(FeatureFlag.RECOMMENDATIONS_TAB)

  const closeDialog = () => {
    onClose()
    setValue(0)
    dispatch({ type: RESET_STATUS })
  }

  return (
    <Dialog
      data-testid="user-info-modal-dialog"
      fullScreen
      TransitionComponent={Transition}
      open={open}
      onClose={closeDialog}
      aria-labelledby="form-dialog-title"
    >
      <AppBar position="sticky">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={closeDialog}
            aria-label="close"
            data-testid="user-info-modal-close-button"
          >
            <CloseIcon />
          </IconButton>
          <Tabs
            value={value}
            onChange={(e, tab) => setValue(tab)}
            aria-label="Info Tabs"
            variant="scrollable"
            classes={{
              root: classes.tabsContainer,
              flexContainer: classes.tabsSpacing
            }}
          >
            <Tab label="User Info" {...a11yProps(0)} />
            <Tab label="Stats" {...a11yProps(1)} />
            {isRecommendationsTabEnabled && <Tab label="Recommendations" {...a11yProps(2)} />}
          </Tabs>
        </Toolbar>
      </AppBar>
      <UserInfoTabs index={value} />
    </Dialog>
  )
}
